import { FC, useContext, useState } from "react"
import { TaskGroupDetails } from "../Projects/TaskGroupDetails"
import { SingleDrawerContext } from "./Drawer"
import { DrawerBody } from "./DrawerBody"
import { DrawerHeader } from "./DrawerHeader"

type Props = {
  taskGroupId: string
  projectId: string
  onSuccess?: () => void
}

export const TaskGroupDrawer: FC<Props> = ({ taskGroupId, projectId, onSuccess = () => {} }) => {
  const { handleClose } = useContext(SingleDrawerContext)
  const [currentTab, setCurrentTab] = useState("details")

  return (
    <>
      <DrawerHeader handleClose={handleClose} href={`/projects/${projectId}/group/${taskGroupId}/details`} />

      <DrawerBody>
        {
          <TaskGroupDetails
            taskGroupId={taskGroupId}
            projectId={projectId}
            currentTab={currentTab}
            withTitle
            setCurrentTab={setCurrentTab}
            onSuccess={onSuccess}
          />
        }
      </DrawerBody>
    </>
  )
}

import { differenceInSeconds, endOfDay, isToday, startOfDay } from "date-fns"
import { FC } from "react"
import { TimeEntry, User } from "../../../../graphql/generated/client-types-and-hooks"
import { classNames } from "../../../../helpers/classNames"
import { getTimeEntryDateBookends } from "../../../../helpers/getTimeEntryDateBookends"
import { secondsToFormattedString } from "../../../../helpers/time-utility"
import { PickPlus } from "../../../../types/helpers"
import { DateTimeOrMaybeJustTimePicker } from "../../../Formik/DateTimeOrMaybeJustTimePicker"
import { ProjectAndTasksMultiSelects } from "../../../Formik/MultiSelect/implementations/ProjectAndTaskMultiSelects"
import { TimeEntryActivityBar } from "../TimeEntryActivityBar"

export type EditableTimeEntryExpectation = PickPlus<
  TimeEntry,
  "id" | "durationInSeconds" | "endAt" | "startAt" | "projectId" | "taskId"
> & {
  project: PickPlus<TimeEntry["project"], "name">
  task: PickPlus<TimeEntry["task"], "name">
}

export type EditableTimeEntryProps = {
  sameDay: boolean
  timeEntries: EditableTimeEntryExpectation[]
  timeEntry: EditableTimeEntryExpectation
  user: PickPlus<User, "id" | "taskId">
}

export const EditableTimeEntry: FC<EditableTimeEntryProps> = ({ timeEntries, timeEntry, sameDay: isSameDay, user }) => {
  const now = new Date()

  const [startOfWindow, endOfWindow] = getTimeEntryDateBookends(timeEntries)
  const isItToday = isToday(timeEntry.startAt)
  const lastTimeSelectable = isItToday ? now : endOfDay(endOfWindow)
  // window Start of Day
  const windowSOD = startOfDay(startOfWindow)

  if (!timeEntry) return <div>Loading....</div>

  // TODO: Model validation of the two times into a callable validation function. That would let us run the s
  //ame validation configurations on submit and on edit with the complete context
  // and provide a single helpful message based on it.

  // TODO: Constrain times to ensure no double booking with other time entries.
  return (
    <div className="flex flex-col gap-2 gap-y-6 md:gap-y-4">
      <TimeEntryActivityBar
        minDate={startOfWindow}
        maxDate={endOfWindow}
        selectedTimeEntryIds={[timeEntry.id]}
        timeEntries={timeEntries}
        className={"mb-4"}
        withClickBehavior={true}
      />
      <ProjectAndTasksMultiSelects formGroupId={timeEntry.id} users={[user]} includeCurrentAssignment={true} />
      <div className="flex flex-col gap-6 md:gap-4">
        <DateTimeOrMaybeJustTimePicker
          disableFuture={true}
          label="from"
          maxDateTime={timeEntry.endAt ? timeEntry.endAt : endOfWindow}
          minDateTime={windowSOD}
          name={`${timeEntry.id}.startAt`}
          required={true}
          skipDisabled={true}
          timeSteps={{ minutes: 15 }}
          timeOnly={isSameDay}
        />
        <DateTimeOrMaybeJustTimePicker
          disableFuture={isItToday}
          timeSteps={{ minutes: 15 }}
          minDateTime={timeEntry.startAt}
          maxDateTime={isItToday ? lastTimeSelectable : undefined}
          maxDate={isItToday ? undefined : lastTimeSelectable}
          timeOnly={isSameDay}
          required={!!timeEntry.endAt}
          label="to"
          name={`${timeEntry.id}.endAt`}
        />
      </div>
      <div
        className={classNames("flex whitespace-nowrap text-sm", !timeEntry.endAt ? "text-blue-600" : "text-gray-400")}
      >
        Task total: {secondsToFormattedString(differenceInSeconds(timeEntry.startAt, timeEntry.endAt || now))}
      </div>
    </div>
  )
}

import { Box, Button, Chip, Typography } from "@mui/material"
import { DataGrid, GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid"
import { FC, useContext, useMemo, useState } from "react"
import { BiDotsHorizontalRounded, BiX } from "react-icons/bi"
import {
  Task,
  UserAssignment,
  useBulkUpdateUserAssignmentsMutation,
  useDeleteUserAssignmentMutation,
} from "../graphql/generated/client-types-and-hooks"
import { classNames } from "../helpers/classNames"
import { getFullName } from "../helpers/getFullName"
import { sortBy } from "../helpers/sortBy"
import { ModalProps, useModalProps } from "../hooks/useModalProps"
import { DrawerContext } from "../providers/DrawerProvider"
import { PermissionsContext } from "../providers/PermissionsProvider/PermissionsProvider"
import { PickPlus } from "../types/helpers"
import { ChipContainer } from "./Chips/ChipContainer"
import { CreateOrUpdateUserAssignmentForm, UserAssignmentUpdateCandidate } from "./CreateOrUpdateUserAssignmentForm"
import { H5 } from "./Elements"
import { MuiModal } from "./Modals/MuiModal"
import { errorSnack, successSnack } from "./Notistack/ThemedSnackbars"
import { TaskDrawer } from "./Partials/Drawer/TaskDrawer"
import { ProjectBadge } from "./ProjectBadge"
import { QuickMenu } from "./QuickMenu"
import { TableAddButton } from "./TableAddButton"
import { UserBadge } from "./UserBadge"

type AssignmentExpectation = PickPlus<
  UserAssignment,
  "id" | "projectId" | "taskId" | "userId" | "isCurrentAssignment"
> & {
  project?: PickPlus<UserAssignment["project"], "id" | "name">
  task?: PickPlus<Task, "id" | "name"> | null
  user?: PickPlus<UserAssignment["user"], "id" | "firstName" | "lastName" | "jobTitle" | "isClockedIn">
}

export const UserAssignmentsTable = ({
  assignments,
  preassignments,
  refetch,
  showCurrentAssignments,
  title,
}: {
  assignments: AssignmentExpectation[]
  preassignments: {
    userId?: string
    taskId?: string
    projectId?: string
  }
  refetch?: () => void
  showCurrentAssignments?: boolean
  title?: string
}) => {
  const [editTarget, setEditTarget] = useState<UserAssignmentUpdateCandidate[] | null>(null)

  const { hasGrantedPermission } = useContext(PermissionsContext)

  const canUpdatePermissionsInBulk = hasGrantedPermission("user:assign:*")

  const { push: pushDrawer } = useContext(DrawerContext)

  const [, deleteUserAssignmentMutation] = useDeleteUserAssignmentMutation()
  const [, bulkUpdateUserAssignmentMutation] = useBulkUpdateUserAssignmentsMutation()

  const editAssignmentModalProps = useModalProps("Edit")
  const createAssignmentModalProps = useModalProps("Create")
  const removeLastAssignmentModalProps = useModalProps("Remove Access")

  const AssignmentChip: FC<{ assignment: AssignmentExpectation; assignmentsCount: number }> = ({
    assignment,
    assignmentsCount,
  }) => {
    // current assignments cannot be deleted here
    // a user must reassign that user to another task
    const onDelete = (assignment: AssignmentExpectation) => {
      if (assignmentsCount === 1) {
        setEditTarget([assignment])
        removeLastAssignmentModalProps.handleOpenModal()
        return
      }

      deleteUserAssignmentMutation({ id: assignment.id })
    }

    return (
      <Chip
        className={classNames(assignment?.task?.id && "hover:underline")}
        color={assignment.isCurrentAssignment ? "primary" : "default"}
        key={assignment.id}
        label={assignment?.task?.name || "Full Project"}
        onClick={() => {
          if (!assignment?.task?.id) return
          pushDrawer(<TaskDrawer taskId={assignment?.task?.id} />)
        }}
        // only add onDelete if this assignment is not the current assignment and the user has permissions to
        // make those updates. This is to prevent the delete part of the chip from rendering
        {...(!assignment.isCurrentAssignment && canUpdatePermissionsInBulk && { onDelete: () => onDelete(assignment) })}
      />
    )
  }

  const columns: GridColDef[] = [
    { field: "id" },
    {
      field: "userId",
      flex: 2,
      minWidth: 200,
      renderCell: ({ row }: GridRenderCellParams<UserWithAssignments>) => (
        <Box paddingLeft="0.5rem">{row.user && <UserBadge user={row.user} />}</Box>
      ),
      renderHeader: () => (
        <Box paddingLeft="1rem">
          <Typography fontWeight={600} fontSize="1rem">
            User
          </Typography>
        </Box>
      ),
    },
    {
      field: "projectId",
      flex: 1,
      minWidth: 200,
      renderCell: (params: GridRenderCellParams<ProjectWithAssignments>) => (
        <Box paddingLeft="0.5rem">{params.row.project && <ProjectBadge project={params.row.project} />}</Box>
      ),
      renderHeader: () => (
        <Box paddingLeft="1rem">
          <Typography fontWeight={600} fontSize="1rem">
            Project
          </Typography>
        </Box>
      ),
    },
    {
      field: "taskId",
      flex: 4,
      minWidth: 200,
      renderCell: (params: GridRenderCellParams<UserWithAssignments | ProjectWithAssignments>) => {
        return (
          <ChipContainer>
            {params.row.assignments?.map((assignment: AssignmentExpectation) => {
              return (
                <AssignmentChip
                  key={assignment.id}
                  assignment={assignment}
                  assignmentsCount={params.row.assignments.length}
                />
              )
            })}
            {canUpdatePermissionsInBulk && (
              <button
                className="text-gray-400 hover:text-gray-600 transition-colors"
                onClick={() => {
                  setEditTarget(params.row.assignments || null)
                  editAssignmentModalProps.handleOpenModal()
                }}
                type="button"
              >
                Add / Edit
              </button>
            )}
          </ChipContainer>
        )
      },
      renderHeader: () => (
        <Box className="px-2">
          <Typography fontWeight={600} fontSize="1rem">
            Project Access
          </Typography>
        </Box>
      ),
    },
    {
      field: "currentAssignment",
      flex: 1,
      minWidth: 80,
      renderCell: ({ row }: GridRenderCellParams<UserWithAssignments & ProjectWithAssignments>) => {
        const currentAssignments = row.assignments.filter((a) => a.isCurrentAssignment)
        return currentAssignments.length > 0 ? (
          <ChipContainer>
            {currentAssignments.map((assignment: AssignmentExpectation) => (
              <AssignmentChip key={assignment.id} assignment={assignment} assignmentsCount={row.assignments.length} />
            ))}
          </ChipContainer>
        ) : null
      },
      headerName: "Current Assignment",
    },
    {
      field: "extraAssignments",
      flex: 1,
      minWidth: 80,
      renderCell: ({ row }: GridRenderCellParams<UserWithAssignments & ProjectWithAssignments>) => {
        const currentAssignments = row.assignments.filter((a) => !a.isCurrentAssignment)
        return currentAssignments.length > 0 ? (
          <ChipContainer>
            {currentAssignments.map((assignment: AssignmentExpectation) => (
              <AssignmentChip key={assignment.id} assignment={assignment} assignmentsCount={row.assignments.length} />
            ))}
          </ChipContainer>
        ) : null
      },

      headerName: "Extra Assignment",
    },
  ]
  if (!showCurrentAssignments) {
    columns.push({
      field: "actions",
      type: "actions",
      width: 80,
      getActions: ({ row }: GridRowParams<UserWithAssignments | ProjectWithAssignments>) => {
        const nonCurrentAssignments = row.assignments.filter(
          (assignment: AssignmentExpectation) => !assignment.isCurrentAssignment
        )

        // if there are no non-current assignments, don't show the menu
        if (!nonCurrentAssignments.length) return []

        return [
          <QuickMenu
            key="quickMenu"
            buttonShape="round"
            className={classNames(
              "h-10 w-10 -mr-4 md:mr-0 flex items-center justify-center flex-none rounded-full transition-colors mt-1",
              "md:h-12 md:w-12",
              "hover:bg-gray-50"
            )}
            disabled={!canUpdatePermissionsInBulk}
            items={[
              [
                {
                  value: "Remove Extra Access",
                  Icon: BiX,
                  color: "red",
                  isDisabled: !canUpdatePermissionsInBulk,
                  onClick: () => {
                    bulkUpdateUserAssignmentMutation({
                      assignmentsToDelete: nonCurrentAssignments.map(
                        (assignment: AssignmentExpectation) => assignment.id
                      ),
                    })
                      .then(() => {
                        successSnack("assignment deleted!")
                      })
                      .catch((err) => {
                        console.error(err)
                        errorSnack("Error deleting assignment")
                      })
                  },
                },
              ],
            ]}
          >
            <BiDotsHorizontalRounded className="w-6 h-6" />
          </QuickMenu>,
        ]
      },
    })
  }

  const Toolbar = () => {
    return canUpdatePermissionsInBulk ? (
      <div>
        <Button color="primary" onClick={createAssignmentModalProps.handleOpenModal}>
          Add user access
        </Button>
      </div>
    ) : null
  }

  const rowData = useMemo(() => {
    if (preassignments.projectId) {
      return combineUserAssignments(assignments)
    }
    if (preassignments.taskId) {
      return combineUserAssignments(assignments, showCurrentAssignments)
    }

    return combineProjectAssignments(assignments)
  }, [assignments, preassignments.projectId, preassignments.taskId, showCurrentAssignments])

  return (
    <Box>
      <div className={classNames("flex items-center flex-wrap gap-8", title ? "justify-between" : "justify-end")}>
        {title && <H5 className="my-0">{title}</H5>}
        <Toolbar />
      </div>
      <Box sx={{ height: "auto", width: "100%", marginTop: "1rem" }}>
        <DataGrid
          autoHeight
          columns={columns}
          disableRowSelectionOnClick={true}
          getEstimatedRowHeight={() => 70}
          getRowHeight={() => "auto"}
          sx={{
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": { py: !preassignments.taskId ? "12px" : "4px" },
            maxWidth: "93vw",
          }}
          rows={rowData}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
                projectId: !(preassignments.projectId || preassignments.taskId),
                taskId: !preassignments.taskId,
                userId: !preassignments.userId,
                currentAssignment: !!showCurrentAssignments,
                extraAssignment: !!preassignments.taskId,
                actions: !!preassignments.taskId,
              },
            },
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
        />
      </Box>

      {canUpdatePermissionsInBulk && (
        <TableAddButton onClick={createAssignmentModalProps.handleOpenModal} label="Add user access" />
      )}
      {createAssignmentModalProps.isOpen && (
        <CreateOrUpdateUserAssignmentForm
          contextUserAssignments={assignments}
          modalProps={createAssignmentModalProps}
          preassignments={preassignments}
          refetch={refetch}
        />
      )}
      {editAssignmentModalProps.isOpen && (
        <CreateOrUpdateUserAssignmentForm
          contextUserAssignments={assignments}
          modalProps={editAssignmentModalProps}
          preassignments={{ ...preassignments, userId: editTarget?.[0]?.userId }}
          refetch={refetch}
          userAssignments={editTarget}
        />
      )}
      {removeLastAssignmentModalProps.isOpen && (
        <RemoveLastAssignmentModal
          modalProps={removeLastAssignmentModalProps}
          handleEditAccessClick={() => {
            setEditTarget(assignments.filter((assignment) => assignment.userId === editTarget?.at(0)?.userId) || null)
            removeLastAssignmentModalProps.handleCloseModal()
            editAssignmentModalProps.handleOpenModal()
          }}
          handleFormSubmit={(assignment?: AssignmentExpectation | null) => {
            if (!assignment) return

            deleteUserAssignmentMutation({ id: assignment.id })
            if (refetch) refetch()
            removeLastAssignmentModalProps.handleCloseModal()
          }}
          assignment={editTarget?.at(0)}
        />
      )}
    </Box>
  )
}

type RemoveLastAssignmentModalType = {
  assignment?: AssignmentExpectation | null
  handleEditAccessClick: () => void
  handleFormSubmit: (assignment?: AssignmentExpectation | null) => void
  modalProps: ModalProps
}

const RemoveLastAssignmentModal: FC<RemoveLastAssignmentModalType> = ({
  assignment,
  handleEditAccessClick,
  handleFormSubmit,
  modalProps,
}) => {
  return (
    <MuiModal
      isOpen={modalProps.isOpen}
      contentLabel="Remove Access"
      handleCloseModal={modalProps.handleCloseModal}
      submitButtonText="Remove Access"
      submitForm={() => handleFormSubmit(assignment)}
      isLoading={false}
      submitButtonColor="error"
    >
      <div className="flex flex-col gap-4 min-h-[140px] items-start">
        <p>Are you sure you want to remove {getFullName(assignment?.user)}’s extra access to this project?</p>
        <p>
          You can also{" "}
          <button className="text-blue-600 underline" type="button" onClick={handleEditAccessClick}>
            edit their access
          </button>{" "}
          if you didn’t mean to remove all extra access.
        </p>
      </div>
    </MuiModal>
  )
}

type ProjectWithAssignments = {
  id: string
  assignments: AssignmentExpectation[]
  project: AssignmentExpectation["project"]
}

const combineProjectAssignments = (assignments: AssignmentExpectation[]) => {
  return assignments.reduce((acc, assignment) => {
    const project = acc.find((p) => p.id === assignment.projectId)
    if (project) {
      project.assignments.push(assignment)
    } else {
      acc.push({
        id: assignment.projectId!,
        assignments: [assignment],
        project: assignment.project!,
      })
    }
    return acc
  }, [] as ProjectWithAssignments[])
}

type UserWithAssignments = {
  id: string
  assignments: AssignmentExpectation[]
  user: AssignmentExpectation["user"]
}

const combineUserAssignments = (assignments: AssignmentExpectation[], showCurrentAssignments = false) => {
  const userAssignments = assignments.filter(Boolean).reduce((acc, assignment) => {
    const user = acc.find((u) => u.id === assignment.userId)
    if (user) {
      user.assignments.push(assignment)
    } else {
      acc.push({
        id: assignment.userId!,
        assignments: [assignment],
        user: assignment.user!,
      })
    }
    return acc
  }, [] as UserWithAssignments[])

  return (
    userAssignments
      .map((userAssignment) => {
        return {
          ...userAssignment,
          assignments: sortBy(userAssignment.assignments, (a: AssignmentExpectation) => a.task?.name.toLowerCase()),
        }
      })
      // filter out users with only a current assignment for this project
      .filter(
        (userAssignment) =>
          showCurrentAssignments ||
          !!userAssignment.assignments.filter((assignment) => !assignment.isCurrentAssignment).length
      )
  )
}

import { FC, memo } from "react"
import { BiCheckCircle, BiError } from "react-icons/bi"
import { TimeEntryError } from "../../../../graphql/generated/client-types-and-hooks"
import { pluralize } from "../../../../helpers/pluralize"
import { InfoBanner } from "../../../InfoBanner"

type Props = {
  direction: "in" | "out"
  successes: number
  errors: TimeEntryError[]
}

const PartialErrorInfoBannersComponent: FC<Props> = ({ direction, successes, errors }) => {
  return successes > 0 || errors.length > 0 ? (
    <div className={"flex flex-col gap-0.5 mb-4"}>
      {successes > 0 && (
        <InfoBanner Icon={BiCheckCircle} color={"blue"}>
          {successes} {pluralize("user", successes)} {pluralize("was", successes)} successfully clocked {direction}
        </InfoBanner>
      )}
      {errors.length > 0 && (
        <InfoBanner Icon={BiError} color={"red"}>
          {errors.length} {pluralize("user", errors.length)} {pluralize("was", errors.length)} not clocked {direction}
        </InfoBanner>
      )}
    </div>
  ) : null
}

export const PartialErrorInfoBanners = memo(PartialErrorInfoBannersComponent, (prevProps, nextProps) => {
  const successesAreSame = prevProps.successes === nextProps.successes
  const errorsAreSame = JSON.stringify(prevProps.errors) === JSON.stringify(nextProps.errors)

  return successesAreSame && errorsAreSame
})

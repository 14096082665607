import { FC,ReactNode } from "react"
import { classNames } from "../../../helpers/classNames"
import { TailwindIcon } from "../../../types/tailwind"
import { ConditionalLink } from "../../ConditionalLink"

type Props = {
  Icon: TailwindIcon
  description?: string
  extras?: ReactNode
  extrasClassName?: string
  href?: string
  iconClassNames?: string
  value: string
  valueElement?: ReactNode
}

export const SummaryWidget: FC<Props> = ({ Icon, description, extras, extrasClassName, href, iconClassNames, value, valueElement }) => {
  return (
    <div className="border rounded-lg p-6 flex flex-col justify-between gap-2 w-56 shrink-0 md:w-auto">
      <div className="flex flex-row justify-between items-center align-center ">
        <ConditionalLink className="flex flex-center gap-x-2 font-semibold text-xl" href={href}>
          {!valueElement ? <div className="text-blue-600">{value}</div> : valueElement}
          <div className="text-gray-400">{description}</div>
        </ConditionalLink>
        <Icon className={classNames("h-8 w-8 rounded-lg p-[0.4rem] text-gray-800 bg-gray-100", iconClassNames)} />
      </div>
      {extras && <div className={classNames("text-sm text-gray-400", extrasClassName)}>{extras}</div>}
    </div>
  )
}

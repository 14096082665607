import { Button } from "@mui/material"
import { Form, Formik, FormikHelpers } from "formik"
import { FC } from "react"
import * as Yup from "yup"
import { AssetStatus, useDeleteAssetMutation } from "../graphql/generated/client-types-and-hooks"
import { assetStatusList } from "../helpers/assetStatus"
import { ModalProps } from "../hooks/useModalProps"
import { DropDownMUI } from "./DropDownMUI"
import { ModalBody } from "./Modals/ModalBody"
import { ModalFooter } from "./Modals/ModalFooter"
import StandardModal from "./Modals/StandardModal"
import { errorSnack, successSnack } from "./Notistack/ThemedSnackbars"

type Values = {
  status: AssetStatus | ""
}

export const AssetArchiveModal: FC<{
  formModalProps: ModalProps
  assetId: string
}> = ({ formModalProps, assetId }) => {
  const [, archiveAssetMutation] = useDeleteAssetMutation()

  const handleSubmit = async (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
    try {
      if (!values.status) throw new Error("Status is required")
      await archiveAssetMutation({ id: assetId, status: values.status })
      successSnack("Asset archived successfully")
      formModalProps.handleCloseModal()
    } catch (error) {
      if (error instanceof Error) errorSnack(error.message)
      errorSnack("Error archiving asset")
    } finally {
      setSubmitting(false)
    }
  }

  const archivableStatuses = assetStatusList.filter((status) => status.canBeArchived)

  return (
    <StandardModal {...formModalProps}>
      <Formik
        initialValues={{
          status: "",
        }}
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape({
          status: Yup.mixed<AssetStatus>().required("Status is required"),
        })}
      >
        {({ isSubmitting }) => {
          return (
            <Form className="h-full flex flex-col">
              <ModalBody>
                <div className="h-52">
                  <DropDownMUI items={archivableStatuses} fieldName="status" label="Status" />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button variant="text" color="black" type="button" onClick={formModalProps.handleCloseModal}>
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="error" disabled={isSubmitting}>
                  Archive
                </Button>
              </ModalFooter>
            </Form>
          )
        }}
      </Formik>
    </StandardModal>
  )
}

import { Box, Button, useMediaQuery, useTheme } from "@mui/material"
import { format } from "date-fns"
import { NextPage } from "next"
import Error from "next/error"
import { FC, MutableRefObject, useMemo, useState } from "react"
import { BiStopwatch } from "react-icons/bi"
import { useQuery } from "urql"
import { H4 } from "../../../components/Elements"
import { LoadingIndicator } from "../../../components/Loading/LoadingIndicator"
import StandardModal from "../../../components/Modals/StandardModal"
import { PageTitle } from "../../../components/PageTitle"
import { UserInjuryReports } from "../../../components/Partials/InjuryReports/InjuryReports"
import { CreateTimeEntryForm } from "../../../components/Partials/User/CreateTimeEntryForm"
import {
  EditDayActivityForm,
  TimeEntryWithProjectAndTaskName,
} from "../../../components/Partials/User/EditDayActivityForm/EditDayActivityForm"
import { TimeEntryDataGrid } from "../../../components/Partials/User/TimeEntries/DataGrid/TimeEntryDataGrid"
import { RenderIf } from "../../../components/RenderIf"
import { NotificationsTable } from "../../../components/UserNotifications/NotificationTable"
import { UserNotificationModelType } from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { classNames } from "../../../helpers/classNames"
import { getFullName } from "../../../helpers/getFullName"
import { useDrawerWidth } from "../../../hooks/useDrawerWidth"
import { useModalProps } from "../../../hooks/useModalProps"
import { useWindowSize } from "../../../hooks/useWindowSize"
import UserPageLayout from "./_layout"

const UserTimeCardsPageDocument = graphql(`
  query UserTimeCardsPage($userId: String!) {
    user(id: $userId) {
      id
      firstName
      lastName
      currentProjectId
      archived
      project {
        id
        name
      }
      currentTaskId
      task {
        id
        name
      }
      timeEntries {
        id
        durationInSeconds
        endAt
        signInPhotoUrl
        signOutPhotoUrl
        projectId
        taskId
        startAt

        project {
          id
          name
          isDefault
        }
        task {
          id
          parentTaskId
          name
          visibilityLevel
          parentTask {
            id
            name
          }
        }
      }
    }
  }
`)

const getMarginXValue = (sm: boolean, md: boolean) => {
  if (sm) {
    return 1.5
  }

  return md ? 2 : 1
}
const getDataGridWidth = (
  drawerWidth: number = 0,
  screenWidth: number = 0,
  marginXValue: number,
  isMediumSize: boolean
) => {
  let width: string | number = 0
  // resolves a chicken & egg issue with the drawer size not set wide enough because the data grid doesn't have a width yet
  const useDrawerWidth = drawerWidth && isMediumSize && drawerWidth >= 768

  if (useDrawerWidth) {
    width = drawerWidth
  } else if (screenWidth && screenWidth > 1000) {
    width = `calc(1000px - ${marginXValue * 2}rem)`
  } else {
    width = `calc(100vw - ${marginXValue * 2}rem)`
  }

  return width
}

export const UserPageTimeCards: FC<{ userId: string; drawerRef?: MutableRefObject<null> }> = ({
  userId,
  drawerRef = null,
}) => {
  const [{ data, fetching }, refetch] = useQuery({ query: UserTimeCardsPageDocument, variables: { userId } })

  const { width: drawerWidth } = useDrawerWidth(drawerRef)
  const { width: screenWidth } = useWindowSize()

  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.between("sm", "md"))
  const md = useMediaQuery(theme.breakpoints.up("md"))

  const width = getDataGridWidth(drawerWidth, screenWidth, getMarginXValue(sm, md), md)

  const [selectedTimeEntries, setSelectedTimeEntries] = useState<TimeEntryWithProjectAndTaskName[]>([])
  const addTimeEntryFormModalProps = useModalProps("Add new time entry")
  const editDayActivityFormModalProps = useModalProps("Edit day activity")

  const timeEntries: TimeEntryWithProjectAndTaskName[] = useMemo(() => {
    return (data?.user?.timeEntries || []).map((te) => ({
      ...te,
      isBreak: te.project.isDefault && te.task.visibilityLevel === "HIDDEN",
    }))
  }, [data?.user?.timeEntries])

  const user = data?.user

  if (fetching || !user) return <LoadingIndicator />

  return (
    <>
      <PageTitle title={!user?.firstName && !user?.lastName ? "User time cards" : `${getFullName(user)} time cards`} />
      <NotificationsTable modelType={UserNotificationModelType.User} userId={userId} />
      <div className="grid md:grid-cols-3 grid-cols-1 gap-4 mb-4">
        <H4 className="mb-2 md:col-span-2 grid-col-span-1">Time Card History</H4>
        <div className="pb-3 grid justify-start md:justify-end">
          <RenderIf permissionsInclude="timeEntry:create" context={{ projectId: user.currentProjectId }}>
            <Button
              variant="contained"
              color="secondary"
              className={classNames(user.archived && "opacity-40")}
              endIcon={<BiStopwatch className="w-5 h-5" />}
              onClick={!user.archived ? addTimeEntryFormModalProps.handleOpenModal : undefined}
            >
              Add time entry
            </Button>
          </RenderIf>
        </div>
      </div>
      <div>
        <UserInjuryReports userId={userId} />
      </div>
      <div style={{ width: "100%" }}>
        {
          <Box marginTop={2} width={width}>
            <TimeEntryDataGrid
              userId={userId}
              openEditDayActivityModal={(timeEntries) => {
                setSelectedTimeEntries(timeEntries)
                editDayActivityFormModalProps.handleOpenModal()
              }}
            />
          </Box>
        }
        <div className="text-gray-400 text-xs mt-14">
          <p className="mb-1">* Roll over to next day</p>
          <p>
            {timeEntries.length > 0
              ? `Account active since ${format(timeEntries[timeEntries.length - 1].startAt, "MMM do yyyy")}`
              : "Account has never posted an active clock in"}
          </p>
        </div>
      </div>
      <StandardModal {...addTimeEntryFormModalProps} className="overflow-y-visible">
        <CreateTimeEntryForm
          user={user}
          onCancel={addTimeEntryFormModalProps.handleCloseModal}
          onSuccess={() => {
            refetch()
            addTimeEntryFormModalProps.handleCloseModal()
          }}
          timeEntries={timeEntries}
        />
      </StandardModal>
      <StandardModal {...editDayActivityFormModalProps}>
        <EditDayActivityForm
          onCancel={editDayActivityFormModalProps.handleCloseModal}
          onSuccess={editDayActivityFormModalProps.handleCloseModal}
          timeEntries={selectedTimeEntries}
          user={user}
        />
      </StandardModal>
    </>
  )
}

const UserPage: NextPage<{ userId: string }> = ({ userId }) => {
  return (
    <RenderIf permissionsInclude="user:read" fallbackComponent={<Error statusCode={404} />}>
      <UserPageLayout userId={userId}>
        <UserPageTimeCards userId={userId} />
      </UserPageLayout>
    </RenderIf>
  )
}

UserPage.getInitialProps = async ({ query }) => {
  return {
    userId: query._id as string,
  }
}

export default UserPage

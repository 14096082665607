import { FC } from "react"
import { BiDotsHorizontalRounded } from "react-icons/bi"
import { useCurrentUser } from "../providers/PermissionsProvider/currentUserProvider"
import { CheckedPermission } from "../types/Permission"
import { H5 } from "./Elements"
import { MenuItem, QuickMenuMui } from "./QuickMenuMui"
import { RenderIf } from "./RenderIf"

type Props = {
  isEditing: boolean
  permission?: CheckedPermission
  quickMenuItems?: MenuItem[][]
  title: string
}

export const QuickMenuSectionTitle: FC<Props> = ({ title, quickMenuItems = [], isEditing, permission }) => {
  const { organizationId } = useCurrentUser()

  return (
    <>
      <div className={"flex justify-between items-center min-h-[40px]"}>
        <H5 className={"mt-0 mb-0"}>{title}</H5>
        {permission && (
          <RenderIf permissionsInclude={permission} context={{ organizationId }}>
            {quickMenuItems && !isEditing && (
              <QuickMenuMui items={quickMenuItems} buttonShape="round">
                <BiDotsHorizontalRounded className="text-2xl" />
              </QuickMenuMui>
            )}
          </RenderIf>
        )}
      </div>
      <hr className={"mt-2 mb-4 md:mb-6"} />
    </>
  )
}

import { FC } from "react"
import { PageTitle } from "../../../components/PageTitle"
import { NextPage } from "next"
import Error from "next/error"
import AssetsPageLayout from "./_layout"
import { RenderIf } from "../../../components/RenderIf"
import { AssetDocuments } from "../../../components/Partials/Assets/Documents/AssetDocuments"
import { DocumentsProvider } from "../../../providers/DocumentsProvider"

type AssetDocumentsPageProps = {
  assetId: string
}

export const AssetPageDocuments: FC<AssetDocumentsPageProps> = ({ assetId }) => {
  return (
    <>
      <PageTitle title="Asset Documents" />
      <div className="flex flex-col gap-9">
        <DocumentsProvider>
          <AssetDocuments assetId={assetId} />
        </DocumentsProvider>
      </div>
    </>
  )
}

const AssetPage: NextPage<AssetDocumentsPageProps> = ({ assetId }) => {
  return (
    <RenderIf permissionsInclude="asset:read" fallbackComponent={<Error statusCode={404} />}>
      <AssetsPageLayout assetId={assetId}>
        <AssetPageDocuments assetId={assetId} />
      </AssetsPageLayout>
    </RenderIf>
  )
}

AssetPage.getInitialProps = async ({ query }) => {
  return {
    assetId: query._id as string
  }
}

export default AssetPage
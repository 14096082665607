import { Button, ButtonGroup } from "@mui/material"
import {
  addDays,
  addMonths,
  endOfMonth,
  endOfWeek,
  format,
  isToday,
  startOfMonth,
  startOfToday,
  startOfWeek,
} from "date-fns"
import { useRouter } from "next/router"
import { FC, useEffect, useState } from "react"
import { BiCalendar, BiChevronLeft, BiChevronRight } from "react-icons/bi"
import { getValidatedDateFromDateString } from "../helpers/getDateFromDateString"
import { getQueryStringParams } from "../helpers/getQueryStringParams"
import { CrewViewDatePickerMUI } from "./CrewViewDatePickerMUI"
import { DateRangeFilterString } from "./Partials/Summary/types"

type Props = {
  minDate?: Date | null
  dateRangeType?: DateRangeFilterString
}

export const DateSelectAndRouteToQueryParam: FC<Props> = ({ minDate, dateRangeType = "daily" }) => {
  const router = useRouter()
  const params = getQueryStringParams()
  const initialDate = getValidatedDateFromDateString(params.date) || new Date()
  const [date, setDate] = useState(initialDate)
  const dateRangeTypeButton = {
    monthly: "This Month",
    weekly: "This Week",
    daily: "Today",
    "all-time": undefined,
  }

  useEffect(() => {
    // Set the date to the start of the period when the date range type changes
    const today = startOfToday()
    const newDate = startOfPeriod(today)
    setDate(newDate)
    updateDate(newDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRangeType])

  const updateDate = (newDate: Date) => {
    setDate(newDate)
    router.push(
      { pathname: window.location.pathname, query: { ...params, date: format(newDate, "yyyy-MM-dd") } },
      undefined,
      { shallow: true }
    )
  }
  const startOfPeriod = (date: Date) => {
    switch (dateRangeType) {
      case "monthly":
        return startOfMonth(date)
      case "weekly":
        return startOfWeek(date)
      case "daily":
      default:
        return date
    }
  }

  const endOfPeriod = (date: Date) => {
    switch (dateRangeType) {
      case "monthly":
        return endOfMonth(date)
      case "weekly":
        return endOfWeek(date)
      case "daily":
      default:
        return date
    }
  }

  const adjustDate = (amount: number) => {
    switch (dateRangeType) {
      case "monthly":
        return addMonths(date, amount)
      case "weekly":
        return addDays(date, 7 * amount)
      case "daily":
      default:
        return addDays(date, amount)
    }
  }
  const isComponentDisabled = dateRangeType === "all-time"
  const isPrevDisabled = isComponentDisabled || (minDate && startOfPeriod(date) <= minDate)
  const isNextDisabled =
    isComponentDisabled || format(endOfPeriod(date), "yyyy-MM-dd") >= format(new Date(), "yyyy-MM-dd")

  if (dateRangeType === "all-time") {
    return minDate ? (
      <span className="text-md font-medium">
        {`Displaying data from ${format(startOfPeriod(minDate), "MMM dd, yyyy")} - ${format(
          endOfPeriod(date),
          "MMM dd, yyyy"
        )}`}
      </span>
    ) : null
  }

  return (
    <div>
      <div className="flex flex-wrap items-center gap-2">
        <CrewViewDatePickerMUI
          onChange={(newDate) => newDate && updateDate(newDate)}
          minDate={minDate || undefined}
          maxDate={new Date()}
          format={dateRangeType === "monthly" ? "MMMM" : "MMMM dd"}
          value={date}
          slots={{ openPickerIcon: BiCalendar }}
          disabled={isComponentDisabled}
        />
        <div>
          <ButtonGroup>
            <Button
              className="min-w-0 p-2"
              color="secondary"
              variant="contained"
              onClick={() => !isPrevDisabled && updateDate(adjustDate(-1))}
              disabled={Boolean(isPrevDisabled)}
            >
              <BiChevronLeft className="h-6 w-6" />
            </Button>
            <Button
              className="min-w-0 p-2"
              color="secondary"
              variant="contained"
              onClick={() => !isNextDisabled && updateDate(adjustDate(1))}
              disabled={isNextDisabled}
            >
              <BiChevronRight className="h-6 w-6" />
            </Button>
          </ButtonGroup>
        </div>
        <Button
          type="button"
          color="secondary"
          variant="contained"
          onClick={() => updateDate(new Date())}
          disabled={isToday(date) || isComponentDisabled}
        >
          {dateRangeTypeButton[dateRangeType]}
        </Button>
      </div>
      {dateRangeType === "weekly" && (
        <span className="text-sm font-normal text-gray-500">
          {`Displaying data for the week of 
            ${format(startOfPeriod(date), "MMM dd")} - ${format(endOfPeriod(date), "MMM dd")}`}
        </span>
      )}
    </div>
  )
}

import { Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import { FC } from "react"
import { BiCheckSquare, BiRefresh, BiSortAlt2 } from "react-icons/bi"
import { TimeEntry, User, useResendUserInvitesMutation } from "../../../../graphql/generated/client-types-and-hooks"
import { Project } from "../../../../graphql/generated/gql/graphql"
import { useModalProps } from "../../../../hooks/useModalProps"
import { PickPlus } from "../../../../types/helpers"
import { CustomMenu } from "../../../CustomMenu"
import { DevelopmentFeatureFlag } from "../../../DevelopmentFeatureFlag"
import StandardModal from "../../../Modals/StandardModal"
import { errorSnack, successSnack } from "../../../Notistack/ThemedSnackbars"
import { OrganizationRequiresClockInEvidence } from "../../../OrganizationRequiresClockInEvidence"
import { RenderIf } from "../../../RenderIf"
import { ReassignUserTaskForm } from "../../User/ReassignUserTaskForm/ReassignUserTaskForm"
import { BulkClockActionsWithEvidence } from "./BulkClockActionsWithEvidence"
import { LegacyBulkClockActions } from "./LegacyBulkClockActions"

export type ModernUserWithTimeEntry = PickPlus<
  User,
  | "id"
  | "organizationId"
  | "currentProjectId"
  | "currentTaskId"
  | "firstName"
  | "imageUrl"
  | "isClockedIn"
  | "jobTitle"
  | "lastName"
  | "projectId"
  | "taskId"
> & {
  latestTimeEntry?: PickPlus<TimeEntry, "id" | "endAt" | "startAt" | "taskId" | "evidence"> | null
}

export type ModernBulkClockInCandidate = {
  imagePath?: string
  startAt?: Date
  user: PickPlus<
    ModernUserWithTimeEntry,
    "id" | "currentProjectId" | "currentTaskId" | "organizationId" | "latestTimeEntry"
  >
}

export type ModernBulkClockOutCandidate = {
  imagePath?: string
  signaturePath?: string
  startAt?: Date
  user: PickPlus<ModernUserWithTimeEntry, "id" | "latestTimeEntry" | "currentProjectId" | "currentTaskId">
}

type Props = {
  onSuccess?: () => void
  project?: PickPlus<Project, "id">
  selectedUsers: ModernUserWithTimeEntry[]
  setSelectedUsers: (selectedUsers: ModernUserWithTimeEntry[]) => void
}

const BulkActions: FC<Props> = ({ onSuccess, project, selectedUsers }) => {
  const reassignUserTaskFormModalProps = useModalProps("Reassign user")
  const [, resendUserInvitesMutation] = useResendUserInvitesMutation()

  const handleResendUserInvites = async () => {
    const { error } = await resendUserInvitesMutation({ userIds: selectedUsers.map(({ id }) => id) })
    if (error) return errorSnack("Error unarchiving user")

    successSnack("User invite sent")
    onSuccess?.()
  }

  return (
    <>
      <List disablePadding sx={{ minWidth: 180 }}>
        <RenderIf permissionsInclude={"timeEntry:create"}>
          <OrganizationRequiresClockInEvidence
            fallbackComponent={<LegacyBulkClockActions onSuccess={onSuccess} selectedUsers={selectedUsers} />}
          >
            <BulkClockActionsWithEvidence onSuccess={onSuccess} selectedUsers={selectedUsers} />
          </OrganizationRequiresClockInEvidence>
        </RenderIf>
        <RenderIf permissionsInclude="user:assign">
          <ListItem disablePadding>
            <ListItemButton onClick={reassignUserTaskFormModalProps.handleOpenModal}>
              <ListItemIcon>
                <BiSortAlt2 className="h-5 w-5 rotate-90" />
              </ListItemIcon>
              <ListItemText primary="Reassign" />
            </ListItemButton>
          </ListItem>
        </RenderIf>
        <DevelopmentFeatureFlag name="App Invitations">
          <RenderIf permissionsInclude={"user:create"}>
            <ListItem disablePadding>
              <ListItemButton onClick={handleResendUserInvites}>
                <ListItemIcon>
                  <BiRefresh className="h-5 w-5" />
                </ListItemIcon>
                <ListItemText primary="Resend app invite" />
              </ListItemButton>
            </ListItem>
          </RenderIf>
        </DevelopmentFeatureFlag>
      </List>
      <StandardModal {...reassignUserTaskFormModalProps}>
        <ReassignUserTaskForm
          onCancel={reassignUserTaskFormModalProps.handleCloseModal}
          onSuccess={async () => {
            onSuccess?.()
            reassignUserTaskFormModalProps.handleCloseModal()
          }}
          preselectedUsers={selectedUsers}
          projectId={project?.id}
        />
      </StandardModal>
    </>
  )
}

export const BulkActionMenu: FC<Props> = ({ onSuccess, project, selectedUsers, setSelectedUsers }) => {
  return (
    <CustomMenu
      anchor={
        <Button
          color="primary"
          endIcon={<BiCheckSquare className="text-white h-5 w-5" />}
          sx={{ minWidth: "145px" }}
          variant="contained"
        >
          Bulk actions
        </Button>
      }
    >
      <BulkActions
        onSuccess={onSuccess}
        project={project}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
      />
    </CustomMenu>
  )
}

import { NextPage } from "next"
import Error from "next/error"
import { FC, useContext } from "react"
import { PageTitle } from "../../../../components/PageTitle"
import { AssignedAssetsDataGrid } from "../../../../components/Partials/Assets/DataGrid/AssignedAssetsDataGrid"
import { RenderIf } from "../../../../components/RenderIf"
import { TransferAssetToTaskUser } from "../../../../components/TransferAssetModal/TransferAssetToTaskUser"
import { useModalProps } from "../../../../hooks/useModalProps"
import { AssignedAssetListProvider } from "../../../../providers/AssignedAssetListProvider"
import { useCurrentUser } from "../../../../providers/PermissionsProvider/currentUserProvider"
import TaskLayout, { TaskLayoutContext } from "./_layout"

type Props = {
  taskId: string
}

export const TaskAssetsPageContents: FC<{ onSuccess?: () => void }> = ({ onSuccess }) => {
  const { task } = useContext(TaskLayoutContext)

  const assetModalProps = useModalProps("Transfer Assets")

  const handleSuccess = () => {
    onSuccess?.()
  }

  return (
    <>
      <PageTitle title={`${task?.name || "Task"} assets`} />
      {task && (
        <AssignedAssetListProvider>
          <AssignedAssetsDataGrid
            taskId={task.id}
            isComplete={!!task.isComplete}
            handleAssignAsset={assetModalProps.handleOpenModal}
          />
        </AssignedAssetListProvider>
      )}
      {assetModalProps.isOpen && task && (
        <TransferAssetToTaskUser formModalProps={assetModalProps} task={task} onSuccess={handleSuccess} />
      )}
    </>
  )
}

const TaskPage: NextPage<Props> = ({ taskId }) => {
  const currentUser = useCurrentUser()

  return (
    <RenderIf
      permissionsInclude={"project:read"}
      context={{ projectId: currentUser.assignments.map((a) => a.projectId) }}
      fallbackComponent={<Error statusCode={404} />}
    >
      <TaskLayout taskId={taskId} useDataGridStyles>
        <TaskAssetsPageContents />
      </TaskLayout>
    </RenderIf>
  )
}

TaskPage.getInitialProps = async ({ query }) => {
  return {
    taskId: query._task_id as string,
  }
}

export default TaskPage

import { FC } from "react"
import { BiStopwatch } from "react-icons/bi"
import { User } from "../graphql/generated/client-types-and-hooks"
import { classNames } from "../helpers/classNames"
import { useUpdatingUserClockedInHoursAndMinutes } from "../hooks/useUpdatingUserClockedInHoursAndMinutes"
import { PickPlus } from "../types/helpers"

type Props = {
  user: PickPlus<User, "isClockedIn" | "secondsClockedSinceOrgMidnight">
}

export const UserTimeCounter: FC<Props> = ({ user }) => {
  const { hoursClockedIn, minutesClockedIn } = useUpdatingUserClockedInHoursAndMinutes(user)

  return (
    <div
      className={classNames(
        "flex items-center gap-x-1 w-full justify-center text-base",
        "md:justify-start",
        user.isClockedIn && `text-blue-600`,
        !user.isClockedIn && `text-gray-400`
      )}
    >
      <BiStopwatch className="w-6 h-6" />
      <span>
        {hoursClockedIn} hr {minutesClockedIn} min
      </span>
    </div>
  )
}

import { graphql } from "../../../../graphql/generated/gql";
import { DocumentsList } from "../../Documents/DocumentsList";
import { Document } from "../../Documents/DocumentsList";
import { FC, useContext, useEffect, useState } from "react";
import { useQuery } from "urql";
import { DocumentsContext } from "../../../../providers/DocumentsProvider";

const UserDocumentsQuery = graphql(`
  query UserDocuments($userId: String!) {
    user(id: $userId) {
      id
      myFiles {
        id
        fileName
        key
        contentType
        objectVersion
        userId
        status
        expiresAt
        createdAt
        documentUrl
      }
    }
  }
`)

type Props = {
  userId: string
}

type UserDocuments = 
  | {
      id: string
      fileName: string
      key: string
      contentType: string
      objectVersion: number
      userId?: string | null | undefined
      status?: string | null | undefined
      expiresAt?: Date | null | undefined
      createdAt: Date
      documentUrl?: string | null | undefined
    }[]
  | undefined

export const UserDocumentsList: FC<Props> = ({ userId }) => {
  const [{ data, fetching }, getDocuments] = useQuery({
    query: UserDocumentsQuery,
    variables: { userId },
    pause: !userId
  })

  const { startRefresh, onCompleteRefresh, editDocument, searchText, refreshList, documentModalProps } = useContext(DocumentsContext)
  const [filteredRows, setFilteredRows] = useState<Document[]>([])

  useEffect(() => {
    if (startRefresh) {
      getDocuments()
      onCompleteRefresh()
    }
  }, [userId, startRefresh, onCompleteRefresh, getDocuments])

  useEffect(() => {
    if (searchText && data?.user?.myFiles) {
      const filtered = data.user.myFiles.filter((doc) => doc.fileName.toLowerCase().includes(searchText.toLowerCase())) || []

      setFilteredRows(transformData(filtered))
    } else {
      setFilteredRows(transformData(data?.user?.myFiles))
    }
  }, [searchText, data?.user?.myFiles])

  return (
    <DocumentsList
      fetching={fetching}
      filteredRows={filteredRows}
      documentModalProps={documentModalProps}
      editDocument={editDocument}
      refreshList={refreshList} />
  )
}

const transformData = (userDocuments: UserDocuments) => {
  const rowData: Document[] = userDocuments?.map((doc) => ({
    id: doc.id,
    documentUrl: doc?.documentUrl || undefined,
    name: doc.fileName,
    uploaded: doc.createdAt,
    type: doc.contentType.split("/")?.[1],
    status: doc?.status || undefined,
    expirationDate: doc?.expiresAt || undefined
  })) || []

  return rowData
}
import { ArrayHelpers, FieldArray, Form, Formik, FormikValues } from "formik"
import { FC } from "react"
import { BiX } from "react-icons/bi"
import * as Yup from "yup"
import {
  Asset,
  AssetReportType,
  useInsertManyAssetReportsMutation,
} from "../../../graphql/generated/client-types-and-hooks"
import { AssetStatus } from "../../../graphql/generated/gql/graphql"
import { Available, getAssetStatusLabel, isOperational, stringToAssetStatus } from "../../../helpers/assetStatus"
import { ModalProps } from "../../../hooks/useModalProps"
import { AssetGroupAssignmentInventory } from "../../../types/Asset"
import { AssetReportInventoryReport } from "../../../types/AssetReport"
import { PickPlus } from "../../../types/helpers"
import { AddButton } from "../../AddButton"
import { ButtonFilled, ButtonHollow } from "../../Elements"
import { MultiSelect } from "../../Formik/MultiSelect/MultiSelect"
import { ModalBody } from "../../Modals/ModalBody"
import { ModalFooter } from "../../Modals/ModalFooter"
import StandardModal from "../../Modals/StandardModal"
import { errorSnack, successSnack } from "../../Notistack/ThemedSnackbars"
import { ChecklistCounter } from "./ChecklistCounter"
import { AssetInventoryReportItem } from "./InventoryCheckModal/AssetInventoryReportItem"

export type AssignmentQuantityFormValues = {
  assets: {
    [assetId: string]: {
      note?: string
      status: AssetStatus
      active: boolean
      photos?: { uploaded: boolean; fileId: string; objectKey: string }[]
    }
  }
  statuses: { status: string[]; quantity: string[] }[]
}

type AssetExpectation = PickPlus<
  Asset,
  "id" | "name" | "assetGroupId" | "status" | "isAssetGroup" | "assetChildCount" | "ownershipType"
>

export const AssignmentQuantityReportModal: FC<{
  inventoryModalProps: ModalProps
  assignment: AssetGroupAssignmentInventory | undefined
  asset: AssetExpectation
}> = ({ inventoryModalProps, assignment, asset }) => {
  const count = assignment?.count || 0

  let statusOptions = Object.values(AssetStatus).map((status) => ({
    id: status,
    label: getAssetStatusLabel(status),
    searchableTextString: getAssetStatusLabel(status),
    value: status,
    disabled: false,
  }))

  const adjustQuantities = (
    values: FormikValues,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  ) => {
    const previousCounts = values.statuses[0]
    const total = values.statuses.reduce(
      (total: number, { quantity }: { quantity: string }) => (total += +quantity?.[0] || 0),
      0
    )

    const delta = count - total

    if (delta !== 0) {
      const newCount = +previousCounts.quantity + delta
      setFieldValue("statuses.0.quantity", [`${newCount}`])
    }
  }

  const initialValues: AssignmentQuantityFormValues = {
    assets: {
      [asset.id]: {
        status: assignment?.status || Available,
        active: false,
      },
    },
    statuses: [
      {
        status: [assignment?.status || Available],
        quantity: [`${count}`],
      },
    ],
  }

  const [, createAssetReportsMutation] = useInsertManyAssetReportsMutation()

  const onSubmit = async (values: AssignmentQuantityFormValues) => {
    const inventoryReport: AssetReportInventoryReport = {
      note: values.assets[asset.id].note,
      photos: values.assets[asset.id].photos?.filter((p) => p.uploaded).map((p) => p.objectKey),
      fileIds: values.assets[asset.id].photos?.filter((p) => p.uploaded).map((p) => p.fileId),
    }
    const reports = values.statuses.map((status) => {
      const assetStatus = stringToAssetStatus(status.status[0])
      return {
        assetId: asset.id,
        assetGroupId: assignment?.assetGroupId,
        quantityReported: +status.quantity?.[0],
        type: AssetReportType.Inventory,
        statusChange: {
          status: assetStatus,
          active: isOperational(assetStatus),
        },
        inventoryReport,
      }
    })

    createAssetReportsMutation({ reports }).then((result) => {
      if (result.error) {
        errorSnack("Error submitting reports")
      } else {
        successSnack("Report Submitted")
        inventoryModalProps.handleCloseModal()
      }
    })
  }

  const updateSelectOptions = (selectedStatuses: string[]) => {
    statusOptions.map((so) => (so.disabled = selectedStatuses.includes(so.value)))
  }

  const validationSchema = Yup.object().shape({
    statuses: Yup.array().of(
      Yup.object().shape({
        status: Yup.array()
          .of(Yup.string().label("Status"))
          .test("validData", "Status required", (status) => !!status?.length),
        quantity: Yup.array()
          .of(Yup.string().label("Quantity"))
          .test("validData", "Quantity required", (quantity) => !!quantity?.length),
      })
    ),
  })

  const getStatusOptions = (values: FormikValues, index: number) => {
    const length = (+values.statuses[index].quantity + +values.statuses[0].quantity || +values.statuses[0].quantity) - 1

    return Array.from(Array(length), (_, i) => {
      const item = (i + 1).toString()

      return {
        id: item,
        label: item,
        searchableTextString: item,
        value: item,
      }
    })
  }

  return (
    <StandardModal {...inventoryModalProps}>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ values, setFieldValue, handleSubmit }) => (
          <>
            <ModalBody className="flex flex-col gap-2">
              <ChecklistCounter assets={[asset]} />
              <div className="flex flex-col gap-4">
                <AssetInventoryReportItem key={asset.id} asset={asset} showStatusPill={false}>
                  <FieldArray
                    name="statuses"
                    render={(arrayHelpers: ArrayHelpers) => (
                      <Form>
                        <div>
                          {(values.statuses || []).map((_, index) => {
                            updateSelectOptions(values.statuses.map((s) => s.status?.[0]))
                            return (
                              <div key={index}>
                                <div className="flex justify-between items-center gap-4">
                                  <div className="w-32">
                                    <MultiSelect
                                      placeholder="Select"
                                      name={`statuses.${index}.quantity`}
                                      options={getStatusOptions(values, index)}
                                      isSingleSelect
                                      disabled={index === 0}
                                      onChange={() => adjustQuantities(values, setFieldValue)}
                                    />
                                  </div>
                                  <div className="flex gap-1 flex-1">
                                    <div className="flex-1">
                                      <MultiSelect
                                        name={`statuses.${index}.status`}
                                        options={statusOptions}
                                        isSingleSelect
                                      />
                                    </div>
                                    <div className="self-center w-12 pb-3 px-4">
                                      {!!index && (
                                        <button
                                          type="button"
                                          onClick={() => {
                                            arrayHelpers.remove(index)
                                            if (index === values.statuses.length - 1) {
                                              adjustQuantities(values, setFieldValue)
                                            }
                                          }}
                                        >
                                          <BiX />
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        {+values.statuses[0].quantity > 1 && values.statuses.length < statusOptions.length && (
                          <AddButton
                            className="container"
                            label="Add quantity status change"
                            onClick={() => {
                              if (values.statuses.length < statusOptions.length) {
                                arrayHelpers.push({ status: [], quantity: [] })
                                values.assets[asset.id] = {
                                  ...values.assets[asset.id],
                                  status: Available,
                                }
                              }
                            }}
                          />
                        )}
                      </Form>
                    )}
                  />
                </AssetInventoryReportItem>
              </div>
            </ModalBody>
            <ModalFooter>
              <ButtonHollow type="button" onClick={inventoryModalProps.handleCloseModal}>
                Cancel
              </ButtonHollow>
              <ButtonFilled type="button" onClick={() => handleSubmit()}>
                Report
              </ButtonFilled>
            </ModalFooter>
          </>
        )}
      </Formik>
    </StandardModal>
  )
}

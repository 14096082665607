import { FC, useState } from "react"
import { BiChevronDown, BiChevronUp, BiSubdirectoryRight } from "react-icons/bi"
import { useQuery } from "urql"
import { Asset, AssetAssignableType } from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { classNames } from "../../../helpers/classNames"
import { useAssetListGroupConsolidation } from "../../../hooks/useAssetListGroupConsolidation"
import { PickPlus } from "../../../types/helpers"
import { AssetBadge } from "../../AssetBadge"
import { AssetImage } from "../../AssetImage"
import { ButtonFilled } from "../../Elements"
import { IconButton } from "../../IconButton"
import { Monogram } from "../../Monogram"
import { Row } from "../../Table/Row"
import { AssetStatusPill } from "./AssetStatusPill"

type AssetExpectation = PickPlus<
  Asset,
  | "id"
  | "active"
  | "assetChildCount"
  | "assetGroupId"
  | "assignableId"
  | "assignableType"
  | "assignedAssetId"
  | "assignedUserId"
  | "companyAssetNumber"
  | "deletedAt"
  | "imageUrl"
  | "isAssetGroup"
  | "name"
  | "ownershipType"
  | "status"
>

type Props = {
  asset: AssetExpectation
  assignableAssets: PickPlus<Asset, "id" | "assignedAssetId">[]
  activeAssets: AssetExpectation[]
  className?: string
  indentLevel?: number
  forceOpen?: boolean
  parentIsCollapsed?: boolean
  onAssign?: (selectedAssignable: AssetExpectation, selectedAssignableType: AssetAssignableType) => void
  searchTerm?: string
}

const AssetReassignmentRowDocument = graphql(`
  query AssetReassignmentRow($assetId: String!) {
    asset(id: $assetId) {
      id
      isAssetGroup
      compositeKey
      childAssets {
        id
        assignableId
        assignableType
        assignedUserId

        active
        assetChildCount
        compositeKey
        imageUrl
        isAssetGroup
        name
        ownershipType
        status
      }
      childAssetGroups {
        assetGroupId
        assignableId
        assignableType
        status
        compositeKey
        count

        groupParent {
          id
          name
          imageUrl
          ownershipType
        }
      }
    }
  }
`)

export const AssetReassignmentRow: FC<Props> = ({
  activeAssets,
  asset,
  assignableAssets,
  className = "",
  forceOpen = false,
  indentLevel = 0,
  onAssign = () => {},
  parentIsCollapsed = false,
  searchTerm = "",
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true)

  const [{ data: queryData }] = useQuery({
    query: AssetReassignmentRowDocument,
    variables: { assetId: asset.id },
    pause: asset.isAssetGroup || asset.assetChildCount < 1,
  })

  const combinedAssetChildren = useAssetListGroupConsolidation({
    assets: queryData?.asset?.childAssets || [],
    assetGroups: queryData?.asset?.childAssetGroups || [],
  })

  // you can't assign an asset to itself
  if (assignableAssets.find((a) => a.id === asset.id)) {
    return null
  }

  const childAssets = queryData?.asset?.isAssetGroup ? [] : combinedAssetChildren

  const allParents: AssetExpectation[] = getAllParents(asset, activeAssets).reverse()

  // You also can't assign an asset to its parent  TODO: Ryan.... make sure you've covered that

  return (
    <>
      <Row
        className={classNames(
          "grid grid-cols-12 md:gap-y-6 group grow transition-opacity",
          "md:grid-flow-row md:grid-rows-1 md:items-center md:gap-x-6",
          !asset.name.toLowerCase().includes(searchTerm.toLowerCase()) && "hidden",
          className
        )}
      >
        <div className="col-span-12 flex justify-between gap-2 md:col-span-4">
          <div className="flex gap-x-3 items-center">
            <div className="flex gap-2">
              {indentLevel > 0 && (
                <div className="flex items-center gap-[5px]">
                  {[...Array(indentLevel)].map((_e, i) => (
                    <div
                      key={i}
                      className={classNames(
                        "w-[5px] h-12 md:h-10 rounded-full",
                        i + 1 === indentLevel ? "bg-gray-800" : "bg-gray-200"
                      )}
                    />
                  ))}
                </div>
              )}
              <AssetBadge asset={asset} />
            </div>
          </div>
        </div>
        <div className={"col-span-12 mt-2 md:mt-0 md:col-span-3"}>
          {asset.assignableType === AssetAssignableType.User && asset.assignedUserId && (
            <>
              <div className="col-span-12 uppercase mt-6 md:mt-0 flex items-center font-medium text-sm text-gray-400 md:hidden">
                Assignment
              </div>
              <AssignedUserDisplayBadge userId={asset.assignedUserId} />
            </>
          )}
          {asset.assignableType === AssetAssignableType.Asset && forceOpen && (
            <div className="items-center grid gap-2">
              {allParents.map((parent, index) => (
                <div
                  className="flex gap-1 items-center bg-red-500"
                  key={parent.id}
                  title={parent.companyAssetNumber || ""}
                  style={{
                    paddingLeft: `${Math.max(0, index * 24 - 18)}px`,
                  }}
                >
                  {index !== 0 && <BiSubdirectoryRight className="text-gray-300" />}
                  <div className="flex items-center gap-2 text-sm">
                    <AssetImage width="w-6" height="h-6" asset={parent} />
                    {parent.name}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className={classNames("py-1 col-span-12 mt-2 text-blue-600", "md:mt-0 md:col-span-2")}>
          <div className="col-span-12 uppercase mt-2 md:mt-0 flex items-center font-medium text-sm text-gray-400 md:hidden">
            Status
          </div>
          <div className="mt-2 md:mt-0">
            <AssetStatusPill asset={asset} />
          </div>
        </div>
        <div className={classNames("hidden mt-2", "md:flex md:mt-0 md:col-span-1 justify-self-end")}>
          {!forceOpen && !queryData?.asset?.isAssetGroup && asset.assetChildCount > 0 && (
            <IconButton
              Icon={isCollapsed ? BiChevronDown : BiChevronUp}
              onClick={() => setIsCollapsed((ic) => !ic)}
              color="white"
            />
          )}
        </div>

        <div className="col-span-12 md:hidden mt-4">
          {!forceOpen && !asset.isAssetGroup && asset.assetChildCount > 0 && (
            <>
              <button
                className="w-full bg-gray-50 h-10 px-2.5 gap-x-2 flex justify-center items-center active:bg-gray-300 ease-in-out transition-colors rounded-md"
                onClick={() => setIsCollapsed((ic) => !ic)}
                type="button"
              >
                {isCollapsed ? <BiChevronDown className="w-6 h-6" /> : <BiChevronUp className="w-6 h-6" />}
              </button>
            </>
          )}
        </div>

        <div className="col-span-12 md:col-span-2 mt-2 md:mt-0 flex justify-end">
          <ButtonFilled
            disabled={assignableAssets.every((a) => a.assignedAssetId === asset.id) || !!asset.assetGroupId}
            className="py-3"
            color="primary"
            type="button"
            onClick={() => onAssign(asset, AssetAssignableType.Asset)}
          >
            Assign
          </ButtonFilled>
        </div>
      </Row>

      {/* Recursively render child assets until we run out */}
      {childAssets?.length > 0 &&
        childAssets.map((childAsset) => {
          return (
            <AssetReassignmentRow
              activeAssets={activeAssets}
              asset={childAsset}
              assignableAssets={assignableAssets}
              className={classNames(!forceOpen && (parentIsCollapsed || isCollapsed) && "hidden")}
              forceOpen={forceOpen}
              indentLevel={indentLevel + 1}
              key={childAsset.id}
              parentIsCollapsed={isCollapsed || parentIsCollapsed}
              onAssign={onAssign}
              searchTerm={searchTerm}
            />
          )
        })}
    </>
  )
}

const AssignedUserDisplayBadgeQueryDocument = graphql(`
  query AssignedUserDisplayBadge($id: String!) {
    user(id: $id) {
      id
      firstName
      imageUrl
      jobTitle
      lastName
      archived
      isClockedIn
    }
  }
`)
const AssignedUserDisplayBadge = ({ userId }: { userId: string }) => {
  const [{ data }] = useQuery({
    query: AssignedUserDisplayBadgeQueryDocument,
    variables: { id: userId },
    requestPolicy: "cache-first",
    pause: !userId,
  })

  if (!data?.user) {
    return null
  }

  return (
    <div className="flex gap-3 items-center">
      <Monogram width="w-8" height="h-8" user={data?.user!} />
      {data?.user?.firstName} {data?.user?.lastName}
    </div>
  )
}

const getAllParents = (asset: AssetExpectation, assets?: AssetExpectation[]): AssetExpectation[] => {
  if (!assets) return []

  const parentAsset = assets.find((a) => a.id === asset.assignedAssetId)

  if (!parentAsset) {
    return []
  }

  return [parentAsset, ...getAllParents(parentAsset, assets)]
}

import { format } from "date-fns"
import { FC, memo } from "react"
import { BiError } from "react-icons/bi"
import { ModernUserWithTimeEntry } from "."
import { PickPlus } from "../../../../types/helpers"
import { Monogram } from "../../../Monogram"

type Props = {
  user: PickPlus<ModernUserWithTimeEntry, "firstName" | "imageUrl" | "lastName" | "jobTitle"> &
    Pick<ModernUserWithTimeEntry, "latestTimeEntry">
  type: "in" | "out"
}

const ConflictTeamMemberRowComponent: FC<Props> = ({ user, type }) => {
  return (
    <div>
      <div className={"flex gap-4 items-center"}>
        <Monogram user={user} className={"grayscale brightness-125"} />
        <div className={"grow"}>
          <p className={"text-gray-400"}>
            {user.firstName} {user.lastName}
          </p>
          <p className={"text-sm text-gray-300"}>{user.jobTitle}</p>
        </div>
        <div className={"h-10 w-10 bg-red-600 rounded flex justify-center items-center"}>
          <BiError className={"h-6 w-6 text-white"} />
        </div>
      </div>
      <p className={"text-sm text-red-600 ml-14 mt-2"}>
        {user.firstName} {user.lastName} cannot be clocked {type} before{" "}
        {format(
          type === "in" && user.latestTimeEntry?.endAt ? user.latestTimeEntry?.endAt : user.latestTimeEntry?.startAt!,
          "h:mm aaa"
        )}
        .
      </p>
    </div>
  )
}

export const ConflictTeamMemberRow = memo(ConflictTeamMemberRowComponent, (prevProps, nextProps) => {
  const timeEntriesMatch =
    JSON.stringify(prevProps.user.latestTimeEntry) === JSON.stringify(nextProps.user.latestTimeEntry)

  return timeEntriesMatch
})

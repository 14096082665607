import { TaskListItem } from "../../graphql/generated/gql/graphql"

export type TaskListAction = {
  type: string
  payload?: any
}

type TaskListProviderFilterType = "reports" | "team" | "assets" | "subtasks"

export type TaskListProviderFilters = Array<TaskListProviderFilterType>

export type TaskList = TaskListItem[]

export type TaskListState = {
  filters: TaskListProviderFilters
  showReport: string[]
  showTeam: string[]
  showAssets: string[]
  expanded: string[]
  taskList: TaskList
  refreshTasksAndGroups: boolean
  refreshGroupTasks: boolean
}

export const initialTaskListState: TaskListState = {
  filters: ["subtasks"],
  showReport: [],
  showTeam: [],
  showAssets: [],
  expanded: [],
  taskList: [],
  refreshTasksAndGroups: true,
  refreshGroupTasks: false,
}

export const TASK_LIST_ACTION_TYPES = {
  addTasks: "ADD_TASKS",
  refreshTaskList: "REFRESH_TASK_LIST",
  refreshTaskListSuccess: "REFRESH_TASKS_AND_GROUPS_SUCCESS",
  toggleTaskAssets: "TOGGLE_TASK_ASSETS",
  toggleTaskCompletion: "TOGGLE_TASK_COMPLETION",
  toggleTaskGroupAccordion: "TOGGLE_TASK_GROUP_ACCORDION",
  toggleTaskReport: "TOGGLE_TASK_REPORT",
  toggleTaskTeam: "TOGGLE_TASK_TEAM",
  updateFilters: "UPDATE_FILTERS",
}

const newFilterDisplayState = (
  previousState: TaskListState,
  newFilters: TaskListProviderFilters,
  type: TaskListProviderFilterType,
  ids: string[]
) => {
  const showAll = newFilters.includes(type)
  const clearAll = previousState.filters.includes(type)

  if (showAll) {
    return [...ids]
  }

  let previous: string[]

  switch (type) {
    case "team":
      previous = previousState.showTeam
      break
    case "assets":
      previous = previousState.showAssets
      break
    default:
      previous = previousState.showReport
  }

  return clearAll ? [] : previous
}

export const taskListReducer = (state: TaskListState, action: TaskListAction) => {
  const { type, payload } = action
  let newFilters: TaskListProviderFilters
  let id: string
  let open: string[]
  const allIds = state.taskList.map((tl) => tl?.taskId || tl.taskGroupId!)
  const allParentIds = state.taskList.filter((task) => !task?.groupId).map((tl) => tl?.taskId || tl.taskGroupId!)
  const allGroupIds = state.taskList?.filter((task) => task.taskGroupId)?.map((tl) => tl.taskGroupId!)

  switch (type) {
    case TASK_LIST_ACTION_TYPES.updateFilters:
      newFilters = [...payload?.filters]

      const showReport = newFilterDisplayState(state, newFilters, "reports", allIds)
      const showTeam = newFilterDisplayState(state, newFilters, "team", allIds)
      const showAssets = newFilterDisplayState(state, newFilters, "assets", allIds)
      const openGroups = state.filters.length && !newFilters.length ? [] : [...allGroupIds]

      return {
        ...state,
        filters: newFilters,
        showReport,
        showTeam,
        showAssets,
        expanded: openGroups,
      }

    case TASK_LIST_ACTION_TYPES.toggleTaskReport:
      newFilters = [...state.filters]
      id = payload?.taskId
      let reports = [...state.showReport]
      open = [...state.expanded]

      if (reports?.includes(id)) {
        reports = reports?.filter((f) => f !== id)
        newFilters = state.filters?.filter((f) => f !== "reports")
      } else {
        reports.push(id)
        open.push(id)

        if (allParentIds.every((id) => reports.includes(id))) {
          newFilters.push("reports")
        }
      }

      return {
        ...state,
        filters: newFilters,
        showReport: reports,
        expanded: open,
      }

    case TASK_LIST_ACTION_TYPES.toggleTaskTeam:
      newFilters = [...state.filters]
      id = payload?.taskId
      open = [...state.expanded]

      let team = [...state.showTeam]

      if (team?.includes(id)) {
        team = team.filter((t) => t !== id)
        newFilters = state.filters?.filter((f) => f !== "team")
      } else {
        team.push(id)
        open.push(id)

        if (allGroupIds.some((groupId) => groupId === id)) {
          state.taskList.filter((task) => task.groupId === id).forEach((t) => team.push(t.taskId!))
        }

        if (allIds.every((id) => team?.includes(id))) {
          newFilters.push("team")
        }
      }

      return {
        ...state,
        filters: newFilters,
        showTeam: team,
        expanded: open,
      }

    case TASK_LIST_ACTION_TYPES.toggleTaskAssets:
      newFilters = [...state.filters]
      id = payload?.taskId
      open = [...state.expanded]

      let assets = [...state.showAssets]

      if (assets?.includes(id)) {
        assets = assets.filter((a) => a !== id)
        newFilters = state.filters.filter((f) => f !== "assets")
      } else {
        assets.push(id)
        open.push(id)

        if (allGroupIds.some((groupId) => groupId === id)) {
          state.taskList.filter((task) => task.groupId === id).forEach((t) => assets.push(t.taskId!))
        }

        if (allIds.every((id) => assets.includes(id))) {
          newFilters.push("assets")
        }
      }

      return {
        ...state,
        filters: newFilters,
        showAssets: assets,
        expanded: open,
      }

    case TASK_LIST_ACTION_TYPES.toggleTaskGroupAccordion:
      const isExpanded = state.expanded.some((id) => id === payload.taskGroupId)
      const expanded = isExpanded
        ? state.expanded.filter((id) => id !== payload.taskGroupId)
        : [...state.expanded, payload.taskGroupId]

      newFilters = [...state.filters]

      // if first subtask is expanded
      if (expanded.length > 0 && !state.filters.includes("subtasks")) newFilters.push("subtasks")

      // if last subtask is closed
      if (expanded.length < 1 && state.filters.includes("subtasks"))
        newFilters = state.filters.filter((f) => f !== "subtasks")

      return { ...state, expanded, filters: newFilters }

    case TASK_LIST_ACTION_TYPES.toggleTaskCompletion:
      const newTaskList = [...state.taskList]
      // update completed count of the task
      const taskIndex = newTaskList.findIndex(({ taskId }) => taskId === payload.taskId)
      newTaskList[taskIndex].completedTaskCount = payload.isComplete ? 1 : 0
      // update completed count of tasks in the group task
      if (payload.groupId) {
        const groupTaskIndex = newTaskList.findIndex(({ taskGroupId }) => taskGroupId === payload.groupId)
        newTaskList[groupTaskIndex].completedTaskCount = newTaskList
          .filter(({ groupId }) => groupId && groupId === payload.groupId)
          .reduce((total, { completedTaskCount }) => total + completedTaskCount, 0)
      }
      return { ...state, taskList: newTaskList }

    case TASK_LIST_ACTION_TYPES.addTasks:
      const expandedGroupIds = state.filters.includes("subtasks")
        ? (state.taskList || []).filter((task) => task.taskGroupId)?.map((tl) => tl.taskGroupId!)
        : []

      return {
        ...state,
        taskList: payload?.taskList || [],
        expanded: expandedGroupIds,
      }

    case TASK_LIST_ACTION_TYPES.refreshTaskList:
      return { ...state, refreshTasksAndGroups: true, refreshGroupTasks: true }
    case TASK_LIST_ACTION_TYPES.refreshTaskListSuccess:
      return { ...state, refreshTasksAndGroups: false }

    default:
      return state
  }
}

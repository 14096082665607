import { TimeEntry } from "../../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../../types/helpers"

type TimeEntryWithDates = PickPlus<TimeEntry, "startAt" | "endAt">

const getLatestDate = (timeEntry: TimeEntryWithDates | undefined | null): Date | undefined => {
  return timeEntry?.endAt ? timeEntry.endAt : timeEntry?.startAt
}

export function sortUsersWithLatestTimeEntryByTimeAscending<U, T extends TimeEntryWithDates>(
  a: { user: U; latestTimeEntry?: T | undefined | null },
  b: { user: U; latestTimeEntry?: T | undefined | null }
): number {
  return sortByLatestTimeEntryByTimeAscending(a, b)
}

export function sortByLatestTimeEntryByTimeAscending<T extends TimeEntryWithDates>(
  a: { latestTimeEntry?: T | undefined | null },
  b: { latestTimeEntry?: T | undefined | null }
): number {
  const aLatestDate = getLatestDate(a.latestTimeEntry)
  const bLatestDate = getLatestDate(b.latestTimeEntry)

  if (aLatestDate && bLatestDate) {
    return bLatestDate.getTime() - aLatestDate.getTime()
  }

  if (aLatestDate && !bLatestDate) return -1

  if (!aLatestDate && bLatestDate) return 1

  return 0
}

import { FC, useMemo } from "react"
import { classNames } from "../helpers/classNames"

type Source = {
  value: number
  underClassName: string
  completedClassName: string
  overClassName: string
}

type Props = {
  sources: Source[]
  expectedMaximum: number
  expectedMaximumClassName?: string
  onlyBar?: boolean
}

export const StackedHorizontalBarChart: FC<Props> = ({
  sources,
  expectedMaximum,
  expectedMaximumClassName = "bg-gray-200",
  onlyBar = false,
}) => {
  const sourceSums = useMemo(() => {
    return sources.map(({ value }) => value).reduce((total, current) => current + total, 0)
  }, [sources])

  const elements = useMemo(() => {
    if (sourceSums <= expectedMaximum) {
      return sources.map((source) => ({
        ...source,
        value: (source.value / expectedMaximum) * 100,
      }))
    }

    return sources.map((source) => ({
      ...source,
      value: (source.value / sourceSums) * 100,
    }))
  }, [sources, sourceSums, expectedMaximum])
  return (
    <div className="flex flex-col gap-2">
      {!onlyBar && (
        <div className="text-xs text-gray-500">
          Total: {Math.floor(sourceSums * 10) / 10} / {expectedMaximum}
        </div>
      )}
      <div className={classNames("flex bg-gray-200 h-2 clipped-rounded", expectedMaximumClassName)}>
        {elements
          .filter((element) => element.value > 0)
          .map((element, i) => (
            <div
              key={`element-${i}`}
              className={classNames(
                "h-2 outline outline-white outline-1",
                sourceSums < expectedMaximum && element.underClassName,
                sourceSums === expectedMaximum && element.completedClassName,
                sourceSums > expectedMaximum && element.overClassName
              )}
              style={{ width: `${element.value}%` }}
            />
          ))}
      </div>
    </div>
  )
}

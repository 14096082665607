import Link from "next/link"
import { FC } from "react"
import { BiLayer, BiStopwatch } from "react-icons/bi"
import { User } from "../../../graphql/generated/client-types-and-hooks"
import { classNames } from "../../../helpers/classNames"
import { useModalProps } from "../../../hooks/useModalProps"
import { useUpdatingUserClockedInHoursAndMinutes } from "../../../hooks/useUpdatingUserClockedInHoursAndMinutes"
import { PickPlus } from "../../../types/helpers"
import { ClockInOut } from "../../ClockInOut"
import { ButtonFilled, H5 } from "../../Elements"
import StandardModal from "../../Modals/StandardModal"
import { successSnack } from "../../Notistack/ThemedSnackbars"
import { QuickActionContainer } from "../../QuickActionContainer"
import { QuickActionRow } from "../../QuickActionRow"
import { RenderIf } from "../../RenderIf"
import { DrawerLink } from "../Drawer/DrawerLink"
import { TaskDrawer } from "../Drawer/TaskDrawer"
import { ReassignUserTaskForm } from "./ReassignUserTaskForm/ReassignUserTaskForm"

type Props = {
  user: PickPlus<
    User,
    | "id"
    | "archived"
    | "currentProjectId"
    | "currentTaskId"
    | "firstName"
    | "isClockedIn"
    | "jobTitle"
    | "lastName"
    | "projectId"
    | "secondsClockedSinceOrgMidnight"
    | "taskId"
  > & {
    currentProject?: PickPlus<User["currentProject"], "id" | "name"> | null
    currentTask?: PickPlus<User["currentTask"], "id" | "name"> | null
  }
  refetchUser: () => void
}

export const UserQuickActions: FC<Props> = ({ refetchUser, user }) => {
  const { hoursClockedIn, minutesClockedIn } = useUpdatingUserClockedInHoursAndMinutes(user)
  const reassignUserTaskFormModalProps = useModalProps("Reassign")

  return (
    <div>
      <H5>Quick Actions</H5>
      <hr />
      <QuickActionContainer>
        <QuickActionRow
          icon={<BiStopwatch className={classNames("h-6 w-6", user.isClockedIn && "text-blue-600")} />}
          title="Time Status"
          subTitle={
            <>
              <p>Clocked {user.isClockedIn ? "in" : "out"}</p>
              <p>/</p>
              <p>
                {hoursClockedIn} hr {minutesClockedIn} min
              </p>
            </>
          }
          action={
            <RenderIf
              permissionsInclude={["timeEntry:clockInOut"]}
              context={{ projectId: user.projectId, userId: user.id }}
            >
              <ClockInOut user={user} />
            </RenderIf>
          }
        />

        <QuickActionRow
          icon={<BiLayer className="h-5 w-5" />}
          title="Current Assignment"
          subTitle={
            <>
              <p>
                <Link href={`/projects/${user?.projectId}`} className="hover:underline">
                  {user.currentProject?.name}
                </Link>
              </p>
              <p>/</p>
              {user.currentTask?.id && (
                <p>
                  <DrawerLink
                    href={`/projects/${user?.projectId}/${user.taskId}`}
                    className="hover:underline"
                    component={<TaskDrawer taskId={user.currentTask.id} />}
                  >
                    {user.currentTask?.name}
                  </DrawerLink>
                </p>
              )}
            </>
          }
          action={
            <RenderIf permissionsInclude={["user:assign"]}>
              <ButtonFilled
                color="black"
                onClick={() => {
                  reassignUserTaskFormModalProps.handleOpenModal()
                }}
                type="button"
              >
                Reassign
              </ButtonFilled>
            </RenderIf>
          }
        />
      </QuickActionContainer>

      {reassignUserTaskFormModalProps.isOpen && (
        <StandardModal className="overflow-y-visible" {...reassignUserTaskFormModalProps}>
          <ReassignUserTaskForm
            onCancel={() => {
              reassignUserTaskFormModalProps.handleCloseModal()
            }}
            onSuccess={() => {
              successSnack("Selected users have been successfully reassigned.")
              refetchUser()
              reassignUserTaskFormModalProps.handleCloseModal()
            }}
            preselectedUsers={[user]}
            projectId={user.currentProjectId}
          />
        </StandardModal>
      )}
    </div>
  )
}

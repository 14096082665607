import {
  ProjectUnitsTableProgressQuery,
  TaskUnitsTableProgressQuery,
} from "../../../../graphql/generated/client-types-and-hooks"
import { TaskDetails } from "../Sections/ProjectAndTaskSummaryUnitsTable"

type ProjectUnitsExpectation = NonNullable<ProjectUnitsTableProgressQuery["project"]>["unitGoals"]

type TaskUnitsExpectation = NonNullable<TaskUnitsTableProgressQuery["task"]>["unitGoals"]

type AggregateUnitProps = {
  [description: string]: {
    targetQuantity: number
    recentProgress: number
    currentProgress: number
    unitType: string | undefined
    totalProgress: number
    previousProgress: number
    tasks: TaskDetails[]
    color: string
  }
}

export function aggregateUnitGoals(unitGoalsData: ProjectUnitsExpectation | TaskUnitsExpectation): AggregateUnitProps {
  const result: AggregateUnitProps = {}

  unitGoalsData.forEach((unitGoal) => {
    const description = unitGoal.deliverableUnit.description
    const recentProgress = unitGoal.totalProgress - unitGoal.progressInDateRange - unitGoal.previousProgress

    if (!result[description]) {
      result[description] = {
        unitType: unitGoal.deliverableUnit.unitOfMeasure,
        targetQuantity: 0,
        previousProgress: 0,
        currentProgress: 0,
        recentProgress: recentProgress,
        totalProgress: 0,
        color: unitGoal.deliverableUnit.color,
        tasks: [],
      }
    }
    result[description].targetQuantity += unitGoal?.targetQuantity || 0
    result[description].currentProgress += unitGoal.progressInDateRange
    result[description].totalProgress += unitGoal.totalProgress
    result[description].previousProgress += unitGoal.previousProgress
    result[description].recentProgress = recentProgress

    if (isProjectUnitGoal(unitGoal)) {
      result[description].tasks.push({
        id: unitGoal.task.id,
        name: unitGoal.task.name,
        unitType: unitGoal.deliverableUnit.unitOfMeasure,
        targetQuantity: unitGoal.targetQuantity || 0,
        currentProgress: unitGoal.progressInDateRange,
        totalProgress: unitGoal.totalProgress,
        previousProgress: unitGoal.previousProgress,
        recentProgress: recentProgress,
        estimatedHours: unitGoal.task.estimatedHours || 0,
        timeEntriesSumDurationInSeconds: unitGoal.task.timeEntriesSumDurationInSeconds,
      })
    }
  })

  return result
}

export function isProjectUnitGoal(
  goal: ProjectUnitsExpectation[0] | TaskUnitsExpectation[0]
): goal is ProjectUnitsExpectation[0] {
  return "task" in goal && typeof goal.task.name === "string"
}

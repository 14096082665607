import { Box, Stack } from "@mui/material"
import { FC, useMemo, useState } from "react"
import { Bar, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import { useQuery } from "urql"
import { graphql } from "../../../graphql/generated/gql"
import { colors } from "../../../helpers/colors"
import { ToggleChip } from "./ChartLegendChip"
import { SummaryUnitsTableProps } from "./Sections/ProjectAndTaskSummaryUnitsTable"
import { mergeDataWithPlaceholders } from "./helpers/generateGraphLabels"
import { prepareDataForRecharts } from "./helpers/getGraphData"
import { useInitialVisibility } from "./helpers/useInitialVisibilityHook"

const ProjectUnitsGraphProgressDocument = graphql(`
  query ProjectUnitsGraphProgress(
    $dateRangeType: String!
    $rangeStart: DateTime!
    $rangeEnd: DateTime!
    $entityId: String!
  ) {
    project(id: $entityId) {
      id
      hoursDataByDateRangeType(dateRangeType: $dateRangeType, rangeEnd: $rangeEnd, rangeStart: $rangeStart)
      startDate
      endDate
      taskProgressEventsWithUnitReports(rangeEnd: $rangeEnd, rangeStart: $rangeStart) {
        id
        createdAt
        unitGoalProgressReports {
          id
          progress
          unitGoal {
            id
            deliverableUnit {
              description
              color
            }
          }
        }
      }
    }
  }
`)

const TaskUnitsGraphProgressDocument = graphql(`
  query TaskUnitsGraphProgress(
    $dateRangeType: String!
    $rangeStart: DateTime!
    $rangeEnd: DateTime!
    $entityId: String!
  ) {
    task(id: $entityId) {
      id
      hoursDataByDateRangeType(dateRangeType: $dateRangeType, rangeEnd: $rangeEnd, rangeStart: $rangeStart)
      startDate
      endDate
      taskProgressEventsWithUnitReports(rangeEnd: $rangeEnd, rangeStart: $rangeStart) {
        id
        createdAt
        unitGoalProgressReports {
          id
          progress
          unitGoal {
            id
            deliverableUnit {
              description
              color
            }
          }
        }
      }
    }
  }
`)

export type UnitGoalReports = {
  id: string
  progress: number
  unitGoal: {
    id: string
    deliverableUnit: {
      description: string
      color: string
    }
  }
}

type TaskProgressEvent = {
  id: string
  createdAt: Date
  unitGoalProgressReports: UnitGoalReports[]
}

type UnitsGraphDataType = {
  project?: {
    id: string
    startDate: Date
    endDate: Date
    taskProgressEventsWithUnitReports: TaskProgressEvent[]
    hoursDataByDateRangeType: any
  }
  task?: {
    id: string
    startDate: Date
    endDate: Date
    taskProgressEventsWithUnitReports: TaskProgressEvent[]
    hoursDataByDateRangeType: any
  }
}

export const SummaryUnitsGraph: FC<SummaryUnitsTableProps> = ({
  dateRangeType = "daily",
  rangeStart,
  entityType,
  rangeEnd,
  entityId,
}) => {
  const [{ data }] = useQuery<UnitsGraphDataType>({
    query: entityType === "task" ? TaskUnitsGraphProgressDocument : ProjectUnitsGraphProgressDocument,
    variables: {
      rangeStart,
      rangeEnd,
      entityId,
      dateRangeType,
    },
    pause: !entityId,
  })

  const dataForGraph = data?.task || data?.project

  const groupedUnits = useMemo(() => {
    const defaultGroupedUnits = { data: [], units: [] }
    if (dataForGraph) {
      const preparedData = prepareDataForRecharts(dataForGraph, dateRangeType)
      const mergedData = mergeDataWithPlaceholders(
        preparedData.data,
        dateRangeType,
        rangeStart,
        dataForGraph.startDate,
        dataForGraph.endDate
      )
      return { ...preparedData, data: mergedData }
    }
    return defaultGroupedUnits
  }, [dateRangeType, dataForGraph, rangeStart])

  const { data: chartData, units } = groupedUnits

  const [unitsVisibility, toggleUnitVisibility] = useInitialVisibility(units)
  const [hoursVisibility, setHoursVisibility] = useState(true)

  const filteredChartData = chartData.map((data) => {
    let filteredData = { ...data }
    Object.keys(unitsVisibility).forEach((unitDescription) => {
      if (!unitsVisibility[unitDescription]) {
        delete filteredData[unitDescription]
      }
    })
    return filteredData
  })

  return (
    data && (
      <Box
        paddingY={3}
        style={{
          border: `1px solid ${colors.neutral[200]}`,
          borderRadius: "8px",
        }}
      >
        <Stack direction="row" flexWrap="wrap" marginLeft="40px" paddingBottom={3}>
          <ToggleChip
            label="Hours"
            color={colors.blue[600]}
            isActive={hoursVisibility}
            toggleVisibility={() => setHoursVisibility(!hoursVisibility)}
          />
          {units.map((unit) => (
            <ToggleChip
              key={unit.description}
              label={unit.description}
              color={unit.color}
              isActive={unitsVisibility[unit.description]}
              toggleVisibility={() => toggleUnitVisibility(unit.description)}
            />
          ))}
        </Stack>

        <ResponsiveContainer width="100%" height={300}>
          <ComposedChart
            width={500}
            height={300}
            data={filteredChartData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 20,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis dy={15} dataKey="name" />
            <YAxis yAxisId="left" orientation="left" label={{ value: "Units", dy: 18, position: "bottom" }} />
            <YAxis yAxisId="right" orientation="right" label={{ value: "Hours", dy: 18, position: "bottom" }} />

            <Tooltip />
            {hoursVisibility && (
              <Line
                activeDot={{ r: 8 }}
                dataKey="hours"
                dot={false}
                stroke={colors.blue[600]}
                strokeDasharray="4 4"
                strokeWidth={2}
                type="monotone"
                yAxisId="right"
              />
            )}
            {units?.map((unit, i) => (
              <Bar yAxisId="left" key={i} dataKey={unit.description} stackId="a" barSize={40} fill={unit.color} />
            ))}
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    )
  )
}

import { FC, useContext } from "react"
import { BiDotsHorizontalRounded, BiEdit, BiTrash } from "react-icons/bi"
import { FaCheckCircle, FaCircle } from "react-icons/fa"
import { Task } from "../../../graphql/generated/client-types-and-hooks"
import { useModalProps } from "../../../hooks/useModalProps"
import { DrawerContext } from "../../../providers/DrawerProvider"
import { PermissionsContext } from "../../../providers/PermissionsProvider/PermissionsProvider"
import { PickPlus } from "../../../types/helpers"
import { H2 } from "../../Elements"
import { MenuItem, QuickMenuMui } from "../../QuickMenuMui"
import { RenderIf } from "../../RenderIf"
import { DeprecatedTabGroup, Tab } from "../../deprecated/TabGroup"
import { DrawerBody } from "../Drawer/DrawerBody"
import { CreateOrEditTaskForm } from "../Tasks/CreateOrEditTaskForm"
import { CompletedTaskBanner } from "./Tasks/CompletedTaskBanner"
import { DeleteTaskModal } from "./Tasks/DeleteTaskModal"
import ToggleCompletionButtonWrapper from "./ToggleCompletionButtonWrapper"

type Props = {
  task: PickPlus<
    Task,
    "id" | "assetCount" | "isComplete" | "isDefault" | "name" | "parentTaskId" | "projectId" | "userCount" | "group"
  > & { project: PickPlus<Task["project"], "id" | "isArchived" | "isComplete" | "name"> }

  withTabs: boolean
}

export const tabs: Tab[] = [
  {
    label: "Details",
    href: "/projects/[_project_id]/[_task_id]/details",
  },
  {
    label: "Sub-tasks",
    href: "/projects/[_project_id]/[_task_id]/sub-tasks",
  },
  {
    label: "team",
    href: "/projects/[_project_id]/[_task_id]/team",
  },
  {
    label: "assets",
    href: "/projects/[_project_id]/[_task_id]/assets",
  },
  {
    label: "Summary",
    href: "/projects/[_project_id]/[_task_id]/summary",
  },
]

export const TaskDetailsLayout: FC<Props> = ({ task, withTabs = true }) => {
  const { push: pushDrawer, pop: closeDrawer } = useContext(DrawerContext)
  const { hasPermissionTo } = useContext(PermissionsContext)
  const deleteTaskModalProps = useModalProps("Delete Task")

  const menuItems: MenuItem[][] = []

  if (hasPermissionTo("task:update")) {
    menuItems.push([
      {
        value: "Edit",
        onClick: () =>
          pushDrawer(
            <div>
              <DrawerBody>
                <CreateOrEditTaskForm
                  task={task}
                  onCancel={closeDrawer}
                  onSuccess={closeDrawer}
                  group={task.group || undefined}
                />
              </DrawerBody>
            </div>
          ),
        Icon: BiEdit,
      },
    ])
  }

  // can't delete the default task
  if (!task.isDefault) {
    menuItems.push([
      {
        value: "Delete",
        onClick: () => deleteTaskModalProps.handleOpenModal(),
        Icon: BiTrash,
        color: "red",
      },
    ])
  }

  const CircleFallBack = (
    <>
      {task?.isComplete ? (
        <FaCheckCircle className="h-8 w-8 text-green-200" />
      ) : (
        <FaCircle className="h-8 w-8 text-gray-100" />
      )}
    </>
  )

  return (
    <>
      {task?.isComplete && <CompletedTaskBanner task={task} />}
      <div className="flex col-span-2 content-start">
        <>
          <div className="mt-2 md:mt-3">
            {task && (
              <>
                <RenderIf
                  permissionsInclude="task:complete"
                  context={{ projectId: task?.projectId }}
                  fallbackComponent={CircleFallBack}
                >
                  {task && <ToggleCompletionButtonWrapper task={task} project={task?.project} className="h-8 w-8" />}
                </RenderIf>
              </>
            )}
          </div>
          <div className="grow ml-4 flex gap-x-2 h-16">
            <H2 className="text-gray-800">{task?.name}</H2>
            <QuickMenuMui disabled={task.isDefault} className="self-center" items={menuItems} buttonShape="round">
              <BiDotsHorizontalRounded className="text-2xl" />
            </QuickMenuMui>

            <DeleteTaskModal
              modalProps={deleteTaskModalProps}
              closeModal={deleteTaskModalProps.handleCloseModal}
              task={task}
              isTaskGroup={false}
              onSuccess={closeDrawer}
            />
          </div>
        </>
      </div>
      {withTabs && task?.project && (
        <DeprecatedTabGroup
          tabs={tabs.filter((tab) => {
            if (tab.href === "/projects/[_project_id]/[_task_id]/sub-tasks") {
              return task?.parentTaskId && task.isDefault
            }

            const showTeamTab = task?.isComplete ? tab.href !== "/projects/[_project_id]/[_task_id]/team" : true
            return showTeamTab
          })}
        />
      )}
    </>
  )
}

import { FC, useContext } from "react"
import { H2 } from "../../Elements"
import { CreateOrEditAssetForm } from "../Assets/CreateOrEditAssetForm"
import { SingleDrawerContext } from "./Drawer"
import { DrawerBody } from "./DrawerBody"
import { DrawerHeader } from "./DrawerHeader"

export const AssetCreationDrawer: FC = () => {
  const { handleClose } = useContext(SingleDrawerContext)

  return (
    <>
      <DrawerHeader handleClose={handleClose} href="/assets/create" />

      <DrawerBody>
        <H2 className={"mb-8"}>New Asset</H2>
        <CreateOrEditAssetForm onCancel={handleClose} onSuccess={handleClose} />
      </DrawerBody>
    </>
  )
}

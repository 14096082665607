import { RefObject, useEffect, useState } from "react"

export const useDrawerWidth = (drawerRef: RefObject<HTMLDivElement> | null) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const getOffsets = () => ({
      width: drawerRef?.current?.offsetWidth || 0,
      height: drawerRef?.current?.offsetHeight || 0,
    })

    const handleResize = () => {
      setDimensions(getOffsets())
    }

    if (drawerRef?.current) {
      setDimensions(getOffsets())
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [drawerRef])

  return dimensions
}

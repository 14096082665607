import { FC } from "react"
import { BiChevronDown, BiChevronUp } from "react-icons/bi"
import { classNames } from "../helpers/classNames"

type Props = {
  isAscending: boolean
  isActive: boolean
}

export const SortChevron: FC<Props> = ({ isAscending, isActive }) => {
  return isAscending ? (
    <BiChevronDown className={classNames("h-6 w-6", !isActive && "text-gray-300")} />
  ) : (
    <BiChevronUp className={classNames("h-6 w-6", !isActive && "text-gray-300")} />
  )
}

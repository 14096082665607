import { Typography } from "@mui/material"
import { Form, Formik } from "formik"
import { FC } from "react"
import { useQuery } from "urql"
import * as Yup from "yup"
import {
  GetTaskForReportingQuery,
  ReportTaskProgressMutationVariables,
  useReportTaskProgressMutation,
} from "../graphql/generated/client-types-and-hooks"
import { graphql } from "../graphql/generated/gql"
import { useHandleError } from "../hooks/useHandleError"
import { TextField } from "./Formik/TextField"
import { ImageUploadPreviewList } from "./ImageUploadPreviewList"
import { MuiModal } from "./Modals/MuiModal"
import { errorSnack, successSnack } from "./Notistack/ThemedSnackbars"
import { UnitGoalRow } from "./UnitGoalRow"

type FileObject = { id: string; preview: string; file: File; signedUrl: string; uploaded: boolean; fileId: string }

const TasksQuery = graphql(`
  query GetTaskForReporting($taskId: String!) {
    task(id: $taskId) {
      id
      name
      isDefault
      projectId
      group {
        id
        name
      }
      unitGoals {
        id
        isPrimary
        targetQuantity
        totalProgress
        deliverableUnit {
          id
          description
          unitOfMeasure
        }
      }
    }
  }
`)

export const UnitReportModalContents: FC<{ task?: GetTaskForReportingQuery["task"] }> = ({ task }) => {
  const unitGoals = (task?.unitGoals || []).sort((a, b) => {
    if (a.isPrimary && !b.isPrimary) return -1
    if (!a.isPrimary && b.isPrimary) return 1
    return 0
  })

  return (
    <Form className={"h-full flex flex-col"}>
      <div className="flex flex-col gap-2">
        <Typography variant="subtitle2" className="uppercase mb-2 font-semibold" color="primary">
          {!task?.group ? (
            task?.name
          ) : (
            <div>
              <span className="text-gray-400">{task?.group.name} / </span>
              {task?.name}
            </div>
          )}
        </Typography>

        {unitGoals.map((unitGoal, i) => (
          <UnitGoalRow
            key={unitGoal.id}
            unitGoalFieldName={`unitGoalProgress[${i}].unitGoalId`}
            progressFieldName={`unitGoalProgress[${i}].progress`}
            unitGoal={unitGoal}
          />
        ))}

        <TextField multiline fullWidth label="Notes" name="note" required={false} rows={2} />

        <ImageUploadPreviewList disabled={!task?.id} prefix={`task/${task?.id}/image`} name="files" />
      </div>
    </Form>
  )
}

type UnitReportModalValueType = {
  taskId: string
  note: string
  files: FileObject[]
  unitGoalProgress: { unitGoalId: string; progress: number }[]
}

export const UnitReportModal: FC<{
  taskId: string
  isOpen: boolean
  closeModal: () => void
  onSuccess?: () => void
}> = ({ taskId, isOpen, closeModal, onSuccess = () => null }) => {
  const [, reportTaskProgressMutation] = useReportTaskProgressMutation()

  const [{ data: taskData, error }, _refetchTasks] = useQuery({
    query: TasksQuery,
    pause: !taskId,
    variables: { taskId },
  })

  useHandleError(error, "Could not fetch Task")

  const handleSubmit = (values: UnitReportModalValueType, resetForm: () => void) => {
    const data: ReportTaskProgressMutationVariables = {
      projectId: taskData?.task?.projectId!,
      taskId: values.taskId,
      note: values.note,
      unitGoalProgress: values.unitGoalProgress.filter((unitGoal) => unitGoal), // Protect against null values which are definitely possible because of the way the modal form contents edits the data
      fileIds: values.files.map(({ fileId }: FileObject) => fileId),
    }

    reportTaskProgressMutation(data).then((result) => {
      if (result.error) {
        errorSnack("Something went wrong with submitting the form")
      } else {
        successSnack("Report saved successfully")
        resetForm()
        closeModal()
        onSuccess()
      }
    })
  }

  if (!taskData) return null

  return (
    <Formik<UnitReportModalValueType>
      initialValues={{
        taskId: taskData.task.id,
        note: "",
        files: [],
        unitGoalProgress: [],
      }}
      validationSchema={Yup.object().shape({
        taskId: Yup.string(),
        note: Yup.string(),
        files: Yup.array(
          Yup.object().shape({
            uploaded: Yup.bool().required().oneOf([true], "Files must finish uploading"),
            fileId: Yup.string(),
          })
        ),
        unitGoalProgress: Yup.array(
          Yup.object().shape({
            unitGoalId: Yup.string(),
            progress: Yup.number()
              .typeError("Progress must be a number")
              .test(
                "maxDigits",
                "Amount cannot exceed 7 digits",
                (value) => String(Math.floor(Math.abs(value ?? 0))).length <= 7
              ),
          })
        ),
      })}
      onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
    >
      {({ submitForm, resetForm, values }) => (
        <MuiModal
          contentLabel="Task Report"
          submitButtonText="Report"
          isOpen={isOpen}
          handleCloseModal={() => {
            resetForm()
            closeModal()
          }}
          disabled={
            // at least one of these things must be provided
            !values.files.every((file: FileObject) => file.uploaded) &&
            !values.note &&
            values.unitGoalProgress.every((unitGoal: any) => !unitGoal?.progress)
          }
          submitForm={submitForm}
        >
          <UnitReportModalContents task={taskData?.task} />
        </MuiModal>
      )}
    </Formik>
  )
}

import { FC, memo, ReactNode } from "react"
import { classNames } from "../helpers/classNames"
import { TailwindIcon } from "../types/tailwind"

type Props = {
  Icon: TailwindIcon
  color: "blue" | "red"
  children: ReactNode
  className?: string
}

const InfoBannerComponent: FC<Props> = ({ Icon, color, children, className }) => {
  return (
    <div className={classNames(`flex text-${color}-600 bg-${color}-50 rounded px-3 py-2 gap-2 mb-2`, className)}>
      <Icon className={"h-6 w-6"} />
      <p className={"grow"}>{children}</p>
    </div>
  )
}

export const InfoBanner = memo(InfoBannerComponent, (prevProps, nextProps) => {
  const childrenMatch = prevProps.children === nextProps.children
  const classNameMatches = prevProps.className === nextProps.className

  return childrenMatch && classNameMatches
})

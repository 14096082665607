import Error from "next/error"
import { FC, useEffect, useMemo, useState } from "react"
import { BiSearchAlt2, BiX } from "react-icons/bi"
import { useQuery } from "urql"
import { graphql } from "../../../graphql/generated/gql"
import { classNames } from "../../../helpers/classNames"
import { useHandleError } from "../../../hooks/useHandleError"
import { H5 } from "../../Elements"
import { SearchInput } from "../../Elements/SearchInput"
import { IconButton } from "../../IconButton"
import { ColumnTitle } from "../../Table/ColumnTitle"
import { ColumnTitles } from "../../Table/ColumnTitles"
import { Table } from "../../Table/Table"
import { TableContextMenuContainer } from "../../TableContextMenuContainer"
import { AssetQuantitySkeleton } from "./AssetQuantity.skeleton"
import { AssetQuantityRow } from "./AssetQuantityRow"
import { AssetsEmptyState } from "./AssetsEmptyState"

const AssetQuantityTableDocument = graphql(`
  query AssetQuantityTable($assetId: String!) {
    asset(id: $assetId) {
      id
      status
      assetChildCount
      assetGroupId
      assignableId
      companyAssetNumber
      compositeKey
      groupQuantity
      imageUrl
      isAssetGroup
      name
      ownershipType
      lastInventoriedAt
      inventoryRequirements {
        photoRequired
        intervalInSeconds
      }
    }
    assetGroups(assetGroupId: $assetId) {
      assetGroupId
      assignableId
      assignableType
      assignedAssetId
      assignedTaskId
      assignedUserId
      count
      status
      assignedAsset {
        id
        assetChildCount
        assetGroupId
        assignableId
        companyAssetNumber
        compositeKey
        groupQuantity
        imageUrl
        isAssetGroup
        name
        status
        ownershipType
      }
      assignedTask {
        id
        name
        projectId
        project {
          id
          name
        }
      }
      assignedUser {
        id
        firstName
        imageUrl
        jobTitle
        lastName
      }
      compositeKey
      groupParent {
        id
        active
        assetChildCount
        imageUrl
        isAssetGroup
        name
        companyAssetNumber
        compositeKey
        ownershipType
      }
    }
  }
`)

export const AssetQuantityTable: FC<{
  active?: boolean
  assetId: string
  deleted?: boolean
  title?: string
}> = ({ active, assetId, deleted }) => {
  const [{ data, fetching, error }] = useQuery({
    query: AssetQuantityTableDocument,
    variables: { assetId },
  })

  const [tableContextMenuState, setTableContextMenuState] = useState<"initial" | "search">("initial")
  const [searchTerm, setSearchTerm] = useState("")
  const [searchIsOpen, setSearchIsOpen] = useState(false)

  useHandleError(error, "An error occurred while fetching asset details. Please reload the page to try again.")

  const assignmentGroups = data?.assetGroups

  const showEmptyState = useMemo(() => {
    return !fetching && assignmentGroups && assignmentGroups.length < 1
  }, [fetching, assignmentGroups])

  useEffect(() => {
    if (searchIsOpen) {
      setTableContextMenuState("search")
      return
    }

    setTableContextMenuState("initial")
  }, [searchIsOpen, searchTerm])

  const asset = useMemo(() => {
    if (!data?.asset) return null

    return data.asset
  }, [data])

  if (fetching) {
    return <AssetQuantitySkeleton />
  }

  if (!asset) {
    return <Error statusCode={404} />
  }

  return (
    <div>
      <div className={classNames("flex gap-x-8 flex-wrap justify-between", "md:pb-4 md:items-center")}>
        <H5>Asset Quantity</H5>

        <div className="relative justify-end min-h-5 w-auto pb-0 hidden md:flex">
          <TableContextMenuContainer isOpen={tableContextMenuState === "initial"}>
            <IconButton onClick={() => setSearchIsOpen(true)} Icon={BiSearchAlt2} />
          </TableContextMenuContainer>

          <TableContextMenuContainer isOpen={tableContextMenuState === "search"}>
            <SearchInput searchTerm={searchTerm} setSearchTerm={setSearchTerm} onClose={() => setSearchIsOpen(false)} />
            <IconButton
              onClick={() => {
                setSearchIsOpen(false)
                setSearchTerm("")
              }}
              Icon={BiX}
            />
          </TableContextMenuContainer>
        </div>
      </div>

      <ColumnTitles className="hidden md:block">
        <div className="md:col-span-2 md items-center">
          <ColumnTitle isSortable={false} className={"md:ml-2"}>
            Quantity
          </ColumnTitle>
        </div>
        <ColumnTitle isSortable={false} className="md:col-span-6">
          Assignee
        </ColumnTitle>
        <ColumnTitle isSortable={false} className="md:col-span-4">
          Status
        </ColumnTitle>
      </ColumnTitles>
      <Table>
        {showEmptyState && <AssetsEmptyState active={active} deleted={deleted} />}

        {assignmentGroups?.map((assignment) => {
          const key = [assignment.assignableId, assignment.assignableType, assignment.status].join()
          return (
            <AssetQuantityRow
              key={key}
              assignment={{ ...assignment }}
              asset={{
                ...asset,
                id: assignment.assetGroupId,
                assetGroupId: assignment.assetGroupId,
                assetChildCount: assignment.count,
                lastInventoriedAt: asset.lastInventoriedAt,
                inventoryRequirements: asset.inventoryRequirements,
              }}
              isInventory={assignment.assignableType === "Asset" && assignment.assignableId == assetId}
            />
          )
        })}
        <div className="font-semibold pt-4">Total: {asset.groupQuantity}</div>
      </Table>
    </div>
  )
}

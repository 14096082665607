/* eslint-disable @next/next/no-img-element */
import { Typography } from "@mui/material"
import { format } from "date-fns"
import { FC, useMemo, useState } from "react"
import { useQuery } from "urql"
import { TaskOrProjectSummaryImageSectionQuery } from "../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../graphql/generated/gql"
import { colors } from "../../../../helpers/colors"
import { useModalProps } from "../../../../hooks/useModalProps"
import { PreviewDocumentModal } from "../../Documents/DocumentModals"
import { Document } from "../../Documents/DocumentsList"
import { formatDisplayDate } from "../helpers/formatDisplayDate"
import { GroupedObject, groupImageAndNotesByDate } from "../helpers/groupByDate"
import { DateRangeFilterString, SummarySectionProps } from "../types"

const SummaryImageSectionDocument = graphql(`
  query TaskOrProjectSummaryImageSection(
    $projectId: String!
    $taskId: String
    $rangeEnd: DateTime!
    $rangeStart: DateTime!
    $filter: TaskProgressEventFilter
  ) {
    taskProgressEvents(
      projectId: $projectId
      taskId: $taskId
      rangeEnd: $rangeEnd
      rangeStart: $rangeStart
      filter: $filter
    ) {
      id
      imageUrls
      createdAt
      reporter {
        id
        fullName
      }
      task {
        id
        name
      }
    }
  }
`)

export const SummaryImageSection: FC<SummarySectionProps> = ({
  taskId,
  projectId,
  rangeEnd,
  rangeStart,
  dateRangeType = "daily",
  filter,
}) => {
  const imageQueryVariables = {
    projectId,
    taskId,
    filter: filter === "images" ? "IMAGES" : undefined,
    rangeStart,
    rangeEnd,
  }

  const [{ data }] = useQuery<TaskOrProjectSummaryImageSectionQuery>({
    query: SummaryImageSectionDocument,
    variables: imageQueryVariables,
    pause: !projectId,
  })

  const taskProgressEvents = data?.taskProgressEvents

  const groupedImages = useMemo(() => {
    return taskProgressEvents ? groupImageAndNotesByDate(taskProgressEvents, dateRangeType) : {}
  }, [taskProgressEvents, dateRangeType])

  return Object.keys(groupedImages).length !== 0 ? (
    <div>
      <Typography variant="h4">Images</Typography>
      {Object.entries(groupedImages).map(([key, imagesForDate], i) => (
        <div className="mb-6" key={i}>
          <Typography variant="h5" fontSize={16}>
            {formatDisplayDate(key, dateRangeType)}
          </Typography>
          <ImageGrid images={imagesForDate} dateRangeType={dateRangeType} />
        </div>
      ))}
    </div>
  ) : (
    filter !== "all" && <Typography variant="h3">No Images</Typography>
  )
}

const ImageGrid: FC<{ images: GroupedObject[]; dateRangeType: DateRangeFilterString }> = ({
  images,
  dateRangeType,
}) => {
  const showDate = dateRangeType === "monthly" || dateRangeType === "all-time"
  const previewDocumentModalProps = useModalProps("Document Preview")
  const [previewDocument, setPreviewDocument] = useState<Document>()
  const [initialSlideIndex, setInitialSlideIndex] = useState(0)

  const onPreview = (imgObj: GroupedObject, i: number) => {
    const document: Document = {
      id: imgObj.id,
      documentUrl: imgObj.documentUrl,
      name: imgObj.reporterName,
      uploaded: imgObj.uploaded,
      type: imgObj.type || "image",
    }

    setPreviewDocument(document)
    setInitialSlideIndex(i)
    previewDocumentModalProps.handleOpenModal()
  }

  return (
    <>
      <div className="flex h-42 overflow-x-auto space-x-4">
        {images.map((imgObj, i) => (
          <div className="flex-none" key={i}>
            <img
              src={imgObj.documentUrl}
              alt={`Image ${i} uploaded by ${imgObj.reporterName} at ${imgObj.uploaded}`}
              loading="lazy"
              className="cursor-pointer w-36 h-36 object-cover object-center rounded-lg mb-2.5"
              onClick={() => onPreview(imgObj, i)}
            />
            <Typography color={colors.gray[500]} variant="body2" component="p">
              {imgObj.reporterName}
            </Typography>
            <Typography color={colors.gray[500]} variant="body2" component="p">
              {showDate && `${format(imgObj.uploaded, "MMM do")} at `}
              {format(imgObj.uploaded, "h:mm a").toLowerCase()}
            </Typography>
            {imgObj.taskName && (
              <Typography color={colors.gray[500]} variant="body2" component="p">
                {imgObj.taskName}
              </Typography>
            )}
          </div>
        ))}
      </div>
      <PreviewDocumentModal
        {...previewDocumentModalProps}
        document={previewDocument}
        images={images}
        initialSlideIndex={initialSlideIndex}
      />
    </>
  )
}

import { Box, Button, Grid, Typography } from "@mui/material"
import { FC } from "react"
import { Asset } from "../../../graphql/generated/client-types-and-hooks"
import { colors } from "../../../helpers/colors"
import { useModalProps } from "../../../hooks/useModalProps"
import { PickPlus } from "../../../types/helpers"
import { AssetBadge } from "../../AssetBadge"
import { AssetQuickAddTable } from "./AssetQuickAdd"

type NestedAssetsProps = {
  childAssets: (PickPlus<
    Asset,
    | "id"
    | "active"
    | "assetChildCount"
    | "assetGroupId"
    | "assignableId"
    | "assignableType"
    | "companyAssetNumber"
    | "groupQuantity"
    | "imageUrl"
    | "isAssetGroup"
    | "name"
    | "ownershipType"
    | "status"
  > & { activeCount?: number; missingCount?: number; depth?: number })[]
  asset: PickPlus<Asset, "id" | "name">
}

export const NestedAssetsGrid: FC<NestedAssetsProps> = ({ asset, childAssets }) => {
  const assetQuickAddModalProps = useModalProps(`Add Assets to ${asset.name}`)

  return (
    <div>
      <div className="flex justify-between">
        <Typography variant="h5">Nested Assets</Typography>
        <Button onClick={assetQuickAddModalProps.handleOpenModal}>Add new assets</Button>
      </div>
      <div className="relative">
        {childAssets.length > 0 && (
          <Grid container spacing={1}>
            {childAssets.map((childAsset) => (
              <Grid key={childAsset.id} item md={3} xs={12} alignItems="center">
                <Box borderRadius="8px" border="1px solid" borderColor={colors.gray[200]} padding="10px">
                  <AssetBadge asset={{ ...childAsset, assetChildCount: childAsset.assetChildCount }} />
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </div>
      <AssetQuickAddTable {...assetQuickAddModalProps} parentAssetId={asset.id} />
    </div>
  )
}

import { Formik, FormikValues } from "formik"
import { Dispatch, FC, SetStateAction } from "react"
import * as Yup from "yup"
import { TemplateListItem } from "../Drawer/CustomInspectionTemplateDrawer"
import { InspectionItemForm } from "./InspectionItemForm"

export const listItemTypes = [
  {
    value: "y/n",
    label: "Pass / Fail",
  },
  {
    value: "number",
    label: "Number entry",
  },
  {
    value: "text",
    label: "Text entry",
  },
]

export const InspectionListItemSchema = Yup.object().shape({
  label: Yup.string(),
  type: Yup.string()
    .oneOf(listItemTypes.map((t) => t.value))
    .required(),
  photoRequired: Yup.boolean(),
  photoLabel: Yup.string(),
  addRule: Yup.boolean(),
  rule: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .when("addRule", {
      is: true,
      then: (schema) => schema.required("Required"),
    }),
  failedStatus: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .when("addRule", {
      is: true,
      then: (schema) => schema.required("Required"),
    }),
})

const emptyListItem: TemplateListItem = {
  label: "",
  rowId: Date.now(),
  type: listItemTypes[0].value,
  photoRequired: false,
  photoLabel: "",
  addRule: false,
}

export const CustomInspectionTemplateForm: FC<{
  itemToEdit?: TemplateListItem
  listItems: TemplateListItem[]
  setListItems: Dispatch<SetStateAction<TemplateListItem[] | undefined>>
}> = ({ itemToEdit, listItems, setListItems }) => {
  const handleAddListItem = (values: FormikValues, resetForm: () => void) => {
    const { id, rowId, label, type, photoRequired, photoLabel, addRule, rule, failedStatus } = values
    setListItems([
      ...listItems,
      {
        id,
        rowId,
        label,
        type,
        photoRequired,
        photoLabel,
        addRule,
        rule,
        failedStatus,
      },
    ])

    resetForm()
  }

  const initialValues = itemToEdit ? { ...itemToEdit, addRule: Boolean(itemToEdit.rule) } : emptyListItem

  return (
    <section className={"pt-5 mt-12"}>
      <Formik initialValues={initialValues} onSubmit={() => {}} validationSchema={InspectionListItemSchema}>
        <InspectionItemForm handleAddListItem={handleAddListItem} />
      </Formik>
    </section>
  )
}

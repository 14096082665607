import { Button } from "@mui/material"
import Fade from "@mui/material/Fade"
import { useRouter } from "next/router"
import { FC, useEffect } from "react"
import { classNames } from "../../../helpers/classNames"
import { getQueryStringParams } from "../../../helpers/getQueryStringParams"

type Option = {
  title: string
  value: string
}

type Props = {
  options: Option[]
  queryStringParameter: string
  filter: string
  setFilter: (filter: any) => void
  defaultFilterValue: string
}

export const FilterGroupWithQueryStringParam: FC<Props> = ({
  options,
  queryStringParameter,
  filter,
  setFilter,
  defaultFilterValue,
}) => {
  const router = useRouter()

  useEffect(() => {
    const { [queryStringParameter]: value } = getQueryStringParams()

    if (value) {
      setFilter(value)
    } else {
      setFilter(defaultFilterValue)
    }
  }, [queryStringParameter, setFilter, defaultFilterValue])

  return (
    <div className="flex gap-x-5 overflow-x-auto">
      {options.map((option) => (
        <div key={option.value} className="relative">
          <Button
            disableRipple
            onClick={() => {
              if (window.location.pathname) {
                const params = getQueryStringParams()

                router.replace(
                  {
                    pathname: window.location.pathname,
                    query: { ...params, [queryStringParameter]: option.value },
                  },
                  undefined,
                  { shallow: true }
                )
              }

              setFilter(option.value)
            }}
            className={classNames("px-1 uppercase text-blue-600", filter !== option.value && "text-gray-400")}
          >
            {option.title}
          </Button>
          <Fade in={filter === option.value} mountOnEnter unmountOnExit>
            <div
              className={classNames(
                "absolute left-0 bottom-0 h-0.5 transition-all rounded-sm w-full",
                filter === option.value ? "bg-blue-600" : "bg-transparent"
              )}
            />
          </Fade>
        </div>
      ))}
    </div>
  )
}

import { FC } from "react"
import { Asset, AssetGroup } from "../../../../../graphql/generated/client-types-and-hooks"
import { classNames } from "../../../../../helpers/classNames"
import { PickPlus } from "../../../../../types/helpers"
import { AssetBadge } from "../../../../AssetBadge"

export const QuantizedAssetBadgeCell: FC<{ assetGroup: AssetGroup; indentLevel: number }> = ({
  assetGroup,
  indentLevel = 0,
}) => {
  const asset: PickPlus<
    Asset,
    "id" | "name" | "assetGroupId" | "companyAssetNumber" | "isAssetGroup" | "assetChildCount" | "ownershipType"
  > = {
    id: assetGroup.groupParent.id,
    name: assetGroup.groupParent.name,
    assetGroupId: assetGroup.assetGroupId,
    companyAssetNumber: assetGroup.groupParent.companyAssetNumber,
    isAssetGroup: true,
    assetChildCount: assetGroup.count,
    ownershipType: assetGroup.groupParent.ownershipType,
  }

  return (
    <div className="flex gap-2 pl-[10px]" style={{ marginLeft: indentLevel > 0 ? "50px" : "0" }}>
      {indentLevel > 0 && (
        <div className="flex items-center gap-[5px]">
          {[...Array(indentLevel)].map((_e, i) => (
            <div
              key={i}
              className={classNames(
                "w-[5px] h-12 md:h-10 rounded-full",
                i + 1 === indentLevel ? "bg-gray-800" : "bg-gray-200"
              )}
            />
          ))}
        </div>
      )}
      <AssetBadge asset={asset} />
    </div>
  )
}

import {
  ProjectUnitsTableProgressQuery,
  TaskUnitsTableProgressQuery,
} from "../../../../graphql/generated/client-types-and-hooks"
import { formatDateToCompactString } from "../../../../helpers/formatDateOnlyNumbers"
import { generateCsv } from "../../../../helpers/generateCsv"
import { getFriendlyFloat } from "../../../../helpers/getFriendlyFloat"
import { titleCase } from "../../../../helpers/titleCase"
import { DateRangeFilterString } from "../types"
import { isProjectUnitGoal } from "./aggregateUnitGoals"

type ExportProps = {
  data: ProjectUnitsTableProgressQuery["project"] | TaskUnitsTableProgressQuery["task"]
  rangeEnd: Date
  rangeStart: Date
  dateRangeType?: DateRangeFilterString
}
export function exportToCSV({ data, rangeStart, rangeEnd, dateRangeType }: ExportProps): Promise<void> {
  return new Promise((resolve, reject) => {
    try {
      const csv = convertUnitsGridToCSV({ data, rangeStart, rangeEnd, dateRangeType })
      const blob = new Blob([csv], { type: "text/csv" })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.setAttribute("hidden", "")
      a.setAttribute("href", url)
      const date = formatDateToCompactString(rangeStart)
      a.setAttribute("download", `${data?.name}_UnitReports/${date}.csv`)
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)
      resolve()
    } catch (error) {
      reject(error)
    }
  })
}

function convertUnitsGridToCSV({ data, rangeStart, rangeEnd, dateRangeType }: ExportProps): string {
  const formatDate = (date: Date) => {
    return new Date(date).toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" })
  }

  const dateValue =
    dateRangeType === "daily"
      ? formatDate(rangeStart)
      : dateRangeType === "all-time" && data?.startDate
      ? `${formatDate(data?.startDate)} - ${formatDate(rangeEnd)}`
      : `${formatDate(rangeStart)} - ${formatDate(rangeEnd)}`

  const header = [
    "Date",
    "Task",
    "Unit",
    "Unit Of Measure",
    "Total Progress",
    "Target Quantity",
    "Progress",
    "Unit Rate",
    "Is Primary Unit",
  ]
  dateRangeType ? header.splice(4, 0, titleCase(dateRangeType) + " Progress") : "Progress"

  const csvData: (string | false | number | undefined)[][] = [header]

  if (data?.unitGoals) {
    data.unitGoals.forEach((unitGoal) => {
      const progressPercentage = (unitGoal.totalProgress / (unitGoal.targetQuantity || 1)) * 100
      const unitRatePercentage = ((unitGoal.progressInDateRange / (unitGoal.targetQuantity || 1)) * 100).toFixed(2)
      const taskName = isProjectUnitGoal(unitGoal) ? unitGoal.task.name : data.name

      csvData.push([
        dateValue,
        taskName,
        unitGoal.deliverableUnit.description,
        unitGoal.deliverableUnit.unitOfMeasure,
        `${getFriendlyFloat(unitGoal.progressInDateRange)} ${unitGoal.deliverableUnit.unitOfMeasure}`,
        `${getFriendlyFloat(unitGoal.totalProgress)} ${unitGoal.deliverableUnit.unitOfMeasure}`,
        `${getFriendlyFloat(unitGoal.targetQuantity ?? 0)} ${unitGoal.deliverableUnit.unitOfMeasure}`,
        `${getFriendlyFloat(progressPercentage)}%`,
        `${getFriendlyFloat(unitRatePercentage)}%`,
        `${unitGoal.isPrimary ? "✓" : ""}`,
      ])
    })
  }

  return generateCsv(csvData)
}

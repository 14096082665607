import { Button } from "@mui/material"
import { FC, useContext, useEffect, useState } from "react"
import { BiPencil, BiSearchAlt2, BiUserPlus, BiX } from "react-icons/bi"
import { useQuery } from "urql"
import { Project, Task, TeamAssignmentsQuery } from "../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../graphql/generated/gql"
import { classNames } from "../../../../helpers/classNames"
import { sortByFieldAscending } from "../../../../helpers/sorts/sortByFieldAscending"
import { sortByLatestTimeEntryStatusAscending } from "../../../../helpers/sorts/sortUsersWithLatestTimeEntryByStatusAscending"
import { sortByLatestTimeEntryByTimeAscending } from "../../../../helpers/sorts/sortUsersWithLatestTimeEntryByTimeAscending"
import { useModalProps } from "../../../../hooks/useModalProps"
import { useTableSorting } from "../../../../hooks/useTableSorting"
import { DrawerContext } from "../../../../providers/DrawerProvider"
import { PickPlus } from "../../../../types/helpers"
import { AddRow } from "../../../AddRow"
import { H4 } from "../../../Elements"
import Checkbox from "../../../Elements/Checkbox"
import { SearchInput } from "../../../Elements/SearchInput"
import { IconButton } from "../../../IconButton"
import LoadingIndicatorModal from "../../../Modals/LoadingIndicatorModal"
import StandardModal from "../../../Modals/StandardModal"
import { ProjectUnitReportForm } from "../../../ProjectUnitReportForm"
import { RenderIf } from "../../../RenderIf"
import { ColumnTitle } from "../../../Table/ColumnTitle"
import { ColumnTitles } from "../../../Table/ColumnTitles"
import { Table } from "../../../Table/Table"
import { TableContextMenuContainer } from "../../../TableContextMenuContainer"
import { UnitReportModal } from "../../../UnitReportModal"
import { DrawerLink } from "../../Drawer/DrawerLink"
import { BulkActionMenu, ModernUserWithTimeEntry } from "../../Team/BulkTeamActions"
import { ReassignUserTaskForm } from "../../User/ReassignUserTaskForm/ReassignUserTaskForm"
import { UsersSkeleton } from "../../User/Users.skeleton"
import { AssignUserForm } from "./AssignUserForm"
import { TeamListRow } from "./TeamListRow"

export const testLabel_TeamTabMainCheckbox = "team-tab-main-checkbox"

type Props = {
  project: PickPlus<Project, "id">
  task?: PickPlus<Task, "id" | "name" | "isDefault" | "isComplete" | "hasReportableUnit"> & {
    project: PickPlus<Project, "id" | "isArchived">
    subtasks: PickPlus<Task, "id" | "name">[]
  }
  onSuccess?: () => void
}

const TeamAssignmentsDocument = graphql(`
  query TeamAssignments($filter: UserFilter!, $projectId: String!) {
    users(filter: $filter, first: 200) {
      edges {
        node {
          id
          archived
          currentProjectId
          currentTaskId
          firstName
          imageUrl
          isClockedIn
          jobTitle
          lastName
          organizationId
          latestTimeEntry {
            id
            endAt
            startAt
            evidence
            taskId
            userId
            durationInSeconds
          }
          projectId
          project {
            id
            name
          }
          secondsClockedSinceOrgMidnight
          taskId
          task {
            id
            name
          }
        }
      }
    }

    project(id: $projectId) {
      id
      isComplete
      tasks {
        id
        projectId
        subtasks {
          id
        }
      }
    }
  }
`)

type UserNode = NonNullable<TeamAssignmentsQuery["users"]["edges"][0]>["node"]
const isAUser = (user: UserNode | undefined): user is UserNode => {
  return !!user
}

export const TeamAssignments: FC<Props> = ({ project, task, onSuccess }) => {
  const [{ data, fetching }, fetch] = useQuery({
    query: TeamAssignmentsDocument,
    variables: {
      projectId: project.id,
      filter: { projectId: project.id, taskId: task?.id },
    },
    pause: !project.id,
  })
  const assignToProjectFormModalProps = useModalProps("Assign to project")
  const reassignUserTaskFormModalProps = useModalProps("Reassign user")
  const loadingIndicatorModalProps = useModalProps()

  const users = data?.users?.edges?.map((edge) => edge?.node).filter(isAUser) || []
  const { tableSortingState, sortByKey } = useTableSorting<UserNode>(
    users,
    {
      name: sortByFieldAscending(
        (entry: PickPlus<UserNode, "firstName" | "lastName">) => `${entry.firstName} ${entry.lastName}`
      ),
      task: sortByFieldAscending((entry: { task: PickPlus<UserNode["task"], "name"> }) => entry.task!.name),
      status: sortByLatestTimeEntryStatusAscending,
      time: sortByLatestTimeEntryByTimeAscending,
    },
    "name"
  )

  const tasks = data?.project?.tasks || []
  const [selectedUsers, setSelectedUsers] = useState<ModernUserWithTimeEntry[]>([])
  const [searchIsOpen, setSearchIsOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const [tableContextMenuState, setTableContextMenuState] = useState<"initial" | "search" | "userSelected">("initial")
  const visibleUsers = task
    ? users.filter((user) => user && user.taskId === task?.id)
    : users.filter(
        (user) =>
          user &&
          (data?.project?.tasks || []).some(
            (task) => user.task?.id === task.id || task.subtasks?.some((subTask) => user.taskId === subTask.id)
          )
      )

  useEffect(() => {
    if (selectedUsers.length > 0) {
      setTableContextMenuState("userSelected")
      return
    }

    if (searchIsOpen) {
      setTableContextMenuState("search")
      return
    }

    setTableContextMenuState("initial")
  }, [selectedUsers.length, searchIsOpen, searchTerm])

  const handleReassignTask = (user: ModernUserWithTimeEntry) => {
    if (!selectedUsers.find((u) => u.id === user.id)) {
      setSelectedUsers([...selectedUsers, user])
    }

    reassignUserTaskFormModalProps.handleOpenModal()
  }

  const refetch = () => {
    fetch()
    onSuccess?.()
  }

  const showUnitReportButton = task ? !!task.hasReportableUnit && !task.isDefault : true

  return (
    <>
      {fetching ? (
        <UsersSkeleton />
      ) : (
        <>
          <div
            className={classNames(
              "flex flex-wrap justify-between items-start relative mb-2",
              !data?.project?.isComplete && "mb-6"
            )}
          >
            <H4>{task ? "Task Assignments" : "Team List"}</H4>
            <div className="relative w-full lg:w-auto flex justify-start lg:justify-end min-h-[20px]">
              {!data?.project?.isComplete && (
                <TableContextMenuContainer isOpen={tableContextMenuState === "initial"}>
                  {showUnitReportButton && (
                    <RenderIf permissionsInclude="task:update">
                      {!!task?.isComplete ? (
                        <Button size="large" variant="contained" color="secondary" endIcon={<BiPencil />} disabled>
                          Report
                        </Button>
                      ) : (
                        <ReportUnitsButton project={project} task={task} />
                      )}
                    </RenderIf>
                  )}
                  <IconButton onClick={() => setSearchIsOpen(true)} Icon={BiSearchAlt2} />
                  <RenderIf permissionsInclude="user:assign" context={{ projectId: tasks.find((t) => t)?.projectId }}>
                    <IconButton
                      onClick={assignToProjectFormModalProps.handleOpenModal}
                      Icon={BiUserPlus}
                      disabled={!!task?.isComplete}
                      className={classNames(!!task?.isComplete && "opacity-50")}
                    />
                  </RenderIf>
                </TableContextMenuContainer>
              )}

              <TableContextMenuContainer isOpen={tableContextMenuState === "search"}>
                <SearchInput
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  onClose={() => setSearchIsOpen(false)}
                />
                <IconButton
                  onClick={() => {
                    setSearchIsOpen(false)
                    setSearchTerm("")
                  }}
                  Icon={BiX}
                />
              </TableContextMenuContainer>

              <TableContextMenuContainer isOpen={tableContextMenuState === "userSelected"}>
                <BulkActionMenu selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} project={project} />
              </TableContextMenuContainer>
            </div>
          </div>
          <ColumnTitles>
            <div className="col-span-3 flex gap-x-3 items-center">
              <RenderIf permissionsInclude={["timeEntry:create", "user:assign"]}>
                <Checkbox
                  checked={visibleUsers.length === selectedUsers.length}
                  testLabel={testLabel_TeamTabMainCheckbox}
                  onChange={() => {
                    if (visibleUsers.length === selectedUsers.length) {
                      setSelectedUsers([])
                    } else {
                      setSelectedUsers(visibleUsers)
                    }
                  }}
                />
              </RenderIf>

              <ColumnTitle
                className={"col-span-2 md:ml-2"}
                sortByKey={sortByKey}
                sortKey={"name"}
                tableSortingState={tableSortingState}
              >
                Name
              </ColumnTitle>
            </div>
            <ColumnTitle
              className={"col-span-4"}
              sortByKey={sortByKey}
              sortKey="task"
              tableSortingState={tableSortingState}
            >
              Task
            </ColumnTitle>
            <ColumnTitle
              className={"col-span-2"}
              tableSortingState={tableSortingState}
              sortByKey={sortByKey}
              sortKey={"status"}
            >
              Status
            </ColumnTitle>
            <ColumnTitle
              className={"col-span-2"}
              tableSortingState={tableSortingState}
              sortByKey={sortByKey}
              sortKey={"time"}
            >
              Time
            </ColumnTitle>
          </ColumnTitles>
          <Table>
            {tableSortingState.sortedData
              .filter((user) => {
                if (task) return task.id === user?.taskId
                return tasks.find((task) => task.id === user?.task!.id) || true
              })
              .filter((user) => {
                const fullSearchText = `${user.firstName} ${user.lastName} ${user.jobTitle}`.toLowerCase()
                return fullSearchText.includes(searchTerm.toLowerCase())
              })
              .map((user) => (
                <TeamListRow
                  toggleUserSelection={(selectedUser) => {
                    const foundUser = selectedUsers.find((user) => selectedUser.id === user.id)
                    if (!foundUser) {
                      setSelectedUsers([...selectedUsers, user])
                    } else {
                      setSelectedUsers(selectedUsers.filter((user) => selectedUser.id !== user.id))
                    }
                  }}
                  key={user.id}
                  rowSelected={!!selectedUsers.find((selectedUser) => selectedUser.id == user.id)}
                  user={user}
                  hideProjectColumn={true}
                  setModalData={handleReassignTask}
                />
              ))}
          </Table>
          <RenderIf permissionsInclude="user:assign" context={{ projectId: project.id }}>
            {!data?.project?.isComplete && (
              <AddRow
                label={`Add team member to ${task ? "task" : "project"}`}
                onClick={assignToProjectFormModalProps.handleOpenModal}
                disabled={!!task?.isComplete}
              />
            )}
          </RenderIf>

          <StandardModal className="overflow-y-visible" {...assignToProjectFormModalProps}>
            <AssignUserForm
              project={project}
              task={task}
              onSuccess={async () => {
                refetch()
                assignToProjectFormModalProps.handleCloseModal()
              }}
              onCancel={assignToProjectFormModalProps.handleCloseModal}
            />
          </StandardModal>

          <StandardModal className="overflow-y-visible" {...reassignUserTaskFormModalProps}>
            <ReassignUserTaskForm
              projectId={project?.id}
              preselectedUsers={selectedUsers}
              onSuccess={() => {
                refetch()
                setSelectedUsers([])
                reassignUserTaskFormModalProps.handleCloseModal()
              }}
              onCancel={reassignUserTaskFormModalProps.handleCloseModal}
            />
          </StandardModal>

          <LoadingIndicatorModal {...loadingIndicatorModalProps} />
        </>
      )}
    </>
  )
}

const ReportUnitsButton = ({ project, task }: { project: Props["project"]; task?: Props["task"] }) => {
  const { clearAll } = useContext(DrawerContext)
  const [reportModalOpen, setReportModalOpen] = useState(false)

  // if there is no task we can safely assume that we should show the project report modal
  if (!task) {
    return (
      <DrawerLink component={<ProjectUnitReportForm projectId={project.id} handleClose={() => clearAll()} />}>
        <Button component="span" size="large" variant="contained" color="secondary" endIcon={<BiPencil />}>
          Report
        </Button>
      </DrawerLink>
    )
  }

  return (
    <>
      <Button
        onClick={() => setReportModalOpen(true)}
        size="large"
        variant="contained"
        color="secondary"
        endIcon={<BiPencil />}
      >
        Report
      </Button>
      <UnitReportModal isOpen={reportModalOpen} closeModal={() => setReportModalOpen(false)} taskId={task.id} />
    </>
  )
}

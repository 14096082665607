import { FC } from "react"
import { User } from "../../graphql/generated/client-types-and-hooks"
import { TimeEntry } from "../../graphql/generated/gql/graphql"
import { PickPlus } from "../../types/helpers"
import { OrganizationRequiresClockInEvidence } from "../OrganizationRequiresClockInEvidence"
import { ClockInOutWithEvidence } from "./ClockInOutWithEvidence"
import { PureClockInOut } from "./PureClockInOut"

type Props = {
  user: PickPlus<User, "id" | "firstName" | "lastName" | "projectId" | "taskId" | "archived" | "isClockedIn"> & {
    latestTimeEntry?: PickPlus<TimeEntry, "id" | "startAt" | "endAt"> | null
  }
  className?: string
  onSuccess?: () => void
  disabled?: boolean
}

export const ClockInOut: FC<Props> = ({ user, className, onSuccess, disabled = false }) => {
  return (
    <div className="flex items-center space-x-2">
      <OrganizationRequiresClockInEvidence
        fallbackComponent={<PureClockInOut disabled={disabled} user={user} onSuccess={onSuccess} />}
      >
        <ClockInOutWithEvidence disabled={disabled} user={user} onSuccess={onSuccess} className={className} />
      </OrganizationRequiresClockInEvidence>
    </div>
  )
}

import { addHours, format, subHours } from "date-fns"
import { Form, useField } from "formik"
import { FC, useEffect, useMemo } from "react"
import { useQuery } from "urql"
import { ModernUserWithTimeEntry } from "."
import { TimeEntryError } from "../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../graphql/generated/gql"
import { canBeClockedOut } from "../../../../helpers/canBeClockedOut"
import { uploadTimeEntryEvidence } from "../../../../helpers/uploadTimeEntryEvidence"
import { useHandleError } from "../../../../hooks/useHandleError"
import { PickPlus } from "../../../../types/helpers"
import { ButtonFilled, ButtonHollow } from "../../../Elements"
import { ModalBody } from "../../../Modals/ModalBody"
import { ModalFooter } from "../../../Modals/ModalFooter"
import { infoSnack } from "../../../Notistack/ThemedSnackbars"
import { DeprecatedTimePicker } from "../../../deprecated/TimePicker"
import { ClockOutTeamMemberRow } from "./ClockOutTeamMemberRow"
import { ConflictTeamMemberRow } from "./ConflictTeamMemberRow"
import { PartialErrorInfoBanners } from "./PartialErrorInfoBanners"
import { getUserErrorMap } from "./getUserErrorMap"

export type BulkClockOutCandidate = {
  imagePath?: string
  injuredStatus?: boolean
  signaturePath?: string
  startAt?: Date
  user: PickPlus<ModernUserWithTimeEntry, "id">
}

type UserExpectation = PickPlus<
  ModernUserWithTimeEntry,
  "id" | "firstName" | "jobTitle" | "lastName" | "latestTimeEntry"
>

type Props = {
  handleCancel: () => void
  successes: number
  errors: TimeEntryError[]
  taskId?: string
  userIds?: string[]
}

type EvidenceKey = "imagePath" | "signaturePath" | "injuredStatus"

const BulkClockOutUsers = graphql(`
  query BulkClockOutUsers($first: Int, $filter: UserFilter!) {
    users(first: $first, filter: $filter) {
      totalCount
      edges {
        node {
          id
          firstName
          lastName
          jobTitle
          imageUrl
          latestTimeEntry {
            id
            taskId
            endAt
            startAt
            evidence
          }
        }
      }
    }
  }
`)

export const BulkClockOutFormContents: FC<Props> = ({ taskId, userIds, handleCancel, successes, errors }) => {
  const [{ data, error }] = useQuery({
    query: BulkClockOutUsers,
    pause: !taskId && !userIds?.length,
    variables: { first: userIds?.length, filter: { isClockedIn: true, taskId, userIds } },
  })

  useHandleError(error, "There was a problem loading some user data")

  // Todo: Update the bulk clock out to use pagination rather than limiting the UI to 100 rows.
  useEffect(() => {
    if (data?.users.totalCount && data?.users.totalCount > 100)
      infoSnack(`Only displaying 100 of the ${data?.users.totalCount} selected users.`)
  }, [data?.users.totalCount])

  const [endAtField] = useField<Date>("endAt")
  const [candidatesField, _, candidatesHelpers] = useField<BulkClockOutCandidate[]>("candidates")
  const userErrorMap = useMemo(() => getUserErrorMap(errors), [errors])

  const countOfUsersToClockOut = useMemo(() => {
    return candidatesField.value.filter((candidate) => candidate.imagePath && candidate.signaturePath)?.length
  }, [candidatesField.value])

  const addEvidenceToCandidate = async (user: UserExpectation, key: EvidenceKey, blob?: Blob, _state?: boolean) => {
    if (key === "injuredStatus") {
    }
    const path = await uploadTimeEntryEvidence(blob!, user.id)

    if (path) {
      const update = { [key]: path }
      const userAtCandidateIndex = candidatesField.value.findIndex((candidate) => candidate.user.id === user.id)

      if (userAtCandidateIndex >= 0) {
        //  If the user exists as a candidate, update the candidate.
        const updatedCandidates = [...candidatesField.value]

        updatedCandidates[userAtCandidateIndex] = {
          ...updatedCandidates[userAtCandidateIndex],
          ...update,
        }

        candidatesHelpers.setValue(updatedCandidates.filter((candidate) => candidate.imagePath && candidate.imagePath))
      } else {
        //  If there is no candidate for the user, create one.
        candidatesHelpers.setValue([...candidatesField.value, { user, ...update }])
      }
    }
  }

  const removeEvidenceFromCandidate = (user: UserExpectation, key: EvidenceKey) => () => {
    const userAtCandidateIndex = candidatesField.value.findIndex((candidate) => candidate.user.id === user.id)
    const updatedCandidates = [...candidatesField.value]

    updatedCandidates[userAtCandidateIndex][key] = undefined

    candidatesHelpers.setValue(updatedCandidates)
  }

  return (
    <Form>
      <ModalBody>
        <PartialErrorInfoBanners direction={"out"} successes={successes} errors={errors} />
        <DeprecatedTimePicker name={"endAt"} startDate={subHours(new Date(), 2)} endDate={addHours(new Date(), 2)} />
        <p className={"text-gray-400 mt-4 mb-8"}>
          Bulk clock out (
          {candidatesField.value.filter((candidate) => candidate.imagePath && candidate.signaturePath).length}) on{" "}
          {format(endAtField.value, "eee, MMMM yyy")} at {format(endAtField.value, "hh:mm aaa")}.
        </p>
        <div className={"flex flex-col gap-4"}>
          {data?.users.edges.map((edge) => {
            const user = edge?.node
            if (!user) return null
            return canBeClockedOut(user.latestTimeEntry, endAtField.value, userErrorMap[user.id]) ? (
              <ClockOutTeamMemberRow
                user={user}
                key={user.id}
                hasBeenPhotographed={candidatesField.value.some(
                  (candidate) => candidate.user.id === user.id && candidate.imagePath
                )}
                hasSigned={candidatesField.value.some(
                  (candidate) => candidate.user.id === user.id && candidate.signaturePath
                )}
                collectInjured={(isInjured) => addEvidenceToCandidate(user, "injuredStatus", undefined, isInjured)}
                collectPhoto={(blob) => addEvidenceToCandidate(user, "imagePath", blob)}
                cancelPhoto={removeEvidenceFromCandidate(user, "imagePath")}
                collectSignature={(blob) => addEvidenceToCandidate(user, "signaturePath", blob)}
                cancelSignature={removeEvidenceFromCandidate(user, "signaturePath")}
              />
            ) : (
              <ConflictTeamMemberRow key={user.id} user={user} type={"out"} />
            )
          })}
        </div>
      </ModalBody>
      <ModalFooter>
        <ButtonHollow type={"button"} onClick={handleCancel}>
          Cancel
        </ButtonHollow>
        <ButtonFilled type={"submit"} disabled={countOfUsersToClockOut < 1}>
          Clock out ({countOfUsersToClockOut})
        </ButtonFilled>
      </ModalFooter>
    </Form>
  )
}

export type ClassType = {
    underClassName: string
    completedClassName: string
    overClassName: string
}

export const createSource = (value: number, classNames: ClassType) => ({ value, ...classNames })

export const HOURS_INACTIVE_COLORS = {
    underClassName: "bg-[#B1C5F8]",
    completedClassName: "bg-[#B4DCBC]",
    overClassName: "bg-[#F4B4B2]",
}

export const HOURS_ACTIVE_COLORS = {
    underClassName: "bg-blue-600",
    completedClassName: "bg-green-600",
    overClassName: "bg-red-600",
}

export const UNITS_INACTIVE_COLORS = {
    underClassName: "bg-[#A1CEEE]",
    completedClassName: "bg-[#B4DCBC]",
    overClassName: "bg-[#F4B4B2]",
}

export const UNITS_ACTIVE_COLORS = {
    underClassName: "bg-sky-500",
    completedClassName: "bg-green-600 opacity-40",
    overClassName: "bg-red-600 opacity-40",
}
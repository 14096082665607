import { add, formatDistance, isPast, isToday } from "date-fns"
import { NextPage } from "next"
import { FC, useMemo } from "react"
import { useQuery } from "urql"
import { AssetInspectionReportModal } from "../../../components/AssetInspectionReportModal"
import { ButtonFilled, H5 } from "../../../components/Elements"
import { PageTitle } from "../../../components/PageTitle"
import { AssignmentQuantityReportModal } from "../../../components/Partials/Assets/AssignmentQuantityReportModal"
import { InventoryIntervalLookup } from "../../../components/Partials/Assets/CreateOrEditAssetForm/InventoryIntervalOptions"
import { Asset } from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { useHandleError } from "../../../hooks/useHandleError"
import { useModalProps } from "../../../hooks/useModalProps"
import { AssetGroupAssignmentInventory } from "../../../types/Asset"
import { PickPlus } from "../../../types/helpers"
import AssetsPageLayout from "./_layout"

type Props = {
  assetId: string
}

const AssetReportsDocument = graphql(
  `
    query AssetReports($assetId: String!) {
      asset(id: $assetId) {
        id
        active
        assetChildCount
        assetGroupId
        assignableId
        assignableType
        companyAssetNumber
        groupQuantity
        imageUrl
        isAssetGroup
        name
        ownershipType
        status
        inventoryRequirements {
          intervalInSeconds
          photoRequired
        }
        inspectionTemplatesAssignments {
          id
          inspectionTemplate {
            id
            name
          }
        }
      }
      assetGroups(assetGroupId: $assetId) {
        assetGroupId
        assignableId
        assignableType
        assignedUserId
        count
        status
        assetGroupId
      }
    }
  `
)

export const AssetReports: FC<Props> = ({ assetId }) => {
  const inventoryCheckModal = useModalProps("Inventory Check")
  const inspectionCheckProps = useModalProps("Inspection Check")

  const [{ data, error }] = useQuery({
    query: AssetReportsDocument,
    variables: { assetId },
  })
  useHandleError(error, "An error occurred while fetching asset details. Please reload the page to try again.")

  const asset = useMemo(() => {
    if (!data?.asset) return {} as Asset

    return data.asset
  }, [data])

  const assignment = data?.assetGroups?.find((ag) => ag.assignableId === asset.assignableId) || {
    assetGroupId: asset.assetGroupId,
    assignableId: asset.assignableId,
    assignableType: asset.assignableType,
    count: asset.groupQuantity || 1,
    status: asset.status,
  }

  return (
    <>
      <PageTitle title={`${asset?.name || "Asset"} reports`} />
      <H5>Asset Reports</H5>
      <div className={"flex flex-col md:flex-row justify-between border-gray-200 border-y py-6 gap-6 md:items-center"}>
        <div>
          <div className={"font-semibold"}>Inventory Report</div>
          <AssetInventoryDueIn asset={asset} />
        </div>
        <ButtonFilled type="button" color="primary" onClick={inventoryCheckModal.handleOpenModal}>
          Report
        </ButtonFilled>
      </div>
      {asset?.inspectionTemplatesAssignments?.map(({ inspectionTemplate }) => (
        <div
          key={inspectionTemplate.id}
          className={"flex flex-col md:flex-row justify-between border-gray-200 border-b py-6 gap-6 md:items-center"}
        >
          <div>
            <div className={"font-semibold"}>{inspectionTemplate.name}</div>
            <div className={"text-gray-400 text-sm"}>Inspection Report</div>
          </div>
          <ButtonFilled type="button" color="primary" onClick={inspectionCheckProps.handleOpenModal}>
            Report
          </ButtonFilled>

          <AssetInspectionReportModal
            assetId={assetId}
            assetReportTemplateId={inspectionTemplate.id}
            formModalProps={inspectionCheckProps}
          />
        </div>
      ))}

      <AssignmentQuantityReportModal
        inventoryModalProps={inventoryCheckModal}
        assignment={assignment as AssetGroupAssignmentInventory}
        asset={asset}
      />
    </>
  )
}

const AssetPage: NextPage<Props> = ({ assetId }) => {
  return (
    <AssetsPageLayout assetId={assetId}>
      <AssetReports assetId={assetId} />
    </AssetsPageLayout>
  )
}

AssetPage.getInitialProps = async ({ query }) => {
  return {
    assetId: query._id as string,
  }
}

export default AssetPage

const AssetInventoryDueIn: FC<{ asset: PickPlus<Asset, "inventoryRequirements" | "lastInventoriedAt"> }> = ({
  asset,
}) => {
  if (!asset.inventoryRequirements?.intervalInSeconds)
    return <div className={"text-gray-400 text-sm"}>Not Required</div>

  let color = "text-orange-400"
  let dueAt
  if (asset.lastInventoriedAt) {
    dueAt = add(asset.lastInventoriedAt, { seconds: asset.inventoryRequirements.intervalInSeconds })
    if (isPast(dueAt)) color = "text-red-400"
    if (isToday(dueAt)) color = "text-orange-400"
    // Ok, we are good... so lets go ahead and set it to the normal
    color = "text-gray-400"
  }

  return (
    <div className="text-sm text-gray-400">
      {asset.inventoryRequirements.intervalInSeconds ? (
        <>
          <span>{InventoryIntervalLookup[asset.inventoryRequirements.intervalInSeconds]}</span>
          <span> -- </span>
          <span className={color}>{dueAt ? <>Due in {formatDistance(new Date(), dueAt)}</> : <>Due now</>}</span>
        </>
      ) : (
        "Not required"
      )}
    </div>
  )
}

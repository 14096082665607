import { LinearProgress } from "@mui/material"
import {
  DataGridPro,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GridCellParams,
  GridColDef,
  GridRowParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro"
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro"
import { FC, MutableRefObject, useCallback, useContext, useMemo } from "react"
import { AssetAssignableType, AssetGroup } from "../../../../graphql/generated/client-types-and-hooks"
import { Asset } from "../../../../graphql/generated/gql/graphql"
import { sortBy } from "../../../../helpers/sortBy"
import { AssetListContext } from "../../../../providers/AssetListProvider"
import { AssignedAssetListContext } from "../../../../providers/AssignedAssetListProvider"
import { DevelopmentFeatureFlagContext } from "../../../../providers/DevelopmentFeatureFlagProvider"
import { PermissionsContext } from "../../../../providers/PermissionsProvider/PermissionsProvider"
import { AssetStatusPill } from "../AssetStatusPill"
import { AssetBadgeCell } from "./AssetBadgeCell"
import { AssetDetailsPanel } from "./AssetDetailsPanel"
import { AssetQuickActionsCell } from "./AssetQuickActionsCell"
import { AssigneeCell } from "./AssigneeCell"
import { ProjectCell } from "./ProjectCell"
import { QuantityCell } from "./QuantityCell"
import { QuantizedAssetDataGrid } from "./Quantized/QuantizedAssetDataGrid"
import { TaskCell } from "./TaskCell"

export type SortDirection = "asc" | "desc"

const defaultColumnProps: Partial<GridColDef> = {
  disableColumnMenu: true,
  editable: false,
  filterable: false,
  sortable: false,
}

export const showAssetGroups = (asset: Asset | AssetGroup, useAssetGroupFeatures = false) =>
  useAssetGroupFeatures && asset.hasOwnProperty("groupParent")

export const getAssetListColumns = (apiRef: MutableRefObject<GridApiPro>, indentLevel = 0) => {
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: indentLevel > 0 ? 1.2 : 1, // adjust cell alignment for nested assets
      minWidth: 150,
      renderCell: ({ row: asset }: GridCellParams) => (
        <AssetBadgeCell
          asset={asset as Asset}
          depth={indentLevel}
          addSubIcon={asset.assignableType === AssetAssignableType.Asset}
        />
      ),
    },
    {
      field: "quantity",
      headerName: "Qty.",
      flex: 0.5,
      minWidth: 75,
      renderCell: ({ row: asset }: GridCellParams) => <QuantityCell asset={asset as Asset} />,
    },
    {
      field: "assignee",
      headerName: "Assignee",
      flex: 1,
      renderCell: ({ row: asset }: GridCellParams) => <AssigneeCell asset={asset} />,
    },
    {
      field: "project",
      headerName: "Project",
      flex: 1,
      renderCell: ({ row: asset }: GridCellParams) => <ProjectCell asset={asset} />,
    },
    {
      field: "task",
      headerName: "Task",
      flex: 1,
      renderCell: ({ row: asset }: GridCellParams<Asset>) => <TaskCell asset={asset} />,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.55,
      renderCell: ({ row: asset }: GridCellParams) => <AssetStatusPill asset={asset} />,
    },
    {
      field: "menu",
      headerName: "",
      type: "actions",
      editable: false,
      filterable: false,
      sortable: false,
      flex: 0.5,
      align: "right",
      renderCell: ({ row: asset }: GridCellParams<Asset>) => <AssetQuickActionsCell asset={asset} apiRef={apiRef} />,
    },
  ].map((column) => ({ ...defaultColumnProps, ...column } as GridColDef))

  return columns
}

export const AssetDataGrid: FC<{
  assignedAsset?: boolean
  loadingAssets: boolean
  projectId?: string
  taskId?: string
  totalCount?: number
}> = ({ assignedAsset = false, loadingAssets, projectId, taskId, totalCount = 0 }) => {
  const { flagIsEnabled } = useContext(DevelopmentFeatureFlagContext)
  const assetListContext = useContext(AssetListContext)
  const assignedAssetListContext = useContext(AssignedAssetListContext)
  const ctx = assignedAsset ? assignedAssetListContext : assetListContext
  const apiRef = useGridApiRef()
  const { hasPermissionTo } = useContext(PermissionsContext)

  const getDetailPanelContent = useCallback(
    ({ row: asset }: GridRowParams) =>
      asset.isAssetGroup && flagIsEnabled("Quantized Assets Improvements") ? (
        <QuantizedAssetDataGrid assetId={asset.id} embeddedList />
      ) : (
        <AssetDetailsPanel assetId={asset.id} showTaskColumn={!!projectId} indentLevel={1} />
      ),
    [projectId, flagIsEnabled]
  )
  const getDetailPanelHeight = useCallback(() => "auto", [])
  const columns: GridColDef[] = useMemo(() => getAssetListColumns(apiRef, 0), [apiRef])
  const isViewingInTab = !!projectId || !!taskId

  return (
    <div
      className="w-full h-[80vh]"
      style={{
        // 120px is about the height of the necessary elements above the grid
        height: isViewingInTab ? `80vh` : `calc(100vh - 120px")`,
      }}
    >
      <DataGridPro
        apiRef={apiRef}
        checkboxSelection={hasPermissionTo(["asset:transfer", "asset:update"])}
        columns={columns}
        disableRowSelectionOnClick
        density="comfortable"
        filterMode="server"
        initialState={{
          columns: {
            columnVisibilityModel: {
              [GRID_DETAIL_PANEL_TOGGLE_FIELD]: false,
              project: !!projectId ? false : true,
              task: !!projectId ? true : false,
            },
          },
        }}
        isRowSelectable={(params) =>
          params.row.assignableType !== AssetAssignableType.Asset && !params.row.isAssetGroup
        }
        loading={loadingAssets}
        sortingMode="server"
        paginationMode="server"
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
        keepNonExistentRowsSelected
        pageSizeOptions={[50]}
        onSortModelChange={([model]) => ctx.sortList(model)}
        rows={sortBy(ctx.loadedAssets, "name") || []}
        getRowId={(row) => row.compositeKey}
        rowCount={totalCount}
        rowSelectionModel={ctx.rowsSelected}
        onRowSelectionModelChange={ctx.setRowsSelected}
        onRowsScrollEnd={() => ctx.setCursor(ctx.nextCursor)}
        scrollEndThreshold={200}
        slots={{ loadingOverlay: LinearProgress }}
        sx={{
          border: "none",
          "& .MuiDataGrid-row": {
            borderTop: "1px solid #e0e0e0",
          },
          "& .MuiDataGrid-detailPanel .MuiDataGrid-row": { border: "none" },
          "& .MuiDataGrid-cell:focus": { outline: "none" },
          "& .MuiDataGrid-cell:focus-within": { outline: "none" },
          "& .MuiDataGrid-columnHeader:focus": { outline: "none" },
          "& .MuiDataGrid-columnHeader:focus-within": { outline: "none" },
          "& .MuiDataGrid-virtualScroller": { overflowX: "hidden" },
        }}
      />
    </div>
  )
}

import format from "date-fns/format"
import isSameDay from "date-fns/isSameDay"
import { Form, Formik } from "formik"
import { FC, useMemo } from "react"
import { TimeEntry, User, useTimeEntryEditMutation } from "../../../../graphql/generated/client-types-and-hooks"
import { getTimeEntryArrayTotalSeconds } from "../../../../helpers/getTimeEntryArrayTotalSeconds"
import { secondsToFormattedString } from "../../../../helpers/time-utility"
import { validateTimeEntries } from "../../../../helpers/validateTimeEntry"
import { PickPlus } from "../../../../types/helpers"
import { ButtonFilled, ButtonHollow } from "../../../Elements"
import { LoadingIndicator } from "../../../Loading/LoadingIndicator"
import { ModalBody } from "../../../Modals/ModalBody"
import { ModalFooter } from "../../../Modals/ModalFooter"
import { errorSnack } from "../../../Notistack/ThemedSnackbars"
import { EditableTimeEntry } from "./EditableTimeEntry"

type Values = {
  [key: string]: Props["timeEntries"][0] & {
    selectedProjectId: [string]
    selectedTaskId: [string]
  }
}

export type TimeEntryWithProjectAndTaskName = PickPlus<
  TimeEntry,
  | "id"
  | "projectId"
  | "taskId"
  | "endAt"
  | "startAt"
  | "durationInSeconds"
  | "isBreak"
  | "signInPhotoUrl"
  | "signOutPhotoUrl"
> & { project: PickPlus<TimeEntry["project"], "name">; task: PickPlus<TimeEntry["task"], "name"> }

type Props = {
  onCancel: () => void
  onSuccess: () => void
  timeEntries: TimeEntryWithProjectAndTaskName[]
  user: PickPlus<User, "id" | "taskId">
}

const generateInitialValuesFromProps = (timeEntries: Props["timeEntries"]): Values => {
  return Object.fromEntries(
    timeEntries.map((timeEntry) => {
      return [
        timeEntry.id,
        {
          ...timeEntry,
          selectedProjectId: [timeEntry.projectId],
          selectedTaskId: [timeEntry.taskId],
        },
      ]
    })
  )
}

export const EditDayActivityForm: FC<Props> = ({ onCancel, onSuccess, timeEntries, user }) => {
  const initialValues = useMemo(() => {
    return generateInitialValuesFromProps(timeEntries)
  }, [timeEntries])
  const [{ fetching: mutationIsFetching }, editTimeEntryMutation] = useTimeEntryEditMutation()
  const now = new Date()

  const onSubmit = (values: Values) => {
    for (const [id, { startAt, endAt, selectedTaskId }] of Object.entries(values)) {
      const initialValue = initialValues[id]
      if (
        initialValue.endAt === endAt &&
        initialValue.startAt === startAt &&
        initialValue.selectedTaskId[0] === selectedTaskId[0]
      ) {
        continue
      }
      // TODO: Capture order of operations in how we apply the updates: criteria...
      // 1) Is shrinking (should be safe to do in forward order),
      // 2) is growing? Should be safe to do in a second pass.
      //
      // Currently, the only optimization is to remove anything that doesn't have updates from the initial
      // state... we don't want to touch it! :D
      if (!selectedTaskId[0]) throw new Error("Invalid data")

      handleClockEvent(id, selectedTaskId[0], startAt, endAt)
    }
  }

  const handleClockEvent = (timeEntryId: string, taskId: string, startAt: Date, endAt?: Date | null) => {
    editTimeEntryMutation({ id: timeEntryId, startAt, endAt: endAt || null, taskId }).then((result) => {
      if (result.error) {
        errorSnack("Something went wrong.  Please refresh and try again.")
        console.error(result.error)
      } else {
        onSuccess()
      }
    })
  }

  const totalSeconds = useMemo(() => getTimeEntryArrayTotalSeconds(timeEntries), [timeEntries])

  if (timeEntries.length < 1)
    return (
      <div className="h-48">
        <LoadingIndicator />
      </div>
    )

  const firstTimeEntry = timeEntries[0]

  const allAreSameDay = timeEntries.every((timeEntry) => {
    const start = isSameDay(timeEntry.startAt, firstTimeEntry.startAt)
    const end = timeEntry.endAt ? isSameDay(timeEntry.endAt, firstTimeEntry.startAt) : isSameDay(timeEntry.startAt, now)
    return start && end
  })

  const reversedTimeEntries = timeEntries.sort((a, b) => (a.startAt < b.startAt ? -1 : 1))

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validateOnBlur={false}
      validate={(values) => validateTimeEntries(values, timeEntries)}
      onSubmit={onSubmit}
    >
      {(props) => (
        <Form className={"h-full flex flex-col"}>
          <ModalBody>
            <div>
              <div className="flex flex-col md:flex-row md:justify-between items-baseline mb-4">
                <div className="text-lg font-bold">
                  {format(reversedTimeEntries?.[reversedTimeEntries.length - 1].startAt, "EEEE, MMMM do")}
                </div>
                <div className="text-sm md:text-md text-gray-400">Total: {secondsToFormattedString(totalSeconds)}</div>
              </div>

              <div className="transition-all flex flex-col gap-12">
                {timeEntries.map((timeEntry) => {
                  return (
                    <EditableTimeEntry
                      sameDay={allAreSameDay}
                      key={timeEntry.id}
                      timeEntries={timeEntries}
                      timeEntry={timeEntry}
                      user={user}
                    />
                  )
                })}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <ButtonHollow type="button" onClick={onCancel} disabled={mutationIsFetching || props.isSubmitting}>
              Cancel
            </ButtonHollow>
            <ButtonFilled type="submit" disabled={mutationIsFetching}>
              Save
            </ButtonFilled>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  )
}

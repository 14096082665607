import { FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, TextField } from "@mui/material"
import { Form, Formik, FormikValues, useField } from "formik"
import { FC, useContext, useEffect } from "react"
import { useQuery } from "urql"
import * as Yup from "yup"
import { Asset, Task, useTransferAssetsMutation } from "../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../graphql/generated/gql"
import { ModalProps } from "../../hooks/useModalProps"
import { SelectableAsset } from "../../types/Asset"
import { SelectableUser } from "../../types/User"
import { PickPlus } from "../../types/helpers"
import { AssetImage } from "../AssetImage"
import { Chip } from "../Chips/Chip"
import { ChipContainer } from "../Chips/ChipContainer"
import { ButtonFilled, ButtonHollow } from "../Elements"
import { AssetsMultiSelect } from "../Formik/MultiSelect/implementations/AssetsMultiSelect"
import { TeamMembersMultiSelect } from "../Formik/MultiSelect/implementations/TeamMembersMultiSelect"
import { ModalBody } from "../Modals/ModalBody"
import { ModalFooter } from "../Modals/ModalFooter"
import { ModalLoadingContext } from "../Modals/StandardModal"
import { errorSnack, successSnack } from "../Notistack/ThemedSnackbars"

const GetProjectTasksDocument = graphql(`
  query TransferAssetModalGetProjectTasks($projectId: String!) {
    project(id: $projectId) {
      id
      tasks {
        id
        name
      }
    }

    assets(projectId: $projectId, includeGroupedAssets: false) {
      id
      assignableId
      assignableType
      assignedAssetId
      assignedTaskId
      assignedTask {
        id
        name
        projectId
        project {
          id
          name
        }
      }
      assignedUserId
      assignedUser {
        id
        firstName
        imageUrl
        jobTitle
        lastName
      }

      active
      assetChildCount
      companyAssetNumber
      compositeKey
      deletedAt
      groupQuantity
      imageUrl
      isAssetGroup
      name
      ownershipType
      status
    }

    assetGroups(projectId: $projectId) {
      assetGroupId
      assignableId
      assignableType
      assignedTask {
        id
        name
        projectId
        project {
          id
          name
        }
      }
      compositeKey
      count
      groupParent {
        id
        name
        imageUrl
        ownershipType
      }
      status
    }
  }
`)

export const TransferAssetModal: FC<{
  assets: SelectableAsset[]
  formModalProps: ModalProps
  selectedUserIds?: string[]
  selectedAssets?: string[]
  projectId?: string
  task?: PickPlus<Task, "id" | "name"> | null
  onSuccess?: () => void
  teamMembers: SelectableUser[]
}> = ({
  assets,
  formModalProps,
  selectedAssets = [],
  selectedUserIds,
  onSuccess = () => {},
  teamMembers,
  projectId,
  task,
}) => {
  const [, transferAssetMutation] = useTransferAssetsMutation()
  const { isLoading, setIsLoading } = useContext(ModalLoadingContext)

  const handleSubmit = async (values: FormikValues) => {
    setIsLoading(true)

    let selectedAssignable

    switch (values.assignmentType) {
      case "User":
        selectedAssignable = values.userAssignment?.[0]
        break
      case "Asset":
        selectedAssignable = values.assetAssignment
        break
      case "Task":
        selectedAssignable = values.taskAssignment
        break
      default:
        selectedAssignable = ""
    }

    transferAssetMutation({
      assetIds: values.assets,
      assignableId: selectedAssignable,
      assignableType: values.assignmentType,
      projectIdIfTask: projectId,
    }).then((result) => {
      if (result.error) {
        errorSnack("An error was encountered while reassigning users. Please try again.")
        console.error(result.error)
      } else {
      }
      if (onSuccess) onSuccess()
      successSnack("Asset Assigned")
      formModalProps.handleCloseModal()

      setIsLoading(false)
    })
  }

  return (
    <Formik
      initialValues={{
        assignmentType: "User",
        userAssignment: selectedUserIds ?? [assets[0]?.assignableId],
        assets: selectedAssets,
      }}
      validationSchema={Yup.object().shape({
        assignmentType: Yup.string(),
        userAssignment: Yup.array(
          Yup.string().when("assignmentType", {
            is: "User",
            then: (schema) => schema.required("Required"),
          })
        ),
        taskAssignment: Yup.string().when("assignmentType", {
          is: "Task",
          then: (schema) => schema.required("Required"),
        }),
        assetAssignment: Yup.string().when("assignmentType", {
          is: "Asset",
          then: (schema) => schema.required("Required"),
        }),
        assets: Yup.array(Yup.string().required("Required")),
      })}
      onSubmit={handleSubmit}
    >
      <FormContents
        assets={assets}
        handleClose={formModalProps.handleCloseModal}
        isLoading={isLoading}
        teamMembers={teamMembers}
        projectId={projectId}
        task={task}
      />
    </Formik>
  )
}

type FormContentsProps = {
  assets: Pick<Asset, "id" | "assignableId" | "companyAssetNumber" | "imageUrl" | "name">[]
  projectId?: string
  task?: PickPlus<Task, "id" | "name"> | null
  handleClose: () => void
  isLoading: boolean
  teamMembers: SelectableUser[]
}
const FormContents: FC<FormContentsProps> = ({ assets, isLoading, handleClose, teamMembers, projectId = "", task }) => {
  const [assetsField, _meta, helpers] = useField<string[]>("assets")
  const [tasksField, _taskMeta, taskHelpers] = useField<string>("taskAssignment")
  const [assetAssignmentField, _assignmentMeta, assignmentHelpers] = useField<string>("assetAssignment")
  const [assignmentType, _typeMeta, typeHelper] = useField<string>("assignmentType")
  const [{ data: componentQuery }] = useQuery({
    query: GetProjectTasksDocument,
    variables: { projectId },
    pause: !projectId,
  })

  const projectAssets = componentQuery?.assets

  useEffect(() => {
    if (!projectId) {
      taskHelpers.setValue(task?.id || "")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Form className={"h-full flex flex-col"}>
      <ModalBody className="min-h-[250px]">
        <AssetsMultiSelect assets={assets} name={"assets"} />
        <RadioGroup
          name="assignmentType"
          row
          className="py-5"
          value={assignmentType.value || "User"}
          onChange={(e) => typeHelper.setValue(e?.target?.value)}
        >
          <FormControlLabel value="User" control={<Radio />} label="User" />
          <FormControlLabel value="Asset" control={<Radio />} label="Asset" />
          <FormControlLabel value="Task" control={<Radio />} label="Task" />
        </RadioGroup>
        {assignmentType.value === "User" && (
          <TeamMembersMultiSelect teamMembers={teamMembers} name={"userAssignment"} isSingleSelect={true} />
        )}
        {assignmentType.value === "Asset" && (
          <FormControl fullWidth>
            <TextField
              select
              label="Assets"
              name="assetAssignment"
              fullWidth
              size="small"
              value={assetAssignmentField.value || ""}
              onChange={(e) => {
                assignmentHelpers.setValue(e.target.value)
              }}
            >
              {projectAssets?.length ? (
                projectAssets.map((asset) => (
                  <MenuItem key={asset.id} value={asset?.id}>
                    {asset?.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled value="">
                  <em>No assets available to select</em>
                </MenuItem>
              )}
            </TextField>
          </FormControl>
        )}
        {assignmentType.value === "Task" && (
          <FormControl fullWidth>
            <TextField
              select
              label="Tasks"
              name="taskAssignment"
              fullWidth
              size="small"
              value={tasksField.value || ""}
              onChange={(e) => {
                taskHelpers.setValue(e.target.value)
              }}
            >
              {getTaskOptions(projectId ? componentQuery?.project?.tasks! : task ? [task] : [])}
            </TextField>
          </FormControl>
        )}
        <ChipContainer className="pt-4">
          {assetsField.value
            .map((assetId) => assets.find((asset) => asset.id === assetId))
            .map((asset) => {
              if (!asset) return null
              return (
                <Chip
                  key={asset.id}
                  onDelete={() => {
                    helpers.setValue(assetsField.value.filter((assetId) => assetId !== asset.id))
                  }}
                >
                  <div className="rounded-full overflow-hidden">
                    <AssetImage
                      width="w-7 md:w-7"
                      height="h-7 md:h-7"
                      asset={asset}
                      placeholderImageBgColor="bg-white"
                      placeholderImageTextColor="text-gray-500"
                    />
                  </div>
                  <p className="max-w-48 overflow-hidden leading-tight truncate">{asset.name}</p>
                </Chip>
              )
            })}
        </ChipContainer>
      </ModalBody>
      <ModalFooter>
        <ButtonHollow type="button" onClick={handleClose}>
          Cancel
        </ButtonHollow>
        <ButtonFilled type="submit" disabled={isLoading || !assetsField.value.length}>
          Reassign
        </ButtonFilled>
      </ModalFooter>
    </Form>
  )
}

function getTaskOptions<T extends PickPlus<Task, "id" | "name">>(tasks: T[]) {
  if (!tasks.length) {
    return (
      <MenuItem disabled value="">
        <em>No tasks available to select</em>
      </MenuItem>
    )
  }

  return tasks.map((task: T) => (
    <MenuItem key={task?.id} value={task?.id} selected={tasks.length === 1}>
      {task.name}
    </MenuItem>
  ))
}

import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import { format } from "date-fns"
import { FC } from "react"
import { BiLogOut, BiStopwatch } from "react-icons/bi"
import {
  TimeEntry,
  User,
  useBulkClockInMutation,
  useBulkClockOutMutation,
} from "../../../../graphql/generated/client-types-and-hooks"
import { pluralize } from "../../../../helpers/pluralize"
import { useClockedInUsers } from "../../../../hooks/useClockedInUsers"
import { useClockedOutUsers } from "../../../../hooks/useClockedOutUsers"
import { useModalProps } from "../../../../hooks/useModalProps"
import { PickPlus } from "../../../../types/helpers"
import { errorSnack, infoSnack } from "../../../Notistack/ThemedSnackbars"

type Props = {
  onSuccess?: () => void
  selectedUsers: (PickPlus<
    User,
    "id" | "organizationId" | "projectId" | "taskId" | "currentProjectId" | "currentTaskId" | "isClockedIn"
  > & {
    latestTimeEntry?: PickPlus<TimeEntry, "endAt"> | null
  })[]
}

export const LegacyBulkClockActions: FC<Props> = ({ onSuccess, selectedUsers }) => {
  const [, bulkClockInMutation] = useBulkClockInMutation()
  const [, bulkClockOutMutation] = useBulkClockOutMutation()
  const loadingIndicatorModalProps = useModalProps()
  const clockedInUsers = useClockedInUsers(selectedUsers)
  const clockedOutUsers = useClockedOutUsers(selectedUsers)

  const handleBulkClockIn = () => {
    const candidates = clockedOutUsers.map((user) => ({
      user,
    }))
    const now = new Date()

    loadingIndicatorModalProps.handleOpenModal()
    bulkClockInMutation({
      candidates: candidates.map((candidate) => ({
        projectId: candidate.user.currentProjectId,
        taskId: candidate.user.currentTaskId,
        userId: candidate.user.id,
      })),
      startAt: now,
    }).then((result) => {
      if (result.error) {
        loadingIndicatorModalProps.handleCloseModal()
        errorSnack(`Error with Bulk Clock in`)
        console.error(result.error)
      } else {
        const { errors, success } = result.data?.bulkClockIn || { errors: [], success: 0 }
        const doubleBookings = errors?.filter((error) => error.reason === "ATTEMPTED_DOUBLE_BOOKING")
        const alreadyClockedInUsers = errors?.filter((error) => error.reason === "ALREADY_CLOCKED_IN")

        if (success > 0) {
          onSuccess?.()
          infoSnack(`Clocked in ${success} ${pluralize("user", success)}`)
        }
        if (doubleBookings.length) {
          infoSnack(
            `${doubleBookings.length} ${pluralize("user", doubleBookings.length)} ${pluralize(
              "is",
              doubleBookings.length
            )} unable to be clocked in at ${format(now, "h:mm aaa")}`
          )
        }
        if (alreadyClockedInUsers.length) {
          infoSnack(
            `${alreadyClockedInUsers.length} ${pluralize("user", alreadyClockedInUsers.length)} were already clocked in`
          )
        }

        loadingIndicatorModalProps.handleCloseModal()
      }
    })
  }

  const handleBulkClockOut = () => {
    const candidates = clockedInUsers.map((user) => ({ user }))
    const now = new Date()

    loadingIndicatorModalProps.handleOpenModal()
    bulkClockOutMutation({
      candidates: candidates.map((candidate) => ({
        userId: candidate.user.id,
      })),
      endAt: now,
    }).then((result) => {
      if (result.error) {
        loadingIndicatorModalProps.handleCloseModal()
        errorSnack(`Error with Bulk Clock out`)
        console.error(result.error)
      } else {
        const { errors, success } = result.data?.bulkClockOut || { errors: [], success: 0 }
        const attemptedTimeTravelers = errors.filter((error) => error.reason === "END_CANNOT_BE_BEFORE_START")
        const alreadyClockedInUsers = errors.filter((error) => error.reason === "ALREADY_CLOCKED_IN")

        if (success > 0) {
          onSuccess?.()
          infoSnack(`Clocked out ${success} ${pluralize("user", success)}`)
        }
        if (attemptedTimeTravelers.length) {
          infoSnack(
            `${attemptedTimeTravelers.length} ${pluralize("user", attemptedTimeTravelers.length)} ${pluralize(
              "is",
              attemptedTimeTravelers.length
            )} unable to be clocked out at the clock-out time (${format(now, "h:mm aaa")})`
          )
        }
        if (alreadyClockedInUsers.length) {
          infoSnack(
            `${alreadyClockedInUsers.length} ${pluralize("user", alreadyClockedInUsers.length)} ${pluralize(
              "is",
              alreadyClockedInUsers.length
            )} already clocked out`
          )
        }
        loadingIndicatorModalProps.handleCloseModal()
      }
    })
  }
  return (
    <>
      {clockedOutUsers.length > 0 && (
        <ListItem disablePadding>
          <ListItemButton onClick={handleBulkClockIn}>
            <ListItemIcon>
              <BiStopwatch className="h-5 w-5" />
            </ListItemIcon>
            <ListItemText primary="Clock in" />
          </ListItemButton>
        </ListItem>
      )}
      {clockedInUsers.length > 0 && (
        <ListItem disablePadding>
          <ListItemButton onClick={handleBulkClockOut}>
            <ListItemIcon>
              <BiLogOut className="h-5 w-5" />
            </ListItemIcon>
            <ListItemText primary="Clock out" />
          </ListItemButton>
        </ListItem>
      )}
    </>
  )
}

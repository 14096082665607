import { NextPage } from "next"
import { createContext, FC, ReactNode, useContext, useEffect } from "react"
import { useQuery } from "urql"
import { Tab } from "../../../../components/deprecated/TabGroup"
import Layout from "../../../../components/Layout/Layout"
import { TaskDetailsLayout } from "../../../../components/Partials/Projects/TaskDetailsLayout"
import { TaskGroupDetailsLayout } from "../../../../components/Partials/Projects/TaskGroupDetailsLayout"
import { SkeletonContainer } from "../../../../components/Skeletons/SkeletonContainer"
import { SkeletonElement } from "../../../../components/Skeletons/SkeletonElement"
import { Task } from "../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../graphql/generated/gql"
import { BreadcrumbsContext } from "../../../../providers/BreadcrumbsProvider"
import { PickPlus } from "../../../../types/helpers"

const GetTaskQuery = graphql(`
  query GetTaskDetails($id: String!) {
    task(id: $id) {
      id
      name
      projectId
      parentTaskId

      archived
      assetCount
      isComplete
      isDefault
      userCount

      project {
        id
        name
        isArchived
        isComplete
      }
    }
  }
`)

const GetTaskGroupQuery = graphql(`
  query GetTaskGroupDetails($id: String!) {
    taskGroup(id: $id) {
      id
      completedTaskCount
      name
      projectId
      taskCount
    }
  }
`)

type Props = {
  children: ReactNode
  taskId?: string
  taskGroupId?: string
  projectId?: string
  isTaskGroup?: boolean
  useDataGridStyles?: boolean
}

type PageContentProps = {
  children: ReactNode
  taskId?: string
  taskGroupId?: string
  projectId?: string
  withTabs?: boolean
  isTaskGroup?: boolean
}

export const tabs: Tab[] = [
  {
    label: "Details",
    href: "/projects/[_project_id]/[_task_id]/details",
  },
  {
    label: "team",
    href: "/projects/[_project_id]/[_task_id]/team",
  },
  {
    label: "assets",
    href: "/projects/[_project_id]/[_task_id]/assets",
  },
  {
    label: "Summary",
    href: "/projects/[_project_id]/[_task_id]/summary",
  },
]

type TaskLayoutContextType = {
  task: PickPlus<Task, "id" | "name" | "projectId" | "isComplete"> | null
}

export const TaskLayoutContext = createContext<TaskLayoutContextType>({ task: null })

export const TaskLayoutPageContent: FC<PageContentProps> = ({
  children,
  taskId,
  taskGroupId,
  projectId,
  withTabs = true,
  isTaskGroup = false,
}) => {
  const [{ data: taskQuery, fetching: taskIsLoading }] = useQuery({
    query: GetTaskQuery,
    variables: { id: taskId! },
    pause: !taskId || taskId === taskGroupId,
  })

  const [{ data: taskGroupQuery, fetching: taskGroupIsLoading }] = useQuery({
    query: GetTaskGroupQuery,
    variables: { id: taskGroupId! },
    pause: !isTaskGroup || !taskGroupId,
  })

  const { setAdditionalBreadcrumbs, setIsLoading, setLabelSubstitutions } = useContext(BreadcrumbsContext)

  useEffect(() => {
    setIsLoading(taskIsLoading || taskGroupIsLoading)
    if (taskQuery?.task?.project) {
      setLabelSubstitutions([
        {
          original: taskQuery.task.project.id,
          replacement: taskQuery?.task.project.name,
        },
        {
          original: taskQuery.task.id,
          replacement: taskQuery.task.name,
        },
      ])
    }
    return () => {
      setAdditionalBreadcrumbs([])
    }
  }, [setAdditionalBreadcrumbs, setIsLoading, setLabelSubstitutions, taskGroupIsLoading, taskIsLoading, taskQuery])

  const task = taskQuery?.task
  const taskGroup = taskGroupQuery?.taskGroup

  const value = {
    task: {
      id: isTaskGroup ? taskGroup?.id! : task?.id!,
      name: isTaskGroup ? taskGroup?.name! : task?.name!,
      projectId: isTaskGroup ? taskGroup?.projectId! : task?.projectId!,
      isComplete: task?.isComplete,
      ...taskGroup,
    },
  }

  return (
    <TaskLayoutContext.Provider value={{ task: value.task }}>
      {taskIsLoading || (taskGroupIsLoading && <TaskLoadingSkeleton />)}
      {!isTaskGroup && task && <TaskDetailsLayout task={task} withTabs={withTabs} />}
      {isTaskGroup && taskGroup && (
        <TaskGroupDetailsLayout
          taskGroup={taskGroup}
          withTabs={isTaskGroup ? false : withTabs}
          projectId={projectId || ""}
        />
      )}
      {children}
    </TaskLayoutContext.Provider>
  )
}

const TaskLoadingSkeleton = () => {
  return (
    <SkeletonContainer>
      <div className="flex gap-4 my-4">
        <SkeletonElement className="w-8 h-8" />
        <SkeletonElement className="w-64 h-8" />
      </div>
    </SkeletonContainer>
  )
}

const TaskLayout: NextPage<Props> = ({ children, taskId, taskGroupId, isTaskGroup, projectId, useDataGridStyles }) => {
  return (
    <Layout useDataGridStyles={useDataGridStyles}>
      <TaskLayoutPageContent taskId={taskId} taskGroupId={taskGroupId} isTaskGroup={isTaskGroup} projectId={projectId}>
        {children}
      </TaskLayoutPageContent>
    </Layout>
  )
}

export default TaskLayout

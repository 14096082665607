import { Typography } from "@mui/material"
import { DataGridPro, GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro"
import { FC, cloneElement } from "react"
import { BiCheckCircle, BiChevronsUp, BiListUl, BiSubdirectoryRight } from "react-icons/bi"
import { useQuery } from "urql"
import { ProjectSummaryTaskTableQuery } from "../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../graphql/generated/gql"
import { classNames } from "../../../../helpers/classNames"
import { colors } from "../../../../helpers/colors"
import { getFriendlyFloat } from "../../../../helpers/getFriendlyFloat"
import {
  ProductionRateSpan,
  getProductionRateArrowClassName,
} from "../../../Widgets/SummaryWidgets/ProductionRateWidget"
import { TaskTitle } from "../../Projects/Tasks/TaskTitle"
import { SummarySectionProps } from "../types"

export const defaultColumnProps: Partial<GridColDef> = {
  disableColumnMenu: true,
  filterable: false,
  flex: 1,
  hideable: false,
  minWidth: 100,
  resizable: false,
  sortable: false,
}

const ProjectSummaryTaskTableDocument = graphql(`
  query ProjectSummaryTaskTable($projectId: String!, $rangeEnd: DateTime!, $rangeStart: DateTime!) {
    project(id: $projectId) {
      id
      tasks(includeCompleted: true, includeSubtasks: false) {
        id
        completedHours(rangeStart: $rangeStart, rangeEnd: $rangeEnd)
        estimatedHours
        isComplete
        productionRate(rangeStart: $rangeStart, rangeEnd: $rangeEnd)
        name
        assetCount(rangeStart: $rangeStart, rangeEnd: $rangeEnd)
        usersWhoClockedOrReportedCount(rangeStart: $rangeStart, rangeEnd: $rangeEnd)
        primaryUnitGoals {
          id
          targetQuantity
          progressInDateRange(rangeStart: $rangeStart, rangeEnd: $rangeEnd)
          deliverableUnit {
            id
            description
            unitOfMeasure
          }
        }
      }
      taskGroups {
        id
        completedHours
        isComplete
        name
        assetCount(rangeStart: $rangeStart, rangeEnd: $rangeEnd)
        productionRate(rangeStart: $rangeStart, rangeEnd: $rangeEnd)
        userCount(rangeStart: $rangeStart, rangeEnd: $rangeEnd)
        taskCount
      }
    }
  }
`)

type TaskExpectation = NonNullable<ProjectSummaryTaskTableQuery["project"]>["tasks"][0]
type RowData = {
  id: TaskExpectation["id"]
  assetCount: number
  completedHours: TaskExpectation["completedHours"]
  name: TaskExpectation["name"]
  productionRate: number | undefined
  unitName: string | undefined
  unitProgress: string | undefined
  userCount: number

  // Convenience or helper properties
  href: string
  isComplete: boolean
  isTaskGroup: boolean
}

export const ProjectSummaryTaskTable: FC<SummarySectionProps> = ({ projectId, rangeEnd, rangeStart }) => {
  const [{ data }] = useQuery({
    query: ProjectSummaryTaskTableDocument,
    variables: { rangeEnd, rangeStart, projectId },
  })

  const rows: RowData[] = []
  data?.project?.tasks
    .filter(
      // We only want to render things that are interesting to the user.  If this feature isn't reconsidered during finalization of this flag, we can bring it into a queryable filter rather than being a "client only" filter.
      (task) => {
        return task.primaryUnitGoals.some((g) => g.progressInDateRange) || task.usersWhoClockedOrReportedCount > 0
      }
    )
    .forEach((task) => {
      const hasUnitGoals = task.primaryUnitGoals.length > 0
      const hasTooManyUnitGoals = task.primaryUnitGoals.length > 1
      const unitGoal = task.primaryUnitGoals[0]
      rows.push({
        ...task,

        href: `/projects/${projectId}/${task.id}/summary`,
        isTaskGroup: false,
        isComplete: task.isComplete !== null,

        completedHours: task.completedHours,
        unitName: hasUnitGoals ? (hasTooManyUnitGoals ? "Multiple" : unitGoal.deliverableUnit.description) : undefined,
        userCount: task.usersWhoClockedOrReportedCount,
        unitProgress: hasUnitGoals
          ? hasTooManyUnitGoals
            ? "Multiple"
            : `${unitGoal.progressInDateRange} ${unitGoal.deliverableUnit.unitOfMeasure}`
          : undefined,
      })
    })
  data?.project?.taskGroups.forEach((taskGroup) => {
    rows.push({
      ...taskGroup,
      name: `${taskGroup.name} (${taskGroup.taskCount})`,
      productionRate: taskGroup.productionRate,
      unitName: undefined,
      unitProgress: undefined,

      href: `/projects/${projectId}/groups/${taskGroup.id}/details`,
      isTaskGroup: true,
    })
  })
  const columns = [
    {
      field: "taskName",
      flex: 3,
      minWidth: 150,
      renderCell: ({ row }: { row: RowData }) => (
        <div className="flex gap-x-2 items-center">
          {cloneElement(getIconComponent(row), { color: colors.neutral[50] })}
          <TaskTitle
            taskOrGroupId={row.id}
            projectId={projectId}
            isComplete={Boolean(row.isComplete)}
            size="small"
            name={row.name}
            secondaryTitle
            tab="summary"
          />
        </div>
      ),
      headerName: "Name",
    },
    {
      field: "userCount",
      headerName: "Team",
    },
    {
      field: "assetCount",
      headerName: "Assets",
    },
    {
      field: "completedHours",
      headerName: "Hours",
      renderCell: ({ row }: GridRenderCellParams<RowData>) => <div>{getFriendlyFloat(row.completedHours)} hrs</div>,
    },
    {
      field: "unitName",
      headerName: "Unit",
      flex: 2,
      renderCell: ({ row }: GridRenderCellParams<RowData>) =>
        row.unitName ? row.unitName : <span className="text-gray-400">N/A</span>,
    },
    {
      field: "unitProgress",
      flex: 2,
      headerName: "Progress",
      renderCell: ({ row }: GridRenderCellParams<RowData>) =>
        row.unitProgress ? row.unitProgress : <span className="text-gray-400">N/A</span>,
    },
    {
      field: "productionRate",
      headerName: "Rate",
      flex: 2,
      renderCell: ({ row }: GridRenderCellParams<RowData>) =>
        row.productionRate ? (
          <div className="flex gap-x-2 items-center">
            <BiChevronsUp className={classNames("w-6 h-6", getProductionRateArrowClassName(row.productionRate))} />
            <ProductionRateSpan rate={row.productionRate} />
          </div>
        ) : (
          <span className="text-gray-400">N/A</span>
        ),
    },
  ]

  return (
    <div>
      <Typography variant="h4">Tasks</Typography>
      <DataGridPro
        autoHeight
        loading={!data}
        columns={columns.map((column) => ({ ...defaultColumnProps, ...column }))}
        disableRowSelectionOnClick
        initialState={{ pagination: { paginationModel: { page: 0, pageSize: 10 } } }}
        pageSizeOptions={[10]}
        pagination
        rows={rows}
      />
    </div>
  )
}

const getIconComponent = (row: RowData) => {
  return row.isComplete ? (
    <BiCheckCircle className="p-1 mr-2 h-6 w-6 rounded-md bg-green-600" />
  ) : row.isTaskGroup ? (
    <BiSubdirectoryRight className="p-1 mr-2 h-6 w-6 rounded-md bg-gray-800" />
  ) : (
    <BiListUl className="p-1 mr-2 h-6 w-6 rounded-md bg-gray-800" />
  )
}

import { addHours, format, subHours } from "date-fns"
import { Form, useField } from "formik"
import { FC, useEffect, useMemo } from "react"
import { useQuery } from "urql"
import { ModernBulkClockInCandidate } from "."
import { TimeEntryError } from "../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../graphql/generated/gql"
import { canBeClockedIn } from "../../../../helpers/canBeClockedIn"
import { uploadTimeEntryEvidence } from "../../../../helpers/uploadTimeEntryEvidence"
import { useHandleError } from "../../../../hooks/useHandleError"
import { ButtonFilled, ButtonHollow } from "../../../Elements"
import { ModalBody } from "../../../Modals/ModalBody"
import { ModalFooter } from "../../../Modals/ModalFooter"
import { infoSnack } from "../../../Notistack/ThemedSnackbars"
import { DeprecatedTimePicker } from "../../../deprecated/TimePicker"
import { ClockInTeamMemberRow } from "./ClockInTeamMemberRow"
import { ConflictTeamMemberRow } from "./ConflictTeamMemberRow"
import { PartialErrorInfoBanners } from "./PartialErrorInfoBanners"
import { getUserErrorMap } from "./getUserErrorMap"

type Props = {
  handleCancel: () => void
  successes: number
  errors: TimeEntryError[]
  taskId?: string
  userIds?: string[]
}

const BulkClockInUsers = graphql(`
  query BulkClockInUsers($first: Int, $filter: UserFilter!) {
    users(first: $first, filter: $filter) {
      totalCount
      edges {
        node {
          id
          firstName
          lastName
          jobTitle
          imageUrl
          organizationId
          currentTaskId
          currentProjectId
          latestTimeEntry {
            id
            taskId
            endAt
            startAt
            evidence
          }
        }
      }
    }
  }
`)

export const BulkClockInFormContents: FC<Props> = ({ handleCancel, successes, errors, taskId, userIds }) => {
  const [{ data, error }] = useQuery({
    query: BulkClockInUsers,
    pause: !taskId && !userIds?.length,
    variables: { first: userIds?.length, filter: { isClockedIn: false, taskId, userIds } },
  })

  useHandleError(error, "There was a problem loading some user data")

  // Todo: Update the bulk clock in to use pagination rather than limiting the UI to 100 rows.
  useEffect(() => {
    if (data?.users.totalCount && data?.users.totalCount > 100)
      infoSnack(`Only displaying 100 of the ${data?.users.totalCount} selected users.`)
  }, [data?.users.totalCount])

  const [startAtField] = useField<Date>("startAt")
  const [candidatesField, _, candidatesHelpers] = useField<ModernBulkClockInCandidate[]>("candidates")
  const userErrorMap = useMemo(() => getUserErrorMap(errors), [errors])

  const readyCandidateCount = useMemo(() => {
    return candidatesField.value.filter((candidate) =>
      canBeClockedIn(candidate.user.latestTimeEntry, startAtField.value, userErrorMap[candidate.user.id])
    ).length
  }, [candidatesField.value, startAtField.value, userErrorMap])

  return (
    <Form>
      <ModalBody>
        <PartialErrorInfoBanners direction={"in"} successes={successes} errors={errors} />
        <DeprecatedTimePicker name={"startAt"} startDate={subHours(new Date(), 2)} endDate={addHours(new Date(), 2)} />
        <p className={"text-gray-400 mt-4 mb-8"}>
          Bulk clock in ({readyCandidateCount}) on {format(startAtField.value, "eee, MMMM yyy")} at{" "}
          {format(startAtField.value, "hh:mm aaa")}.
        </p>
        <div className={"flex flex-col gap-4"}>
          {data?.users.edges.map((edge) => {
            const user = edge?.node
            if (!user) return null
            return canBeClockedIn(user.latestTimeEntry, startAtField.value, userErrorMap[user.id]) ? (
              <ClockInTeamMemberRow
                key={user.id}
                user={user}
                hasBeenPhotographed={candidatesField.value.some((candidate) => candidate.user.id === user.id)}
                cancelPhoto={() => {
                  candidatesHelpers.setValue(candidatesField.value.filter((candidate) => candidate.user.id !== user.id))
                }}
                collectPhoto={async (photoBlob) => {
                  const imagePath = await uploadTimeEntryEvidence(photoBlob, user.id)

                  if (imagePath) {
                    candidatesHelpers.setValue([...candidatesField.value, { user, imagePath }])
                  }
                }}
              />
            ) : (
              <ConflictTeamMemberRow key={user.id} user={user} type={"in"} />
            )
          })}
        </div>
      </ModalBody>
      <ModalFooter>
        <ButtonHollow type={"button"} onClick={handleCancel}>
          Cancel
        </ButtonHollow>
        <ButtonFilled type={"submit"} disabled={readyCandidateCount === 0}>
          Clock in ({readyCandidateCount})
        </ButtonFilled>
      </ModalFooter>
    </Form>
  )
}

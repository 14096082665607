import { useQuery } from "urql"
import { graphql } from "../../graphql/generated/gql"
import { getProjectProgressSummary } from "../../helpers/summarizeProgressFromTaskList"

// Queries for the progress of a specified task and its subtasks.
const TaskAndSubtaskProgressQueryDocument = graphql(`
  query TaskAndSubtaskProgressSummaryHook($parentTaskId: String!) {
    task(id: $parentTaskId) {
      id
      isComplete
      estimatedHours
      timeEntriesSumDurationInSeconds
      unitGoalsSumProgress
      unitGoalsSumTargetQuantity
    }
    subtasks(parentTaskId: $parentTaskId) {
      id
      isComplete
      estimatedHours
      timeEntriesSumDurationInSeconds
      unitGoalsSumProgress
      unitGoalsSumTargetQuantity
    }
  }
`)

/**
Custom hook to fetch and summarize the progress of a task and its subtasks.
@param parentTaskId - The ID of the parent task.
@returns An object containing the summarized progress data, along with fetching status, potential errors, and a refetch function.
 */
export const useGetTaskAndSubtaskProgressSummary = (parentTaskId: string) => {
  const [{ data, error, fetching }, refetch] = useQuery({
    query: TaskAndSubtaskProgressQueryDocument,
    variables: { parentTaskId },
    pause: !parentTaskId,
  })

  const tasks = [...(data?.subtasks || [])]

  if (data?.task) tasks.push(data?.task)
  return {
    data: getProjectProgressSummary(tasks),
    fetching,
    error,
    refetch,
  }
}

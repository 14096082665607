import { format } from "date-fns"
import { utcToZonedTime } from "date-fns-tz"
import { TaskUnitsGraphProgressQuery } from "../../../../graphql/generated/client-types-and-hooks"
import { UnitSummaryGraphHoursData } from "../../../../models/TimeEntryModel"
import { DateRangeFilterString } from "../types"
import { formatDisplayDate } from "./formatDisplayDate"
import { getGroupingKey } from "./getGroupingKey"

export type GraphData = {
  description: string
  color: string
  progress: number
}

type UnitsData = Omit<GraphData, "progress">

export type RechartData = {
  name: string
  hours: number
  [description: string]: number | string
}

export function prepareDataForRecharts(
  task: TaskUnitsGraphProgressQuery["task"],
  dateRangeType: DateRangeFilterString = "daily"
): { data: RechartData[]; units: UnitsData[] } {
  const groupedItems: { [key: string]: GraphData[] } = {}
  const uniqueUnits: { [description: string]: UnitsData } = {}
  const { taskProgressEventsWithUnitReports: taskProgressEvents } = task || { taskProgressEvents: [] }

  for (const event of taskProgressEvents) {
    const groupingKey = getGroupingKey(new Date(event.createdAt), dateRangeType)
    const formattedName = formatDisplayDate(groupingKey, dateRangeType, true)

    if (!groupedItems[formattedName]) {
      groupedItems[formattedName] = []
    }

    for (const report of event.unitGoalProgressReports || []) {
      const existingUnit = groupedItems[formattedName].find(
        (item) => item.description === report.unitGoal.deliverableUnit.description
      )

      if (existingUnit) {
        existingUnit.progress += report.progress ?? 0
      } else {
        const newUnit = {
          description: report.unitGoal.deliverableUnit.description,
          color: report.unitGoal.deliverableUnit.color,
          progress: report.progress,
        }
        groupedItems[formattedName].push(newUnit)
      }

      uniqueUnits[report.unitGoal.deliverableUnit.description] = {
        description: report.unitGoal.deliverableUnit.description,
        color: report.unitGoal.deliverableUnit.color,
      }
    }
  }

  const hoursLookup: { [formattedDate: string]: number } = {}
  task.hoursDataByDateRangeType?.forEach((hourData: UnitSummaryGraphHoursData) => {
    const utcDate = utcToZonedTime(hourData.date, "UTC")
    const date = dateRangeType === "all-time" ? format(utcDate, "yyyy-MM-01") : format(utcDate, "yyyy-MM-dd")
    const formattedDate = formatDisplayDate(date, dateRangeType, true)
    hoursLookup[formattedDate] = hourData.duration_in_hours
  })

  const rechartData = Object.entries(groupedItems).map(([name, units]) => {
    const hours = Math.round(hoursLookup[name] * 100) / 100 || 0
    const dataItem: RechartData = { name, hours }

    units.forEach((unit) => {
      dataItem[unit.description] = unit.progress
    })
    return dataItem
  })
  return { data: rechartData, units: Object.values(uniqueUnits) }
}

import { FC } from "react"
import { BiRuler, BiTimeFive } from "react-icons/bi"
import { Task } from "../../../../graphql/generated/client-types-and-hooks"
import { useGetTaskAndSubtaskProgressSummary } from "../../../../hooks/queries/useGetTaskAndSubtaskProgressSummary"
import { PickPlus } from "../../../../types/helpers"
import { DateProgressBar } from "../../../ProgressBars/DateProgressBar"
import { DateProgressGroup } from "../../../ProgressBars/DateProgressGroup"
import { ProgressBar } from "../../../ProgressBars/ProgressBar"
import { SkeletonContainer } from "../../../Skeletons/SkeletonContainer"
import { SkeletonElement } from "../../../Skeletons/SkeletonElement"
import { TaskType } from "../Tasks/TaskRow"
import { TaskPillCluster } from "./TaskPillCluster"

type TaskProgressBarProps = {
  task: PickPlus<Task, "id" | "endDate" | "completedHours" | "estimatedHours" | "startDate"> &
    ({ isComplete?: boolean } | PickPlus<Task, "isComplete">)
  type?: TaskType
  drawer?: boolean
  mobile?: boolean
}

/** Renders a task's progress bar cluster. */
export const TaskProgressBar: FC<TaskProgressBarProps> = ({ task, type, drawer = false, mobile = false }) => {
  const { data, fetching } = useGetTaskAndSubtaskProgressSummary(task.id)

  if (fetching) {
    return (
      <SkeletonContainer>
        <div className="grid gap-1">
          <SkeletonElement className="h-6" />
          <SkeletonElement className="h-6" />
          <SkeletonElement className="h-6" />
        </div>
      </SkeletonContainer>
    )
  }

  return (
    <>
      {(type === "primaryTask" || drawer) && (
        <DateProgressGroup start={task.startDate} end={task.endDate} isComplete={!!task.isComplete}>
          <DateProgressBar start={task.startDate} end={task.endDate} isComplete={!!task.isComplete} />
          <ProgressBar
            Icon={BiTimeFive}
            isComplete={!!task.isComplete}
            itemLabel={"hours"}
            max={data?.targetHours}
            textColor={data?.completedHours ? "text-blue-800" : "text-gray-400"}
            value={data?.completedHours}
          />
          <ProgressBar
            bgColor="bg-sky-500"
            Icon={BiRuler}
            isComplete={!!task.isComplete}
            itemLabel="units"
            max={data?.targetUnits}
            textColor={data?.completedUnits ? "text-sky-800" : "text-gray-400"}
            value={data?.completedUnits}
          />
        </DateProgressGroup>
      )}
      <TaskPillCluster mobile={mobile} subtask={type === "groupTask"} task={task} />
    </>
  )
}

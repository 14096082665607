import { Button, Divider, IconButton, ListItemIcon, ListItemText, MenuItem, MenuList, Typography } from "@mui/material"
import { FC, useContext } from "react"
import { BiAddToQueue, BiCar, BiCheck, BiFilter, BiListPlus, BiPlus, BiSortAlt2 } from "react-icons/bi"
import { UseQueryExecute } from "urql"
import { AssetCategory, AssetStatus } from "../../../../graphql/generated/client-types-and-hooks"
import { assetCategoryOptions } from "../../../../helpers/assetCategory"
import { assetStatusList } from "../../../../helpers/assetStatus"
import { classNames } from "../../../../helpers/classNames"
import { useModalProps } from "../../../../hooks/useModalProps"
import { AssetListContext } from "../../../../providers/AssetListProvider"
import { AssignedAssetListContext } from "../../../../providers/AssignedAssetListProvider"
import { useFlags } from "../../../../providers/DevelopmentFeatureFlagProvider"
import { DrawerContext } from "../../../../providers/DrawerProvider"
import { AssetFilter } from "../../../AssetFilter"
import { CustomMenu } from "../../../CustomMenu"
import { SearchBar } from "../../../Elements/SearchBar"
import { IconButton as CvIconButton } from "../../../IconButton"
import { QuickMenu } from "../../../QuickMenu"
import { RenderIf } from "../../../RenderIf"
import { AssetCreationDrawer } from "../../Drawer/AssetCreationDrawer"
import { DrawerLink } from "../../Drawer/DrawerLink"
import { ReassignAssetDrawer } from "../../Drawer/ReassignAssetDrawer"
import { AssetQuickAddTable } from "../AssetQuickAdd"

export const AssetListHeader: FC<{
  assignedAsset?: boolean
  fetchAssets: UseQueryExecute
  projectId?: string
  taskId?: string
  isComplete?: boolean
  handleAssignAsset?: () => void
}> = ({ assignedAsset = false, fetchAssets, projectId, taskId, isComplete, handleAssignAsset }) => {
  const assetListContext = useContext(AssetListContext)
  const assignedAssetListContext = useContext(AssignedAssetListContext)
  const ctx = assignedAsset ? assignedAssetListContext : assetListContext
  const { push: pushDrawer } = useContext(DrawerContext)
  const { flagIsEnabled } = useFlags()

  const selectStatus = (status: AssetStatus) => {
    ctx.replaceList()
    ctx.selectStatus(status)
    fetchAssets()
  }

  const deselectStatus = (status: AssetStatus) => {
    ctx.replaceList()
    ctx.deselectStatus(status)
    fetchAssets()
  }

  const selectCategory = (category: AssetCategory) => {
    ctx.replaceList()
    ctx.selectCategory(category)
    fetchAssets()
  }

  const deselectCategory = (category: AssetCategory) => {
    ctx.replaceList()
    ctx.deselectCategory(category)
    fetchAssets()
  }

  const setArchivedFilter = (isArchived: boolean) => {
    ctx.deselectAllStatuses()
    ctx.replaceList()
    ctx.setIsArchived(isArchived)
    fetchAssets()
  }

  const isArchived = ctx.isArchived
  const hasProjectOrTaskId = !!projectId || !!taskId

  const assetQuickAddModalProps = useModalProps("Add Assets")

  const statusFilterOptions = {
    label: "STATUS",
    items: assetStatusList
      .filter((option) => {
        if (isArchived) return option.canBeArchived
        return option.canBeActive
      })
      .map((option) => ({
        label: option.label,
        onSelect: () => selectStatus(option.value),
        onDeselect: () => deselectStatus(option.value),
        disabled: hasProjectOrTaskId,
      })),
  }

  const categoryFilterOptions = {
    label: "CATEGORY",
    items: assetCategoryOptions.map((option) => ({
      label: option.label,
      onSelect: () => selectCategory(option.value),
      onDeselect: () => deselectCategory(option.value),
      disabled: hasProjectOrTaskId,
    })),
  }

  const extraFilters = [statusFilterOptions]

  if (flagIsEnabled("Asset Category")) extraFilters.push(categoryFilterOptions)

  return (
    <div>
      <div className="flex gap-x-8 items-center flex-wrap justify-between">
        <Typography fontSize={hasProjectOrTaskId ? 24 : 36} fontWeight={600} marginBottom={1}>
          Assets
        </Typography>
      </div>
      {/* SUB-HEADER */}
      <div className="grid grid-cols-12 gap-x-2 gap-y-4 md:gap-x-3 md:gap-y-5">
        {/* SEARCH BAR */}
        <div className="col-span-7 md:col-span-5">
          <SearchBar fullWidth onChange={ctx.setSearchValue} onCancel={ctx.setSearchValue} />
        </div>
        {/* ACTIONS */}
        <div className="col-span-5 md:col-span-7 flex gap-3 items-center justify-end">
          {ctx?.rowsSelected && ctx?.rowsSelected?.length > 0 && (
            <RenderIf permissionsInclude="asset:transfer">
              <Button
                variant="contained"
                color="secondary"
                startIcon={<BiSortAlt2 className="rotate-90 w-5 h-5" />}
                onClick={() =>
                  pushDrawer(<ReassignAssetDrawer assetsIdsToReassign={ctx.selectedAssets.map((asset) => asset.id)} />)
                }
              >
                Reassign
              </Button>
            </RenderIf>
          )}

          {!hasProjectOrTaskId && !isArchived && ctx.rowsSelected && ctx.rowsSelected.length === 0 && (
            <RenderIf permissionsInclude="asset:create">
              <QuickMenu
                menuButtonProps={{ variant: "contained", color: "secondary", endIcon: <BiPlus /> }}
                items={[
                  [
                    {
                      value: "Add asset",
                      onClick: () => pushDrawer(<AssetCreationDrawer />),
                      Icon: BiCar,
                    },
                    {
                      value: "Quick add",
                      onClick: () => assetQuickAddModalProps.handleOpenModal(),
                      Icon: BiListPlus,
                    },
                  ],
                ]}
              >
                Add asset
              </QuickMenu>
            </RenderIf>
          )}
          {hasProjectOrTaskId && (
            <RenderIf permissionsInclude="asset:create">
              {handleAssignAsset ? (
                <CvIconButton
                  data-test="taskDrawer:assetsTab:reassignAsset"
                  onClick={handleAssignAsset}
                  Icon={BiAddToQueue}
                  disabled={isComplete}
                  className={classNames(isComplete && "opacity-50")}
                />
              ) : (
                <DrawerLink href="/assets/create" component={<AssetCreationDrawer />}>
                  <CvIconButton Icon={BiPlus} />
                </DrawerLink>
              )}
            </RenderIf>
          )}
        </div>
        {/* FILTERS */}
        {flagIsEnabled("My <> Filters") ? (
          <div className="col-span-12 flex gap-2">
            <AssetFilter
              extraFilters={extraFilters}
              noun="assets"
              setVisibilityFilter={ctx.setVisibilityFilter}
              setArchivedFilter={setArchivedFilter}
            />
          </div>
        ) : (
          <div className="col-span-12 flex gap-2">
            <CustomMenu
              open={ctx.filterMenuOpen}
              setOpen={ctx.setFilterMenuOpen}
              anchor={
                <IconButton className="rounded-full w-8 h-8 bg-gray-200">
                  <BiFilter />
                </IconButton>
              }
            >
              <MenuList className="flex flex-col px-2" sx={{ minWidth: 200 }}>
                <Typography className="font-medium px-3 mb-3">Filter Menu</Typography>
                <Divider className="mb-2" />
                <MenuItem onClick={() => setArchivedFilter(!isArchived)}>
                  <ListItemText>Active</ListItemText>
                  {!isArchived && <MenuItemSelected />}
                </MenuItem>
              </MenuList>
            </CustomMenu>

            {/* ADD NEW FILTER */}
            <IconButton className="w-8 h-8" onClick={() => ctx.setFilterMenuOpen(true)}>
              <BiPlus className="w-5 h-5" />
            </IconButton>
          </div>
        )}
      </div>

      <AssetQuickAddTable {...assetQuickAddModalProps} />
    </div>
  )
}

const MenuItemSelected = () => (
  <ListItemIcon className="justify-end">
    <BiCheck className="text-xl text-blue-600" />
  </ListItemIcon>
)

import { Box, IconButton } from "@mui/material"
import { DataGridPro, GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GridCellParams, GridColDef } from "@mui/x-data-grid-pro"
import { format } from "date-fns"
import { NextPage } from "next"
import Error from "next/error"
import { FC, useState } from "react"
import { BiChevronDown, BiChevronUp, BiImage, BiNote } from "react-icons/bi"
import { useQuery } from "urql"
import { H3 } from "../../../components/Elements"
import { InspectButton } from "../../../components/InspectionButton"
import { InspectionSummary } from "../../../components/InspectionSummary"
import { InventoryButton } from "../../../components/InventoryButton"
import { NAButton } from "../../../components/NAbutton"
import { PageTitle } from "../../../components/PageTitle"
import { AssetHistoryDropDown } from "../../../components/Partials/Assets/AssetHistoryDropdown"
import { AssetReportStatusPill } from "../../../components/Partials/Assets/AssetReportStatusPill"
import { AssetReportType } from "../../../components/Partials/Assets/AssetReportType"
import { FailedInspectionsDataGrid } from "../../../components/Partials/Inspection/FailedInspectionsDataGrid"
import { InventoryHistoryResult } from "../../../components/Partials/Inventory/InventoryHistoryResult"
import { RenderIf } from "../../../components/RenderIf"
import { CompactUserBadge } from "../../../components/UserBadge"
import { NotificationsTable } from "../../../components/UserNotifications/NotificationTable"
import {
  AssetHistoryQuery,
  AssetReport,
  UserNotificationModelType,
} from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { classNames } from "../../../helpers/classNames"
import AssetPageLayout from "./_layout"

type Props = {
  assetId: string
}

const AssetHistoryDocument = graphql(`
  query AssetHistory($assetId: String!) {
    asset(id: $assetId) {
      id
      active
      groupQuantity
      name
      status
      assetChildCount
      assignableId
      assignableType
      isAssetGroup
      ownershipType
      inferredOwner {
        id
      }
      inventoryRequirements {
        photoRequired
        intervalInSeconds
      }
      inspectionTemplatesAssignments {
        id
        inspectionTemplate {
          id
          name
        }
      }
    }
    assetGroups(assetGroupId: $assetId) {
      assetGroupId
      assignableId
      assignableType
      assignedUserId
      count
      status
    }
    assetReports(assetId: $assetId) {
      id
      assetId
      notes
      createdAt
      quantityReported
      reporter {
        id
        archived
        firstName
        imageUrl
        jobTitle
        lastName
        isClockedIn
      }
      statusChange {
        active
        status
        missingCount
      }
      type
      inspectionReport {
        id
        photoUrls
      }
      inspectionReportAggregations {
        trueCount
        falseCount
      }
      inventoryReport {
        fileIds
        note
        photoUrls
      }
    }
  }
`)

export const AssetHistory: FC<Props> = ({ assetId }) => {
  const [clickedIndex, setClickedIndex] = useState(null)

  const gridCellCenterStyling = "flex gap-2 items-center "

  const [{ data }] = useQuery({
    query: AssetHistoryDocument,
    variables: { assetId },
  })
  const columns: GridColDef[] = [
    { field: "id" },
    {
      headerName: "Date",
      field: "createdAt",
      flex: 2,
      minWidth: 60,
      valueFormatter: (params) => {
        return format(params.value, "MMM do, yyyy")
      },
    },
    {
      headerName: "Type",
      field: "type",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Box>
          <AssetReportType type={params.row.type} />
        </Box>
      ),
    },
    {
      headerName: "By",
      field: "reporter",
      flex: 3,
      minWidth: 180,
      renderCell: (params) => (
        <Box>
          <CompactUserBadge user={params.row.reporter} />
        </Box>
      ),
    },

    {
      headerName: "Status",
      field: "status",
      flex: 2,
      minWidth: 175,
      renderCell: (params) => (
        <Box>
          <AssetReportStatusPill report={params.row} />
        </Box>
      ),
    },
    {
      headerName: "Result",
      field: "inspection",
      sortable: false,
      minWidth: 110,
      flex: 2,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            {params.row.type === "Inspection" ? (
              <InspectionSummary report={params.row.inspectionReportAggregations} />
            ) : (
              <InventoryHistoryResult report={params.row} />
            )}
          </>
        )
      },
    },
    {
      headerName: "Notes",
      field: "notes",
      sortable: false,
      filterable: false,
      width: 75,
      renderCell: (params: GridCellParams) => {
        const reportNote = params.row.inventoryReport?.note || params.row.notes

        return params.row.type === "Transfer" ? (
          <NAButton />
        ) : (
          reportNote && (
            <div className={gridCellCenterStyling}>
              <BiNote className="rounded h-6 w-6 p-1 bg-gray-200" />
            </div>
          )
        )
      },
    },
    {
      headerName: "Img.",
      field: "image",
      sortable: false,
      filterable: false,
      width: 75,
      renderCell: (params: GridCellParams<AssetReport>) => {
        const photoCount = (
          params.row.inventoryReport?.photoUrls ||
          params.row?.inspectionReport?.flatMap((r) => r?.photoUrls)?.filter((url) => url) ||
          []
        ).length
        return (
          photoCount > 0 && (
            <div className={gridCellCenterStyling}>
              <BiImage className="rounded h-6 w-6 p-1 bg-gray-200" />
              <p className="ml-2flex">{photoCount}</p>
            </div>
          )
        )
      },
    },
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: (params) => {
        return (
          <IconButton
            key="1"
            onClick={() => {
              setClickedIndex(params.row.id === clickedIndex ? null : params.row.id)
            }}
          >
            {clickedIndex === params.row.id ? <BiChevronUp /> : <BiChevronDown>Collapse</BiChevronDown>}
          </IconButton>
        )
      },
    },
  ]

  const title = "Asset History"
  return (
    <Box>
      <NotificationsTable modelType={UserNotificationModelType.Asset} assetId={assetId} />
      <PageTitle title={`${data?.asset?.name || "Asset"} history`} />

      <div className={classNames("flex items-center flex-wrap gap-8", title ? "justify-between" : "justify-end")}>
        {title && <H3 className={"md:pb-4"}>{title}</H3>}
        <div>
          {data && (
            <InventoryButton asset={data.asset} assetGroups={data.assetGroups} includeIcon={true} className="mr-2" />
          )}
          {data && (
            <InspectButton
              asset={{
                id: data.asset.id,
                inspectionTemplatesAssignments: data.asset.inspectionTemplatesAssignments,
              }}
              includeIcon={true}
            />
          )}
        </div>
      </div>
      <FailedInspectionsDataGrid assetId={assetId} />
      <Box sx={{ height: "auto", width: "100%", marginTop: "1rem" }}>
        <DataGridPro<AssetHistoryQuery["assetReports"][0]>
          autoHeight
          columns={columns}
          disableRowSelectionOnClick={true}
          getEstimatedRowHeight={() => 70}
          getRowHeight={() => "auto"}
          sx={{
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": { py: "12px" },
          }}
          getDetailPanelHeight={() => "auto"}
          getDetailPanelContent={({ row }) => <AssetHistoryDropDown assetReportId={row.id} />}
          rows={data?.assetReports || []}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
                projectId: true,
                quantityReported: (data?.asset?.groupQuantity || 0) > 1,
                taskId: true,
                inspection: true,
                status: true,
              },
            },
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
        />
      </Box>
    </Box>
  )
}

const AssetPage: NextPage<Props> = ({ assetId }) => {
  return (
    <RenderIf permissionsInclude="asset:read" fallbackComponent={<Error statusCode={404} />}>
      <AssetPageLayout assetId={assetId}>
        <AssetHistory assetId={assetId} />
      </AssetPageLayout>
    </RenderIf>
  )
}

AssetPage.getInitialProps = async ({ query }) => {
  return {
    assetId: query._id as string,
  }
}

export default AssetPage

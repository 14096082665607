import { FC } from "react"
import { BiX } from "react-icons/bi"
import { Input } from "./Input"

type Props = {
  className?: string
  onClose?: () => void
  setSearchTerm: (value: string) => void
  searchTerm: string
}

export const SearchInput: FC<Props> = ({ onClose, searchTerm, setSearchTerm }) => {
  return (
    <div className="relative h-10 bg-gray-100 flex justify-center items-center rounded">
      <Input
        autoFocus={true}
        className="lg:w-[340px]"
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyUp={(e) => {
          if (e.key !== "Escape") return

          if (searchTerm) {
            setSearchTerm("")
            return
          }

          if (onClose) onClose()
        }}
        placeholder="Search"
        type="text"
        value={searchTerm}
      />
      {searchTerm && (
        <button className="absolute right-1 top-2" onClick={() => setSearchTerm("")} type="button">
          <BiX className="w-6 h-6 text-gray-400" />
        </button>
      )}
    </div>
  )
}

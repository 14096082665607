import { NextPage } from "next"
import { FC, ReactNode, useContext, useEffect } from "react"
import { BiDotsHorizontalRounded, BiEdit, BiHash, BiLink, BiPurchaseTag, BiTrash, BiX } from "react-icons/bi"
import { useQuery } from "urql"
import { AssetArchiveModal } from "../../../components/AssetArchiveModal"
import { AssetImage } from "../../../components/AssetImage"
import { H2 } from "../../../components/Elements"
import Layout from "../../../components/Layout/Layout"
import { AssetSkeleton } from "../../../components/Partials/Assets/Asset.skeleton"
import { AssetEditDrawer } from "../../../components/Partials/Drawer/AssetEditDrawer"
import { MenuItem, QuickMenuMui } from "../../../components/QuickMenuMui"
import { DeprecatedTabGroup, Tab } from "../../../components/deprecated/TabGroup"
import { assetsMemberProfilePageTestSelectors } from "../../../cypress/support/testSelectors"
import { useRestoreAssetMutation } from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { useHandleError } from "../../../hooks/useHandleError"
import { useModalProps } from "../../../hooks/useModalProps"
import { BreadcrumbsContext } from "../../../providers/BreadcrumbsProvider"
import { DrawerContext } from "../../../providers/DrawerProvider"
import { PermissionsContext } from "../../../providers/PermissionsProvider/PermissionsProvider"

export const tabs: Tab[] = [
  {
    label: "Details",
    href: "/assets/[_id]/details",
    testSelector: assetsMemberProfilePageTestSelectors.tabs.details,
  },
  {
    label: "History",
    href: "/assets/[_id]/history",
    testSelector: assetsMemberProfilePageTestSelectors.tabs.history,
  },
]

type Props = {
  children: ReactNode
  assetId: string
  withTabs?: boolean
}

const AssetDetailsPageDocument = graphql(`
  query AssetDetailsPage($id: String!) {
    asset(id: $id) {
      id
      assetChildCount
      assetGroupId
      assignableType
      assignableId
      companyAssetNumber
      deletedAt
      groupQuantity
      imageUrl
      isAssetGroup
      name
      category
      ownershipType
      active
      activeChangedAt
      assetChildCount
      assetGroupMemberCount
      purchaseDetails {
        price
        date
      }
      rentalAgreement {
        rate {
          daily
          weekly
          monthly
        }
        endOn
        startOn
      }
      manufacturer {
        id
        make
        name
        year
        model
      }
      vendorContact {
        name
        email
        phone
      }
      inventoryRequirements {
        intervalInSeconds
        photoRequired
      }
      inspectionTemplatesAssignments {
        id
        assetId
        intervalInSeconds
        lastReportedAt
        startDate
        inspectionTemplate {
          id
          name
        }
      }
    }
  }
`)

export const AssetPageContent: FC<Props> = ({ children, assetId, withTabs = true }) => {
  const [{ data, fetching, error }] = useQuery({
    query: AssetDetailsPageDocument,
    variables: { id: assetId },
  })
  useHandleError(error, "An error occurred while fetching asset details. Please reload the page to try again.")

  const { setLabelSubstitutions, setIsLoading } = useContext(BreadcrumbsContext)
  const { push: pushDrawer } = useContext(DrawerContext)
  const { hasPermissionTo } = useContext(PermissionsContext)

  const archiveAssetModalProps = useModalProps("Archive Asset")
  const [_restoreAssetData, assetRestoreMutation] = useRestoreAssetMutation()

  useEffect(() => {
    setIsLoading(fetching)
    setLabelSubstitutions([
      {
        original: data?.asset?.id || "",
        replacement: `${data?.asset?.name}`,
      },
    ])
  }, [fetching, setIsLoading, setLabelSubstitutions, data?.asset?.id, data?.asset?.name])

  tabs.push({
    label: "Documents",
    href: "/assets/[_id]/documents",
    testSelector: assetsMemberProfilePageTestSelectors.tabs.documents,
  })

  const menuItems: MenuItem[][] = []

  if (hasPermissionTo("asset:update") && data?.asset) {
    menuItems.push([
      {
        value: "Edit",
        onClick: () => pushDrawer(<AssetEditDrawer asset={data.asset} />),
        Icon: BiEdit,
      },
    ])
  }

  if (hasPermissionTo("asset:delete") && data?.asset.deletedAt === null) {
    menuItems.push([
      {
        value: "Archive asset",
        color: "red",
        Icon: BiTrash,
        onClick: () => archiveAssetModalProps.handleOpenModal(),
      },
    ])
  }

  if (hasPermissionTo("asset:delete") && data?.asset.deletedAt !== null) {
    menuItems.push([
      {
        value: "Unarchive asset",
        color: "red",
        Icon: BiTrash,
        onClick: () => {
          assetRestoreMutation({
            id: data?.asset.id!,
          })
        },
      },
    ])
  }

  return (
    <div>
      {fetching && <AssetSkeleton />}
      {!fetching && data?.asset && (
        <>
          <div className="flex mb-4 gap-x-4">
            <AssetImage width="w-20" height="h-20" padding="p-6" asset={data?.asset} />
            <div className="md:ml-4 flex flex-col justify-center gap-2">
              <div className="flex gap-x-2">
                <H2 className="text-gray-800 my-0">{data?.asset.name}</H2>
                <QuickMenuMui className="self-center" items={menuItems} buttonShape="round">
                  <BiDotsHorizontalRounded className="text-2xl" />
                </QuickMenuMui>
              </div>
              <div className="flex gap-3 items-center text-gray-600 truncate">
                {data?.asset.ownershipType === "RENT" && (
                  <div className="flex items-center gap-0.5 text-orange-600">
                    <BiPurchaseTag />
                    <div>Rental</div>
                  </div>
                )}
                {data?.asset.assetChildCount > 0 && !data?.asset.isAssetGroup && !data?.asset.assetGroupId && (
                  <div className="flex items-center gap-0.5">
                    <BiLink />
                    <div>{data?.asset.assetChildCount}</div>
                  </div>
                )}
                {!!data?.asset.groupQuantity && data?.asset.groupQuantity > 1 && (
                  <div className="flex items-center gap-0.5">
                    <BiX />
                    <div>{data?.asset.groupQuantity}</div>
                  </div>
                )}
                {data?.asset.companyAssetNumber && (
                  <div className="flex items-center gap-0.5">
                    <BiHash />
                    <div className="truncate">{data?.asset.companyAssetNumber}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {withTabs && <DeprecatedTabGroup tabs={tabs} />}
          {children}
          <AssetArchiveModal assetId={data.asset.id} formModalProps={archiveAssetModalProps} />
        </>
      )}
    </div>
  )
}

const AssetsPageLayout: NextPage<Props> = ({ children, assetId }) => {
  return (
    <Layout>
      <AssetPageContent assetId={assetId}>{children}</AssetPageContent>
    </Layout>
  )
}

export default AssetsPageLayout

import { Typography } from "@mui/material"
import { UserNotificationModelType } from "@prisma/client"
import { FC } from "react"
import { BiHash, BiLink, BiPurchaseTag, BiX } from "react-icons/bi"
import { Asset } from "../graphql/generated/client-types-and-hooks"
import { classNames } from "../helpers/classNames"
import { PickPlus } from "../types/helpers"
import { AssetImage } from "./AssetImage"
import { AssetDrawer } from "./Partials/Drawer/AssetDrawer"
import { DrawerLink } from "./Partials/Drawer/DrawerLink"
import { NotificationBubble } from "./UserNotifications/NotificationBubble"

type AssetBadgeAssetType = PickPlus<
  Asset,
  | "id"
  | "name"
  | "assetGroupId"
  | "companyAssetNumber"
  | "isAssetGroup"
  | "assetChildCount"
  | "ownershipType"
  | "groupQuantity"
>

export type AssetBadgeProps = {
  asset: AssetBadgeAssetType & { activeCount?: number; missingCount?: number; depth?: number }
  className?: string
}

export const AssetBadge: FC<AssetBadgeProps> = ({ asset, className }) => {
  const {
    id,
    name,
    assetGroupId,
    companyAssetNumber,
    isAssetGroup,
    assetChildCount,
    ownershipType,
    groupQuantity,
    activeCount = 0,
    missingCount = 0,
  } = asset

  const isAssetChildStack = Boolean(assetGroupId) && assetChildCount > 0
  const assetStackQuantity = activeCount + missingCount

  return (
    <DrawerLink
      href={`/assets/${id}/details`}
      component={<AssetDrawer assetId={id} />}
      className={classNames("flex gap-1.5 items-center", className)}
    >
      <AssetImage width="w-12 lg:w-9" height="h-12 lg:h-9" padding="p-0" asset={asset} />
      <div className="grid content-center gap-y-0.5 overflow-hidden">
        <div className="flex items-center">
          <NotificationBubble
            className="mt-1"
            shouldRender={(notifications) => {
              return !!notifications.filter(
                (n) => n.modelType === UserNotificationModelType.Asset && n.asset?.id === asset.id
              ).length
            }}
          />
          <Typography variant="h6" className="mt-1 h-[17px] overflow-hidden break-all">
            {name}
          </Typography>
        </div>
        <div className="flex gap-2 items-center text-xs text-gray-600 truncate">
          {ownershipType === "RENT" && (
            <div className="flex items-center gap-px text-orange-600">
              <BiPurchaseTag />
              <div>Rental</div>
            </div>
          )}
          {(isAssetGroup || isAssetChildStack) && (
            <div className="flex items-center gap-px">
              {isAssetGroup || assetGroupId ? <BiX /> : <BiLink />}
              <div>{assetGroupId ? assetChildCount : groupQuantity || assetStackQuantity}</div>
            </div>
          )}
          {companyAssetNumber && (
            <div className="flex items-center gap-px">
              <BiHash />
              <div className="truncate">{companyAssetNumber}</div>
            </div>
          )}
        </div>
      </div>
    </DrawerLink>
  )
}

import { FC, useContext } from "react"
import { CompleteTaskWorkflow, Props } from "../Tasks/CompleteTaskWorkflow"
import { SingleDrawerContext } from "./Drawer"
import { DrawerBody } from "./DrawerBody"
import { DrawerHeader } from "./DrawerHeader"

export const CompleteTaskDrawer: FC<Props> = ({ onError, onSuccess, project, task }) => {
  const { handleClose } = useContext(SingleDrawerContext)
  return (
    <>
      <DrawerHeader handleClose={handleClose} href={`/projects/${project?.id}/${task.id}/complete`} />
      <DrawerBody>
        <CompleteTaskWorkflow
          onError={() => {
            onError?.()
          }}
          onSuccess={() => {
            onSuccess?.()
            handleClose()
          }}
          project={project}
          task={task}
        />
      </DrawerBody>
    </>
  )
}

import { TimeEntry } from "../../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../../types/helpers"
import { isClockedIn } from "../isClockedIn"

export function sortUsersWithLatestTimeEntryByStatusAscending<U, T extends PickPlus<TimeEntry, "startAt" | "endAt">>(
  a: { user: U; latestTimeEntry?: T | undefined | null },
  b: { user: U; latestTimeEntry?: T | undefined | null }
): -1 | 0 | 1 {
  return sortByLatestTimeEntryStatusAscending(a, b)
}

export function sortByLatestTimeEntryStatusAscending<T extends PickPlus<TimeEntry, "startAt" | "endAt">>(
  a: { latestTimeEntry?: T | undefined | null },
  b: { latestTimeEntry?: T | undefined | null }
): -1 | 0 | 1 {
  if (isClockedIn(a.latestTimeEntry) && !isClockedIn(b.latestTimeEntry)) return -1
  if (!isClockedIn(a.latestTimeEntry) && isClockedIn(b.latestTimeEntry)) return 1

  return 0
}

import format from "date-fns/format"
import { Formik } from "formik"
import { FC, useContext, useState } from "react"
import { ModernBulkClockOutCandidate } from "."
import { TimeEntryError, useBulkClockOutMutation } from "../../../../graphql/generated/client-types-and-hooks"
import { canBeClockedOut } from "../../../../helpers/canBeClockedOut"
import { getCurrentLocation } from "../../../../helpers/getCurrentLocation"
import { pluralize } from "../../../../helpers/pluralize"
import { ModalLoadingContext } from "../../../Modals/StandardModal"
import { errorSnack, infoSnack } from "../../../Notistack/ThemedSnackbars"
import { BulkClockOutFormContents } from "./BulkClockOutFormContents"

type Props = {
  onCancel: () => void
  onSuccess: () => void
  taskId?: string
  userIds?: string[]
}

type FormValues = {
  endAt: Date
  candidates: ModernBulkClockOutCandidate[]
}

export const BulkClockOutModalContents: FC<Props> = ({ onCancel, onSuccess, taskId, userIds }) => {
  const { setIsLoading } = useContext(ModalLoadingContext)
  const [, bulkClockOutMutation] = useBulkClockOutMutation()
  const [successes, setSuccesses] = useState<number>(0)
  const [errors, setErrors] = useState<TimeEntryError[]>([])

  const initialValues: FormValues = {
    endAt: new Date(),
    candidates: [],
  }

  const handleBulkClockOut = async (endAt: Date, candidates: ModernBulkClockOutCandidate[]) => {
    try {
      const location = await getCurrentLocation()

      bulkClockOutMutation({
        candidates: candidates
          .filter((candidate) => canBeClockedOut(candidate.user.latestTimeEntry, endAt))
          .map((candidate) => ({
            userId: candidate.user.id,
            endEvidence: { location },
          })),
        endAt,
      }).then((result) => {
        if (result.error) {
          errorSnack(`Error with Bulk Clock out`)
          console.error(result.error)
        } else {
          const { errors, success } = result.data?.bulkClockOut || { errors: [], success: 0 }
          const attemptedTimeTravelers = errors.filter((error) => error.reason === "END_CANNOT_BE_BEFORE_START")
          const alreadyClockedInUsers = errors.filter((error) => error.reason === "ALREADY_CLOCKED_IN")

          if (errors.length > 0) setErrors(errors)

          if (success > 0) {
            infoSnack(`Clocked out ${success} ${pluralize("user", success)}`)
            setSuccesses(success)
            if (errors.length === 0) onSuccess()
          }
          if (attemptedTimeTravelers.length) {
            infoSnack(
              `${attemptedTimeTravelers.length} ${pluralize("user", attemptedTimeTravelers.length)} ${pluralize(
                "is",
                attemptedTimeTravelers.length
              )} unable to be clocked out at the clock-out time (${format(endAt, "h:mm aaa")})`
            )
          }
          if (alreadyClockedInUsers.length) {
            infoSnack(
              `${alreadyClockedInUsers.length} ${pluralize("user", alreadyClockedInUsers.length)} ${pluralize(
                "is",
                alreadyClockedInUsers.length
              )} already clocked out`
            )
          }
        }
      })
    } catch (e) {
      console.error(e)
    }
  }

  const onSubmit = (values: FormValues) => {
    if (values.candidates.length) {
      setIsLoading(true)
      handleBulkClockOut(values.endAt, values.candidates)
        .catch((e) => console.error(e))
        .then(() => {
          setIsLoading(false)
        })
    }
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      <BulkClockOutFormContents
        taskId={taskId}
        userIds={userIds}
        handleCancel={onCancel}
        successes={successes}
        errors={errors}
      />
    </Formik>
  )
}

import { Typography } from "@mui/material"
import { FC, useContext } from "react"
import { colors } from "../../../../helpers/colors"
import { usePermissions } from "../../../../providers/PermissionsProvider/PermissionsProvider"
import { TaskListDispatchContext, TaskListStateContext } from "../../../../providers/TaskListProvider/TaskListProvider"
import { TASK_LIST_ACTION_TYPES } from "../../../../providers/TaskListProvider/taskListReducer"
import { DrawerLink } from "../../Drawer/DrawerLink"
import { TabValue, TaskDrawer } from "../../Drawer/TaskDrawer"
import { TaskGroupDrawer } from "../../Drawer/TaskGroupDrawer"

type Props = {
  taskOrGroupId: string
  projectId: string
  isComplete?: boolean
  size: "small" | "large"
  isTaskGroup?: boolean
  name?: string
  secondaryTitle?: boolean
  tab?: TabValue
}

export const TaskTitle: FC<Props> = ({
  name,
  taskOrGroupId,
  projectId,
  isComplete,
  size,
  isTaskGroup = false,
  secondaryTitle = false,
  tab = "details",
}) => {
  const { hasPermissionTo } = usePermissions()

  const dispatch = useContext(TaskListDispatchContext)
  const { taskList } = useContext(TaskListStateContext)
  const onSuccess = () => dispatch({ type: TASK_LIST_ACTION_TYPES.refreshTaskList })

  const task = taskList?.find(
    (task) => taskOrGroupId != null && (task.taskId === taskOrGroupId || task.taskGroupId === taskOrGroupId)
  )
  const taskName = name || task?.name
  const isGroup = task?.taskGroupId != null

  const isDisabled = !hasPermissionTo("task:read", { taskId: taskOrGroupId, projectId })

  return (
    <DrawerLink
      disabled={isDisabled}
      component={
        isGroup ? (
          <TaskGroupDrawer taskGroupId={taskOrGroupId} projectId={projectId!} onSuccess={onSuccess} />
        ) : (
          <TaskDrawer taskId={taskOrGroupId!} refetch={onSuccess} tab={tab} />
        )
      }
      href={
        isTaskGroup
          ? `/projects/${projectId}/group/${taskOrGroupId}/details`
          : `/projects/${projectId}/${taskOrGroupId}/summary`
      }
    >
      <div className="flex flex-wrap items-center">
        <Typography
          variant="h6"
          fontSize={size === "small" ? 16 : 20}
          sx={{
            textDecoration: isComplete ? "line-through" : secondaryTitle ? null : isDisabled ? null : "underline",
            cursor: isDisabled ? "not-allowed" : "pointer",
            color: secondaryTitle
              ? isComplete
                ? colors.green[600]
                : colors.gray[800]
              : isComplete
              ? colors.gray[400]
              : colors.blue[600],
            fontWeight: secondaryTitle ? 400 : 700,
            ":hover": {
              textDecoration: isDisabled ? "auto" : "underline",
            },
          }}
        >
          {taskName}
        </Typography>
        {isGroup && (
          <div className="ml-2 text-gray-400 text-sm">
            {task.completedTaskCount}/{task.taskCount} Completed
          </div>
        )}
      </div>
    </DrawerLink>
  )
}

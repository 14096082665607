import { FC, useState } from "react"
import { BiCamera, BiHealth } from "react-icons/bi"
import { FaSignature } from "react-icons/fa"
import { User } from "../../../../graphql/generated/client-types-and-hooks"
import { classNames } from "../../../../helpers/classNames"
import { PickPlus } from "../../../../types/helpers"
import { ModalTitle } from "../../../ClockInOut/ClockInOutWithEvidence/ModalTitle"
import { SignatureModalBody } from "../../../ClockInOut/ClockInOutWithEvidence/SignatureModalBody"
import StandardModal from "../../../Modals/StandardModal"
import { Monogram } from "../../../Monogram"
import { PhotoBooth } from "../../../PhotoBooth"

type Props = {
  cancelPhoto: () => void
  cancelSignature: () => void
  collectInjured: (isInjured: boolean) => void
  collectPhoto: (photoBlob: Blob) => void
  collectSignature: (signatureBlob: Blob) => void
  hasBeenPhotographed: boolean
  hasSigned: boolean
  user: PickPlus<User, "id" | "firstName" | "lastName" | "jobTitle">
}

export const ClockOutTeamMemberRow: FC<Props> = ({
  cancelPhoto,
  cancelSignature,
  collectInjured,
  collectPhoto,
  collectSignature,
  hasBeenPhotographed,
  hasSigned,
  user,
}) => {
  const [photoBoothIsOpen, setPhotoBoothIsOpen] = useState(false)
  const [signatureModalIsOpen, setSignatureModalIsOpen] = useState(false)
  const [isInjured, setIsInjuredState] = useState(false)

  /*
   Always take the user through the same workflow:
   1. Take a photo
   2. Sign

   This applies to progress through a workflow, as well as to what part of the workflow button clicks will trigger.
   For instance, clicking the signature button for a user when the user has no photo taken will prompt a photo, followed
   by a signature.
  */

  return (
    <>
      <div className={"flex gap-2 items-center"}>
        <Monogram user={user} />{" "}
        <div className={"grow"}>
          <p>
            {user.firstName} {user.lastName}
          </p>
          <p className={"text-sm text-gray-400"}>{user.jobTitle}</p>
        </div>
        <button
          type={"button"}
          className={classNames(
            "flex justify-center items-center rounded h-10 w-10 bg-gray-100 transition-all",
            hasBeenPhotographed && "bg-green-600"
          )}
          onClick={() => {
            if (hasBeenPhotographed) {
              cancelPhoto()
            } else {
              setPhotoBoothIsOpen(true)
            }
          }}
        >
          <BiCamera className={classNames("h-6 w-6", hasBeenPhotographed ? "text-white" : "text-gray-600")} />
        </button>
        <button
          type={"button"}
          className={classNames(
            "flex justify-center items-center rounded h-10 w-10 bg-gray-100 transition-all",
            hasSigned && "bg-green-600",
            isInjured && "bg-orange-600"
          )}
          onClick={() => {
            if (hasSigned) {
              cancelSignature()
            } else if (!hasBeenPhotographed) {
              setPhotoBoothIsOpen(true)
            } else {
              setSignatureModalIsOpen(true)
            }
          }}
        >
          {isInjured ? (
            <BiHealth className="h-6 w-6 text-white" />
          ) : (
            <FaSignature className={classNames("h-6 w-6", hasSigned ? "text-white" : "text-gray-600")} />
          )}
        </button>
      </div>
      <PhotoBooth
        title={
          <div>
            Bulk Clock Out{" "}
            <span className={"text-gray-400"}>
              / {user.firstName} {user.lastName}
            </span>
          </div>
        }
        isOpen={photoBoothIsOpen}
        setIsOpen={setPhotoBoothIsOpen}
        onAcceptPhoto={(photo) => {
          collectPhoto(photo)

          if (!hasSigned) {
            setSignatureModalIsOpen(true)
          }
        }}
        onFail={() => {}}
        withFaceFrame={true}
      />
      <StandardModal
        isOpen={signatureModalIsOpen}
        contentLabel={<ModalTitle user={user} type={"Out"} />}
        handleCloseModal={() => {
          setSignatureModalIsOpen(false)
        }}
      >
        <SignatureModalBody
          setSignature={collectSignature}
          setInjuryStatus={(b: boolean) => {
            setIsInjuredState(b)
            collectInjured(b)
          }}
          handleSuccess={() => {
            setSignatureModalIsOpen(false)
          }}
          handleCancel={() => setSignatureModalIsOpen(false)}
        />
      </StandardModal>
    </>
  )
}

import { NextPage } from "next"
import Error from "next/error"
import { FC, useContext } from "react"
import { useQuery } from "urql"
import { DevelopmentFeatureFlag } from "../../../components/DevelopmentFeatureFlag"
import { PageTitle } from "../../../components/PageTitle"
import { ParentAssignmentAndControl } from "../../../components/Partials/Team/ParentAssignmentAndControl"
import { BasicInfo } from "../../../components/Partials/User/BasicInfo"
import { UserSkeleton } from "../../../components/Partials/User/User.skeleton"
import { UserQuickActions } from "../../../components/Partials/User/UserQuickActions"
import { RenderIf } from "../../../components/RenderIf"
import { UserAssignmentsTable } from "../../../components/UserAssignmentsTable"
import { graphql } from "../../../graphql/generated/gql"
import { getFullName } from "../../../helpers/getFullName"
import { useHandleError } from "../../../hooks/useHandleError"
import { PermissionsContext } from "../../../providers/PermissionsProvider/PermissionsProvider"
import UserPageLayout from "./_layout"

type UserPageDetailsProps = {
  userId: string
}

const GetUserDetails = graphql(`
  query TeamDetailsPageGetUser($id: String!, $fetchPay: Boolean!) {
    user(id: $id) {
      id
      currentProjectId
      currentTaskId
      organizationId
      projectId
      taskId
      companyUserId

      archived
      email
      firstName
      imageUrl
      isClockedIn
      jobTitle
      lastName
      phoneNumber
      phoneNumberExt
      secondsClockedSinceOrgMidnight

      payRate @include(if: $fetchPay)
      payType @include(if: $fetchPay)

      currentProject {
        id
        name
      }

      currentTask {
        id
        name
      }

      assignments {
        id
        isCurrentAssignment
        projectId
        taskId
        userId
        project {
          id
          code
          imageUrl
          name
        }
        task {
          id
          name
        }
        taskId
      }

      workersCompCode {
        id
        name
        ncciCode
      }

      childAncestorNodes {
        userId
        depth
      }
      parents {
        id
        firstName
        lastName
        jobTitle
      }
      roles {
        id
        description
        name
      }
    }
  }
`)

export const UserPageDetails: FC<UserPageDetailsProps> = ({ userId }) => {
  const { hasPermissionTo } = useContext(PermissionsContext)
  const [{ data, fetching, error }, refetch] = useQuery({
    query: GetUserDetails,
    variables: { id: userId, fetchPay: hasPermissionTo("payRate:read") },
    pause: !userId,
  })

  const user = data?.user

  useHandleError(error, "Unable to retrieve user details")

  if (!user) {
    return <UserSkeleton />
  }

  return (
    <>
      <PageTitle title={!user?.firstName ? "User details" : `${getFullName(user)} details`} />
      <div className="grid gap-12">
        <div>
          {fetching ? (
            <div>Loading...</div>
          ) : (
            <>
              <UserQuickActions user={user} refetchUser={refetch} />
              <DevelopmentFeatureFlag name="Org Charting">
                <ParentAssignmentAndControl user={user} />
              </DevelopmentFeatureFlag>
            </>
          )}
        </div>

        <div>
          <div className="flex flex-col gap-9">
            {<BasicInfo user={user} />}
            <UserAssignmentsTable
              assignments={user?.assignments || []}
              preassignments={{ userId }}
              refetch={refetch}
              title="Extra Assignments"
            />
          </div>
        </div>
      </div>
    </>
  )
}

const UserPage: NextPage<UserPageDetailsProps> = ({ userId }) => {
  return (
    <RenderIf permissionsInclude="user:list" context={{ userId }} fallbackComponent={<Error statusCode={404} />}>
      <UserPageLayout userId={userId}>
        <UserPageDetails userId={userId} />
      </UserPageLayout>
    </RenderIf>
  )
}

UserPage.getInitialProps = async ({ query }) => {
  return {
    userId: query._id as string,
  }
}

export default UserPage

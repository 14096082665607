import { FC } from "react"
import { BiRuler } from "react-icons/bi"
import { UnitGoal } from "../../../graphql/generated/client-types-and-hooks"
import { getFriendlyFloat } from "../../../helpers/getFriendlyFloat"
import { pluralize } from "../../../helpers/pluralize"
import { PickPlus } from "../../../types/helpers"
import { StackedHorizontalBarChart } from "../../StackedHorizontalBarChart"
import { SummaryWidget } from "./SummaryWidget"
import { UNITS_ACTIVE_COLORS, UNITS_INACTIVE_COLORS, createSource } from "./WidgetSourceColors"

type Props = {
  unitGoals?: PickPlus<UnitGoal, "targetQuantity">[]
  totalUnits: number
  previousUnits: number
  currentUnits?: number
  isLoading: boolean
}

export const UnitsDeliveredWidget: FC<Props> = ({
  unitGoals = [],
  totalUnits = 0,
  previousUnits = 0,
  currentUnits = 0,
}) => {
  const recentUnits = totalUnits - currentUnits - previousUnits

  const sources =
    currentUnits === 0
      ? [createSource(previousUnits + recentUnits, UNITS_INACTIVE_COLORS)]
      : [
          createSource(previousUnits, UNITS_INACTIVE_COLORS),
          createSource(currentUnits, UNITS_ACTIVE_COLORS),
          createSource(recentUnits, UNITS_INACTIVE_COLORS),
        ]

  return (
    <SummaryWidget
      Icon={BiRuler}
      value={getFriendlyFloat(currentUnits)}
      description={`${pluralize("unit", totalUnits)}`}
      extras={
        <StackedHorizontalBarChart
          sources={sources}
          expectedMaximum={unitGoals.reduce((sum, unitGoal) => sum + (unitGoal.targetQuantity || 0), 0)}
        />
      }
    />
  )
}

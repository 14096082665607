import {
  Button,
  Chip,
  Divider,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material"
import { DataGridPro, GridCellParams, GridColDef } from "@mui/x-data-grid-pro"
import { FC, useContext, useEffect, useState } from "react"
import { BiCheck, BiFilter, BiPlus } from "react-icons/bi"
import { useQuery } from "urql"
import { AssetGroup } from "../../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../../graphql/generated/gql"
import { sortAssetGroups } from "../../../../../helpers/getSortedAssetGroups"
import { useHandleError } from "../../../../../hooks/useHandleError"
import { useModalProps } from "../../../../../hooks/useModalProps"
import { AssetListContext } from "../../../../../providers/AssetListProvider"
import { DrawerContext } from "../../../../../providers/DrawerProvider"
import { CustomMenu } from "../../../../CustomMenu"
import { InventoryReportDrawer } from "../../../Drawer/InventoryReportDrawer"
import { AssignmentQuantityReportModal } from "../../AssignmentQuantityReportModal"
import { AddQuantizedAssetsModal } from "./AddQuantizedAssetsModal"
import { ArchiveQuantizedAssetModal } from "./ArchivedQuantizedAssetModal"
import { QuantizedAssetBadgeCell } from "./QuantizedAssetBadgeCell"
import { QuantizedAssetQuickActionsCell } from "./QuantizedAssetQuickActionsCell"
import { QuantizedAssetStatusPill } from "./QuantizedAssetStatusPill"
import { QuantizedAssigneeCell } from "./QuantizedAssigneeCell"
import { QuantizedProjectCell } from "./QuantizedProjectCell"
import { QuantizedQuantityCell } from "./QuantizedQuantityCell"
import { ReturnToInventoryModal } from "./ReturnToInventoryModal"
import { UnarchiveQuantizedAssetModal } from "./UnarchiveQuanitizedAssetModal"

const QuantizedAssetDocument = graphql(`
  query QuantizedAssetQuery($assetId: String!) {
    asset(id: $assetId) {
      id
      assetChildCount
      assetGroupId
      assignableId
      companyAssetNumber
      groupQuantity
      imageUrl
      isAssetGroup
      name
      ownershipType
      status
    }
    assetGroups(assetGroupId: $assetId) {
      assetGroupId
      assignableId
      assignableType
      assignedAssetId
      assignedTaskId
      assignedUserId
      count
      compositeKey
      status
      assignedAsset {
        id
        assetChildCount
        assetGroupId
        assignableId
        companyAssetNumber
        groupQuantity
        imageUrl
        isAssetGroup
        name
        ownershipType
      }
      assignedTask {
        id
        name
        projectId
        project {
          id
          name
        }
      }
      assignedUser {
        id
        firstName
        fullName
        imageUrl
        jobTitle
        lastName
        currentProject {
          id
          name
        }
      }
      groupParent {
        id
        active
        assetChildCount
        imageUrl
        isAssetGroup
        name
        companyAssetNumber
        ownershipType
        status
      }
    }
  }
`)

const ArchivedAssetGroupsDocument = graphql(`
  query ArchivedAssetGroups($assetId: String!) {
    archivedAssetGroups(assetId: $assetId) {
      assetGroupId
      assignableId
      assignableType
      assignedAssetId
      assignedTaskId
      assignedUserId
      count
      compositeKey
      status
      assignedAsset {
        id
        assetChildCount
        assetGroupId
        assignableId
        companyAssetNumber
        groupQuantity
        imageUrl
        isAssetGroup
        name
        ownershipType
      }
      assignedTask {
        id
        name
        projectId
        project {
          id
          name
        }
      }
      assignedUser {
        id
        firstName
        fullName
        imageUrl
        jobTitle
        lastName
        currentProject {
          id
          name
        }
      }
      groupParent {
        id
        active
        assetChildCount
        imageUrl
        isAssetGroup
        name
        companyAssetNumber
        ownershipType
        status
      }
    }
  }
`)

const defaultColumnProps: Partial<GridColDef> = {
  disableColumnMenu: true,
  editable: false,
  filterable: false,
  sortable: false,
  flex: 1,
}

export const QuantizedAssetDataGrid: FC<{ assetId: string; embeddedList?: boolean }> = ({
  assetId,
  embeddedList = false,
}) => {
  const ctx = useContext(AssetListContext)

  const inventoryModalProps = useModalProps("Inventory Check")
  const [addToAsset, setAddToAsset] = useState<AssetGroup>()
  const returnToInventoryProps = useModalProps("Return to Inventory")
  const [assetToReturn, setAssetToReturn] = useState<AssetGroup>({} as AssetGroup)
  const [groupToInventory, setGroupToInventory] = useState<AssetGroup>({} as AssetGroup)
  const [assetToArchive, setAssetToArchive] = useState<AssetGroup | undefined>()
  const [assetToUnarchive, setAssetToUnarchive] = useState<AssetGroup | undefined>()
  const [filterMenuOpen, setFilterMenuOpen] = useState(false)
  const [assignmentGroups, setAssignmentGroups] = useState<AssetGroup[]>([])

  const addQuantityModalProps = useModalProps("Add Quantity")
  const addAssetQuantityModalProps = {
    ...addQuantityModalProps,
    handleCloseModal: () => {
      setAddToAsset(undefined)
      addQuantityModalProps.handleCloseModal()
    },
  }
  const archiveModalProps = useModalProps("Archive Assets")
  const archiveAssetQuantityModalProps = {
    ...archiveModalProps,
    handleCloseModal: () => {
      setAssetToArchive(undefined)
      archiveModalProps.handleCloseModal()
    },
  }
  const unarchiveModalProps = useModalProps("Unarchive Assets")
  const unarchiveAssetQuantityModalProps = {
    ...unarchiveModalProps,
    handleCloseModal: () => {
      setAssetToUnarchive(undefined)
      unarchiveModalProps.handleCloseModal()
    },
  }

  const [{ data, fetching, error }] = useQuery({
    query: QuantizedAssetDocument,
    variables: { assetId },
    pause: ctx.isArchived,
  })

  useHandleError(error, "An error occurred while fetching asset details. Please reload the page to try again.")

  const [{ data: archivedGroups, fetching: loadingArchived, error: archivedError }] = useQuery({
    query: ArchivedAssetGroupsDocument,
    variables: { assetId },
    pause: !ctx.isArchived,
  })
  const { push: pushDrawer } = useContext(DrawerContext)

  useHandleError(archivedError, "An error occurred while getting archived group details. Please try again.")

  useEffect(() => {
    if (ctx.isArchived) {
      return
    }

    if (!data?.assetGroups?.length) {
      setAssignmentGroups([])

      return
    }

    setAssignmentGroups(sortAssetGroups(data.assetGroups as AssetGroup[]))
  }, [data?.assetGroups, embeddedList, ctx.isArchived])

  useEffect(() => {
    if (!ctx.isArchived) {
      return
    }

    if (!archivedGroups?.archivedAssetGroups?.length) {
      setAssignmentGroups([])

      return
    }

    setAssignmentGroups(sortAssetGroups(archivedGroups.archivedAssetGroups as AssetGroup[], !embeddedList))
  }, [archivedGroups?.archivedAssetGroups, embeddedList, ctx.isArchived])

  const handleReturnToInventory = (asset: AssetGroup) => {
    setAssetToReturn(asset)

    returnToInventoryProps.handleOpenModal()
  }

  const handleInventoryReport = (assignment: AssetGroup) => {
    setGroupToInventory(assignment)
    asset && pushDrawer(<InventoryReportDrawer asset={asset} />)
  }

  const handleAddAssetsToGroup = (assetGroup: AssetGroup) => {
    setAddToAsset(assetGroup)
    addAssetQuantityModalProps.handleOpenModal()
  }

  const handleArchive = (assetGroup: AssetGroup) => {
    setAssetToArchive(assetGroup)
    archiveAssetQuantityModalProps.handleOpenModal()
  }

  const handleUnarchive = (assetGroup: AssetGroup) => {
    setAssetToUnarchive(assetGroup)
    unarchiveAssetQuantityModalProps.handleOpenModal()
  }

  const asset = data?.asset

  if (!asset || (Array.isArray(assignmentGroups) && !assignmentGroups.length)) {
    return null
  }

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "name",
      hideHeader: true,
      flex: 1.2,
      minWidth: 150,
      renderCell: ({ row: assetGroup }: GridCellParams<AssetGroup>) => (
        <QuantizedAssetBadgeCell assetGroup={assetGroup} indentLevel={1} />
      ),
    },
    {
      field: "quantity",
      headerName: "Qty.",
      flex: 0.5,
      minWidth: 75,
      renderCell: ({ row: assetGroup }: GridCellParams<AssetGroup>) => (
        <QuantizedQuantityCell
          assetGroup={assetGroup}
          handleReturnToInventory={handleReturnToInventory}
          handleArchive={handleArchive}
          handleUnarchive={handleUnarchive}
          isArchived={ctx.isArchived}
        />
      ),
    },
    {
      field: "assignment",
      headerName: "Assignment",
      renderCell: ({ row: assetGroup }: GridCellParams<AssetGroup>) => (
        <QuantizedAssigneeCell assetGroup={assetGroup} />
      ),
    },
    {
      field: "project",
      headerName: "Project",
      renderCell: ({ row: assetGroup }: GridCellParams<AssetGroup>) => <QuantizedProjectCell assetGroup={assetGroup} />,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.55,
      renderCell: ({ row: assetGroup }: GridCellParams<AssetGroup>) => (
        <QuantizedAssetStatusPill assetGroup={assetGroup} isArchived={ctx.isArchived} />
      ),
    },
    {
      field: "menu",
      headerName: "",
      type: "actions",
      flex: 0.5,
      align: embeddedList ? "center" : "right",
      renderCell: ({ row: assetGroup }: GridCellParams<AssetGroup>) => (
        <QuantizedAssetQuickActionsCell
          assetGroup={assetGroup}
          handleInventoryReport={handleInventoryReport}
          handleReturnToInventory={handleReturnToInventory}
          openAddQuantityModal={handleAddAssetsToGroup}
          handleArchiveAsset={handleArchive}
          handleUnarchiveAsset={handleUnarchive}
          isArchived={ctx.isArchived}
          className="pr-2"
        />
      ),
    },
  ].map((c) => ({ ...defaultColumnProps, ...c } as GridColDef))

  return (
    <div className="w-full">
      {!embeddedList && (
        <>
          <div className="flex justify-between">
            <Typography variant="h5">Quantity Assignments</Typography>
            <Button variant="text" onClick={addAssetQuantityModalProps.handleOpenModal}>
              Add
            </Button>
          </div>
          {/* FILTERS */}
          <div className="flex">
            <CustomMenu
              open={filterMenuOpen}
              setOpen={setFilterMenuOpen}
              anchor={
                <IconButton className="rounded-full w-8 h-8 bg-gray-200">
                  <BiFilter />
                </IconButton>
              }
            >
              <MenuList className="flex flex-col px-2" sx={{ minWidth: 200 }}>
                <Typography className="font-medium px-3 mb-3">Filter Menu</Typography>
                <Divider className="mb-2" />
                <MenuItem onClick={() => ctx.setIsArchived(false)}>
                  <ListItemText>Active</ListItemText>
                  {!ctx.isArchived && <MenuItemSelected />}
                </MenuItem>
                <MenuItem onClick={() => ctx.setIsArchived(true)}>
                  <ListItemText>Archived</ListItemText>
                  {ctx.isArchived && <MenuItemSelected />}
                </MenuItem>
              </MenuList>
            </CustomMenu>
            {/* ACTIVE FILTERS */}
            {ctx.isArchived ? (
              <Chip
                className="text-gray-600"
                label="Archived"
                variant="filled"
                onDelete={() => ctx.setIsArchived(false)}
              />
            ) : (
              <Chip className="text-gray-600" label="Active" variant="filled" />
            )}

            {/* ADD NEW FILTER */}
            <IconButton className="w-8 h-8" onClick={() => setFilterMenuOpen(true)}>
              <BiPlus className="w-5 h-5" />
            </IconButton>
          </div>
        </>
      )}
      <DataGridPro
        autoHeight
        columns={columns}
        density="comfortable"
        disableRowSelectionOnClick
        getRowId={(row) => row.compositeKey}
        loading={fetching || loadingArchived}
        rows={assignmentGroups || []}
        slots={{ loadingOverlay: LinearProgress }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              name: embeddedList,
            },
          },
        }}
        hideFooter={embeddedList}
        sx={{
          border: "none",
          "& .MuiDataGrid-cell:focus": { outline: "none" },
          "& .MuiDataGrid-cell:focus-within": { outline: "none" },
          "& .MuiDataGrid-columnHeader:focus": { outline: "none" },
          "& .MuiDataGrid-columnHeader:focus-within": { outline: "none" },
          "& .MuiDataGrid-columnHeaders": { display: embeddedList ? "none" : "block" },
        }}
      />

      <AddQuantizedAssetsModal formModalProps={addAssetQuantityModalProps} asset={asset} selectedGroup={addToAsset} />
      <AssignmentQuantityReportModal
        asset={asset}
        assignment={groupToInventory}
        inventoryModalProps={inventoryModalProps}
      />
      <ReturnToInventoryModal modalProps={returnToInventoryProps} asset={assetToReturn} />
      <ArchiveQuantizedAssetModal modalProps={archiveAssetQuantityModalProps} asset={assetToArchive} />
      <UnarchiveQuantizedAssetModal modalProps={unarchiveAssetQuantityModalProps} asset={assetToUnarchive} />
    </div>
  )
}

const MenuItemSelected = () => (
  <ListItemIcon className="justify-end">
    <BiCheck className="text-xl text-blue-600" />
  </ListItemIcon>
)

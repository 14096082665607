import { FC } from "react"
import { BiDislike, BiLike } from "react-icons/bi"
import { AssetReportInput } from "../../../graphql/generated/client-types-and-hooks"
import { classNames } from "../../../helpers/classNames"

export const InventoryHistoryResult: FC<{ report: AssetReportInput }> = ({ report }) => {
  const thumbStyles = "text-white p-1 rounded h-6 w-6"
  const numStyles = "mx-1.5 text-md flex items-center"

  const missingCount = report.statusChange?.missingCount ?? 0
  const availableCount = report?.quantityReported ?? 0 - missingCount

  return (
    <div className="flex items-center justify-center">
      <>
        <BiLike className={classNames("bg-green-600", thumbStyles)} />
        {availableCount > 0 && <div className={classNames("min-w-[20px]", numStyles)}>{availableCount}</div>}{" "}
      </>
      {missingCount > 0 && (
        <>
          <BiDislike className={classNames(" bg-orange-600", thumbStyles)} />
          <div className={classNames(numStyles)}>{missingCount}</div>
        </>
      )}
    </div>
  )
}

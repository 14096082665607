import { Formik } from "formik"
import { FC, useContext, useState } from "react"
import { ModernBulkClockInCandidate } from "."
import { TimeEntryError, useBulkClockInMutation } from "../../../../graphql/generated/client-types-and-hooks"
import { canBeClockedIn } from "../../../../helpers/canBeClockedIn"
import { getCurrentLocation } from "../../../../helpers/getCurrentLocation"
import { pluralize } from "../../../../helpers/pluralize"
import { ModalLoadingContext } from "../../../Modals/StandardModal"
import { infoSnack } from "../../../Notistack/ThemedSnackbars"
import { BulkClockInFormContents } from "./BulkClockInFormContents"

type Props = {
  onCancel: () => void
  onSuccess: () => void
  taskId?: string
  userIds?: string[]
}

type FormValues = {
  startAt: Date
  candidates: ModernBulkClockInCandidate[]
}

export const BulkClockInModalContents: FC<Props> = ({ onCancel, onSuccess, taskId, userIds }) => {
  const { setIsLoading } = useContext(ModalLoadingContext)
  const initialValues: FormValues = { startAt: new Date(), candidates: [] }
  const [, bulkClockInMutation] = useBulkClockInMutation()
  const [successes, setSuccesses] = useState<number>(0)
  const [errors, setErrors] = useState<TimeEntryError[]>([])

  const handleBulkClockIn = async (startAt: Date, candidates: ModernBulkClockInCandidate[]) => {
    try {
      const location = await getCurrentLocation()

      bulkClockInMutation({
        startAt,
        candidates: candidates
          .filter((candidate) => canBeClockedIn(candidate.user.latestTimeEntry, startAt))
          .map((candidate) => ({
            startEvidence: { imagePath: candidate.imagePath, location },
            projectId: candidate.user.currentProjectId,
            taskId: candidate.user.currentTaskId,
            userId: candidate.user.id,
          })),
      }).then((result) => {
        const data = result.data?.bulkClockIn
        const doubleBookings = (data?.errors || []).filter((error) => error.reason === "ATTEMPTED_DOUBLE_BOOKING")

        if (doubleBookings.length) {
          setSuccesses(data?.success || 0)
          setErrors(doubleBookings)
        } else {
          const alreadyClockedInUsers = (data?.errors || []).filter((error) => error.reason === "ALREADY_CLOCKED_IN")
          const successes = data?.success || 0

          if (alreadyClockedInUsers.length) {
            infoSnack(
              `${alreadyClockedInUsers.length} ${pluralize("user", alreadyClockedInUsers.length)} ${pluralize(
                "is",
                alreadyClockedInUsers.length
              )} already clocked in`
            )
          }
          infoSnack(`Clocked in ${successes} ${pluralize("user", successes)}`)
          onSuccess()
        }
      })
    } catch (e) {
      console.error(e)
    }
  }

  const handleSubmit = (values: FormValues) => {
    if (values.candidates.length) {
      setIsLoading(true)
      handleBulkClockIn(values.startAt, values.candidates)
        .catch((e) => {
          console.error(e)
        })
        .then(() => {
          setIsLoading(false)
        })
    }
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <BulkClockInFormContents
        taskId={taskId}
        userIds={userIds}
        handleCancel={onCancel}
        successes={successes}
        errors={errors}
      />
    </Formik>
  )
}

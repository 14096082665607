import { Box, Button } from "@mui/material"
import { FC, ReactElement, useContext } from "react"
import {
  BiDotsHorizontalRounded,
  BiDownArrowAlt,
  BiEdit,
  BiListOl,
  BiListUl,
  BiMenuAltRight,
  BiPencil,
  BiPlus,
  BiTrash,
  BiUpArrowAlt,
} from "react-icons/bi"
import { useMutation } from "urql"
import { graphql } from "../../../../graphql/generated/gql"
import { getTaskListItemSortOrder } from "../../../../helpers/getTaskListItemSortOrder"
import { DrawerContext } from "../../../../providers/DrawerProvider"
import { PermissionsContext } from "../../../../providers/PermissionsProvider/PermissionsProvider"
import { TaskListDispatchContext, TaskListStateContext } from "../../../../providers/TaskListProvider/TaskListProvider"
import { TASK_LIST_ACTION_TYPES } from "../../../../providers/TaskListProvider/taskListReducer"
import { ProjectUnitReportForm } from "../../../ProjectUnitReportForm"
import { MenuItem, QuickMenuMui } from "../../../QuickMenuMui"
import { RenderIf } from "../../../RenderIf"
import { DrawerBody } from "../../Drawer/DrawerBody"
import { DrawerHeader } from "../../Drawer/DrawerHeader"
import { DrawerLink } from "../../Drawer/DrawerLink"
import { TaskDrawer } from "../../Drawer/TaskDrawer"
import { TaskEditDrawer } from "../../Drawer/TaskEditDrawer"
import { TaskGroupDrawer } from "../../Drawer/TaskGroupDrawer"
import { TaskGroupEditDrawer } from "../../Drawer/TaskGroupEditDrawer"
import { TaskReorderDrawer } from "../../Drawer/TaskReorderDrawer"
import { CreateOrEditTaskForm } from "../../Tasks/CreateOrEditTaskForm"

type Props = {
  taskId: string | undefined
  taskName: string | undefined
  assetCount?: number
  userCount?: number
  projectId: string
  isTaskComplete: boolean
  isTaskGroup: boolean
  isDefault: boolean
  sortOrder: number | undefined
  handleReport: (taskId: string | undefined) => void
  handleDelete: (taskId: string, taskName: string, isTaskGroup: boolean, assetCount: number, userCount: number) => void
  onSuccess?: () => void
  groupId?: string
}

type DrawerProps = {
  children: ReactElement
  isTaskGroup: boolean
  projectId: string
  taskId: string
  assetCount: number
  userCount: number
  handleClose: () => void
}

const TaskItemUpdateTaskSortOrderMutation = graphql(`
  mutation UpdateTaskSortOrder($type: String!, $taskId: String!, $sortOrder: Int!) {
    updateTaskSortOrder(type: $type, taskId: $taskId, sortOrder: $sortOrder) {
      id
      sortOrder
    }
  }
`)

export const TaskRowActions: FC<Props> = ({
  handleDelete,
  handleReport,
  isDefault,
  isTaskComplete,
  isTaskGroup = false,
  onSuccess = () => {},
  projectId,
  sortOrder,
  groupId,
  taskId,
  taskName,
  assetCount = 0,
  userCount = 0,
}) => {
  const { push: pushDrawer, clearAll } = useContext(DrawerContext)
  const dispatch = useContext(TaskListDispatchContext)

  const { taskList } = useContext(TaskListStateContext)

  const sameLevelTasks = (
    groupId ? taskList.filter((task) => task.groupId === groupId) : taskList.filter((task) => !task.groupId)
  )
    .map((task) => ({
      ...task,
      sortOrder: getTaskListItemSortOrder(task),
    }))
    .sort((taskA, taskB) => {
      return (taskA.sortOrder || 0) - (taskB.sortOrder || 0)
    })
  const subTasks = taskList.filter((task) => task.groupId === taskId)
  const hasSubTasks = subTasks.length > 0
  const task = taskList.find((task) => task.taskId === taskId)

  const incompleteSameLevelTasks = sameLevelTasks.filter((task) => !task?.isComplete)

  const taskIndex = incompleteSameLevelTasks.findIndex((task) => task.taskId === taskId || task.taskGroupId === taskId)
  const nextSortOrder = incompleteSameLevelTasks.at(taskIndex + 1)?.sortOrder || 0
  const previousSortOrder = incompleteSameLevelTasks.at(taskIndex - 1)?.sortOrder || 0
  const highestSortOrder = incompleteSameLevelTasks.length > 0 ? incompleteSameLevelTasks.at(-1)?.sortOrder || 0 : 0
  const isComplete = task?.isComplete || false

  const { hasPermissionTo } = useContext(PermissionsContext)

  const href = isTaskGroup
    ? `/projects/${projectId}/group/${taskId}/details`
    : `/projects/${projectId}/${taskId}/details`

  const [_, updateTaskSortOrderMutation] = useMutation(TaskItemUpdateTaskSortOrderMutation)

  const handleChangeSortOrder = async (type: "task" | "taskGroup", taskId: string, sortOrder: number) => {
    updateTaskSortOrderMutation({ type, taskId, sortOrder }).then(() => {
      dispatch({ type: TASK_LIST_ACTION_TYPES.refreshTaskList })
    })
  }

  const handleClose = () => {
    clearAll()
    dispatch({ type: TASK_LIST_ACTION_TYPES.refreshTaskList })
  }

  const DrawerComponent = () => {
    if (isTaskGroup) {
      return (
        <div className="p-8">
          <TaskGroupDrawer taskGroupId={taskId!} projectId={projectId} onSuccess={onSuccess} />
        </div>
      )
    }

    return <TaskDrawer taskId={taskId!} refetch={onSuccess} />
  }

  const CreateNewSubtask = () => (
    <RenderIf permissionsInclude="task:create">
      <div>
        <DrawerHeader handleClose={handleClose} href={`projects/${projectId}/tasks/create`} />
        <DrawerBody>
          <CreateOrEditTaskForm
            task={{ projectId }}
            onCancel={handleClose}
            onSuccess={handleClose}
            group={{ id: taskId || "", name: taskName || "" }}
          />
        </DrawerBody>
      </div>
    </RenderIf>
  )

  const menuItems: MenuItem[][] = [
    [
      {
        value: "View Task",
        Icon: BiListUl,
        drawer: {
          href,
          component: <DrawerComponent />,
        },
      },
    ],
  ]

  if (!groupId && isTaskGroup) {
    const item = {
      value: "Add Sub-task",
      Icon: BiPlus,
      drawer: {
        href,
        component: <CreateNewSubtask />,
      },
    }

    menuItems[0].push(item)
  }

  if (!isComplete) {
    // don't show up/down if no sort order is set
    if (!isDefault && typeof sortOrder !== "undefined") {
      // if this is the first item in the list then don't show the up arrow
      if (sortOrder > 0) {
        menuItems[0].push({
          value: "Move Up",
          Icon: BiUpArrowAlt,
          onClick: () => handleChangeSortOrder(isTaskGroup ? "taskGroup" : "task", taskId!, previousSortOrder),
        })
      }

      // if this is the last item in the list then don't show the down arrow
      if (highestSortOrder > sortOrder) {
        menuItems[0].push({
          value: "Move Down",
          Icon: BiDownArrowAlt,
          onClick: () => handleChangeSortOrder(isTaskGroup ? "taskGroup" : "task", taskId!, nextSortOrder),
        })
      }
    }

    // add reorder option if this is a top level task
    if (!groupId) {
      menuItems[0].push({
        value: "Reorder",
        Icon: BiListOl,
        drawer: {
          href,
          component: <TaskReorderDrawer projectId={projectId} />,
        },
      })
    }

    // add reorder sub-tasks option if this is a grouped task or has sub-tasks
    if (!hasSubTasks || groupId) {
      menuItems[0].push({
        value: "Reorder sub-tasks",
        Icon: BiMenuAltRight,
        drawer: {
          href,
          component: <TaskReorderDrawer projectId={projectId} taskGroupId={groupId} />,
        },
      })
    }

    if (!isDefault && hasPermissionTo("task:update") && !isTaskGroup && taskId) {
      menuItems[0].push({
        value: "Edit",
        onClick: () => pushDrawer(<TaskEditDrawer taskId={taskId} />),
        Icon: BiEdit,
      })
    }

    if (!isDefault && hasPermissionTo("task:update") && isTaskGroup && taskId) {
      menuItems[0].push({
        value: "Edit",
        onClick: () => pushDrawer(<TaskGroupEditDrawer taskGroupId={taskId} />),
        Icon: BiEdit,
      })
    }
  }

  // can't delete the default task
  if (!isDefault) {
    menuItems.push([
      {
        value: "Delete",
        onClick: () => handleDelete(taskId!, taskName!, isTaskGroup, assetCount, userCount),
        Icon: BiTrash,
        color: "red",
        isDisabled: isTaskComplete,
      },
    ])
  }

  return (
    <Box display="flex" flexWrap="nowrap" alignItems="center">
      <ConditionalDrawerLink
        isTaskGroup={isTaskGroup}
        projectId={projectId}
        taskId={taskId!}
        handleClose={() => clearAll()}
        assetCount={assetCount}
        userCount={userCount}
      >
        <Button
          disabled={!hasPermissionTo("task:report", { projectId: projectId }) || isTaskComplete || isDefault}
          variant="contained"
          color="secondary"
          endIcon={<BiPencil size="18px" />}
          sx={{ fontSize: "14px", height: "32px", marginRight: 1 }}
          onClick={() => !isTaskGroup && handleReport(taskId)}
        >
          Report
        </Button>
      </ConditionalDrawerLink>

      <Box>
        <QuickMenuMui items={menuItems} disabled={!hasPermissionTo("task:update")}>
          <BiDotsHorizontalRounded className="w-6 h-6" />
        </QuickMenuMui>
      </Box>
    </Box>
  )
}

const ConditionalDrawerLink: FC<DrawerProps> = ({ children, isTaskGroup, projectId, taskId, handleClose }) => {
  return isTaskGroup ? (
    <DrawerLink component={<ProjectUnitReportForm projectId={projectId} taskId={taskId} handleClose={handleClose} />}>
      {children}
    </DrawerLink>
  ) : (
    children
  )
}

import { Skeleton } from "@mui/material"
import { FC, useMemo } from "react"
import { useQuery } from "urql"
import { TimeEntryActivityDocument } from "../../../graphql/generated/client-types-and-hooks"
import { getHoursAndMinutesFromSeconds } from "../../../helpers/getHoursAndMinutesFromSeconds"
import { TeamMemberDetailTimeEntryRow, TeamSummaryQueryProps } from "./TeamTableDropDown"

enum Days {
  Sun = "Sun",
  Mon = "Mon",
  Tue = "Tue",
  Wed = "Wed",
  Thu = "Thu",
  Fri = "Fri",
  Sat = "Sat",
}

export enum Mode {
  Weekly,
  Monthly,
}

interface TimeCellProps extends TeamSummaryQueryProps {
  mode: Mode
  index?: number
  day?: Days
}

export const daysOfWeek: Days[] = [Days.Sun, Days.Mon, Days.Tue, Days.Wed, Days.Thu, Days.Fri, Days.Sat]

export const TimeCell: FC<TimeCellProps> = ({ mode, index, day, userId, rangeStart, rangeEnd, projectId }) => {
  const [{ data }] = useQuery({
    query: TimeEntryActivityDocument,
    variables: { rangeStart, rangeEnd, projectId, userId },
  })

  const timeEntries = useMemo(() => data?.user?.timeEntries || [], [data])

  const aggregateTime = useMemo(() => {
    return mode === Mode.Weekly
      ? aggregateWeeklyTime(timeEntries, rangeStart, rangeEnd)
      : aggregateMonthlyTime(timeEntries, rangeStart, rangeEnd)
  }, [timeEntries, rangeStart, rangeEnd, mode])

  let hours: number = 0
  let minutes: string = "00"

  if (mode === Mode.Weekly && day !== undefined) {
    const weeklyTime = aggregateTime as Record<string, number>
    const time = weeklyTime[day]
    ;({ hours, minutes } = getHoursAndMinutesFromSeconds(time))
  } else if (mode === Mode.Monthly && index !== undefined) {
    const monthlyTime = aggregateTime as number[]
    const time = monthlyTime[index]
    ;({ hours, minutes } = getHoursAndMinutesFromSeconds(time))
  }

  return (
    <>
      {data ? (
        isNaN(hours) ? (
          <Skeleton className="w-10 h-10" />
        ) : (
          <span>{`${hours}:${minutes}`}</span>
        )
      ) : (
        <Skeleton className="w-10 h-10" />
      )}
    </>
  )
}

const aggregateWeeklyTime = (timeEntries: TeamMemberDetailTimeEntryRow[], rangeStart: Date, rangeEnd: Date) => {
  const aggregatedTimes: Record<string, number> = daysOfWeek.reduce((acc, day) => ({ ...acc, [day]: 0 }), {})

  timeEntries.forEach((entry) => {
    const entryStart = new Date(entry.startAt)
    if (entryStart >= new Date(rangeStart) && entryStart <= new Date(rangeEnd)) {
      const day = daysOfWeek[entryStart.getDay()]
      aggregatedTimes[day] += entry.durationInSeconds
    }
  })

  return aggregatedTimes
}

const aggregateMonthlyTime = (timeEntries: TeamMemberDetailTimeEntryRow[], rangeStart: Date, rangeEnd: Date) => {
  const numberOfWeeks = countWeeksInMonth(rangeStart, rangeEnd)
  const weeksAggregatedTimes = Array(numberOfWeeks).fill(0)

  for (let i = 0; i < numberOfWeeks; i++) {
    const [weekStart, weekEnd] = getWeekStartAndEnd(i, rangeStart)
    weeksAggregatedTimes[i] = timeEntries.reduce((acc, entry) => {
      const entryStart = new Date(entry.startAt)
      return entryStart >= weekStart && entryStart <= weekEnd ? acc + entry.durationInSeconds : acc
    }, 0)
  }

  return weeksAggregatedTimes
}

const getWeekStartAndEnd = (weekIndex: number, rangeStart: Date) => {
  const weekStart = new Date(rangeStart)
  weekStart.setDate(rangeStart.getDate() - rangeStart.getDay() + weekIndex * 7)
  const weekEnd = new Date(weekStart)
  weekEnd.setDate(weekStart.getDate() + 6)
  return [weekStart, weekEnd]
}

export function countWeeksInMonth(rangeStart: Date, rangeEnd: Date) {
  rangeStart.setHours(0, 0, 0, 0)
  rangeEnd.setHours(0, 0, 0, 0)

  const totalDays = (rangeEnd.getTime() - rangeStart.getTime()) / (1000 * 60 * 60 * 24) + 1 // +1 to include the last day

  // Find out the day of the week for the first day of the month
  const firstDayOfWeek = rangeStart.getDay()
  const daysInFirstWeek = 7 - firstDayOfWeek
  const isFirstWeekValid = firstDayOfWeek < 6 // 0 is Sunday and 6 is Saturday
  const remainingDays = totalDays - daysInFirstWeek
  const fullWeeks = Math.floor(remainingDays / 7)

  const daysInLastWeek = remainingDays % 7
  const isLastWeekValid = daysInLastWeek > 0 && rangeEnd.getDay() > 0 && rangeEnd.getDay() < 6

  // If there are any remaining days after calculating the full weeks, consider them as an additional week if they include a weekday
  const additionalWeek = isLastWeekValid ? 1 : 0

  const numberOfWeeks = (isFirstWeekValid ? 1 : 0) + fullWeeks + additionalWeek

  return numberOfWeeks
}

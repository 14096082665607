import { Form, Formik, FormikValues } from "formik"
import { FC } from "react"
import { useQuery } from "urql"
import * as Yup from "yup"
import {
  DeliverableUnit,
  useCreateUnitGoalMutation,
  useEditUnitGoalMutation,
} from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { UnitGoal } from "../../../graphql/generated/gql/graphql"
import { useHandleError } from "../../../hooks/useHandleError"
import { PickPlus } from "../../../types/helpers"
import { ButtonFilled } from "../../Elements/ButtonFilled"
import { ButtonHollow } from "../../Elements/ButtonHollow"
import { ModalBody } from "../../Modals/ModalBody"
import { ModalFooter } from "../../Modals/ModalFooter"
import { errorSnack, successSnack } from "../../Notistack/ThemedSnackbars"
import { UnitInput } from "../../UnitInput"
import { TargetProductionRate } from "./TargetProductionRate"

type UnitGoalExpectation = PickPlus<UnitGoal, "id" | "isPrimary" | "targetQuantity" | "deliverableUnitId"> & {
  deliverableUnit: PickPlus<DeliverableUnit, "id" | "description" | "referenceNumber" | "unitOfMeasure">
}

const AddOrEditGoalModalQueryDocument = graphql(`
  query AddOrEditGoalModal {
    deliverableUnits {
      id
      description
      unitOfMeasure
    }
  }
`)

type Props = {
  closeModal: () => void
  onSuccess?: () => void
  unitGoal?: UnitGoalExpectation
  isPrimary?: boolean
  taskId: string
  taskEstimatedHours: number | undefined
}

export const AddOrEditGoalModal: FC<Props> = ({
  closeModal,
  unitGoal,
  taskId,
  taskEstimatedHours,
  isPrimary = true,
  onSuccess,
}) => {
  const [{ data: deliverableUnitsData, error: unitsError }] = useQuery({ query: AddOrEditGoalModalQueryDocument })
  const [, addMutation] = useCreateUnitGoalMutation()
  const [, editMutation] = useEditUnitGoalMutation()

  useHandleError(unitsError, "Error loading organization deliverable units")

  const handleSubmit = (values: FormikValues) => {
    const deliverableUnitId = values.deliverableUnitId
    if (unitGoal?.id) {
      editMutation({
        id: unitGoal.id,
        goal: {
          deliverableUnitId,
          targetQuantity: parseFloat(values.unitGoalTarget),
          isPrimary,
        },
      }).then((result) => {
        if (result.error) {
          errorSnack("Failed to edit goal")
        } else {
          onSuccess?.()
          successSnack("Unit edited successfully.")
          closeModal()
        }
      })
    } else {
      addMutation({
        taskId,
        goal: { deliverableUnitId, isPrimary, targetQuantity: parseFloat(values.unitGoalTarget) },
      }).then((result) => {
        if (result.error) {
          errorSnack("Failed to create goal")
        } else {
          onSuccess?.()
          successSnack("Unit saved successfully.")
          closeModal()
        }
      })
    }
  }

  return (
    <Formik
      initialValues={{
        deliverableUnitId: [unitGoal?.deliverableUnit.id || ""],
        unitGoalTarget: unitGoal?.targetQuantity || "",
      }}
      validationSchema={Yup.object().shape({
        deliverableUnitId: Yup.string().nullable().required("Required"),
        unitGoalTarget: isPrimary ? Yup.number().required("Required") : Yup.number(),
      })}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <Form className={"h-full flex flex-col"}>
          <ModalBody>
            <UnitInput deliverableUnitId={unitGoal?.deliverableUnit.id || ""} isCreatable />

            <div className="py-5">
              <TargetProductionRate
                estimatedHours={taskEstimatedHours}
                units={deliverableUnitsData?.deliverableUnits || []}
                deliverableUnitId={
                  Array.isArray(values.deliverableUnitId) ? values.deliverableUnitId[0] : values.deliverableUnitId || ""
                }
                unitGoalTarget={+values.unitGoalTarget}
              />
            </div>

            {!!taskEstimatedHours && <p className="text-gray-400 pb-3">Estimated man-hours: {taskEstimatedHours}</p>}

            {unitGoal && (
              <p className="text-gray-400 ">
                If you update the selected unit, all previous reporting on this Goal will be updated to reflect the
                change.
              </p>
            )}
            <div className="mb-20" />
          </ModalBody>
          <ModalFooter>
            <ButtonHollow onClick={() => closeModal()} type="button">
              Cancel
            </ButtonHollow>
            <ButtonFilled type="submit">Save unit</ButtonFilled>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  )
}

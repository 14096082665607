import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { FC, useContext, useState } from "react"
import { BiDotsHorizontalRounded, BiPencil, BiTargetLock, BiTrash } from "react-icons/bi"
import { DeliverableUnit, Task, UnitGoal, UnitGoalProgress } from "../../../graphql/generated/client-types-and-hooks"
import { calculateProductionRate } from "../../../helpers/calculateProductionRate"
import { colors } from "../../../helpers/colors"
import { useModalProps } from "../../../hooks/useModalProps"
import { PermissionsContext } from "../../../providers/PermissionsProvider/PermissionsProvider"
import { PickPlus } from "../../../types/helpers"
import StandardModal from "../../Modals/StandardModal"
import { QuickMenu } from "../../QuickMenu"
import { MenuItem } from "../../QuickMenuMui"
import { RenderIf } from "../../RenderIf"
import { UnitReportModal } from "../../UnitReportModal"
import { AddOrEditGoalModal } from "../Tasks/AddOrEditGoalModal"
import { DeleteGoalModal } from "../Tasks/DeleteGoalModal"

type UnitGoalExpectation = PickPlus<UnitGoal, "id" | "isPrimary" | "targetQuantity" | "deliverableUnitId"> & {
  deliverableUnit: PickPlus<DeliverableUnit, "id" | "description" | "referenceNumber" | "unitOfMeasure">
} & {
  unitGoalProgress: PickPlus<UnitGoalProgress, "id">[]
}

type TaskExpectation = PickPlus<Task, "id" | "isComplete" | "isDefault"> & {
  unitGoals?: UnitGoalExpectation[]
}

export type DeliverableUnitSectionProps = {
  task: TaskExpectation
  title: string
  isPrimary?: boolean
  onSuccess?: () => void
  taskEstimatedHours: number
}

export const DeliverableUnitSection: FC<DeliverableUnitSectionProps> = ({
  task,
  title,
  isPrimary = true,
  onSuccess,
  taskEstimatedHours,
}) => {
  const addUnitGoalModalProps = useModalProps("Add Unit Goal")
  const editUnitGoalModalProps = useModalProps("Edit Unit Goal")
  const deleteUnitGoalModalProps = useModalProps("Delete Task Goal")

  const [reportModalOpen, setReportModalOpen] = useState(false)

  const allUnitGoals = task?.unitGoals || []
  const unitGoals = allUnitGoals.filter((unitGoal) => unitGoal.isPrimary === isPrimary)

  const { hasPermissionTo } = useContext(PermissionsContext)

  const [selectedUnitGoal, setSelectedUnitGoal] = useState<UnitGoalExpectation>()

  const showUnitReportButton = task ? task.unitGoals?.length !== 0 && !task.isDefault : true

  const rowLevelMenuItems = (unitGoal: UnitGoalExpectation) => {
    const hasBeenReportedOn = !!unitGoal.unitGoalProgress.length
    const items = [
      [
        {
          requiredPermission: "task:create",
          value: "Edit goal",
          onClick: () => {
            setSelectedUnitGoal(unitGoal)
            editUnitGoalModalProps.handleOpenModal()
          },
          Icon: BiPencil,
          isDisabled: !!task?.isComplete,
        },
        {
          requiredPermission: "task:update",
          value: "Delete goal",
          isDisabled: hasBeenReportedOn || !!task?.isComplete,
          onClick: () => {
            setSelectedUnitGoal(unitGoal)
            deleteUnitGoalModalProps.handleOpenModal()
          },
          Icon: BiTrash,
          color: "red",
        },
      ],
    ] as MenuItem[][]

    return items
  }

  const headerStyles = { border: 0 }
  const rowStyles = { width: 180, borderBottom: 0, borderTop: 1, borderColor: colors.gray[200] }

  return (
    <div className="mt-8">
      <div className="flex justify-between items-center">
        <Typography fontSize={20} fontWeight={700}>
          {title}
        </Typography>
        <div>
          {showUnitReportButton && hasPermissionTo("task:update") && (
            <Button
              variant="text"
              disabled={!!task?.isComplete}
              onClick={() => {
                setSelectedUnitGoal(undefined)
                setReportModalOpen(true)
              }}
            >
              Report
            </Button>
          )}
          <RenderIf permissionsInclude="task:create">
            <Button
              variant="text"
              disabled={!!task?.isComplete}
              onClick={() => {
                setSelectedUnitGoal(undefined)
                addUnitGoalModalProps.handleOpenModal()
              }}
            >
              Add
            </Button>
          </RenderIf>
        </div>
      </div>

      <TableContainer sx={{ border: 1, borderRadius: 2, borderColor: colors.gray[200], marginTop: 1 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={headerStyles}>Unit Type</TableCell>
              <TableCell sx={headerStyles}>{isPrimary ? "Target Quantity" : "Estimated Quantity"}</TableCell>
              <TableCell sx={headerStyles}>Unit of Measure</TableCell>
              <TableCell sx={headerStyles}>{isPrimary ? "Production Rate" : ""}</TableCell>
              <TableCell sx={headerStyles}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {unitGoals.map((unitGoal) => {
              const myMenuItems = rowLevelMenuItems(unitGoal)
              return (
                <TableRow key={unitGoal.deliverableUnitId}>
                  <TableCell sx={rowStyles}>{unitGoal.deliverableUnit.description}</TableCell>
                  <TableCell sx={rowStyles}>{unitGoal.targetQuantity}</TableCell>
                  <TableCell sx={rowStyles}>{unitGoal.deliverableUnit.unitOfMeasure}</TableCell>
                  <TableCell sx={rowStyles}>
                    {isPrimary && !!unitGoal.targetQuantity && (
                      <div className="flex items-center text-blue-600 gap-1">
                        <BiTargetLock fontSize={16} />
                        {calculateProductionRate(unitGoals, taskEstimatedHours)} per man-hour
                      </div>
                    )}
                  </TableCell>
                  <TableCell sx={rowStyles}>
                    <RenderIf permissionsInclude={["task:create", "task:update"]}>
                      <QuickMenu className="hidden md:block col-span-1" items={myMenuItems}>
                        <BiDotsHorizontalRounded className="text-2xl min-w-max" />
                      </QuickMenu>
                    </RenderIf>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <UnitReportModal isOpen={reportModalOpen} closeModal={() => setReportModalOpen(false)} taskId={task.id} />
      <StandardModal {...addUnitGoalModalProps}>
        <AddOrEditGoalModal
          closeModal={addUnitGoalModalProps.handleCloseModal}
          unitGoal={selectedUnitGoal}
          isPrimary={isPrimary}
          taskId={task.id}
          taskEstimatedHours={taskEstimatedHours}
          onSuccess={onSuccess}
        />
      </StandardModal>
      <StandardModal {...editUnitGoalModalProps}>
        <AddOrEditGoalModal
          closeModal={editUnitGoalModalProps.handleCloseModal}
          unitGoal={selectedUnitGoal}
          isPrimary={isPrimary}
          taskId={task.id}
          taskEstimatedHours={taskEstimatedHours}
          onSuccess={onSuccess}
        />
      </StandardModal>
      <StandardModal {...deleteUnitGoalModalProps}>
        <DeleteGoalModal
          closeModal={deleteUnitGoalModalProps.handleCloseModal}
          goal={selectedUnitGoal!}
          taskId={task.id}
          onSuccess={onSuccess}
        />
      </StandardModal>
    </div>
  )
}

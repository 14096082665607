import { FC } from "react"
import { AssetReport } from "../../../graphql/generated/client-types-and-hooks"
import { Available, getAssetStatusLabel } from "../../../helpers/assetStatus"
import { PickPlus } from "../../../types/helpers"
import Pill from "../../Pill"

export const AssetReportStatusPill: FC<{
  report: Required<PickPlus<AssetReport, "statusChange" | "type">>
}> = ({ report }) => {
  const { status, active } = report.statusChange || { active: true, status: Available }

  return (
    <>
      <Pill color={active ? "blue" : "orange"}>{getAssetStatusLabel(status)}</Pill>
    </>
  )
}

import { FC } from "react"
import { TimeEntry, User } from "../../../graphql/generated/client-types-and-hooks"
import { GeolocationProvider } from "../../../providers/GeolocationProvider"
import { PickPlus } from "../../../types/helpers"
import { ClockInWithEvidence } from "./ClockInWithEvidence"
import { ClockOutWithEvidence } from "./ClockOutWithEvidence"

type Props = {
  user: PickPlus<User, "id" | "firstName" | "lastName" | "isClockedIn" | "projectId" | "taskId"> & {
    latestTimeEntry?: PickPlus<TimeEntry, "id" | "startAt" | "endAt"> | null
  }
  onSuccess?: () => void
  className?: string
  disabled?: boolean
}

export const ClockInOutWithEvidence: FC<Props> = ({ user, onSuccess, className, disabled = false }) => {
  return (
    <GeolocationProvider>
      {user.isClockedIn && (
        <ClockOutWithEvidence user={user} onSuccess={onSuccess} className={className} disabled={disabled} />
      )}
      {!user.isClockedIn && (
        <ClockInWithEvidence user={user} onSuccess={onSuccess} className={className} disabled={disabled} />
      )}
    </GeolocationProvider>
  )
}

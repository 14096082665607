import { FC } from "react"
import { useQuery } from "urql"
import { graphql } from "../../../graphql/generated/gql"
import { classNames } from "../../../helpers/classNames"
import { useHandleError } from "../../../hooks/useHandleError"
import { LoadingIndicator } from "../../Loading/LoadingIndicator"
import { HoursClockedWidget } from "./HoursClockedWidget"
import { TaskProductionRateWidget } from "./ProductionRateWidget"
import { UnitsDeliveredWidget } from "./UnitsDeliveredWidget"
import { UserCountWidget } from "./UserCountWidget"

type Props = {
  taskId: string
  rangeStart: Date
  rangeEnd: Date
  className?: string
}
const TaskWidgetGroupDocument = graphql(`
  query SummaryWidgetsTaskWidgetGroup($taskId: String!, $rangeEnd: DateTime!, $rangeStart: DateTime!) {
    task(id: $taskId) {
      id
      projectId
      name
      estimatedHours
      completedHours
      previousUnits: sumOfDeliveredUnits(rangeEnd: $rangeStart)
      previousClockedSeconds: timeEntriesSumDurationInSeconds(rangeEnd: $rangeStart)
      totalUnitsInRange: sumOfDeliveredUnits(rangeEnd: $rangeEnd, rangeStart: $rangeStart)
      sumOfDeliveredUnits
      totalClockedSeconds: timeEntriesSumDurationInSeconds(rangeEnd: $rangeEnd, rangeStart: $rangeStart)
      usersWhoClockedOrReportedCount(rangeEnd: $rangeEnd, rangeStart: $rangeStart)
      unitGoals {
        id
        targetQuantity
      }
    }
  }
`)

export const TaskOverviewWidgets: FC<Props> = ({ taskId, rangeStart, rangeEnd, className }) => {
  const [{ data, error }] = useQuery({
    query: TaskWidgetGroupDocument,
    variables: { taskId, rangeStart, rangeEnd },
  })
  useHandleError(error, "An error occurred while loading page data. Please reload the page to try again.")

  if (!data)
    return (
      <div className="h-44 flex items-center">
        <LoadingIndicator />
      </div>
    )

  return (
    <div
      className={classNames(
        "overflow-x-auto -mx-4 px-4 md:mx-0 md:px-0 flex flex-nowrap md:grid md:grid-cols-4 gap-3",
        className
      )}
    >
      <HoursClockedWidget
        estimatedHours={data?.task?.estimatedHours || 0}
        completedHours={data?.task?.completedHours}
        currentSeconds={data?.task.totalClockedSeconds}
        isLoading={!data}
        previousSeconds={data?.task.previousClockedSeconds}
      />
      <UserCountWidget
        userCount={data?.task.usersWhoClockedOrReportedCount}
        id={data?.task.id}
        projectId={data?.task.projectId}
      />
      <UnitsDeliveredWidget
        unitGoals={data?.task?.unitGoals}
        totalUnits={data?.task?.sumOfDeliveredUnits}
        previousUnits={data.task.previousUnits}
        currentUnits={data.task.totalUnitsInRange}
        isLoading={!data}
      />
      <TaskProductionRateWidget
        task={data?.task}
        totalUnits={data?.task?.totalUnitsInRange}
        totalClockedSeconds={data?.task?.totalClockedSeconds}
      />
    </div>
  )
}

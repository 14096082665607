import { Menu, Transition } from "@headlessui/react"
import { differenceInSeconds } from "date-fns"
import Link from "next/link"
import { FC, useCallback, useMemo, useState } from "react"
import { TimeEntry } from "../../../graphql/generated/client-types-and-hooks"
import { classNames } from "../../../helpers/classNames"
import { getSecondsFromRange } from "../../../helpers/getSecondsFromRange"
import { useWindowSize } from "../../../hooks/useWindowSize"
import { PickPlus } from "../../../types/helpers"

type Props = {
  timeEntries: (PickPlus<TimeEntry, "id" | "endAt" | "startAt" | "durationInSeconds" | "projectId" | "taskId"> & {
    project: PickPlus<TimeEntry["project"], "name">
    task: PickPlus<TimeEntry["task"], "name">
    isBreak?: TimeEntry["isBreak"]
  })[]
  minDate: Date
  maxDate: Date
  selectedTimeEntryIds?: string[]
  withClickBehavior?: boolean
  className?: string
}

type SectionProps = {
  id: string
  left: number
  width: number
  onClick: () => void
  isSelected?: boolean
  isBreak?: boolean
}

export const TimeEntryActivityBar: FC<Props> = ({
  selectedTimeEntryIds = [],
  timeEntries,
  minDate,
  maxDate,
  className,
  withClickBehavior = false,
}) => {
  const windowSize = useWindowSize()
  const [containerWidth, setContainerWidth] = useState(0)
  const [link, setLink] = useState("")
  const [LinkBody, setLinkBody] = useState(() => <></>)

  const ref = useCallback(
    (node: HTMLDivElement) => {
      if (node) {
        setContainerWidth(node.offsetWidth)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [windowSize]
  )

  const sections: SectionProps[] = useMemo(() => {
    const MIN_SECTION_WIDTH = 10
    const MAX_SECTION_COUNT = Math.floor(containerWidth / MIN_SECTION_WIDTH)

    // If there are no time entries, bail early.
    if (!timeEntries.length) return []

    // If there is not enough room to render all the time entries at their smallest, handle that case.
    if (timeEntries.length > MAX_SECTION_COUNT) {
      // TODO: Handle the case where there are too many time entries to render in the available space.
      return []
    }

    const ACTIVITY_BAR_TOTAL_SECONDS = getSecondsFromRange({ startAt: minDate, endAt: maxDate })

    const sections: SectionProps[] = []

    // Build a naive first pass. Don't make assumptions about container fit.
    for (const timeEntry of timeEntries) {
      const previousSection = sections.at(-1)
      const section: SectionProps = {
        id: timeEntry.id,
        left: calculateLeft(timeEntry, previousSection),
        width: calculateWidth(timeEntry),
        onClick: () => {
          setLink(`/projects/${timeEntry.projectId}/${timeEntry.taskId}`)
          setLinkBody(
            <>
              <span className={"text-gray-400"}>{timeEntry.project.name} / </span>
              {timeEntry.task.name}
            </>
          )
        },
        isSelected: selectedTimeEntryIds.some((id) => timeEntry.id === id),
        isBreak: timeEntry.isBreak,
      }

      sections.push(section)
    }

    const lastSection = sections.at(-1) as SectionProps
    const scaleFactor =
      lastSection.left + lastSection.width > containerWidth
        ? containerWidth / (lastSection.left + lastSection.width)
        : 1

    if (scaleFactor > 1) return sections

    // If the time entries extend beyond the boundaries of the visualization, scale everything to that it fits.
    return sections.map((section) => ({
      ...section,
      left: section.left * scaleFactor,
      width: section.width * scaleFactor,
    }))

    function calculateLeft(timeEntry: PickPlus<TimeEntry, "startAt">, previousSection?: SectionProps) {
      const proposedLeft = Math.round(
        (differenceInSeconds(timeEntry.startAt, minDate) * containerWidth) / ACTIVITY_BAR_TOTAL_SECONDS
      )

      // If the section overlaps the previous section to the left, adjust it.
      if (previousSection && proposedLeft < previousSection.left + previousSection.width) {
        return previousSection.left + previousSection.width
      }

      return proposedLeft
    }

    function calculateWidth(timeEntry: PickPlus<TimeEntry, "durationInSeconds">) {
      const proposedSectionWidth = Math.round(
        (timeEntry.durationInSeconds * containerWidth) / ACTIVITY_BAR_TOTAL_SECONDS
      )

      return proposedSectionWidth > MIN_SECTION_WIDTH ? proposedSectionWidth : MIN_SECTION_WIDTH
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minDate, maxDate, timeEntries, containerWidth, setLink, setLinkBody])

  return (
    <Menu as={"div"} className={"w-full relative"}>
      <div className={classNames("flex items-center relative bg-gray-200 h-3 clipped-rounded", className)} ref={ref}>
        {sections.map((sectionProps) => (
          <Section key={sectionProps.id} {...sectionProps} />
        ))}
      </div>
      {withClickBehavior && (
        <Transition
          as="div"
          className="absolute z-10 top-4 w-96 truncate"
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={"rounded-md shadow-lg bg-white border border-gray-200 focus:outline-none p-3 truncate"}
          >
            <Menu.Item as={"div"} className={"px-3 py-2 rounded hover:bg-gray-50 truncate"}>
              <Link href={link} className={"truncate"}>
                {LinkBody}
              </Link>
            </Menu.Item>
          </Menu.Items>
        </Transition>
      )}
    </Menu>
  )
}

const Section: FC<SectionProps> = ({ width, left, onClick, isSelected, isBreak }) => {
  return (
    <Menu.Button
      as={"div"}
      className={classNames(
        "absolute h-3 outline outline-white outline-2 transition-all",
        isSelected ? "bg-blue-600 hover:bg-blue-700" : "bg-[#9FB5EF] hover:bg-[#547FED]",
        isBreak ? "bg-violet-600 hover:bg-violet-700" : "bg-[#9FB5EF] hover:bg-[#547FED]"
      )}
      style={{ width: `${width}px`, left: `${left}px` }}
      onClick={onClick}
    />
  )
}

import { Box, Step, StepLabel, Stepper } from "@mui/material"
import { FC, useState } from "react"
import {
  ProjectTasksListQuery,
  Task,
  useMarkProjectAsCompleteMutation,
  useMarkTaskAsCompleteMutation,
} from "../../../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../../../types/helpers"
import { H2 } from "../../Elements"
import { errorSnack, successSnack } from "../../Notistack/ThemedSnackbars"
import { RenderIf } from "../../RenderIf"
import { CompleteTaskReassignAssets } from "./CompleteTaskForms/ReassignAssets"
import { CompleteTaskReassignUsers } from "./CompleteTaskForms/ReassignUsers"

export type Props = {
  onCancel?: () => void
  onError?: () => void
  onSuccess?: () => void
  project: ProjectTasksListQuery["project"]
  task: PickPlus<Task, "id" | "name">
}

export const enum Stage {
  ReassignUsers,
  ReassignAssets,
}

export const CompleteTaskWorkflow: FC<Props> = ({ onCancel, onError, onSuccess, project, task }) => {
  const { id, name } = task
  const [stage, setStage] = useState<Stage>(Stage.ReassignUsers)
  const [completed, setCompleted] = useState<{
    [k in Stage]: boolean
  }>({
    [Stage.ReassignUsers]: false,
    [Stage.ReassignAssets]: false,
  })

  const projectIsEligibleForCompletion = id === project?.unassignedTaskId

  const [{}, markTaskCompleteMutation] = useMarkTaskAsCompleteMutation()
  const [{}, markProjectAsCompleteMutation] = useMarkProjectAsCompleteMutation()

  return (
    <RenderIf permissionsInclude="task:complete" fallbackComponent={<Box>No permission!</Box>}>
      <div className={"flex flex-row flex-wrap"}>
        <H2 className={"mr-4 truncate w-full"}>
          Complete task <span className={"text-4xl mt-2 text-gray-400"}>{name}</span>
        </H2>
        <Box className="w-full pt-2">
          <Stepper activeStep={stage} alternativeLabel>
            <Step key={Stage.ReassignUsers} completed={completed[Stage.ReassignUsers]}>
              <StepLabel>Team</StepLabel>
            </Step>
            <Step key={Stage.ReassignAssets} completed={completed[Stage.ReassignAssets]}>
              <StepLabel>Assets</StepLabel>
            </Step>
          </Stepper>
        </Box>
      </div>
      {stage === Stage.ReassignUsers && (
        <div className="h-[60vh]">
          <CompleteTaskReassignUsers
            projectId={project?.id!}
            taskId={id}
            onCancel={onCancel}
            onSuccess={() => {
              setStage(Stage.ReassignAssets)
              setCompleted({ ...completed, [Stage.ReassignUsers]: true })
            }}
          />
        </div>
      )}
      {stage === Stage.ReassignAssets && (
        <div className="h-[60vh]">
          <CompleteTaskReassignAssets
            projectIsEligibleForCompletion={id === project?.unassignedTaskId}
            taskId={id}
            onCancel={onCancel}
            onSuccess={() => {
              setStage(Stage.ReassignAssets)
              setCompleted({ ...completed, [Stage.ReassignUsers]: true })
              markTaskCompleteMutation({ id }).then((result) => {
                if (result.error) {
                  console.error(result.error)
                  onError?.()
                  errorSnack("Error completing task; please try again")
                } else {
                  if (projectIsEligibleForCompletion) {
                    markProjectAsCompleteMutation({ id: project?.id! }).then((result) => {
                      if (result.error) {
                        console.error(result.error)
                        errorSnack("Error completing project; please try again")
                        onError?.()
                      } else {
                        successSnack("Success! Task and project are completed!")
                        onSuccess?.()
                      }
                    })
                  } else {
                    successSnack("Success! Task completed!")

                    onSuccess?.()
                  }
                }
              })
            }}
          />
        </div>
      )}
    </RenderIf>
  )
}

import { FC } from "react"
import { BiCar } from "react-icons/bi"
import { H3 } from "../../Elements/H3"

export const AssetsEmptyState: FC<{ active?: boolean; deleted?: boolean }> = ({ active, deleted }) => {
  if (!active || deleted) {
    return (
      <div>
        <p className="text-center py-12 text-gray-600 text-sm">No assets found</p>
      </div>
    )
  }

  return (
    <div className="w-full bg-blue-600 grid gap-y-6 text-white justify-center text-center py-16 md:mt-6 rounded-lg">
      <div className="flex items-center justify-center">
        <BiCar className="w-12 h-12 text-blue-400" />
      </div>
      <div className="mt-4">
        <H3 className="text-white">No assets here!</H3>
      </div>
    </div>
  )
}

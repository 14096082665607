import { Button, ButtonGroup } from "@mui/material"
import { FC } from "react"
import { classNames } from "../helpers/classNames"
import { colors } from "../helpers/colors"
import { DateRangeFilterString } from "./Partials/Summary/types"

type Option = {
  title: string
  value: DateRangeFilterString
}

type Props = {
  filter: DateRangeFilterString
  setFilter: (filter: DateRangeFilterString) => void
}

export const DateRangeSelector: FC<Props> = ({ filter = "daily", setFilter }) => {
  const options: Option[] = [
    { title: "Daily", value: "daily" },
    { title: "Weekly", value: "weekly" },
    { title: "Monthly", value: "monthly" },
    { title: "All Time", value: "all-time" },
  ]
  return (
    <div className="flex gap-2 overflow-x-auto">
      <ButtonGroup>
        {options.map((option) => (
          <Button
            disableRipple
            key={option.value}
            variant="outlined"
            color={filter === option.value ? "primary" : "secondary"}
            onClick={() => setFilter(option.value)}
            sx={{
              border: `1px solid ${colors.gray[300]}`,
              "&:hover": {
                border: `1px solid ${colors.gray[300]}`,
                background: `${colors.blue[100]}`,
              },
              maxHeight: "40px",
              padding: "6px 24px",
            }}
            className={classNames(filter === option.value ? "bg-blue-50" : "bg-gray-50")}
          >
            {option.title}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  )
}

import { Autocomplete, Checkbox, TextField } from "@mui/material"
import { useField } from "formik"
import { FC, useMemo, useState } from "react"
import { useQuery } from "urql"
import { graphql } from "../../../../graphql/generated/gql"
import { ProjectStatus } from "../../../../graphql/generated/gql/graphql"
import { classNames } from "../../../../helpers/classNames"
import { sortByFieldAscending } from "../../../../helpers/sorts/sortByFieldAscending"
import { Project } from "../../../../types/Project"
import { SelectableUser, User } from "../../../../types/User"
import UserWithTaskRow from "../Rows/UserWithTaskRow"

type Props = {
  disabled?: boolean
  isSingleSelect?: boolean
  name: string
  onChange?: (selectedValues: string[]) => void
  teamMembers: SelectableUser[]
  withErrorHandling?: boolean
}

type ProjectWithUsers = {
  users: SelectableUser[]
  name: string
}

type ProjectsWithUsers = {
  [projectId: string]: ProjectWithUsers
}

const ProjectsQuery = graphql(`
  query TeamMembersMultiSelectListOrgProjects($status: ProjectStatus!) {
    projectsByStatus(status: $status) {
      id
      name
    }
  }
`)

export const TeamMembersMultiSelect: FC<Props> = ({
  disabled = false,
  name,
  isSingleSelect,
  onChange,
  teamMembers,
  withErrorHandling = true,
}) => {
  const [{ data: projectsData, fetching }] = useQuery({
    query: ProjectsQuery,
    variables: { status: ProjectStatus.Active },
  })
  const [{ value }, { error, touched }, { setValue }] = useField(name)

  const preselectedValue =
    value
      .map((val: string) => {
        const user = teamMembers.find((user) => user.id === val)
        return user
      })
      .filter(Boolean) || []

  const [muiFieldValue, setMuiFieldState] = useState<SelectableUser[]>(preselectedValue || [])

  const projectsWithUsers = teamMembers
    .sort(sortByFieldAscending((user: User) => `${user.firstName} ${user.lastName}`))
    .reduce((acc, user) => {
      const teamMemberProjectId = user.projectId!

      if (acc[teamMemberProjectId]) {
        acc[teamMemberProjectId].users.push(user)
      } else {
        const project = projectsData?.projectsByStatus.find(
          (project: Pick<Project, "id" | "name">) => project.id === teamMemberProjectId
        )
        acc[teamMemberProjectId] = {
          users: [user],
          name: project?.name || "",
        }
      }

      return acc
    }, {} as ProjectsWithUsers)

  const options = useMemo(() => {
    const users = []

    if (!isSingleSelect) {
      users.push({
        id: "all",
        firstName: "All",
        lastName: "Team Members",
        projectId: "",
        currentProjectId: "",
        currentTaskId: "",
        jobTitle: "",
      })
    }

    return [
      ...users,
      ...teamMembers.sort((a, b) => {
        const aProjectName = projectsWithUsers[a.projectId!].name
        const bProjectName = projectsWithUsers[b.projectId!].name

        const aSortString = `${aProjectName === "Overhead" ? "" : aProjectName} ${a.firstName} ${a.lastName}`
        const bSortString = `${bProjectName === "Overhead" ? "" : bProjectName} ${b.firstName} ${b.lastName}`

        return -bSortString.localeCompare(aSortString)
      }),
    ]
  }, [isSingleSelect, projectsWithUsers, teamMembers])

  const allSelected = muiFieldValue.length === teamMembers.length

  if (fetching) return <></>

  return (
    <div>
      <Autocomplete
        disabled={disabled}
        disableCloseOnSelect={!isSingleSelect}
        getOptionLabel={(option) => {
          if (Array.isArray(option)) {
            const singleOption = option[0]
            const user = teamMembers.find((user) => user.id === singleOption?.id)
            if (user) return `${user.firstName} ${user.lastName}`
          }

          const user = teamMembers.find((user) => user.id === option?.id)
          if (user) return `${user.firstName} ${user.lastName}`

          return ""
        }}
        groupBy={(option) => {
          const key = option?.projectId

          if (!key) return ""

          return projectsWithUsers[key].name
        }}
        // @ts-ignore
        isOptionEqualToValue={(option, value) => option.id === value?.[0]?.id} // we always set "value" to an array, which causes a type error here. If "multiple" is false, the component expects "value" to be an object.
        limitTags={3}
        multiple={!isSingleSelect}
        onChange={(_event, newValue) => {
          if (!newValue) {
            setMuiFieldState([])
            setValue([])
            return
          }

          // we always want to work with an array
          const value = Array.isArray(newValue) ? newValue : [newValue]

          // if "select all" is unselected, unselect all team members
          if (allSelected && value.length - 1 === teamMembers.length) {
            setMuiFieldState([])
            setValue([])
            return
          }

          // if "select all" is selected, select all team members
          if (value.find((user: SelectableUser) => user.id === "all")) {
            onChange && onChange(teamMembers.map((user: SelectableUser) => user.id))
            setMuiFieldState(teamMembers)
            setValue(teamMembers.map((user: SelectableUser) => user.id))
            return
          }

          onChange && onChange(value.map((user: SelectableUser) => user.id))
          setMuiFieldState(value)
          setValue(value.map((user: SelectableUser) => user.id))
        }}
        openOnFocus
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!(withErrorHandling && touched && error)}
            helperText={withErrorHandling && touched && error}
            size="small"
            label="Team Member"
          />
        )}
        renderOption={(props, option, { selected }) => {
          const isAllOption = option.id === "all"
          return (
            <li {...props} className={classNames("py-2 px-4 flex items-center cursor-pointer", isAllOption && "h-8")}>
              {!isSingleSelect && (
                <Checkbox style={{ marginRight: 8 }} checked={isAllOption ? allSelected : selected} />
              )}
              {option.id === "all" ? (
                <div className="w-full">Select All</div>
              ) : (
                <div className="w-full">
                  <UserWithTaskRow user={option} />
                </div>
              )}
            </li>
          )
        }}
        sx={{
          "& .MuiInputBase-root.Mui-focused": {
            height: "100%",
            maxHeight: "6rem",
          },
        }}
        value={muiFieldValue}
      />
    </div>
  )
}

import {
  Checkbox,
  Chip,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Radio,
  Typography,
} from "@mui/material"
import { Dispatch, SetStateAction, useState } from "react"
import { BiChevronDown } from "react-icons/bi"
import { ListVisibilityFilter } from "../graphql/generated/client-types-and-hooks"
import { sentenceCase } from "../helpers/sentenceCase"
import { ListVisibilityFilterValues } from "../types/ListVisibilityFilter"
import { CustomMenu } from "./CustomMenu"

const MenuRadio = ({ selected = false }) => (
  <ListItemIcon>
    <Radio checked={selected} />
  </ListItemIcon>
)

const MenuCheckbox = ({ selected = false }) => (
  <ListItemIcon>
    <Checkbox checked={selected} />
  </ListItemIcon>
)

type ExtraFilterSpecification = {
  label: string
  onSelect: () => void
  onDeselect?: () => void
  exclusiveTo?: string | string[]
  disabled?: boolean
  isOnByDefault?: boolean
}

// TODO: Model 'other filters which are exclusive to the primary filters'
type Props = {
  visibilityFilter?: ListVisibilityFilter
  extraFilters?: {
    items: ExtraFilterSpecification[]
    label?: string
  }[]
  noun?: string
  setVisibilityFilter: Dispatch<SetStateAction<ListVisibilityFilter>>
  setArchivedFilter: (isArchived: boolean) => void
}

type InternalVisFilter = ListVisibilityFilter | "Archived"

type visibilityFilterOption = {
  value: InternalVisFilter
  label: string
  onSelect: () => void
}

export const AssetFilter = ({
  extraFilters = [],
  noun,
  setVisibilityFilter,
  setArchivedFilter,
}: Props): JSX.Element => {
  const [filterMenuOpen, setFilterMenuOpen] = useState(false)
  const [internalVisFilter, setInternalVisFilter] = useState<InternalVisFilter>(ListVisibilityFilter.My)

  const visFilterLabel = (visFilter: string | ListVisibilityFilter) => {
    switch (visFilter) {
      case "all":
        return "All"
      case "my":
        return noun ? `My ${noun}` : "My"
      default:
        return sentenceCase(visFilter)
    }
  }

  const visFilterValue = (str: string) => {
    switch (str) {
      case "all":
        return ListVisibilityFilter.All
      case "my":
        return ListVisibilityFilter.My
      default:
        return ListVisibilityFilter.My
    }
  }

  const visibilityFilterOptions: visibilityFilterOption[] = [...ListVisibilityFilterValues].map((value) => ({
    value: visFilterValue(value),
    label: visFilterLabel(value),
    onSelect: () => {
      setVisibilityFilter(visFilterValue(value))
      setArchivedFilter(false)
    },
  }))

  visibilityFilterOptions.push({
    value: "Archived",
    label: "Archived",
    onSelect: () => {
      setVisibilityFilter(ListVisibilityFilter.All)
      setArchivedFilter(true)
    },
  })

  const [activeFilters, setActiveFilters] = useState<Record<string, boolean>>(
    extraFilters
      .map((section) => section.items)
      .flat(1)
      .reduce((acc, filter) => ({ ...acc, [filter.label]: filter.isOnByDefault ? true : false }), {})
  )

  return (
    <>
      <CustomMenu
        open={filterMenuOpen}
        setOpen={setFilterMenuOpen}
        anchor={
          <div className="flex gap-x-2.5 pl-4 justify-between rounded-3xl text-center p-3 leading-4 border border-gray-400 text-gray-800">
            <span className="text-gray-400">Filter: </span>
            <span>{visFilterLabel(internalVisFilter)}</span>
            <BiChevronDown className="inline" />
          </div>
        }
      >
        <MenuList sx={{ minWidth: 250 }}>
          {visibilityFilterOptions.map((option) => (
            <MenuItem
              key={option.value}
              onClick={() => {
                option.onSelect()
                setInternalVisFilter(option.value)
              }}
            >
              <MenuRadio selected={internalVisFilter === option.value} />
              <ListItemText>{option.label}</ListItemText>
            </MenuItem>
          ))}

          {extraFilters.map((section, i) => {
            return (
              <div key={`section-${i}`}>
                <Divider />
                {section.label && <Typography className="font-bold text-sm px-3 mt-3">{section.label}</Typography>}
                {section.items
                  .filter((f) => !f.disabled)
                  .map(({ label, onSelect, exclusiveTo, onDeselect }) => (
                    <MenuItem
                      key={label}
                      onClick={() => {
                        const filterIsActive = !activeFilters[label]
                        const newActiveFilters = { ...activeFilters, [label]: filterIsActive }
                        if (!filterIsActive) onDeselect?.()
                        if (filterIsActive && exclusiveTo)
                          [...exclusiveTo].forEach((exclusion) => (newActiveFilters[exclusion] = false))
                        setActiveFilters(newActiveFilters)
                        if (filterIsActive) onSelect()
                      }}
                    >
                      {exclusiveTo ? (
                        <MenuRadio selected={activeFilters[label]} />
                      ) : (
                        <MenuCheckbox selected={activeFilters[label]} />
                      )}
                      <ListItemText>{sentenceCase(label)}</ListItemText>
                    </MenuItem>
                  ))}
              </div>
            )
          })}
        </MenuList>
      </CustomMenu>

      {extraFilters
        .map((section) => section.items)
        .flat(1)
        .map(
          ({ label, onDeselect }) =>
            activeFilters[label] && (
              <Chip
                key={label}
                sx={{ "&.MuiChip-root": { height: "100%", paddingX: "0.75rem", borderRadius: "1.5rem" } }}
                label={sentenceCase(label)}
                variant="filled"
                {...(onDeselect
                  ? {
                      onDelete: () => {
                        const filters = { ...activeFilters, [label]: false }
                        // If no filters are enabled, restore the default
                        if (Object.values(filters).filter((a) => !!a).length < 1) {
                          extraFilters
                            .map((section) => section.items)
                            .flat(1)
                            .forEach(({ label, isOnByDefault }) => {
                              if (isOnByDefault) filters[label] = true
                            })
                        }
                        setActiveFilters(filters)
                        onDeselect?.()
                      },
                    }
                  : {})}
              />
            )
        )}
    </>
  )
}

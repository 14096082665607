/**
 *
 * This function generates a valid CSV as per the RFC-4180 specification.
 * https://www.rfc-editor.org/rfc/rfc4180
 */
export function generateCsv(lines: (string | false | number | undefined)[][]): string {
  return lines
    .map((line) =>
      line
        .map((value) => {
          if (typeof value === "string") return `"${value}"`
          if (value === undefined) return `""`

          return value
        })
        .join(",")
    )
    .join("\r\n")
}

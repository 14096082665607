import { IconButton } from "@mui/material"
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro"
import { FC, MutableRefObject, useContext, useState } from "react"
import { BiCar, BiChevronDown, BiChevronUp, BiDotsHorizontalRounded, BiEdit, BiSortAlt2, BiTrash } from "react-icons/bi"
import { Asset, useRestoreAssetMutation } from "../../../../graphql/generated/client-types-and-hooks"
import { classNames } from "../../../../helpers/classNames"
import { useModalProps } from "../../../../hooks/useModalProps"
import { DrawerContext } from "../../../../providers/DrawerProvider"
import { PermissionsContext } from "../../../../providers/PermissionsProvider/PermissionsProvider"
import { AssetArchiveModal } from "../../../AssetArchiveModal"
import { QuickMenu } from "../../../QuickMenu"
import { MenuItem } from "../../../QuickMenuMui"
import { AssetDrawer } from "../../Drawer/AssetDrawer"
import { AssetEditDrawer } from "../../Drawer/AssetEditDrawer"
import { AssetGroupReassignmentFilterDefinition, ReassignAssetDrawer } from "../../Drawer/ReassignAssetDrawer"

export const AssetQuickActionsCell: FC<{
  apiRef: MutableRefObject<GridApiPro>
  asset: Asset
}> = ({ apiRef, asset }) => {
  const { hasPermissionTo } = useContext(PermissionsContext)
  const { push: pushDrawer } = useContext(DrawerContext)
  const expandedDetailPanels = apiRef.current.getExpandedDetailPanels()

  const [_restoreAssetData, assetRestoreMutation] = useRestoreAssetMutation()
  const [isExpanded, setIsExpanded] = useState(expandedDetailPanels.includes(asset.compositeKey))
  const archiveAssetModalProps = useModalProps("Archive Asset")

  const quickMenuItems: MenuItem[][] = [
    [
      {
        value: "View",
        Icon: BiCar,
        onClick: () => pushDrawer(<AssetDrawer assetId={asset.id} />),
      },
    ],
  ]

  if (hasPermissionTo("asset:transfer") && !asset?.isAssetGroup && !asset?.deletedAt) {
    quickMenuItems[0].push({
      value: "Reassign",
      onClick: () =>
        pushDrawer(
          <ReassignAssetDrawer
            assetsIdsToReassign={asset.assetGroupId ? [] : [asset.id]}
            assetGroupToReassign={
              asset.assetGroupId
                ? ({
                    assetGroupId: asset.assetGroupId!,
                    assignableId: asset.assignableId,
                    assignableType: asset.assignableType,
                    status: asset.status!,
                    count: asset.assetChildCount,
                    groupParent: asset?.childAssetGroups?.[0]?.groupParent,
                    compositeKey: asset.compositeKey,
                  } as AssetGroupReassignmentFilterDefinition)
                : null
            }
          />
        ),
      Icon: BiSortAlt2,
      iconStyles: "rotate-90 w-5 h-5",
    })
  }

  if (hasPermissionTo("asset:update") && asset) {
    quickMenuItems[0].push({
      value: "Edit",
      onClick: () => pushDrawer(<AssetEditDrawer asset={asset} />),
      Icon: BiEdit,
    })
  }

  if (hasPermissionTo("asset:delete") && asset.deletedAt === null) {
    quickMenuItems.push([
      {
        value: "Archive asset",
        color: "red",
        Icon: BiTrash,
        onClick: () => archiveAssetModalProps.handleOpenModal(),
      },
    ])
  }

  if (hasPermissionTo("asset:delete") && asset.deletedAt !== null) {
    quickMenuItems.push([
      {
        value: "Unarchive asset",
        color: "red",
        Icon: BiTrash,
        onClick: () => {
          assetRestoreMutation({
            id: asset.id,
          })
        },
      },
    ])
  }

  const handleToggle = () => {
    apiRef.current.toggleDetailPanel(asset.compositeKey)
    setIsExpanded(!isExpanded)
  }

  return (
    <div className={classNames("hidden mt-2", "md:flex md:mt-0 md:col-span-1 justify-self-end")}>
      <QuickMenu
        className={classNames(
          "h-10 w-10 -mr-4 md:mr-0 flex items-center justify-center flex-none rounded-full transition-colors p-2",
          "hover:bg-gray-50"
        )}
        items={quickMenuItems}
      >
        <BiDotsHorizontalRounded className="w-6 h-6" />
      </QuickMenu>
      <IconButton
        color="inherit"
        onClick={handleToggle}
        disabled={(!asset.isAssetGroup && asset.assetChildCount === 0) || !!asset?.assetGroupId}
      >
        {isExpanded ? <BiChevronUp /> : <BiChevronDown />}
      </IconButton>
      <AssetArchiveModal assetId={asset.id} formModalProps={archiveAssetModalProps} />
    </div>
  )
}

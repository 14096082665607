import { useRouter } from "next/router"
import { FC, useContext, useMemo } from "react"
import {
  BiCar,
  BiDotsHorizontalRounded,
  BiLayer,
  BiListUl,
  BiPackage,
  BiTask,
  BiTransfer,
  BiTrash,
  BiUserCircle,
} from "react-icons/bi"
import { AssetAssignableType, AssetQuantityTableQuery } from "../../../graphql/generated/client-types-and-hooks"
import { archivableStatuses, isOperational } from "../../../helpers/assetStatus"
import { classNames } from "../../../helpers/classNames"
import { DrawerContext } from "../../../providers/DrawerProvider"
import { PermissionsContext } from "../../../providers/PermissionsProvider/PermissionsProvider"
import { AssetImage } from "../../AssetImage"
import { Monogram } from "../../Monogram"
import Pill from "../../Pill"
import { QuickMenu } from "../../QuickMenu"
import { MenuItem } from "../../QuickMenuMui"
import { Row } from "../../Table/Row"
import { InventoryReportDrawer } from "../Drawer/InventoryReportDrawer"
import { ReassignAssetDrawer } from "../Drawer/ReassignAssetDrawer"
import { AssetStatusPill } from "./AssetStatusPill"

export const AssetQuantityRow: FC<{
  assignment: AssetQuantityTableQuery["assetGroups"][0]

  isInventory: boolean
  asset: AssetQuantityTableQuery["asset"]
}> = ({ assignment, isInventory, asset }) => {
  const router = useRouter()
  const { push: pushDrawer } = useContext(DrawerContext)
  const { hasPermissionTo } = useContext(PermissionsContext)

  const quickMenuItems: MenuItem[][] = [
    [
      {
        value: "Transfer quantities",
        Icon: BiTransfer,
        onClick: () => {
          pushDrawer(<ReassignAssetDrawer assetGroupToReassign={{ ...assignment.groupParent, ...assignment }} />)
        },
      },
    ],
  ]

  if (hasPermissionTo("asset:report")) {
    quickMenuItems[0].push({
      value: "Inventory",
      Icon: BiTask,
      onClick: () => {
        assignment.assignedAsset && pushDrawer(<InventoryReportDrawer asset={{ ...asset }} isQuantizedAsset />)
      },
    })
  }

  if (hasPermissionTo("asset:update") && archivableStatuses.includes(assignment.status)) {
    quickMenuItems.push([
      {
        value: "Archive asset",
        color: "red",
        Icon: BiTrash,
        onClick: () => {
          // TODO: support archive
        },
      },
    ])
  }

  const assigneeQuickMenuItems = useMemo(() => {
    if (assignment.assignableType === AssetAssignableType.User)
      return [
        [
          {
            value: "View user",
            Icon: BiUserCircle,
            onClick: () => {
              router.push(`/team/${assignment.assignedUserId!}`)
            },
          },
        ],
      ]
    if (assignment.assignedTask)
      return [
        [
          {
            value: "View project",
            Icon: BiLayer,
            onClick: () => {
              router.push(`/projects/${assignment.assignedTask?.projectId}`)
            },
          },
        ],
        [
          {
            value: "View task",
            Icon: BiListUl,
            onClick: () => {
              router.push(`/projects/${assignment.assignedTask?.projectId}/${assignment.assignedTaskId}`)
            },
          },
        ],
      ]
    if (assignment.assignedAsset)
      return [
        [
          {
            value: "View asset",
            Icon: BiCar,
            onClick: () => {
              router.push(`/assets/${assignment.assignedAsset?.id}`)
            },
          },
        ],
      ]

    return [[]]
  }, [assignment, router])

  return (
    <Row
      className={classNames(
        "grid grid-cols-12 group gap-y-3",
        "md:grid-flow-row md:gap-6 md:grid-rows-1 md:items-center"
      )}
    >
      <div className="col-span-12 flex flex-wrap md:mt-0 justify-between items-center gap-2 md:col-span-2">
        <div className="w-full font-medium text-sm text-gray-600 md:hidden">QUANTITY</div>

        <div className="flex justify-start gap-3 w-full">
          <div className="bg-gray-100 w-full px-3 py-2 rounded hover:bg-gray-200 hover:rounded transition-all leading-7 md:mt-1.5">
            {assignment.count}
          </div>

          <div className="md:hidden col-span-1 bg-gray-100 p-2.5 pt-3 rounded hover:bg-gray-200 hover:rounded transition-all max-w-[50px] block">
            <BiTransfer
              className="h-5 w-5 m-auto"
              onClick={() =>
                pushDrawer(<ReassignAssetDrawer assetGroupToReassign={{ ...assignment.groupParent, ...assignment }} />)
              }
            />
          </div>
          <div className="md:hidden col-span-1">
            <QuickMenu
              className="h-10 w-10 mr-4 md:mr-0 flex items-center justify-center rounded-full transition-colors p-2 hover:bg-gray-50 md:hidden"
              items={quickMenuItems}
            >
              <BiDotsHorizontalRounded className="w-6 h-6" />
            </QuickMenu>
          </div>
        </div>
      </div>

      <div className="col-span-12 mt-2 md:mt-0 md:col-span-6">
        <div className="w-full font-medium text-sm text-gray-600 md:hidden">ASSIGNEE</div>
        <div className="flex gap-3 w-full pt-2 md:justify-start">
          {isInventory ? (
            <div className="flex bg-gray-100 rounded w-full p-2 max-w-full transition-all gap-2 items-center">
              <BiPackage className="h-6 w-6" />
              <div className="text-left">Inventory</div>
            </div>
          ) : (
            <>
              <QuickMenu
                items={assigneeQuickMenuItems}
                className="w-full mt-2 md:mt-0 md:col-span-2"
                menuButtonClassName={
                  "w-full font-normal text-left cursor-pointer p-2 bg-gray-100 rounded hover:bg-gray-200 hover:rounded transition-all"
                }
              >
                {assignment.assignedAsset && (
                  <div className="w-full truncate leading-tight flex gap-2 items-center">
                    <AssetImage asset={assignment.assignedAsset} height="h-6" width="w-6" padding="p-0" />
                    {assignment.assignedAsset.name}
                  </div>
                )}
                {assignment.assignedTask && (
                  <div className="w-full truncate leading-tight flex gap-2 items-center">
                    <BiLayer className="h-6 w-6" />
                    {`${assignment.assignedTask.project.name} / ${assignment.assignedTask.name}`}
                  </div>
                )}
                {assignment.assignedUser && (
                  <div className="w-full truncate leading-tight flex gap-2 items-center">
                    <Monogram height="h-6" width="w-6" user={assignment.assignedUser} />
                    {assignment.assignedUser.firstName} {assignment.assignedUser.lastName}
                  </div>
                )}
              </QuickMenu>
            </>
          )}
          <div className="hidden md:block col-span-1 bg-gray-100 p-2.5 pt-3 rounded hover:bg-gray-200 hover:rounded transition-all">
            <BiTransfer
              className="h-5 w-5 m-auto"
              onClick={() =>
                pushDrawer(<ReassignAssetDrawer assetGroupToReassign={{ ...assignment.groupParent, ...assignment }} />)
              }
            />
          </div>
        </div>
      </div>
      <div className="col-span-12 mt-2 md:mt-0 md:col-span-3">
        <div className="w-full font-medium text-sm text-gray-600 md:hidden">STATUS</div>
        {assignment.status && (
          <div className="w-full pt-2 truncate leading-tight flex gap-2 items-center">
            {isInventory ? (
              <Pill color="green">Inventory</Pill>
            ) : (
              <AssetStatusPill
                asset={{
                  ...assignment,
                  status: assignment.status,
                  isAssetGroup: assignment.groupParent.isAssetGroup,
                  active: isOperational(assignment.status),
                }}
              />
            )}
          </div>
        )}
      </div>
      <div className={classNames("hidden mt-2", "md:inline-block md:mt-0 md:col-span-1 justify-self-end")}>
        <QuickMenu
          className={classNames(
            "h-10 w-10 -mr-4 md:mr-0 flex items-center justify-center flex-none rounded-full transition-colors p-2",
            "hover:bg-gray-50"
          )}
          items={quickMenuItems}
        >
          <BiDotsHorizontalRounded className="w-6 h-6" />
        </QuickMenu>
      </div>
    </Row>
  )
}

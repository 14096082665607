import { FC } from "react"
import { SkeletonContainer } from "../../Skeletons/SkeletonContainer"
import { SkeletonElement } from "../../Skeletons/SkeletonElement"
import { UserBadgeSkeleton } from "../User/UserBadge.skeleton"

export const NotesSkeleton: FC = () => (
  <>
    <SkeletonElement className={"h-4 w-60 mt-2"} />
    <SkeletonElement className={"h-4 w-36 mt-1 mb-4"} />
  </>
)

export const TransferSkeleton: FC = () => (
  <SkeletonContainer>
    <div className="grid grid-cols-3">
      <div>
        <SkeletonElement className="mb-2 h-4 w-10" />
        <div className="grid-row border-t border-b pt-2 pb-2 h-16 flex items-center">
          <UserBadgeSkeleton />
        </div>
      </div>
      <div>
        <SkeletonElement className="mb-2 h-4 w-10" />
        <div className="grid-row border-t border-b pt-2 pb-2 h-16 flex items-center">
          <UserBadgeSkeleton />
        </div>
      </div>
      <div>
        <SkeletonElement className="mb-2 h-4 w-10" />
        <div className="grid-row border-t border-b pt-2 pb-2 h-16 flex items-center">
          <SkeletonElement className="h-6 w-20" />
        </div>
      </div>
    </div>
  </SkeletonContainer>
)

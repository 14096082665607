import { FC, ReactNode } from "react"
import { BiX } from "react-icons/bi"

type Props = {
  children: ReactNode
  onDelete: () => void
}

export const Chip: FC<Props> = ({ children, onDelete }) => {
  return (
    <div className="px-[6px] py-[5px] rounded-full bg-gray-100 items-center flex gap-x-2 font-regular text-sm">
      {children}
      <button
        type="button"
        className="bg-gray-100 hover:bg-gray-200 transition-all duration-300 p-1 rounded-full"
        onClick={onDelete}
      >
        <BiX className="h-5 w-5" />
      </button>
    </div>
  )
}

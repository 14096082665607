import { Button, FormControlLabel, Radio, RadioGroup, Skeleton } from "@mui/material"
import { Form, Formik } from "formik"
import { FC, useContext, useEffect, useState } from "react"
import { CombinedError, useQuery } from "urql"
import * as Yup from "yup"
import {
  Maybe,
  ScheduleTemplate,
  ScheduledBreak,
  Task,
  UnitGoalInput,
  useTaskEditMutation,
} from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { useHandleError } from "../../../hooks/useHandleError"
import { useFlags } from "../../../providers/DevelopmentFeatureFlagProvider"
import { DrawerContext } from "../../../providers/DrawerProvider"
import { usePermissions } from "../../../providers/PermissionsProvider/PermissionsProvider"
import { MetadataNote } from "../../../types/MetadataNote"
import { PickPlus } from "../../../types/helpers"
import { DropDownMUI, DropDownMUIItem } from "../../DropDownMUI"
import { ButtonFilled, H2, H5 } from "../../Elements"
import { FormRow } from "../../FormRow"
import { DatePicker } from "../../Formik/DatePicker"
import { MetadataNoteListInput } from "../../Formik/MetadataNoteInput"
import { TextField } from "../../Formik/TextField"
import { WorkersCompCodeSelect } from "../../Formik/WorkersCompCodeSelect"
import { errorSnack, successSnack } from "../../Notistack/ThemedSnackbars"
import { PageTitle } from "../../PageTitle"
import { QuickMenuSectionTitle } from "../../QuickMenuSectionTitle"
import { RenderIf } from "../../RenderIf"
import { ScheduleDetails } from "../Organizations/TabPanels/ScheduleSubPanels/ScheduleDetails"
import { DeliverableUnitSection } from "../Projects/DeliverableUnitSection"
import { TaskExtraAssignmentTable } from "../Projects/TaskExtraAssignmentTable"
import { CreateOrEditTaskForm } from "../Tasks/CreateOrEditTaskForm"
import { SingleDrawerContext } from "./Drawer"
import { DrawerBody } from "./DrawerBody"
import { DrawerFooter } from "./DrawerFooter"
import { DrawerHeader } from "./DrawerHeader"

const TaskEditDrawerDocument = graphql(`
  query TaskEditDrawer($id: String!) {
    task(id: $id) {
      id
      name
      description
      groupId
      group {
        id
        name
      }
      projectId

      startDate
      endDate

      estimatedHours
      isDefault
      metadata

      project {
        id
        name
        tasksCount
        taskGroups {
          id
          name
          completedTaskCount
          taskCount
        }
      }

      scheduleTemplate {
        id
        isDefault
        workDays {
          label
          active
        }
        workHours {
          hours
          startTime
          endTime
        }
        nonWorkDays {
          id
          name
          dateRange
          active
        }
      }

      scheduledBreaks {
        id
        durationInMinutes
        isActive
        localizedStartTime
        name
        breakTaskId
        breakTask {
          id
          name
        }
      }

      unitGoals {
        id
        targetQuantity
        isPrimary
        deliverableUnitId
        deliverableUnit {
          id
          description
          referenceNumber
          unitOfMeasure
        }
        unitGoalProgress {
          id
        }
      }
    }
  }
`)

type Values = {
  metadata: MetadataNote[]
  name: string
  taskType: string

  additionalUnitGoals?: UnitGoalInput[]
  description?: Maybe<string>
  endDate?: Maybe<Date | undefined>
  estimatedHours?: Maybe<number> | ""
  groupId?: Maybe<string | undefined>
  primaryUnitGoals?: UnitGoalInput[]
  startDate?: Maybe<Date | undefined>
  workersCompCodeId?: Maybe<string>
}

type Props = {
  taskId: string
}

const validationSchema = Yup.object().shape({
  taskType: Yup.string(),
  name: Yup.string().trim().required("Required").label("Task Name"),
  groupId: Yup.string()
    .nullable()
    .when("taskType", {
      is: "sub-task",
      then: (schema) => schema.required("Task is required"),
    }),
  primaryUnitGoals: Yup.array(
    Yup.object()
      .nullable()
      .shape(
        {
          deliverableUnitId: Yup.string().when("targetQuantity", {
            is: (exist: number) => !!exist,
            then: (schema) => schema.required("Required"),
            otherwise: (schema) => schema.nullable(),
          }),
          targetQuantity: Yup.number().when("deliverableUnitId", {
            is: (exist: string) => !!exist,
            then: (schema) => schema.required("Required"),
            otherwise: (schema) => schema,
          }),
        },
        [["deliverableUnitId", "targetQuantity"]]
      )
  ),
  additionalUnitGoals: Yup.array(
    Yup.object().shape({
      deliverableUnitId: Yup.string().when("targetQuantity", {
        is: (exist: number) => !!exist,
        then: (schema) => schema.required("Required"),
        otherwise: (schema) => schema,
      }),
      targetQuantity: Yup.number(),
    })
  ),
  startDate: Yup.date().nullable(),
  endDate: Yup.date().nullable().min(Yup.ref("startDate"), "Due date must be after Start date"),
  description: Yup.string().nullable(),
  estimatedHours: Yup.number().nullable(),
  workersCompCodeId: Yup.string().nullable(),
  metadata: Yup.array(
    Yup.object().shape({
      content: Yup.string().trim(),
      label: Yup.string().trim(),
    })
  ),
})

type TaskBreak = PickPlus<
  ScheduledBreak,
  "id" | "durationInMinutes" | "localizedStartTime" | "name" | "isActive" | "breakTaskId"
> & { breakTask: PickPlus<Task, "id" | "name"> }

export const TaskEditDrawer: FC<Props> = ({ taskId }) => {
  const { flagIsEnabled } = useFlags()
  const { hasPermissionTo } = usePermissions()
  const { push: pushDrawer } = useContext(DrawerContext)

  const { handleClose } = useContext(SingleDrawerContext)
  const [{ data: data, error, fetching }] = useQuery({
    query: TaskEditDrawerDocument,
    variables: { id: taskId },
  })

  const task = data?.task
  const scheduleTemplate = task?.scheduleTemplate
  const scheduledBreaks = task?.scheduledBreaks

  const [{ error: editError }, updateTask] = useTaskEditMutation()

  useHandleError(error, "There was an error loading the project tasks")
  useHandleError(editError, "There was an error saving the task details.")

  const possibleTaskGroups: DropDownMUIItem[] = (task?.project.taskGroups || [])
    .filter((tg) => tg.completedTaskCount === 0 || tg.completedTaskCount !== tg.taskCount)
    .map((tg) => ({
      value: tg.id,
      label: tg.name,
    }))

  const [taskSchedule, setTaskSchedule] = useState<ScheduleTemplate | undefined>()
  const [taskBreaks, setTaskBreaks] = useState<TaskBreak[] | undefined>()

  useEffect(() => {
    setTaskSchedule(scheduleTemplate || undefined)
    setTaskBreaks(scheduledBreaks || [])
  }, [scheduleTemplate, scheduledBreaks])

  if (fetching || !task || fetching) {
    return <Skeleton />
  }

  const unitGoalInputs = (task?.unitGoals || []).map(({ deliverableUnit, isPrimary, targetQuantity }) => ({
    deliverableUnitId: deliverableUnit.id,
    isPrimary,
    targetQuantity: targetQuantity || 0,
  }))

  const initialValues: Values = {
    estimatedHours: "",
    workersCompCodeId: "",
    ...task,
    additionalUnitGoals: unitGoalInputs.filter(({ isPrimary }) => !isPrimary),
    description: task.description || "",
    groupId: task?.groupId || "",
    metadata: task.metadata || [{ content: "", label: "" }],
    name: task.name || "",
    primaryUnitGoals: unitGoalInputs.filter(({ isPrimary }) => isPrimary),
    taskType: task?.groupId ? "sub-task" : "task",
  }

  const handleError = (error: CombinedError | undefined, message: string) => {
    errorSnack(message)
    console.error(error)
  }

  const handleSuccess = (message: string) => {
    successSnack(message)
    handleClose()
  }

  const onSubmit = async (values: Values) => {
    if (values.taskType !== "sub-task") {
      values.groupId = null
    }

    const scheduledBreaks = taskBreaks?.map((scheduledBreak) => {
      const { breakTask, breakTaskId, ...scheduleBreak } = scheduledBreak

      return {
        ...scheduleBreak,
        breakTaskId,
      }
    })

    updateTask({
      id: task?.id!,
      projectId: task?.projectId!,
      ...values,
      scheduledBreaks,
      schedule: taskSchedule,
      estimatedHours: values.estimatedHours ? +values.estimatedHours : null,
    }).then((result) => {
      if (result.error) {
        return handleError(result.error, "There was an error saving the task details.")
      }
      return handleSuccess("Updated the task details.")
    })
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ isSubmitting, values, setFieldValue }) => (
        <Form>
          <DrawerHeader handleClose={handleClose} href={`/projects/${task.projectId}/${task.id}/details`} />

          <DrawerBody>
            <H2 className="mb-6 text-gray-800">Edit Task</H2>
            <PageTitle title={`${task?.name || "Task"} details`} />

            <H5 className="mt-0 mb-0">Task Type</H5>
            <hr className="mt-2 mb-4 md:mb-6" />
            <RadioGroup
              row
              value={values.taskType}
              name="taskType"
              onChange={(e) => {
                const type = e.currentTarget.value
                setFieldValue("taskType", type)

                if (type !== "sub-task") {
                  setFieldValue("groupId", undefined)
                }
              }}
            >
              <FormControlLabel value="task" control={<Radio />} label="Task" name="taskType" />
              <FormControlLabel value="sub-task" control={<Radio />} label="Sub-task" name="taskType" />
            </RadioGroup>
            <div className="text-sm text-gray-400 mt-2">
              A task is a single checklist item that can have units, users and assets applied to it.
            </div>

            <H5 className="mt-10 mb-0">Basic Info</H5>
            <hr className="mt-2 mb-4 md:mb-6" />

            <FormRow childrenContainerClassName="flex flex-col">
              <TextField name="name" label="Task name" />
            </FormRow>
            {values.taskType === "sub-task" && data?.task.project.tasksCount > 0 && (
              <FormRow labelClassName="mb-10">
                <DropDownMUI items={possibleTaskGroups} fieldName="groupId" label="Summary task" />
              </FormRow>
            )}
            <FormRow childrenContainerClassName="flex flex-col">
              <div className="flex flex-col md:flex-row gap-x-3 gap-y-2 md:gap-y-0">
                <DatePicker name="startDate" label="Start Date" className="grow" />
                <DatePicker name="endDate" label="End Date" className="grow" />
              </div>
            </FormRow>
            <FormRow childrenContainerClassName="flex flex-col mt-6">
              <TextField name="estimatedHours" label="Estimated hours" type="number" />
            </FormRow>

            <RenderIf permissionsInclude={"timeEntry:export"}>
              <FormRow>
                <WorkersCompCodeSelect name="workersCompCodeId" />
              </FormRow>
            </RenderIf>

            <FormRow childrenContainerClassName="flex flex-col mt-6">
              <TextField name="description" label="Task description" multiline rows={4} />
            </FormRow>

            <QuickMenuSectionTitle isEditing title="Task Notes" permission="task:update" />
            <FormRow>
              <MetadataNoteListInput values={values.metadata} />
            </FormRow>

            {flagIsEnabled("Org Scheduling") && (
              <section className="pt-12 grid gap-y-6">
                <div className="flex justify-between items-center border-b">
                  <H5 className="mt-0 mb-0">Schedule</H5>
                  {hasPermissionTo("task:update") && (
                    <Button
                      disabled={task.isDefault}
                      variant="text"
                      onClick={() =>
                        pushDrawer(
                          <CreateOrEditTaskForm
                            task={task}
                            onCancel={handleClose}
                            onSuccess={handleClose}
                            group={task.group || undefined}
                          />
                        )
                      }
                    >
                      Edit
                    </Button>
                  )}
                </div>

                <div className="md:grid md:grid-cols-2 md:gap-4">
                  <ScheduleDetails
                    schedule={taskSchedule}
                    scheduledBreaks={taskBreaks || []}
                    showDaysDatesAndManHours
                  />
                </div>
              </section>
            )}

            {!task.isDefault && (
              <>
                <DeliverableUnitSection
                  title="Reporting Units"
                  task={task}
                  isPrimary={true}
                  taskEstimatedHours={values.estimatedHours || 0}
                />

                <DeliverableUnitSection
                  title="Additional Units"
                  task={task}
                  isPrimary={false}
                  taskEstimatedHours={values.estimatedHours || 0}
                />
              </>
            )}
            <TaskExtraAssignmentTable taskId={task.id} projectId={task.projectId} />

            <DrawerFooter>
              <div className="col-span-12 flex justify-start flex-col md:flex-row gap-4 md:gap-6 md:max-w-md ">
                <ButtonFilled color="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? "Saving" : "Update"}
                </ButtonFilled>
                <Button variant="text" type="button" onClick={handleClose} size="large">
                  Cancel
                </Button>
              </div>
            </DrawerFooter>
          </DrawerBody>
        </Form>
      )}
    </Formik>
  )
}

import { FC } from "react"
import { AssetGroup } from "../../../../../graphql/generated/client-types-and-hooks"
import { Available, getAssetStatusLabel } from "../../../../../helpers/assetStatus"
import Pill from "../../../../Pill"

export const QuantizedAssetStatusPill: FC<{ assetGroup: Pick<AssetGroup, "status">; isArchived?: boolean }> = ({
  assetGroup,
  isArchived = false,
}) => {
  const status = assetGroup.status ? getAssetStatusLabel(assetGroup.status) : "Inactive"
  const active = status === Available && !isArchived

  return (
    <>
      {active && <Pill color="blue">Active</Pill>}
      {!active && <Pill color="orange">{isArchived ? "Archived" : status}</Pill>}
    </>
  )
}

/* eslint-disable @next/next/no-img-element */
import { Typography } from "@mui/material"
import { DataGridPro, GridColDef, GridRowParams } from "@mui/x-data-grid-pro"
import { FC } from "react"
import { useQuery } from "urql"
import { TimeEntryActivityDocument } from "../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../graphql/generated/gql"
import { TaskOrProjectTeamTableQuery } from "../../../../graphql/generated/gql/graphql"
import { getHoursAndMinutesFromSeconds } from "../../../../helpers/getHoursAndMinutesFromSeconds"
import { pluralize } from "../../../../helpers/pluralize"
import { UserBadge } from "../../../UserBadge"
import { CustomDetailPanelToggleColDef } from "../../DataGrid/CustomDetailPanelToggle"
import { ProjectInjuryReports, TaskInjuryReports } from "../../InjuryReports/InjuryReports"
import { TimeEntryActivityBar } from "../../User/TimeEntryActivityBar"
import { TeamMemberDetailPanel, TeamSummaryQueryProps } from "../TeamTableDropDown"
import { Mode, TimeCell, countWeeksInMonth, daysOfWeek } from "../TeamTableTimeCell"
import { SummarySectionProps } from "../types"

const SummaryTeamTableDocument = graphql(`
  query TaskOrProjectTeamTable($projectId: String!, $taskId: String, $rangeEnd: DateTime!, $rangeStart: DateTime!) {
    usersForSummarySection(taskId: $taskId, projectId: $projectId, rangeEnd: $rangeEnd, rangeStart: $rangeStart) {
      id
      currentProjectId
      firstName
      imageUrl
      jobTitle
      lastName
      isClockedIn
      secondsClockedInDateRangeOnProjectOrTask(rangeEnd: $rangeEnd, rangeStart: $rangeStart, taskId: $taskId)
      reportedUnitsInDateRangeByProjectOrTask(rangeEnd: $rangeEnd, rangeStart: $rangeStart, taskId: $taskId) {
        id
        unitGoalProgressReports {
          id
          progress
        }
      }
    }
  }
`)

export const defaultColumnProps: Partial<GridColDef> = {
  flex: 1,
  hideable: false,
  sortable: false,
  disableColumnMenu: true,
  filterable: false,
  resizable: false,
}
type UserExpectation = TaskOrProjectTeamTableQuery["usersForSummarySection"][0]

export const ProjectAndTaskSummaryTeamTable: FC<SummarySectionProps> = ({
  projectId,
  taskId,
  rangeEnd,
  rangeStart,
  dateRangeType = "daily",
}) => {
  const [{ data }] = useQuery<TaskOrProjectTeamTableQuery>({
    query: SummaryTeamTableDocument,
    variables: { taskId, projectId, rangeStart, rangeEnd },
    pause: !projectId,
  })

  let users: UserExpectation[] = []

  if (data) {
    users = data.usersForSummarySection
  }

  const getDetailPanelContent = (params: GridRowParams<UserExpectation>) => (
    <TeamMemberDetailPanel userId={params.row.id} rangeEnd={rangeEnd} rangeStart={rangeStart} projectId={projectId} />
  )

  const columns: GridColDef[] = [
    {
      field: "firstName",
      flex: 1,
      minWidth: 200,
      renderCell: ({ row }: { row: UserExpectation }) => <UserBadge user={row} />,
      headerName: "Name",
    },
    {
      field: "durationInSeconds",
      headerName: "Total",
      minWidth: 120,
      flex: 1,
      renderCell: ({ row }: { row: UserExpectation }) => {
        const { hours, minutes } = getHoursAndMinutesFromSeconds(row.secondsClockedInDateRangeOnProjectOrTask)
        return <span>{`${hours}:${minutes}`}</span>
      },
    },
  ]

  if (dateRangeType === "daily") {
    columns.splice(
      columns.findIndex((column) => column.field === "durationInSeconds"),
      1
    )

    columns.push(
      {
        field: "secondsClockedInDateRangeOnProjectOrTask",
        flex: 1,
        minWidth: 120,
        headerName: "Time",
        renderCell: ({ row }: { row: UserExpectation }) => {
          const { hours, minutes } = getHoursAndMinutesFromSeconds(row.secondsClockedInDateRangeOnProjectOrTask)
          return <span>{`${hours} ${pluralize("hr", hours)} ${minutes} min`} </span>
        },
      },
      {
        field: "timeEntries",
        flex: 1,
        minWidth: 80,
        headerName: "Activity",
        renderCell: ({ row }: { row: UserExpectation }) => {
          return (
            <DeferredTimeEntryActivityBar
              rangeStart={rangeStart}
              rangeEnd={rangeEnd}
              projectId={projectId}
              userId={row.id}
            />
          )
        },
      },
      {
        field: "reportedUnitsInDateRangeByProjectOrTask",
        headerName: "Reports",
        flex: 1,
        minWidth: 80,
        renderCell: ({ row }: { row: UserExpectation }) => {
          const { reportedUnitsInDateRangeByProjectOrTask: reports } = row
          if (reports.length === 0) return <div className="text-gray-400">None</div>
          return (
            <div>
              {reports.length} {pluralize("report", reports.length)}
            </div>
          )
        },
      },
      { ...CustomDetailPanelToggleColDef }
    )
  }

  if (dateRangeType === "weekly") {
    daysOfWeek.forEach((day, index) => {
      columns.splice(index + 1, 0, {
        field: day,
        headerName: day,
        flex: 1,
        minWidth: 80,
        renderCell: ({ row }: { row: UserExpectation }) => (
          <TimeCell
            mode={Mode.Weekly}
            userId={row.id}
            day={day}
            rangeStart={rangeStart}
            rangeEnd={rangeEnd}
            projectId={projectId}
          />
        ),
      })
    })
  }
  if (dateRangeType === "monthly") {
    const numberOfWeeks = countWeeksInMonth(rangeStart, rangeEnd)
    for (let i = 0; i < numberOfWeeks; i++) {
      columns.splice(i + 1, 0, {
        field: `week${i + 1}`,
        headerName: `Week ${i + 1}`,
        flex: 1,
        minWidth: 80,
        renderCell: ({ row }: { row: UserExpectation }) => (
          <TimeCell
            mode={Mode.Monthly}
            index={i}
            userId={row.id}
            rangeStart={rangeStart}
            rangeEnd={rangeEnd}
            projectId={projectId}
          />
        ),
      })
    }
  }
  return (
    <div className="flex flex-col gap-y-4">
      <div>
        <Typography variant="h4">Team & Injuries</Typography>
        <DataGridPro
          autoHeight
          loading={!data}
          columns={columns.map((column) => ({ ...defaultColumnProps, ...column }))}
          disableRowSelectionOnClick
          getDetailPanelHeight={() => "auto" as "auto"}
          getDetailPanelContent={dateRangeType === "daily" ? (params) => getDetailPanelContent(params) : undefined}
          initialState={{ pagination: { paginationModel: { page: 0, pageSize: 10 } } }}
          pageSizeOptions={[10]}
          pagination
          rows={users}
        />
      </div>
      {taskId ? <TaskInjuryReports taskId={taskId} /> : <ProjectInjuryReports projectId={projectId} />}
    </div>
  )
}

const DeferredTimeEntryActivityBar: FC<TeamSummaryQueryProps> = ({ rangeStart, rangeEnd, projectId, userId }) => {
  const [{ data }] = useQuery({
    query: TimeEntryActivityDocument,
    variables: { rangeStart, rangeEnd, projectId, userId },
  })
  return <TimeEntryActivityBar timeEntries={data?.user?.timeEntries ?? []} minDate={rangeStart} maxDate={rangeEnd} />
}

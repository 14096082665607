import { Menu } from "@headlessui/react"
import { addMinutes, differenceInMinutes, format, roundToNearestMinutes, setHours, setMinutes } from "date-fns"
import { useField } from "formik"
import { FC, memo, useEffect, useMemo, useState } from "react"
import { BiChevronDown, BiTimeFive } from "react-icons/bi"
import { classNames } from "../../helpers/classNames"
import { getRoundedDate } from "../../helpers/getRoundedDate"
import { RecapRow } from "../ClockInOut/ClockInOutWithEvidence/RecapRow"

type Props = {
  name: string
  startDate: Date
  endDate: Date
  stepSizeInMinutes?: number
  inputClassName?: string
  canEdit?: boolean
}

const DeprecatedTimePickerComponent: FC<Props> = ({
  name,
  startDate,
  endDate,
  stepSizeInMinutes = 15,
  inputClassName,
  canEdit = true,
}) => {
  const timeFormat = "h:mm aaa"
  const [field, _meta, helpers] = useField(name)
  const [value, setValue] = useState(format(field.value, "HH:mm"))

  //  Generate dates in an array in increments of stepSizeInMinutes from the start date to the end date.
  const availableTimes = useMemo(() => {
    const newAvailableTimes = []
    const roundedStartDate = getRoundedDate(startDate, stepSizeInMinutes)
    const roundedEndDate = getRoundedDate(endDate, stepSizeInMinutes)

    for (let i = 0; i < differenceInMinutes(roundedEndDate, roundedStartDate); i += stepSizeInMinutes) {
      newAvailableTimes.push(addMinutes(getRoundedDate(startDate, stepSizeInMinutes), i))
    }

    return newAvailableTimes
  }, [startDate, endDate, stepSizeInMinutes])

  //  Update Formik when manual value changes are made.
  useEffect(() => {
    const [hours, minutes] = value.split(":").map((value) => parseInt(value))
    const newDate = setHours(setMinutes(roundToNearestMinutes(new Date()), minutes), hours)

    helpers.setValue(newDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <Menu>
      {() => (
        <RecapRow Icon={BiTimeFive} className={"relative"} childrenContainerClassName={"bg-white"}>
          <div className={"flex justify-between w-full"}>
            <input
              type={"time"}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              className={classNames(
                "grow border-0 p-0 focus:outline-none focus:ring-0 disabled:bg-gray-100",
                inputClassName
              )}
              disabled={!canEdit}
            />
            {canEdit && (
              <Menu.Button>
                <BiChevronDown className={"h-6 w-6 text-gray-600"} />
              </Menu.Button>
            )}
          </div>
          {canEdit && (
            <Menu.Items
              className={
                "origin-top-right absolute mt-4 rounded-md shadow-lg bg-white border border-gray-200 focus:outline-none w-full left-0 max-h-80 overflow-y-auto px-5 py-4 flex flex-col gap-2"
              }
            >
              {availableTimes.map((availableTime) => (
                <Menu.Item
                  key={availableTime.toISOString()}
                  as={"div"}
                  onClick={() => {
                    setValue(format(availableTime, "HH:mm"))
                    helpers.setValue(availableTime)
                  }}
                >
                  {format(availableTime, timeFormat)}
                </Menu.Item>
              ))}
            </Menu.Items>
          )}
        </RecapRow>
      )}
    </Menu>
  )
}

export const DeprecatedTimePicker = memo(DeprecatedTimePickerComponent, (prev, next) => {
  const startDateIsSame = prev.startDate?.toISOString() === next.startDate?.toISOString()
  const endDateIsSame = prev.endDate?.toISOString() === next.endDate?.toISOString()
  const stepSizeIsSame = prev.stepSizeInMinutes === next.stepSizeInMinutes
  const inputClassNameIsSame = prev.inputClassName === next.inputClassName
  const canEditIsSAme = prev.canEdit === next.canEdit

  return startDateIsSame && endDateIsSame && stepSizeIsSame && inputClassNameIsSame && canEditIsSAme
})

import { FC, useContext } from "react"
import { useMutation } from "urql"
import { UPDATE_ONE_FILE } from "../../../../graphql/queries/file.queries"
import { AddOrEditDocumentFormValues } from "../../Projects/Documents/ProjectDocuments"
import { FormikHelpers } from "formik"
import { userDocumentUpload } from "../../../../helpers/fileUpload"
import { errorSnack, successSnack } from "../../../Notistack/ThemedSnackbars"
import { UserDocumentsList } from "./UserDocumentsList"
import { DocumentsLayout } from "../../Documents/DocumentsLayout"
import { DocumentsContext } from "../../../../providers/DocumentsProvider"

export const UserDocuments: FC<{ userId: string }> = ({ userId }) => {
  const {
    documentModalProps,
    documentName,
    documentToUpdate,
    fileSelected,

    selectedFileForUpload,
    refreshList,
    clear,
    setSearchText,
    updateDocumentName
  } = useContext(DocumentsContext)

  const [_, updateOneDocument] = useMutation(UPDATE_ONE_FILE)

  const onSearchBarChange = (text: string) => {
    setSearchText(text)
  }
  const onSearchBarCancel = () => {
    setSearchText("")
  }

  const handleSubmit = async (
    values: AddOrEditDocumentFormValues,
    formikProps: FormikHelpers<{
      documentName: string
      status: string
      expirationDate: Date | undefined
    }>
  ) => {
    if (selectedFileForUpload) {
      const uploadedDocument = await userDocumentUpload(
        `${values.documentName}`,
        selectedFileForUpload.blob.type,
        selectedFileForUpload.blob,
        userId,
        values.status,
        values?.expirationDate
      )

      if (uploadedDocument?.fileId) {
        successSnack("document successfully uploaded")
      }
    } else if (documentToUpdate) {
      updateOneDocument({
        id: documentToUpdate.id,
        fileName: values?.documentName || documentToUpdate.name,
        status: values.status,
        expiresAt: values.expirationDate
      }).then((result) => {
        if (result.error) {
          errorSnack("There was a problem updating the document")
          console.error(result.error)

          resetForm(formikProps)
          return
        }

        successSnack("Document updated")
      })
    }

    resetForm(formikProps)
  }

  const resetForm = (
    formikProps: FormikHelpers<{
      documentName: string
      status: string
      expirationDate: Date | undefined
    }>
  ) => {
    refreshList()
    formikProps.resetForm()

    documentModalProps.handleCloseModal()
  }

  return (
    <DocumentsLayout
      title="User Documents"
      documentModalProps={documentModalProps}
      documentName={documentName}
      documentToUpdate={documentToUpdate}
      fileSelected={fileSelected}
      onSearchBarChange={onSearchBarChange}
      onSearchBarCancel={onSearchBarCancel}
      clear={clear}
      handleSubmit={handleSubmit}
      updateDocumentName={updateDocumentName}
    >
      <UserDocumentsList userId={userId} />
    </DocumentsLayout>
  )
}
import { Transition } from "@headlessui/react"
import { UserNotificationModelType } from "@prisma/client"
import { FC, useContext, useState } from "react"
import { useQuery } from "urql"
import { graphql } from "../../../graphql/generated/gql"
import { classNames } from "../../../helpers/classNames"
import { useHandleError } from "../../../hooks/useHandleError"
import { AssetPageContent } from "../../../pages/assets/[_id]/_layout"
import { AssetAssets } from "../../../pages/assets/[_id]/assets"
import { AssetDetails } from "../../../pages/assets/[_id]/details"
import { AssetPageDocuments } from "../../../pages/assets/[_id]/documents"
import { AssetHistory } from "../../../pages/assets/[_id]/history"
import { AssetReports } from "../../../pages/assets/[_id]/reports"
import { RenderIf } from "../../RenderIf"
import { NotificationBubble } from "../../UserNotifications/NotificationBubble"
import { SingleDrawerContext } from "./Drawer"
import { DrawerBody } from "./DrawerBody"
import { DrawerHeader } from "./DrawerHeader"

type Props = {
  assetId: string
}

type Tab = {
  value: string
  label: string
  permission: "asset:read" | "asset:report"
  TabNotificationBubble?: FC
}

const AssetDrawerDocument = graphql(`
  query assetDrawerDocument($id: String!) {
    asset(id: $id) {
      id
      name
      inferredProjectId
      inferredOwner {
        id
      }
    }
  }
`)

export const AssetDrawer: FC<Props> = ({ assetId }) => {
  const [currentTab, setCurrentTab] = useState("details")
  const { handleClose } = useContext(SingleDrawerContext)

  const [{ data, error }] = useQuery({
    query: AssetDrawerDocument,
    variables: { id: assetId },
  })

  useHandleError(error, "An error occurred while fetching asset details. Please reload the page to try again.")

  const tabs: Tab[] = [
    {
      value: "details",
      label: "Details",
      permission: "asset:read",
    },
    {
      value: "history",
      label: "History",
      permission: "asset:read",
      TabNotificationBubble: () => (
        <NotificationBubble
          shouldRender={(userNotifications) =>
            userNotifications.some((un) => un.modelType === UserNotificationModelType.Asset && un.asset?.id === assetId)
          }
        />
      ),
    },
    {
      value: "documents",
      label: "Documents",
      permission: "asset:read",
    },
  ]

  return (
    <>
      <DrawerHeader handleClose={handleClose} href={`/assets/${assetId}/${currentTab}`} />

      <DrawerBody>
        <AssetPageContent assetId={assetId} withTabs={false}>
          <div className="flex gap-6 overflow-x-auto">
            {tabs.map((tab) => {
              const { TabNotificationBubble } = tab

              const isSelected = tab.value === currentTab
              return (
                <RenderIf
                  key={tab.value}
                  permissionsInclude={tab.permission}
                  context={{ userId: data?.asset?.inferredOwner?.id, projectId: data?.asset?.inferredProjectId }}
                >
                  <button
                    onClick={() => setCurrentTab(tab.value)}
                    className={classNames(
                      "relative my-2 pb-2 font-bold uppercase whitespace-nowrap",
                      "md:my-8",
                      !isSelected && "text-gray-400"
                    )}
                  >
                    {TabNotificationBubble && <TabNotificationBubble />}
                    {tab.label}

                    <Transition
                      appear={true}
                      show={true}
                      enter="transition ease-in-out duration-150 transform"
                      enterFrom="opacity-0 translate-y-full"
                      enterTo="opacity-100 translate-y-0"
                    >
                      <div
                        className={classNames(
                          "w-full relative top-1 h-1 transition-all rounded-sm",
                          isSelected ? "bg-blue-600" : "bg-transparent"
                        )}
                      />
                    </Transition>
                  </button>
                </RenderIf>
              )
            })}
          </div>

          {currentTab === "details" && <AssetDetails assetId={assetId} />}
          {currentTab === "assets" && <AssetAssets assetId={assetId} />}
          {currentTab === "reports" && <AssetReports assetId={assetId} />}
          {currentTab === "history" && <AssetHistory assetId={assetId} />}
          {currentTab === "documents" && <AssetPageDocuments assetId={assetId} />}
        </AssetPageContent>
      </DrawerBody>
    </>
  )
}

import { Button, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material"
import { DataGridPro, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid-pro"
import { format, parseISO } from "date-fns"
import { FC, useState } from "react"
import { BiBarChartSquare, BiListUl, BiRuler } from "react-icons/bi"
import { useMutation, useQuery } from "urql"
import {
  ProjectUnitsTableProgressQuery,
  TaskUnitsTableProgressQuery,
} from "../../../../graphql/generated/client-types-and-hooks"

import { graphql } from "../../../../graphql/generated/gql"
import { UPDATE_LAST_EXPORT } from "../../../../graphql/queries/lastExport.queries"
import { colors } from "../../../../helpers/colors"
import { getFriendlyFloat } from "../../../../helpers/getFriendlyFloat"
import { titleCase } from "../../../../helpers/titleCase"
import { errorSnack, successSnack } from "../../../Notistack/ThemedSnackbars"
import { StackedHorizontalBarChart } from "../../../StackedHorizontalBarChart"
import {
  UNITS_ACTIVE_COLORS,
  UNITS_INACTIVE_COLORS,
  createSource,
} from "../../../Widgets/SummaryWidgets/WidgetSourceColors"
import { CustomDetailPanelToggleColDef } from "../../DataGrid/CustomDetailPanelToggle"
import { UnitTableDetailPanel } from "../ProjectSummaryUnitsTableDropDown"
import { SummaryUnitsGraph } from "../UnitsGraph"
import { aggregateUnitGoals } from "../helpers/aggregateUnitGoals"
import { exportToCSV } from "../helpers/exportToCSV"
import { getTaskOrProjectQueryDocument } from "../helpers/getQueryDocument"
import { SummarySectionProps } from "../types"
import { defaultColumnProps } from "./ProjectAndTaskSummaryTeamTable"

const ProjectUnitsTableProgressDocument = graphql(`
  query ProjectUnitsTableProgress($rangeStart: DateTime!, $rangeEnd: DateTime!, $entityId: String!) {
    project(id: $entityId) {
      id
      name
      startDate
      lastExport
      unitGoals {
        id
        isPrimary
        targetQuantity
        totalProgress
        previousProgress(rangeStart: $rangeStart)
        progressInDateRange(rangeEnd: $rangeEnd, rangeStart: $rangeStart)
        task {
          id
          name
          estimatedHours
          timeEntriesSumDurationInSeconds(rangeStart: $rangeStart)
        }
        deliverableUnit {
          id
          description
          unitOfMeasure
          color
        }
      }
    }
  }
`)
const TaskUnitsTableProgressDocument = graphql(`
  query TaskUnitsTableProgress($rangeStart: DateTime!, $rangeEnd: DateTime!, $entityId: String!) {
    task(id: $entityId) {
      id
      name
      startDate
      lastExport
      unitGoals {
        id
        isPrimary
        targetQuantity
        totalProgress
        previousProgress(rangeStart: $rangeStart)
        progressInDateRange(rangeEnd: $rangeEnd, rangeStart: $rangeStart)
        deliverableUnit {
          id
          description
          unitOfMeasure
          color
        }
      }
      estimatedHours
      timeEntriesSumDurationInSeconds
    }
  }
`)

type ProjectOrTaskData = ProjectUnitsTableProgressQuery | TaskUnitsTableProgressQuery

type RowData = {
  id: string
  unitType: string
  unitTotal: string
  totalProgress: number
  previousProgress: number
  currentProgress: number
  recentProgress: number
  taskCount: number
  color: string
}

export type TaskDetails = {
  id: string
  name: string
  unitType: string
  targetQuantity: number
  totalProgress: number
  previousProgress: number
  currentProgress: number
  recentProgress: number
  estimatedHours: number
  timeEntriesSumDurationInSeconds: number
}

export type SummaryUnitsTableProps = Omit<SummarySectionProps, "projectId"> & {
  entityType: "project" | "task"
  entityId: string
}

export const SummaryUnitsTable: FC<SummaryUnitsTableProps> = ({
  dateRangeType = "daily",
  rangeStart,
  rangeEnd,
  entityType,
  entityId,
}) => {
  const [, updateLastExport] = useMutation(UPDATE_LAST_EXPORT)

  const [{ data }] = useQuery<ProjectOrTaskData>({
    query: getTaskOrProjectQueryDocument(entityType, TaskUnitsTableProgressDocument, ProjectUnitsTableProgressDocument),
    variables: {
      rangeStart,
      rangeEnd,
      entityId,
    },
    pause: !entityId,
  })

  const dataToExport = data && "project" in data ? data.project : data && "task" in data ? data?.task : undefined
  const showContent =
    data && "project" in data
      ? !!data.project?.unitGoals.length
      : data && "task" in data
      ? !!data?.task.unitGoals.length
      : false

  const projectUnitGoals = data && "project" in data ? data.project?.unitGoals || [] : []
  const taskUnitGoals = data && "task" in data ? data.task?.unitGoals || [] : []
  const unitGoals = entityType === "project" ? projectUnitGoals : taskUnitGoals
  const aggregatedUnits = aggregateUnitGoals(unitGoals)

  const projectLastExport = data && "project" in data ? data.project?.lastExport : undefined
  const taskLastExport = data && "task" in data ? data.task?.lastExport : undefined
  const lastExportObj = entityType === "project" ? projectLastExport : taskLastExport

  async function handleExport() {
    const exportedAt = new Date().toISOString()

    try {
      await exportToCSV({
        data: dataToExport,
        rangeStart,
        rangeEnd,
        dateRangeType,
      })

      const updateResult = await updateLastExport({
        entityId,
        entityType,
      })

      if (updateResult.error) {
        throw updateResult.error
      }

      successSnack(
        `${titleCase(entityType)} units data exported on ${format(parseISO(exportedAt), "M/d/yy")} at ${format(
          parseISO(exportedAt),
          "h:mm"
        )}`
      )
    } catch (error: any) {
      console.error("Export error:", error.message)
      errorSnack("There was an error during the export process")
    }
  }

  const columns = [
    {
      field: "unitType",
      headerName: "Type",
      flex: 1.5,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="flex">
            <BiRuler
              className="p-1 mr-2 h-6 w-max rounded-md text-neutral-200 "
              style={{ backgroundColor: params.row.color }}
            />
            <p className="mr-1">{params.value}</p>
            {entityType === "project" && <span>({params.row.taskCount})</span>}
          </div>
        )
      },
    },
    { field: "unitTotal", headerName: "Total", flex: 1 },
    {
      field: "unitProgress",
      headerName: "Progress",
      flex: 2,
      renderCell: (params: GridRenderCellParams) => {
        const { previousProgress, recentProgress, currentProgress, targetQuantity } = params.row
        const sources =
          currentProgress === 0
            ? [createSource(previousProgress + recentProgress, UNITS_INACTIVE_COLORS)]
            : [
                createSource(previousProgress, UNITS_INACTIVE_COLORS),
                createSource(currentProgress, UNITS_ACTIVE_COLORS),
                createSource(recentProgress, UNITS_INACTIVE_COLORS),
              ]
        if (params.row) {
          return (
            <StackedHorizontalBarChart
              sources={sources}
              expectedMaximum={targetQuantity}
              expectedMaximumClassName="w-60"
              onlyBar
            />
          )
        }
        return null
      },
    },
    { ...CustomDetailPanelToggleColDef },
  ]

  const rows: RowData[] = Object.entries(aggregatedUnits).map(([description, details], index) => {
    return {
      id: index.toString(),
      unitType: description,
      progressInDateRange: `${details.currentProgress} ${details.unitType}`,
      unitTotal: `${getFriendlyFloat(details.totalProgress, 2)} / ${getFriendlyFloat(details.targetQuantity)} ${
        details.unitType
      }`,
      totalProgress: details.totalProgress,
      currentProgress: details.currentProgress,
      targetQuantity: details.targetQuantity,
      previousProgress: details.previousProgress,
      taskCount: details.tasks.length,
      color: details.color,
      recentProgress: details.recentProgress,
    }
  })

  if (dateRangeType && dateRangeType !== "all-time") {
    columns.splice(1, 0, {
      field: "progressInDateRange",
      flex: 0.75,
      headerName: titleCase(dateRangeType),
    })
  }

  const projectDetailProps =
    entityType === "project"
      ? {
          getDetailPanelHeight: () => "auto" as "auto",
          getDetailPanelContent: (params: GridRowParams) => {
            const row = params.row as RowData
            const tasksForUnit = aggregatedUnits[row.unitType]?.tasks || []
            return <UnitTableDetailPanel tasks={tasksForUnit} dateRangeType={dateRangeType} />
          },
        }
      : {}

  const dateRangeIsNotDaily = dateRangeType !== "daily"
  const [visibility, setVisibility] = useState(["graph", "dataGrid"])

  return (
    <div>
      <div className="flex justify-between items-center align-center">
        <Typography variant="h4">Units</Typography>
        {showContent && (
          <div className="flex mb-6">
            {lastExportObj && (
              <Typography fontSize={14} color={colors.gray[500]} className="my-auto mr-6">
                {`Last export on ${format(parseISO(lastExportObj.exportedAt), "M/d/yy")} at ${format(
                  parseISO(lastExportObj.exportedAt),
                  "h:mm"
                )} by ${lastExportObj.userName}`}
              </Typography>
            )}
            <Button className="mr-2" onClick={handleExport} type="button" variant="outlined" color="secondary">
              Export
            </Button>
            {dateRangeIsNotDaily && (
              <ToggleButtonGroup
                className="ml-2"
                value={visibility}
                onChange={(_event, newVisibility) => {
                  if (newVisibility.includes("graph") || newVisibility.includes("dataGrid")) {
                    setVisibility(newVisibility)
                  }
                }}
              >
                <ToggleButton value="graph" aria-label="show graph">
                  <BiBarChartSquare className="h-6 w-6" />
                </ToggleButton>
                <ToggleButton value="dataGrid" aria-label="show data grid">
                  <BiListUl className="h-6 w-6" />
                </ToggleButton>
              </ToggleButtonGroup>
            )}
          </div>
        )}
      </div>
      <div className="flex flex-col gap-y-4">
        {dateRangeIsNotDaily && visibility.includes("graph") && (
          <SummaryUnitsGraph
            dateRangeType={dateRangeType}
            entityType={entityType}
            entityId={entityId}
            rangeEnd={rangeEnd}
            rangeStart={rangeStart}
          />
        )}
        {visibility.includes("dataGrid") && (
          <DataGridPro
            autoHeight
            pagination
            disableRowSelectionOnClick
            loading={!data}
            rows={rows}
            columns={columns.map((column) => ({ ...defaultColumnProps, ...column }))}
            pageSizeOptions={[10]}
            initialState={{ pagination: { paginationModel: { page: 0, pageSize: 10 } } }}
            {...projectDetailProps}
          />
        )}
      </div>
    </div>
  )
}

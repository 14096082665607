import { FC, useContext, useEffect } from "react"
import { useQuery } from "urql"
import { AssetSort, AssignedAssetsQueryQuery } from "../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../graphql/generated/gql"
import { useHandleError } from "../../../../hooks/useHandleError"
import { AssignedAssetListContext } from "../../../../providers/AssignedAssetListProvider"
import { AssetDataGrid } from "./AssetDataGrid"
import { AssetListHeader } from "./AssetListHeader"

const ProjectAssignedAssetsDocument = graphql(`
  query AssignedAssetsQuery($filter: AssignedAssetFilter!, $sort: AssetSort!) {
    assignedAssets(filter: $filter, sort: $sort) {
      id
      active
      category
      assetChildCount
      assetGroupId
      assignableId
      assignableType
      assignmentsCount
      assignedTask {
        id
        name
        projectId
        project {
          id
          name
        }
      }
      assignedTaskId
      assignedUser {
        id
        archived
        firstName
        lastName
        currentProject {
          id
          name
        }
        currentTask {
          id
          name
        }
      }
      assignedUserId
      companyAssetNumber
      compositeKey
      deletedAt
      groupParentAsset {
        id
        name
        companyAssetNumber
      }
      imageUrl
      inferredOwner {
        id
        currentProject {
          id
          name
        }
        currentTask {
          id
          name
        }
      }
      name
      status
      ownershipType
    }
  }
`)

export type SortDirection = "asc" | "desc"
export type AssignedAssetNode = NonNullable<AssignedAssetsQueryQuery["assignedAssets"]>

type Props = {
  isComplete?: boolean
  projectId?: string
  taskId?: string
  userId?: string
  handleAssignAsset?: () => void
}

export const AssignedAssetsDataGrid: FC<Props> = ({ isComplete, projectId, taskId, userId, handleAssignAsset }) => {
  const ctx = useContext(AssignedAssetListContext)

  const [{ data, fetching: loadingAssets, error: assetsError }, fetchAssets] = useQuery({
    query: ProjectAssignedAssetsDocument,
    variables: {
      filter: {
        isArchived: ctx.isArchived,
        searchText: ctx.searchText,
        projectId,
        taskId,
        userId,
      },
      sort: { by: ctx.sort?.field, direction: ctx.sort?.sort } as AssetSort,
    },
  })

  useHandleError(assetsError, "There was a problem loading assets")

  useEffect(() => {
    const assets = data?.assignedAssets || []

    if (!assets.length) {
      if (ctx.resetList) {
        ctx.setLoadedAssets([])
      }
      return
    }

    ctx.setLoadedAssets(assets)
    if (ctx.resetList) {
      ctx.setResetList(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    // selected rows come across like:
    // "assetId|assignableId|assignableType|status"
    //  we only need the asset ID
    const assetIdsForSelectedRows = ctx.rowsSelected.map((compositeKey) => compositeKey.toString().split("|")[0])
    const assets = ctx.selectedAssets.filter((asset) => assetIdsForSelectedRows.includes(asset.id))

    for (let assetId of assetIdsForSelectedRows) {
      if (assets.some((asset) => assetId === asset.id)) {
        continue
      }

      const found = ctx.loadedAssets?.find((asset) => asset.compositeKey === assetId)

      if (found) {
        assets.push(found)
      }

      ctx.setSelectedAssets(assets)
    }
  }, [ctx, ctx.rowsSelected, ctx.loadedAssets, ctx.selectedAssets])

  return (
    <div className="flex flex-col w-full">
      <AssetListHeader
        assignedAsset
        fetchAssets={fetchAssets}
        projectId={projectId}
        taskId={taskId}
        handleAssignAsset={handleAssignAsset}
        isComplete={isComplete}
      />

      <div className="w-full">
        <AssetDataGrid
          assignedAsset
          loadingAssets={loadingAssets}
          projectId={projectId}
          taskId={taskId}
          totalCount={data?.assignedAssets?.length}
        />
      </div>
    </div>
  )
}

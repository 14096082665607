import { Button } from "@mui/material"
import { FC, useContext } from "react"
import { FaCheckCircle, FaRegCircle } from "react-icons/fa"
import {
  ProjectTasksListQuery,
  TaskListItem,
  useMarkTaskAsIncompleteMutation,
} from "../graphql/generated/client-types-and-hooks"
import { classNames } from "../helpers/classNames"
import { useCompleteTaskWorkflow } from "../hooks/useCompleteTaskWorkflow"
import { PermissionsContext } from "../providers/PermissionsProvider/PermissionsProvider"
import { TaskListDispatchContext } from "../providers/TaskListProvider/TaskListProvider"
import { TASK_LIST_ACTION_TYPES } from "../providers/TaskListProvider/taskListReducer"
import { PickPlus } from "../types/helpers"
import { errorSnack, successSnack } from "./Notistack/ThemedSnackbars"

type Props = {
  taskListItem: PickPlus<TaskListItem, "taskId" | "name" | "isComplete" | "userCount" | "assetCount">
  project: ProjectTasksListQuery["project"]
  taskGroupId?: TaskListItem["taskGroupId"]
  className?: string
  testLabel?: string
}

export const ToggleCompletionButton: FC<Props> = ({ taskListItem, project, taskGroupId, className, testLabel }) => {
  const { taskId, isComplete, name } = taskListItem
  const [, markTaskIncompleteMutation] = useMarkTaskAsIncompleteMutation()
  const markTaskCompleteWorkflow = useCompleteTaskWorkflow()
  const isProjectArchivedOrComplete = !!project?.isArchived || !!project?.isComplete
  const dispatch = useContext(TaskListDispatchContext)
  const { hasPermissionTo } = useContext(PermissionsContext)

  return (
    <>
      {isComplete ? (
        <Button
          disabled={isProjectArchivedOrComplete || !hasPermissionTo("task:reopen")}
          className="p-0"
          test-label={testLabel}
          onClick={() => {
            markTaskIncompleteMutation({ id: taskId! }).then((result) => {
              if (result.error) {
                console.error(result.error)
                errorSnack(`${name} could not be reopened. Please try again.`)
              } else {
                dispatch({
                  type: TASK_LIST_ACTION_TYPES.toggleTaskCompletion,
                  payload: { taskId, groupId: taskGroupId, isTaskComplete: false },
                })
              }
            })
          }}
          type="button"
        >
          <FaCheckCircle className={classNames("text-green-600", className)} />
        </Button>
      ) : (
        <Button
          disabled={isProjectArchivedOrComplete}
          className="p-0"
          test-label={testLabel}
          onClick={() => {
            markTaskCompleteWorkflow({
              taskListItem: { ...taskListItem, project: project },
              onSuccessCallback: () => {
                // ToggleCompletionButton has two potential parent components:
                // 1. tasks.tsx, which has TaskListDispatchContext as a provider. That provider contains 'dispatch'.
                // 2. _layout.tsx, which does not have the same provider, and as a result, does not have 'dispatch'.
                // This is a temporary solution until we can do some refactoring to make this more consistent.
                if (typeof dispatch === "function") {
                  dispatch({
                    type: TASK_LIST_ACTION_TYPES.toggleTaskCompletion,
                    payload: { taskId, groupId: taskGroupId, isTaskComplete: true },
                  })
                }
                successSnack("Success!")
              },
              onErrorCallback: () => {
                errorSnack(`${name} completion error; please try again`)
              },
            })
          }}
          type="button"
        >
          <FaRegCircle className={classNames("text-gray-400", className)} />
        </Button>
      )}
    </>
  )
}

import { Transition } from "@headlessui/react"
import { FC, useContext, useState } from "react"
import { gql, useQuery } from "urql"
import { classNames } from "../../../helpers/classNames"
import { TaskLayoutPageContent } from "../../../pages/projects/[_project_id]/[_task_id]/_layout"
import { TaskAssetsPageContents } from "../../../pages/projects/[_project_id]/[_task_id]/assets"
import { TaskSummaryPageContent } from "../../../pages/projects/[_project_id]/[_task_id]/summary"
import { useCurrentUser } from "../../../providers/PermissionsProvider/currentUserProvider"
import { PageTitle } from "../../PageTitle"
import { RenderIf } from "../../RenderIf"
import { TaskDetails } from "../Projects/TaskDetails"
import { TeamAssignments } from "../Projects/TeamList/TeamAssignments"
import { SingleDrawerContext } from "./Drawer"
import { DrawerBody } from "./DrawerBody"
import { DrawerHeader } from "./DrawerHeader"

type Props = {
  taskId: string
  refetch?: () => void
  tab?: TabValue
}

export type TabValue = "details" | "team" | "assets" | "summary"

const TaskDrawerQuery = gql`
  query TaskDrawerQuery($id: String!) {
    task(id: $id) {
      id
      groupId
      group {
        id
        name
      }
      organizationId
      projectId
      archived
      dateCreated
      description
      endDate
      estimatedHours
      completedHours
      hasReportableUnit
      isComplete
      isDefault
      metadata
      name
      startDate
      workersCompCode {
        id
        name
        ncciCode
      }
      assignees {
        id
        isCurrentAssignment
        projectId
        taskId
        userId
        project {
          id
          name
        }
        user {
          id
          firstName
          lastName
        }
      }
      project {
        id
        name
      }
      unitGoals {
        id
        deliverableUnitId
        targetQuantity
        isPrimary
        deliverableUnit {
          id
          description
          referenceNumber
          unitOfMeasure
        }
        unitGoalProgress {
          id
          progress
        }
      }
    }
  }
`

type Tab = {
  value: TabValue
  label: string
  permission: "task:read" | "task:read-summary"
}

const tabs: Tab[] = [
  {
    value: "details",
    label: "Details",
    permission: "task:read",
  },
  {
    value: "team",
    label: "Team",
    permission: "task:read",
  },
  {
    value: "assets",
    label: "Assets",
    permission: "task:read",
  },
  {
    value: "summary",
    label: "Summary",
    permission: "task:read-summary",
  },
]

export const TaskDrawer: FC<Props> = ({ taskId, refetch, tab = "details" }) => {
  const { handleClose } = useContext(SingleDrawerContext)
  const currentUser = useCurrentUser()
  const [currentTab, setCurrentTab] = useState(tab)
  const [{ data: taskData }, refetchTask] = useQuery({
    query: TaskDrawerQuery,
    variables: { id: taskId },
  })
  const task = taskData?.task

  if (!task) {
    return null
  }

  return (
    <>
      <DrawerHeader handleClose={handleClose} href={`/projects/${task.projectId}/${task.id}/${currentTab}`} />

      <DrawerBody>
        <TaskLayoutPageContent taskId={task.id} withTabs={false}>
          <div className="flex gap-6 overflow-x-scroll">
            {tabs.map((tab) => {
              const isSelected = tab.value === currentTab
              return (
                <RenderIf
                  key={tab.value}
                  permissionsInclude={tab.permission}
                  context={{ projectId: currentUser.projectId! }}
                >
                  <button
                    data-test={`taskDrawer:${tab.value}Tab`}
                    onClick={() => setCurrentTab(tab.value)}
                    className={classNames(
                      "relative my-2 pb-2 font-bold uppercase whitespace-nowrap",
                      "md:my-8",
                      !isSelected && "text-gray-400"
                    )}
                  >
                    {tab.label}

                    <Transition
                      appear={true}
                      show={true}
                      enter="transition ease-in-out duration-150 transform"
                      enterFrom="opacity-0 translate-y-full"
                      enterTo="opacity-100 translate-y-0"
                    >
                      <div
                        className={classNames(
                          "w-full relative top-1 h-1 transition-all rounded-sm",
                          isSelected ? "bg-blue-600" : "bg-transparent"
                        )}
                      />
                    </Transition>
                  </button>
                </RenderIf>
              )
            })}
          </div>

          {currentTab === "details" && <TaskDetails task={task} onSuccess={refetchTask} />}
          {currentTab === "team" && (
            <>
              <PageTitle title={`${task?.name || "Task"} team`} />
              <TeamAssignments project={task.project} task={task} onSuccess={refetch} />
            </>
          )}
          {currentTab === "assets" && (
            <>
              <PageTitle title={`${task?.name || "Task"} assets`} />
              <TaskAssetsPageContents onSuccess={refetch} />
            </>
          )}
          {currentTab === "summary" && (
            <>
              <PageTitle title={`${task?.name || "Task"} summary`} />
              <TaskSummaryPageContent taskId={task.id} />
            </>
          )}
        </TaskLayoutPageContent>
      </DrawerBody>
    </>
  )
}

import { Typography } from "@mui/material"
import { NextPage } from "next"
import { useRouter } from "next/router"
import { FC, useMemo, useState } from "react"
import { useQuery } from "urql"
import { DateRangeSelector } from "../../../../components/DateRangeSelector"
import { DateSelectAndRouteToQueryParam } from "../../../../components/DateSelectAndRouteToQueryParam"
import { PageTitle } from "../../../../components/PageTitle"
import { FilterGroupWithQueryStringParam } from "../../../../components/Partials/Summary/FilterGroupWithQueryStringParam"
import { SummaryImageSection } from "../../../../components/Partials/Summary/Sections/ProjectAndTaskSummaryImagesSection"
import { SummaryNotesSection as TaskSummaryNotesSection } from "../../../../components/Partials/Summary/Sections/ProjectAndTaskSummaryNotesSection"
import { ProjectAndTaskSummaryTeamTable } from "../../../../components/Partials/Summary/Sections/ProjectAndTaskSummaryTeamTable"
import { SummaryTimeline as TaskTimeline } from "../../../../components/Partials/Summary/Sections/ProjectAndTaskSummaryTimeline"
import { SummaryUnitsTable } from "../../../../components/Partials/Summary/Sections/ProjectAndTaskSummaryUnitsTable"
import { DateRangeFilterString, SummaryFilterType } from "../../../../components/Partials/Summary/types"
import { TaskOverviewWidgets } from "../../../../components/Widgets/SummaryWidgets/TaskOverviewWidgets"
import { graphql } from "../../../../graphql/generated/gql"
import { getBookendDates } from "../../../../helpers/getBookendDates"
import { useHandleError } from "../../../../hooks/useHandleError"
import { useCurrentUser } from "../../../../providers/PermissionsProvider/currentUserProvider"
import TaskLayout from "./_layout"

const TaskSummaryPageDocument = graphql(`
  query TaskSummaryPage($taskId: String!, $rangeEnd: DateTime) {
    task(id: $taskId) {
      id
      estimatedHours
      name
      startDate
      timeEntriesSumDurationInSeconds(rangeEnd: $rangeEnd)
      project {
        id
        name
      }
    }
  }
`)

export const TaskSummaryPageContent: FC<{
  taskId: string
  date?: string
}> = ({ taskId }) => {
  const { query } = useRouter()
  const date = query.date as string
  const [dateRangeType, setDateRangeType] = useState<DateRangeFilterString>("daily")

  const { organization } = useCurrentUser()
  const { rangeStart, rangeEnd } = useMemo(
    () => getBookendDates(date, organization?.timezone, dateRangeType),
    [date, organization?.timezone, dateRangeType]
  )

  const [{ data, error }] = useQuery({
    query: TaskSummaryPageDocument,
    variables: { taskId, rangeStart, rangeEnd },
  })

  const [filter, setFilter] = useState<SummaryFilterType>("all")

  const summaryFilterOptions = [
    { title: "All", value: "all" },
    { title: "Units", value: "units" },
    { title: "Team", value: "team" },
    { title: "Images", value: "images" },
    { title: "Notes", value: "notes" },
    { title: "Timeline", value: "timeline" },
  ]

  useHandleError(error, "An error was encountered loading data. Please reload the page to try again.")
  const projectId = data?.task.project.id
  return (
    projectId && (
      <>
        <PageTitle title={`${data?.task?.name || "Task"} summary`} />
        <header className="flex flex-col gap-y-5 pb-10">
          <Typography variant="h3">Task Summary</Typography>

          <div className="flex flex-row gap-5 flex-wrap">
            {<DateRangeSelector filter={dateRangeType} setFilter={setDateRangeType} />}

            <DateSelectAndRouteToQueryParam minDate={data?.task?.startDate} dateRangeType={dateRangeType} />
          </div>

          <TaskOverviewWidgets taskId={taskId} rangeStart={rangeStart} rangeEnd={rangeEnd} />

          <FilterGroupWithQueryStringParam
            defaultFilterValue="all"
            filter={filter}
            options={summaryFilterOptions}
            queryStringParameter="filter"
            setFilter={setFilter}
          />
        </header>
        <main className="flex flex-col gap-y-10 pb-28">
          {(filter === "all" || filter === "units") && (
            <SummaryUnitsTable
              dateRangeType={dateRangeType}
              entityType="task"
              entityId={taskId}
              rangeEnd={rangeEnd}
              rangeStart={rangeStart}
            />
          )}

          {projectId && (filter === "all" || filter === "team") && (
            <ProjectAndTaskSummaryTeamTable
              taskId={taskId}
              projectId={projectId}
              rangeEnd={rangeEnd}
              rangeStart={rangeStart}
              dateRangeType={dateRangeType}
            />
          )}

          {(filter === "all" || filter === "images") && (
            <SummaryImageSection
              taskId={taskId}
              projectId={projectId}
              rangeStart={rangeStart}
              rangeEnd={rangeEnd}
              dateRangeType={dateRangeType}
              filter={filter}
            />
          )}
          {(filter === "all" || filter === "notes") && (
            <TaskSummaryNotesSection
              taskId={taskId}
              projectId={projectId}
              rangeStart={rangeStart}
              rangeEnd={rangeEnd}
              dateRangeType={dateRangeType}
              filter={filter}
            />
          )}

          {(filter === "all" || filter === "timeline") && (
            <TaskTimeline
              taskId={taskId}
              projectId={projectId}
              rangeStart={rangeStart}
              rangeEnd={rangeEnd}
              filter={filter}
            />
          )}
        </main>
      </>
    )
  )
}

const SummaryPageWrapperWithOrg: NextPage = () => {
  const { query } = useRouter()

  const taskId = query._task_id as string

  return (
    <TaskLayout taskId={taskId}>
      <TaskSummaryPageContent taskId={taskId} />
    </TaskLayout>
  )
}

export default SummaryPageWrapperWithOrg

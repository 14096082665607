import { Button } from "@mui/material"
import { useField, useFormikContext } from "formik"
import { FC } from "react"
import { BiDislike, BiLike } from "react-icons/bi"
import { classNames } from "../../../helpers/classNames"

export const YesNoInput: FC<{
  name: string
  className?: string
  disabled?: boolean
  onStatusChange?: (status: boolean) => void
}> = ({ name, className, disabled, onStatusChange }) => {
  const { submitCount } = useFormikContext()
  const [{ value }, { error, touched }, { setValue }] = useField(name)
  const displayError = (touched && error && !disabled) || (submitCount > 0 && error)
  const handleStatusChange = (newStatus: boolean) => {
    setValue(newStatus)
    onStatusChange && onStatusChange(newStatus)
  }
  return (
    <div className={classNames("flex", className)}>
      <div className={classNames("flex gap-0.5 border-2 rounded", displayError ? "border-red-600" : "border-white")}>
        <Button
          disableRipple
          disableFocusRipple
          disableTouchRipple
          size="small"
          onClick={() => handleStatusChange(true)}
          className={classNames(
            "h-10 gap-x-2 flex justify-center items-center active:bg-gray-300 text-gray-800 ease-in-out transition-colors",
            value === true ? "bg-green-600 hover:bg-green-700" : "bg-gray-100 hover:bg-gray-200",
            displayError && "text-red-600"
          )}
          sx={{ minWidth: 0, width: 44, padding: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
        >
          <BiLike
            className={classNames("w-6 h-6", value === true ? "text-white" : "", displayError && "text-red-600")}
          />
        </Button>
      </div>
      <div className={classNames("flex gap-0.5 border-2 rounded ", displayError ? "border-red-600" : "border-white")}>
        <Button
          disableRipple
          disableFocusRipple
          disableTouchRipple
          size="small"
          onClick={() => handleStatusChange(false)}
          className={classNames(
            "h-10 gap-x-2 flex justify-center items-center active:bg-gray-300 text-gray-800 ease-in-out transition-colors",
            value === false ? "bg-orange-600 hover:bg-orange-700" : "bg-gray-100 hover:bg-gray-200",
            displayError && "text-red-600"
          )}
          sx={{ minWidth: 0, width: 44, padding: 0, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
        >
          <BiDislike
            className={classNames("w-6 h-6", value === false ? "text-white" : "", displayError && "text-red-600")}
          />
        </Button>
      </div>
    </div>
  )
}

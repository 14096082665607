import { Skeleton } from "@mui/material"
import { FC, useMemo } from "react"
import { BiCheck, BiChevronsUp, BiLoader } from "react-icons/bi"
import { useQuery } from "urql"
import { Task } from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { classNames } from "../../../helpers/classNames"
import { getFriendlyFloat } from "../../../helpers/getFriendlyFloat"
import { taskProductionRate } from "../../../helpers/taskProductionRate"
import { PickPlus } from "../../../types/helpers"
import { SummaryWidget } from "./SummaryWidget"

type TaskProps = {
  task?: PickPlus<Task, "id" | "estimatedHours"> & { unitGoals: PickPlus<Task["unitGoals"][0], "targetQuantity">[] }
  totalUnits: number | null | undefined
  totalClockedSeconds: number | null | undefined
}

export const TaskProductionRateWidget: FC<TaskProps> = ({ task, totalUnits, totalClockedSeconds }) => {
  const { expected, rate }: { expected: number; rate: number } = useMemo(() => {
    return taskProductionRate({
      clockedSeconds: totalClockedSeconds || 0,
      estimatedHours: task?.estimatedHours ?? undefined,
      unitGoals: task?.unitGoals || [],
      unitsDelivered: totalUnits || 0,
    })
  }, [task, totalUnits, totalClockedSeconds])

  const iconClassName = getProductionRateArrowClassName(rate)

  return (
    <SummaryWidget
      Icon={rate === 1 ? BiCheck : BiChevronsUp}
      iconClassNames={iconClassName}
      value={(rate && rate.toString()) || "-"}
      valueElement={<ProductionRateSpan rate={rate} />}
      extras={
        <div className="text-gray-400 text-xs">
          <div className="overflow-ellipsis font-xs">Expected Rate:</div>
          {!expected ? <Skeleton className="w-10 inline-block" /> : getFriendlyFloat(expected)} units/man-hour
        </div>
      }
    />
  )
}

type ProjectProductionRateWidgetProps = {
  projectId: string
  rangeStart: Date
  rangeEnd: Date
}

const ProjectProductionRateWidgetDocument = graphql(`
  query ProjectProductionRateWidget($projectId: String!, $rangeStart: DateTime!, $rangeEnd: DateTime!) {
    project(id: $projectId) {
      id
      productionRate(rangeStart: $rangeStart, rangeEnd: $rangeEnd)
      deliveredPrimaryGoalUnits(rangeStart: $rangeStart, rangeEnd: $rangeEnd)
      estimatedHours
      primaryGoalTargetQuantity
    }
  }
`)
export const ProductionRateSpan: FC<{ rate: number | undefined }> = ({ rate }) =>
  rate ? (
    <span
      className={classNames(rate > 1 && "text-green-600", rate === 1 && "text-blue-600", rate < 1 && "text-orange-600")}
    >
      {getFriendlyFloat(rate, 2)}
    </span>
  ) : (
    "-"
  )

export const getProductionRateArrowClassName = (rate: number | undefined) => {
  if (!rate) return "text-gray-400"

  if (rate > 1) {
    return "text-green-600"
  }

  if (rate < 1) {
    return "text-orange-600 rotate-180"
  }

  return "text-blue-600"
}

export const ProjectProductionRateWidget: FC<ProjectProductionRateWidgetProps> = ({
  projectId,
  rangeStart,
  rangeEnd,
}) => {
  const [{ data }] = useQuery({
    query: ProjectProductionRateWidgetDocument,
    variables: { projectId, rangeEnd, rangeStart },
  })

  const iconClassName = useMemo(() => getProductionRateArrowClassName(data?.project?.productionRate), [data?.project])

  const rate = data?.project?.productionRate

  const expected =
    data?.project?.primaryGoalTargetQuantity && data?.project.estimatedHours && data?.project.estimatedHours > 0
      ? data.project.primaryGoalTargetQuantity / data?.project.estimatedHours
      : Infinity

  return (
    <SummaryWidget
      Icon={!data ? BiLoader : BiChevronsUp}
      iconClassNames={iconClassName}
      value={(rate && rate.toString()) || "-"}
      valueElement={
        !data ? (
          <Skeleton variant="rectangular" width={150} height={30} />
        ) : (
          <span className="text-gray-400">
            <ProductionRateSpan rate={rate} />
          </span>
        )
      }
      description=""
      extras={
        <div className="text-gray-400 text-xs">
          <div className="overflow-ellipsis font-xs">Expected Rate:</div>
          {!expected ? <Skeleton className="w-10 inline-block" /> : getFriendlyFloat(expected)} units/man-hour
        </div>
      }
    />
  )
}

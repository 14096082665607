import { gql } from "urql"

export const TASK_CREATE = gql`
  mutation TaskCreate(
    $groupId: String
    $parentTaskId: String
    $projectId: String!
    $description: String
    $endDate: DateTime
    $estimatedHours: Float
    $metadata: [MetadataNoteInput!]
    $name: String!
    $startDate: DateTime
    $unitGoals: [UnitGoalInput!]
    $workersCompCodeId: String
    $schedule: ScheduleTemplateInput
    $scheduledBreaks: [ScheduledBreakInput!]
  ) {
    insertOneTask(
      groupId: $groupId
      parentTaskId: $parentTaskId
      projectId: $projectId

      description: $description
      endDate: $endDate
      estimatedHours: $estimatedHours
      metadata: $metadata
      name: $name
      startDate: $startDate
      unitGoals: $unitGoals
      workersCompCodeId: $workersCompCodeId
      schedule: $schedule
      scheduledBreaks: $scheduledBreaks
    ) {
      id
      groupId
      parentTaskId
      projectId

      dateCreated
      description
      endDate
      estimatedHours
      isDefault
      metadata
      name
      startDate
      sumOfDeliveredUnits
      unitGoalsSumProgress
      unitGoalsSumTargetQuantity
      workersCompCode {
        id
        name
        ncciCode
        ncciDescription
        override
        rate
      }
      unitGoals {
        id
      }
    }
  }
`

export const MARK_TASK_COMPLETE = gql`
  mutation MarkTaskAsComplete($id: String!) {
    markTaskCompletion(id: $id, completed: true) {
      ...TaskBase
      ...TaskAdditional
      isComplete
      groupId
      project {
        ...ProjectBase
        tasks {
          id
        }
      }
    }
  }
`

export const MARK_TASK_INCOMPLETE = gql`
  mutation MarkTaskAsIncomplete($id: String!) {
    markTaskCompletion(id: $id, completed: false) {
      ...TaskBase
      ...TaskAdditional
      isComplete
      groupId
      project {
        ...ProjectBase
        tasks {
          id
        }
      }
    }
  }
`

export const EDIT_TASK = gql`
  mutation TaskEdit(
    $id: String!
    $groupId: String
    $parentTaskId: String
    $projectId: String!
    $description: String
    $endDate: DateTime
    $estimatedHours: Float
    $isSubtask: Boolean
    $metadata: [MetadataNoteInput!]
    $name: String!
    $startDate: DateTime
    $workersCompCodeId: String
    $schedule: ScheduleTemplateInput
    $scheduledBreaks: [ScheduledBreakInput!]
  ) {
    updateOneTask(
      id: $id
      groupId: $groupId
      parentTaskId: $parentTaskId
      projectId: $projectId

      description: $description
      endDate: $endDate
      estimatedHours: $estimatedHours
      isSubtask: $isSubtask
      metadata: $metadata
      name: $name
      startDate: $startDate
      workersCompCodeId: $workersCompCodeId
      schedule: $schedule
      scheduledBreaks: $scheduledBreaks
    ) {
      ...TaskBase
      ...TaskAdditional
      parentTask {
        ...TaskBase
        ...TaskAdditional
      }
    }
  }
`

export const DELETE_TASK = gql`
  mutation DeleteTask($id: String!) {
    deleteOneTask(id: $id) {
      id
      name
    }
  }
`

export const DELETE_TASK_GROUP = `
  mutation DeleteTaskGroup($id: String!) {
    deleteOneTaskGroup(id: $id) {
      id
      name
    }
  }
`

export const REPORT_TASK_PROGRESS = gql`
  mutation ReportTaskProgress(
    $projectId: String!
    $taskId: String!
    $fileIds: [String!]
    $note: String
    $unitGoalProgress: [UnitGoalProgressInput!]
  ) {
    reportTaskProgress(
      projectId: $projectId
      taskId: $taskId
      fileIds: $fileIds
      note: $note
      unitGoalProgress: $unitGoalProgress
    ) {
      id
    }
  }
`

export const CREATE_MANY_TASK_REPORTS = gql`
  mutation CreateManyTaskReports($projectId: String!, $taskReports: [TaskReportInput!]!) {
    createManyTaskReports(projectId: $projectId, taskReports: $taskReports) {
      ...TaskBase
      unitGoals {
        ...UnitGoalBase
      }
    }
  }
`

import { GridRowSelectionModel, GridSortItem } from "@mui/x-data-grid-pro"
import { Dispatch, FC, ReactNode, SetStateAction, createContext, useState } from "react"
import { AssetNode } from "../components/Partials/Assets/DataGrid/AssetListDataGrid"
import { AssetCategory, AssetStatus, ListVisibilityFilter } from "../graphql/generated/client-types-and-hooks"
import { useListVisibilityFilter } from "../hooks/useListVisibilityFilter"

type ContextValue = {
  cursor: string | null
  setCursor: Dispatch<SetStateAction<string | null>>

  searchText: string | null
  setSearchText: Dispatch<SetStateAction<string | null>>

  resetList: boolean
  setResetList: Dispatch<SetStateAction<boolean>>

  nextCursor: string | null
  setNextCursor: Dispatch<SetStateAction<string | null>>

  rowsSelected: GridRowSelectionModel
  setRowsSelected: Dispatch<SetStateAction<GridRowSelectionModel>>

  selectedStatuses: AssetStatus[]
  selectStatus: (status: AssetStatus) => void
  deselectStatus: (status: AssetStatus) => void
  deselectAllStatuses: () => void

  selectedCategories: AssetCategory[]
  selectCategory: (status: AssetCategory) => void
  deselectCategory: (status: AssetCategory) => void
  deselectAllCategories: () => void

  isArchived: boolean
  setIsArchived: Dispatch<SetStateAction<boolean>>

  sort: GridSortItem | null | undefined
  setSort: Dispatch<SetStateAction<GridSortItem | null | undefined>>

  selectedAssets: AssetNode[]
  setSelectedAssets: Dispatch<SetStateAction<AssetNode[]>>

  loadedAssets: AssetNode[] | undefined
  setLoadedAssets: Dispatch<SetStateAction<AssetNode[] | undefined>>

  filterMenuOpen: boolean
  setFilterMenuOpen: Dispatch<SetStateAction<boolean>>

  visibilityFilter: ListVisibilityFilter
  setVisibilityFilter: Dispatch<SetStateAction<ListVisibilityFilter>>

  setSearchValue: (value: string | null) => void
  sortList: (value?: GridSortItem | null) => void
  replaceList: () => void
}

export const AssetListContext = createContext<ContextValue>({} as ContextValue)

export const AssetListProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [cursor, setCursor] = useState<string | null>(null)
  const [searchText, setSearchText] = useState<string | null>(null)
  const [resetList, setResetList] = useState(false)
  const [nextCursor, setNextCursor] = useState<string | null>(null)
  const [rowsSelected, setRowsSelected] = useState<GridRowSelectionModel>([])
  const [isArchived, setIsArchived] = useState(false)
  const [sort, setSort] = useState<GridSortItem | undefined | null>()
  const [selectedAssets, setSelectedAssets] = useState<AssetNode[]>([])
  const [loadedAssets, setLoadedAssets] = useState<AssetNode[]>()
  const [filterMenuOpen, setFilterMenuOpen] = useState(false)
  const [selectedStatuses, setSelectedStatuses] = useState<AssetStatus[]>([])
  const [selectedCategories, setSelectedCategories] = useState<AssetCategory[]>([])

  const [visibilityFilter, setVisibilityFilter] = useListVisibilityFilter("asset:read")

  const setSearchValue = (value: string | null) => {
    replaceList()
    setSearchText(value)
  }

  const sortList = (value?: GridSortItem | null) => {
    replaceList()
    setSort({ sort: value?.sort, field: "name" })
  }

  const replaceList = () => {
    setCursor(null)
    setNextCursor(null)
    setResetList(true)
  }

  const selectStatus = (status: AssetStatus) => {
    setSelectedStatuses((prev) => Array.from(new Set([...prev, status])))
  }

  const deselectStatus = (status: AssetStatus) => {
    setSelectedStatuses((prev) => prev.filter((s) => s !== status))
  }

  const deselectAllStatuses = () => {
    setSelectedStatuses([])
  }

  const selectCategory = (category: AssetCategory) => {
    setSelectedCategories((prev) => Array.from(new Set([...prev, category])))
  }

  const deselectCategory = (category: AssetCategory) => {
    setSelectedCategories((prev) => prev.filter((s) => s !== category))
  }

  const deselectAllCategories = () => {
    setSelectedCategories([])
  }

  return (
    <AssetListContext.Provider
      value={{
        cursor,
        setCursor,
        searchText,
        setSearchText,
        resetList,
        setResetList,
        nextCursor,
        setNextCursor,
        rowsSelected,
        setRowsSelected,
        selectedStatuses,
        selectStatus,
        deselectStatus,
        deselectAllStatuses,
        selectedCategories,
        selectCategory,
        deselectCategory,
        deselectAllCategories,
        isArchived,
        setIsArchived,
        sort,
        setSort,
        selectedAssets,
        setSelectedAssets,
        loadedAssets,
        setLoadedAssets,
        filterMenuOpen,
        setFilterMenuOpen,
        visibilityFilter,
        setVisibilityFilter,
        setSearchValue,
        sortList,
        replaceList,
      }}
    >
      {children}
    </AssetListContext.Provider>
  )
}

import { Autocomplete, Box, Checkbox, FormControlLabel, TextField, Typography } from "@mui/material"
import { useField } from "formik"
import { FC, SyntheticEvent, useState } from "react"
import { SelectableAsset } from "../../../../types/Asset"
import { AssetImage } from "../../../AssetImage"

type Props = {
  assets: SelectableAsset[]
  name: string
  onChange?: (selectedValues: string[]) => void
}

export const AssetsMultiSelect: FC<Props> = ({ assets = [], name }) => {
  const [searchText, setSearchText] = useState("")
  const [field, _meta, helpers] = useField(name)

  const handleChange = (_e: SyntheticEvent<Element, Event>, selectedValues: string[]) => {
    helpers.setValue(selectedValues)
  }

  return (
    <Autocomplete
      size="small"
      fullWidth
      multiple
      value={field.value}
      disableCloseOnSelect
      onChange={handleChange}
      noOptionsText="No assets available to assign"
      filterOptions={() => {
        return assets
          .filter(
            (asset) =>
              asset.name.toLowerCase().includes(searchText.toLowerCase()) ||
              asset.companyAssetNumber?.toLowerCase().includes(searchText?.toLowerCase())
          )
          .map((a) => a.id)
      }}
      autoHighlight={false}
      options={assets.map((a) => a.id)}
      results={field.value}
      groupBy={() => ""}
      renderGroup={(params) => (
        <li key={params.key}>
          <FormControlLabel
            className="w-full py-2 px-2 border-b m-0"
            control={
              <Checkbox
                checked={field.value.length === assets.length}
                sx={{ "&.PrivateSwitchBase-input": { width: 0, height: 0, padding: 0 } }}
              />
            }
            label={
              <Typography fontSize={14}>
                Select All <span className="text-gray-400 text-sm">({assets.length})</span>
              </Typography>
            }
            onClick={(e) => {
              e.preventDefault()
              if (field.value.length === assets.length) {
                helpers.setValue([])
              } else {
                const allAssetIds = assets.map((a) => a.id)
                helpers.setValue(allAssetIds)
              }
            }}
          />
          {params.children}
        </li>
      )}
      renderOption={(props, option) => {
        const asset = assets.find((a) => a.id === option)
        return (
          <Box paddingY="2px">
            {/* @ts-ignore */}
            <div className="flex" {...props}>
              {/* @ts-ignore */}
              <Checkbox
                checked={field.value.includes(option)}
                {...props}
                sx={{ "&.MuiCheckbox-root": { padding: 0, marginRight: 1 } }}
              />
              <AssetImage width="w-10 md:w-10" height="h-10 md:h-10" asset={asset} />
              <div className="pl-4">
                <Typography fontSize={14}>{asset?.name}</Typography>
                <Typography fontSize={14} className="text-gray-400">
                  {asset?.companyAssetNumber}
                </Typography>
              </div>
            </div>
          </Box>
        )
      }}
      renderTags={(value) => (
        <Typography paddingLeft={1}>{`(${value.length}) ${value.length > 1 ? "Assets" : "Asset"} selected`}</Typography>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select asset"
          onChange={(e) => setSearchText(e.target.value)}
          sx={{ padding: 0 }}
        />
      )}
    />
  )
}

import { Form, Formik } from "formik"
import { FC, useEffect, useState } from "react"
import { useQuery } from "urql"
import * as Yup from "yup"
import { useTaskEditMutation } from "../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../graphql/generated/gql"
import { Task } from "../../../../graphql/generated/gql/graphql"
import { useHandleError } from "../../../../hooks/useHandleError"
import { PickPlus } from "../../../../types/helpers"
import { ButtonFilled, ButtonHollow } from "../../../Elements"
import { ModalBody } from "../../../Modals/ModalBody"
import { ModalFooter } from "../../../Modals/ModalFooter"
import { DeprecatedDropdown, DropdownItem } from "../../../deprecated/Formik/Dropdown"

type Props = {
  task: PickPlus<Task, "id" | "name" | "projectId">
  onCancel: () => void
}

type Values = {
  parentTask: string
}

const ConvertTaskToSubTaskFormDocument = graphql(`
  query ConvertTaskToSubTaskForm($projectId: String!) {
    tasks(projectId: $projectId) {
      id
      isDefault
      isComplete
      name
      parentTaskId
      project {
        id
        isArchived
        name
      }
    }
  }
`)

export const ConvertTaskToSubTaskForm: FC<Props> = ({ task, onCancel }) => {
  const [, editParentTaskMutation] = useTaskEditMutation()
  const [{ data, error: tasksError }] = useQuery({
    query: ConvertTaskToSubTaskFormDocument,
    variables: { projectId: task.projectId! },
  })
  const [availableParentTasks, setAvailableParentTasks] = useState<DropdownItem[][]>([])

  useHandleError(tasksError, "Error fetching tasks")

  const buildDropdownItem = (task: PickPlus<Task, "id" | "name">, color?: string) => ({
    value: task.id,
    label: task.name,
    color,
  })

  useEffect(() => {
    const newAvailableParentTasks = [
      (data?.tasks || [])
        .filter(
          (filteredTask) =>
            !filteredTask.project.isArchived &&
            !filteredTask.isDefault &&
            !filteredTask.isComplete &&
            filteredTask.id !== task.id
        )
        .map((task) => buildDropdownItem(task)),
    ]

    return setAvailableParentTasks(newAvailableParentTasks)
  }, [task.id, data?.tasks])

  const onSubmit = async (values: Values) => {
    const parentTask = data?.tasks.find((projectTask) => projectTask.id === values.parentTask)

    if (parentTask) {
      editParentTaskMutation({
        id: task.id,
        isSubtask: true,
        name: task.name,
        parentTaskId: parentTask.id,
        projectId: task.projectId,
      }).then((_result) => onCancel())
    }
  }

  const initialValues = {
    parentTask: "",
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        parentTask: Yup.string().required("Task is required"),
      })}
    >
      {({ isSubmitting }) => (
        <Form className={"h-full flex flex-col"}>
          <ModalBody className={"flex flex-col gap-8"}>
            <p className="text-gray-400">Select a parent task for {task.name}</p>
            <DeprecatedDropdown
              items={availableParentTasks}
              name={"parentTask"}
              label={"Parent Task"}
              placeholderText={"Select parent task"}
            />
          </ModalBody>
          <ModalFooter>
            <ButtonHollow type="button" onClick={onCancel}>
              Cancel
            </ButtonHollow>
            <ButtonFilled type="submit" disabled={isSubmitting}>
              Save task
            </ButtonFilled>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  )
}

import { useContext } from "react"
import { ConfirmCompleteTaskModal } from "../components/Modals/ConfirmCompleteTaskModal"
import { CompleteTaskDrawer } from "../components/Partials/Drawer/CompleteTaskDrawer"
import {
  ProjectTasksListQuery,
  Task,
  TaskListItem,
  useMarkTaskAsCompleteMutation,
} from "../graphql/generated/client-types-and-hooks"
import { DrawerContext } from "../providers/DrawerProvider"
import { ModalContext } from "../providers/ModalProvider"
import { PickPlus } from "../types/helpers"
import { useModalProps } from "./useModalProps"

type TaskWorkflowParams = {
  taskListItem: PickPlus<TaskListItem, "taskId" | "name" | "isComplete" | "userCount" | "assetCount"> & {
    project: ProjectTasksListQuery["project"]
  }
  onSuccessCallback?: () => void
  onErrorCallback?: () => void
}

export const useCompleteTaskWorkflow = () => {
  const { push } = useContext(DrawerContext)
  const { showModal } = useContext(ModalContext)
  const modalProps = useModalProps("Complete task")
  const [, markTaskCompleteMutation] = useMarkTaskAsCompleteMutation()

  return ({ onSuccessCallback, onErrorCallback, taskListItem }: TaskWorkflowParams) => {
    const task: PickPlus<Task, "id" | "name"> = {
      id: taskListItem.taskId!,
      name: taskListItem.name,
    }
    const drawerProps = {
      onError: onErrorCallback,
      onSuccess: onSuccessCallback,
      task,
      project: taskListItem.project,
      assetCount: taskListItem.assetCount,
    }

    const modalData = {
      taskListItem,
      modalProps,
      onConfirm: () => {
        markTaskCompleteMutation({ id: task.id }).then((result) => {
          if (result.error) {
            console.error(result.error)
            onErrorCallback?.()
          } else {
            onSuccessCallback?.()
            modalProps.handleCloseModal()
          }
        })
      },
    }

    if (taskListItem.userCount > 0 || drawerProps.assetCount > 0) {
      push(<CompleteTaskDrawer {...drawerProps} />)
    } else {
      showModal(ConfirmCompleteTaskModal, modalData)
    }
  }
}

import { FC } from "react"
import { SkeletonElement } from "../../Skeletons/SkeletonElement"

export const UserBadgeSkeleton: FC = () => {
  return (
    <div className={"flex gap-x-4 items-center"}>
      <SkeletonElement className={"h-12 w-12"} />
      <div className={"flex flex-col justify-center gap-y-3"}>
        <SkeletonElement className={"h-5 w-44"} />
        <SkeletonElement className={"h-3 w-32"} />
      </div>
    </div>
  )
}

import { Box, Button, Step, StepButton, Stepper, Typography } from "@mui/material"
import { FC, useContext } from "react"
import { useQuery } from "urql"
import { graphql } from "../../../graphql/generated/gql"
import { AssetInspectionWizardContext } from "../../../providers/AssetInspectionWizardProvider"
import { InspectionReportForm } from "../Inspection/InspectionReportForm"

const NestedAssetInspectionsDocument = graphql(`
  query NestedAssetInspectionTemplates($assetId: String!) {
    nestedAssets(id: $assetId) {
      id
      name
      inspectionTemplatesAssignments {
        id
        inspectionTemplate {
          id
          assetsCount
          createdAt
          fields {
            id
            label
            photoRequired
            photoLabel
            rule
            failedStatus
            required
            type
          }
        }
      }
    }
  }
`)

export const InspectionReportDrawer: FC<{ assetId: string }> = ({ assetId }) => {
  const { activeStep, completed, isStepSkipped, handleStep, handleReset, steps, setSteps } =
    useContext(AssetInspectionWizardContext)

  const [{ data }] = useQuery({
    query: NestedAssetInspectionsDocument,
    variables: { assetId },
    pause: !assetId,
  })

  const nestedAssets = data?.nestedAssets

  if (!nestedAssets || !nestedAssets.length) {
    return null
  }

  const stepNames = nestedAssets.map((a) => a.name)

  if (stepNames?.length && !steps?.length) {
    setSteps(stepNames)
  }

  const template = nestedAssets?.[activeStep]?.inspectionTemplatesAssignments?.[0]?.inspectionTemplate

  return (
    <div className="py-2 px-3 md:px-8">
      <div className="py-5 px-1 md:px-0">
        <Typography variant="h4" fontSize={32} fontWeight={700}>
          Inspection Report <span className="text-gray-400">{steps[activeStep]}</span>
        </Typography>
      </div>
      <Box paddingTop={4} sx={{ width: "100%" }}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = { completed: completed[index] || false }
            const labelProps: {
              optional?: React.ReactNode
            } = {}
            if (isStepSkipped(index)) {
              stepProps.completed = false
            }
            return (
              <Step key={label} {...stepProps}>
                <StepButton {...labelProps} onClick={() => handleStep(index)} disabled={stepProps.completed}>
                  {label}
                </StepButton>
              </Step>
            )
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <>
            <Typography sx={{ mt: 2, mb: 1 }}>{`All steps completed - you're finished`}</Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </>
        ) : (
          <InspectionReportForm assetId={nestedAssets[activeStep].id} template={template} />
        )}
      </Box>
    </div>
  )
}

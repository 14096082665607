import { FC } from "react"
import { Project, Task } from "../../../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../../../types/helpers"
import { ToggleCompletionButton } from "../../ToggleCompletionButton"

type WrapperProps = {
  task: PickPlus<
    Task,
    "id" | "assetCount" | "isComplete" | "isDefault" | "name" | "parentTaskId" | "projectId" | "userCount"
  >
  project: PickPlus<Project, "id" | "isArchived" | "isComplete" | "name">

  className?: string
}

/**
 * This wrapper function exists to safely handle the discrepancy between the:
 * 'isComplete' boolean field in 'TaskListItem' and the 'isComplete' Date field in 'Task'
 * as well as the 'TaskId' field in 'TaskListItem' and the 'id' field in 'Task'
 * */
const ToggleCompletionButtonWrapper: FC<WrapperProps> = ({ task, project, className }) => {
  const adaptedTask = {
    ...task,
    isComplete: Boolean(task.isComplete),
    taskId: task.id,
  }

  return (
    <ToggleCompletionButton
      taskListItem={adaptedTask}
      project={project}
      className={className}
    />
  )
}

export default ToggleCompletionButtonWrapper

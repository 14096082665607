import { Masonry } from "@mui/lab"
import { Button } from "@mui/material"
import { format } from "date-fns"
import { FC, useContext } from "react"
import { useQuery } from "urql"
import {
  DeliverableUnit,
  UnitGoal,
  UnitGoalProgress,
  WorkersCompCode,
} from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { Task } from "../../../graphql/generated/gql/graphql"
import { useHandleError } from "../../../hooks/useHandleError"
import { useFlags } from "../../../providers/DevelopmentFeatureFlagProvider"
import { DrawerContext } from "../../../providers/DrawerProvider"
import { usePermissions } from "../../../providers/PermissionsProvider/PermissionsProvider"
import { MetadataNote } from "../../../types/MetadataNote"
import { PickPlus } from "../../../types/helpers"
import { H5 } from "../../Elements"
import { LabeledSection } from "../../LabeledSection"
import { PageTitle } from "../../PageTitle"
import { QuickMenuSectionTitle } from "../../QuickMenuSectionTitle"
import { ValueOrNoneLabel } from "../Assets/BasicInfo"
import { DrawerBody } from "../Drawer/DrawerBody"
import { ScheduleDetails } from "../Organizations/TabPanels/ScheduleSubPanels/ScheduleDetails"
import { CreateOrEditTaskForm } from "../Tasks/CreateOrEditTaskForm"
import { TaskQuickActions } from "../Tasks/TaskQuickActions"
import { DeliverableUnitSection } from "./DeliverableUnitSection"
import { TaskExtraAssignmentTable } from "./TaskExtraAssignmentTable"
import { TaskProgressBar } from "./TaskList/TaskProgressBar"

export type UnitGoalExpectation = PickPlus<UnitGoal, "id" | "isPrimary" | "targetQuantity" | "deliverableUnitId"> & {
  deliverableUnit: PickPlus<DeliverableUnit, "id" | "description" | "referenceNumber" | "unitOfMeasure">
} & {
  unitGoalProgress: PickPlus<UnitGoalProgress, "id" | "progress">[]
}
export type TaskExpectation = PickPlus<
  Task,
  | "id"
  | "description"
  | "endDate"
  | "estimatedHours"
  | "isComplete"
  | "isDefault"
  | "metadata"
  | "name"
  | "projectId"
  | "startDate"
  | "completedHours"
> & {
  unitGoals?: UnitGoalExpectation[]
  workersCompCode?: PickPlus<WorkersCompCode, "id" | "name" | "ncciCode"> | null
  group?: { id: string; name: string } | null
}

export type Props = {
  task: TaskExpectation & {
    parentTask?: TaskExpectation
  }
  onSuccess?: () => void
}

const TaskScheduleDetails = graphql(`
  query TaskScheduleDetailsQuery($id: String!) {
    task(id: $id) {
      id
      group {
        id
        name
      }
      scheduleTemplate {
        id
        isDefault
        workDays {
          label
          active
        }
        workHours {
          hours
          startTime
          endTime
        }
        nonWorkDays {
          id
          name
          dateRange
          active
        }
      }

      scheduledBreaks {
        id
        durationInMinutes
        isActive
        localizedStartTime
        name
        breakTaskId
        breakTask {
          id
          name
        }
      }
      startDate
      endDate
      estimatedHours
    }
  }
`)

export const TaskDetails: FC<Props> = ({ task, onSuccess }) => {
  const { push: pushDrawer, pop: handleClose } = useContext(DrawerContext)
  const { hasPermissionTo } = usePermissions()
  const { flagIsEnabled } = useFlags()

  const [{ data, error }] = useQuery({
    query: TaskScheduleDetails,
    variables: { id: task.id },
    pause: !task?.id,
  })

  useHandleError(error, "There was an error retrieving task details.")

  const scheduleTemplate = data?.task?.scheduleTemplate || undefined
  const scheduledBreaks = data?.task?.scheduledBreaks

  return (
    <>
      <PageTitle title={`${task?.name || "Task"} details`} />
      {task?.id && (
        <>
          <div className="pb-12">
            <H5>Quick Actions</H5>
            <hr />
            <TaskQuickActions task={task} />
          </div>

          <div className="pb-12">
            <H5>Task Progress</H5>
            <hr className="mb-6" />
            <TaskProgressBar drawer task={task} />
          </div>
        </>
      )}

      <QuickMenuSectionTitle isEditing={false} title="Task Notes" permission="task:update" />
      <Masonry columns={{ xs: 2, sm: 3, md: 3, lg: 4 }} spacing={2} defaultHeight={450} defaultColumns={4}>
        {(task.metadata || []).map((note: MetadataNote) => (
          <div key={note.label} className={"p-4 m-2 bg-yellow-100 shadow-md flex-column rounded-md"}>
            <p className={"mb-1 text-gray-600 font-bold text-xs uppercase"}>{note.label}</p>
            <div className="font-regular text-black">{note.content}</div>
          </div>
        ))}
      </Masonry>

      {(task.metadata || []).length === 0 && (
        <div className="mb-12">
          <span className="text-gray-500">None</span>
        </div>
      )}

      <div className="flex justify-between items-center min-h-[40px]">
        <H5 className="mt-0 mb-0">Basic Info</H5>
        {hasPermissionTo("task:update") && (
          <Button
            disabled={task.isDefault}
            variant="text"
            onClick={() =>
              pushDrawer(
                <div>
                  <DrawerBody>
                    <CreateOrEditTaskForm
                      task={task}
                      onCancel={handleClose}
                      onSuccess={handleClose}
                      group={task.group || undefined}
                    />
                  </DrawerBody>
                </div>
              )
            }
          >
            Edit
          </Button>
        )}
      </div>
      <hr className="mt-2 mb-4 md:mb-6" />

      <div className="md:grid md:grid-cols-2 md:gap-4">
        <LabeledSection label="Task name">{task.name}</LabeledSection>
        {!flagIsEnabled("Org Scheduling") && (
          <LabeledSection label="Start & End Date">
            {task.startDate && task.endDate ? (
              `${format(new Date(task.startDate), "P")} - ${format(new Date(task.endDate), "P")}`
            ) : (
              <span className="text-gray-500">None</span>
            )}
          </LabeledSection>
        )}
        <LabeledSection label="Parent Task">
          <ValueOrNoneLabel value={task.parentTask?.name} />
        </LabeledSection>

        <LabeledSection label="Estimated Hours">
          <ValueOrNoneLabel value={task.estimatedHours} />
        </LabeledSection>
        <LabeledSection label="Description" className="col-span-2 whitespace-pre-line">
          <ValueOrNoneLabel value={task.description} />
        </LabeledSection>
      </div>

      {flagIsEnabled("Org Scheduling") && (
        <section className="pt-12 grid gap-y-6">
          <div className="flex justify-between items-center border-b">
            <H5 className="mt-0 mb-0">Schedule</H5>
            {hasPermissionTo("task:update") && (
              <Button
                disabled={task.isDefault}
                variant="text"
                onClick={() =>
                  pushDrawer(
                    <div>
                      <DrawerBody>
                        <CreateOrEditTaskForm
                          task={task}
                          onCancel={handleClose}
                          onSuccess={handleClose}
                          group={task.group || undefined}
                        />
                      </DrawerBody>
                    </div>
                  )
                }
              >
                Edit
              </Button>
            )}
          </div>

          <div className="md:grid md:grid-cols-2 md:gap-4">
            <ScheduleDetails
              schedule={scheduleTemplate}
              scheduledBreaks={scheduledBreaks || []}
              startDate={task.startDate}
              endDate={task.endDate}
              estimatedHours={task.estimatedHours}
              showDaysDatesAndManHours
            />
          </div>
        </section>
      )}

      {!task.isDefault && (
        <>
          <DeliverableUnitSection
            isPrimary={true}
            onSuccess={onSuccess}
            task={task}
            taskEstimatedHours={task.estimatedHours || 0}
            title="Reporting Units"
          />

          <DeliverableUnitSection
            isPrimary={false}
            onSuccess={onSuccess}
            task={task}
            taskEstimatedHours={task.estimatedHours || 0}
            title="Additional Units"
          />
        </>
      )}
      <TaskExtraAssignmentTable taskId={task.id} projectId={task.projectId} />
    </>
  )
}

import { Button } from "@mui/material"
import { format } from "date-fns"
import { FC, useContext, useState } from "react"
import { BiCamera, BiLayer, BiListUl, BiTimeFive } from "react-icons/bi"
import { useQuery } from "urql"
import { useClockInMutation } from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { toastClockInError } from "../../../helpers/toastClockInError"
import { uploadTimeEntryEvidence } from "../../../helpers/uploadTimeEntryEvidence"
import { useHandleError } from "../../../hooks/useHandleError"
import { GeolocationProviderContext } from "../../../providers/GeolocationProvider"
import { StartEvidence } from "../../../types/TimeEntryEvidence"
import { User } from "../../../types/User"
import { PickPlus } from "../../../types/helpers"
import { MuiModal } from "../../Modals/MuiModal"
import { PhotoBooth } from "../../PhotoBooth"
import { ModalTitle } from "./ModalTitle"
import { RecapRow } from "./RecapRow"

type Props = {
  user: PickPlus<User, "id" | "firstName" | "lastName" | "projectId" | "taskId">
  onSuccess?: () => void
  className?: string
  disabled?: boolean
}

const ConfirmClockInModalBodyQuery = graphql(`
  query ConfirmClockInModalBodyQuery($projectId: String!, $taskId: String!) {
    project(id: $projectId) {
      id
      name
    }
    task(id: $taskId) {
      id
      name
      parentTask {
        id
        name
      }
    }
  }
`)

export const ClockInWithEvidence: FC<Props> = ({ user, disabled, onSuccess }) => {
  const [photo, setPhoto] = useState<Blob>()
  const [photoBoothIsOpen, setPhotoBoothIsOpen] = useState(false)
  const [clockInRecapModalIsOpen, setClockInRecapModalIsOpen] = useState(false)

  const onAcceptPhoto = (image: Blob) => {
    setPhoto(image)
    setClockInRecapModalIsOpen(true)
  }

  const [{ data: queryData, error }] = useQuery({
    query: ConfirmClockInModalBodyQuery,
    variables: {
      projectId: user.projectId!,
      taskId: user.taskId!,
    },
    pause: !clockInRecapModalIsOpen,
  })
  useHandleError(error, "Failed to get project.")

  const [isLoading, setIsLoading] = useState(false)
  const [_, clockInMutation] = useClockInMutation()

  // TODO: We should determine how to more effectively communicate around errors in the location collection
  const { location, locationError } = useContext(GeolocationProviderContext)

  const onClockInWithPhotoAndLocation = async () => {
    if (photo) {
      setIsLoading(true)
      const imagePath = await uploadTimeEntryEvidence(photo, user.id)

      if (imagePath) {
        const startEvidence: StartEvidence = {
          location,
          locationError: locationError?.message || undefined,
          imagePath,
        }

        clockInMutation({
          taskId: user.taskId!,
          userId: user.id,
          startEvidence,
        }).then((result) => {
          setIsLoading(false)
          setClockInRecapModalIsOpen(false)

          if (result.error) {
            toastClockInError(result.error, user)
          } else {
            onSuccess?.()
          }
        })
      }
    }
  }

  return (
    <>
      <Button
        disabled={disabled}
        color="primary"
        variant="contained"
        fullWidth
        sx={{
          padding: "0.375rem",
          display: "flex",
          alignItems: "center",
          minWidth: "120px",
          width: { md: "150px" },
          justifyContent: "center",
        }}
        onClick={() => setPhotoBoothIsOpen(true)}
      >
        <span>Clock in</span>
      </Button>
      <PhotoBooth
        title={<ModalTitle user={user} type="In" />}
        isOpen={photoBoothIsOpen}
        setIsOpen={setPhotoBoothIsOpen}
        onAcceptPhoto={onAcceptPhoto}
        onFail={(message) => {
          console.error(message)
        }}
        withFaceFrame={true}
      />
      <MuiModal
        isOpen={clockInRecapModalIsOpen}
        isLoading={isLoading}
        contentLabel={<ModalTitle user={user} type="In" />}
        handleCloseModal={() => {
          setClockInRecapModalIsOpen(false)
        }}
        submitForm={onClockInWithPhotoAndLocation}
        submitButtonText="Clock In"
      >
        <div className="flex flex-col gap-2">
          <RecapRow Icon={BiCamera} image={photo}>
            ✓ Time entry photo
          </RecapRow>
          <RecapRow Icon={BiLayer}>{queryData?.project?.name}</RecapRow>
          <RecapRow Icon={BiListUl}>
            {queryData?.task.parentTask ? queryData?.task.parentTask.name : queryData?.task.name}
          </RecapRow>
          <RecapRow Icon={BiTimeFive}>{format(new Date(), "h:mm aaa")}</RecapRow>
          <p className="text-gray-400 text-sm md:mb-20">
            Clock in {user.firstName} {user.lastName} on {format(new Date(), "ccc, MMMM yyyy")} at{" "}
            {format(new Date(), "h:mm aaa")}.
          </p>
        </div>
      </MuiModal>
    </>
  )
}

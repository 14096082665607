import { Box, Typography } from "@mui/material"
import { Form, Formik, FormikHelpers } from "formik"
import { FC } from "react"
import { useQuery } from "urql"
import * as Yup from "yup"
import {
  CreateUserAssignmentMutationVariables,
  DeleteUserAssignmentMutationVariables,
  UserAssignment,
  useBulkUpdateUserAssignmentsMutation,
} from "../graphql/generated/client-types-and-hooks"
import { graphql } from "../graphql/generated/gql"
import { ModalProps } from "../hooks/useModalProps"
import { PickPlus } from "../types/helpers"
import { MuiMultiSelect } from "./Formik/MultiSelect/MuiMultiSelect"
import { Switch } from "./Formik/Switch"
import { UserSelect } from "./Formik/UserSelect"
import { MuiModal } from "./Modals/MuiModal"
import { errorSnack, successSnack } from "./Notistack/ThemedSnackbars"

const placeholder = {
  id: "placeholder",
  value: "",
  disabled: true,
  searchableTextString: "",
}

export type UserAssignmentUpdateCandidate = PickPlus<
  UserAssignment,
  "id" | "projectId" | "taskId" | "userId" | "isCurrentAssignment"
>

export const projectPlaceholder = { ...placeholder, label: "Loading project options..." }
export const taskPlaceholder = { ...placeholder, label: "Please select a project first" }
export const userPlaceholder = { ...placeholder, label: "Loading..." }

export const CreateOrUpdateUserAssignmentFormQueryDocument = graphql(`
  query CreateOrUpdateUserAssignmentForm($after: String, $first: Int) {
    activeProjects {
      id
      name
      tasks {
        id
        name
      }
    }
    users(after: $after, first: $first) {
      edges {
        node {
          id
          firstName
          lastName
          jobTitle
          currentProjectId
          currentTaskId
          projectId
          taskId
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`)

type UserAssignmentFormValues = {
  id?: string
  allowFullProjectAccess: boolean
  projectId: [string]
  taskId?: string[] | null
  userId: string
}

export const CreateOrUpdateUserAssignmentForm: FC<{
  contextUserAssignments?: UserAssignmentUpdateCandidate[] | null
  preassignments: {
    projectId?: string
    taskId?: string
    userId?: string | null
  }
  userAssignments?: UserAssignmentUpdateCandidate[] | null
  modalProps: ModalProps
  refetch?: () => void
}> = ({ contextUserAssignments, preassignments, userAssignments, modalProps, refetch }) => {
  const [{ data }] = useQuery({
    query: CreateOrUpdateUserAssignmentFormQueryDocument,
  })

  const [{ fetching: bulkUpdateUserAssignmentFetching }, bulkUpdateUserAssignmentMutation] =
    useBulkUpdateUserAssignmentsMutation()

  const onSubmit = async (values: UserAssignmentFormValues) => {
    if (userAssignments) {
      // existing user assignments
      const existingAssignmentTaskIds =
        userAssignments
          .filter((userAssignment) => userAssignment.taskId)
          .map((userAssignment) => userAssignment.taskId!) || []

      // find assignments to create
      let taskAssignmentsToCreate: CreateUserAssignmentMutationVariables[] = []

      // create a "full project" assignment if there isn't one already
      if (
        values.allowFullProjectAccess &&
        userAssignments.filter((userAssignment) => userAssignment.taskId === "").length === 0
      ) {
        taskAssignmentsToCreate.push({
          projectId: values.projectId[0],
          taskId: null,
          userId: values.userId,
        })
      }

      // create new task assignments if they don't already exist
      if (!values.allowFullProjectAccess) {
        taskAssignmentsToCreate = (
          values.taskId?.filter((taskId) => !existingAssignmentTaskIds?.includes(taskId)) || []
        ).map((taskId) => {
          return {
            projectId: values.projectId[0],
            taskId,
            userId: values.userId,
          }
        })
      }

      // find assignments to delete
      const assignmentsToDelete = userAssignments.reduce((acc, userAssignment) => {
        const shouldDelete = values.allowFullProjectAccess
          ? userAssignment.taskId && !userAssignment.isCurrentAssignment
          : (!userAssignment.taskId || !values.taskId?.includes(userAssignment.taskId)) &&
            !userAssignment.isCurrentAssignment

        if (!shouldDelete) {
          return acc
        }

        return [...acc, { id: userAssignment.id }]
      }, [] as DeleteUserAssignmentMutationVariables[])

      bulkUpdateUserAssignmentMutation({
        assignmentsToDelete: assignmentsToDelete.map((assignment) => assignment.id),
        assignmentsToCreate: taskAssignmentsToCreate,
      }).then((result) => {
        if (result.error) {
          errorSnack("Error updating user assignment")
        } else {
          successSnack("Update successful")
          modalProps.handleCloseModal()
          if (refetch) refetch()
        }
      })
    } else {
      // find assignments to create
      let taskAssignmentsToCreate = values.allowFullProjectAccess
        ? [
            {
              projectId: values.projectId[0],
              taskId: null,
              userId: values.userId,
            },
          ]
        : values.taskId
            ?.filter((taskId) => {
              // we don't want to recreate assignments that already exist
              return !contextUserAssignments?.find(
                (contextUserAssignment) =>
                  contextUserAssignment.taskId === taskId && contextUserAssignment.userId === values.userId
              )
            })
            ?.map((taskId) => {
              return {
                projectId: values.projectId[0],
                taskId,
                userId: values.userId,
              }
            })

      const assignmentsForCurrentUser =
        contextUserAssignments?.filter((contextUserAssignment) => contextUserAssignment.userId === values.userId[0]) ||
        []

      const assignmentsToDelete: DeleteUserAssignmentMutationVariables[] =
        assignmentsForCurrentUser?.filter((contextUserAssignment) => {
          // delete all assignments if the user is being assigned to a full project
          if (values.allowFullProjectAccess) {
            return !!contextUserAssignment.taskId
          }

          // delete assignments that are not in the new list of assignments
          return !contextUserAssignment.taskId
        }) || []

      bulkUpdateUserAssignmentMutation({
        assignmentsToDelete: assignmentsToDelete.map((assignment) => assignment.id),
        assignmentsToCreate: taskAssignmentsToCreate,
      }).then((result) => {
        if (result.error) {
          errorSnack("Error updating user assignment")
        } else {
          successSnack("Update successful")
          modalProps.handleCloseModal()
          if (refetch) refetch()
        }
      })
    }
  }

  const taskIds = userAssignments
    ?.filter((userAssignment) => userAssignment.taskId)
    .map((userAssignment) => userAssignment.taskId!)
  const userId = userAssignments?.at(0)?.userId || ""
  const allowFullProjectAccess = userAssignments?.some((userAssignment) => !userAssignment.taskId) || false
  const projectId = userAssignments?.at(0)?.projectId || ""

  const initialValues: UserAssignmentFormValues = userAssignments
    ? {
        projectId: [projectId],
        taskId: taskIds,
        userId: userId,
        allowFullProjectAccess,
      }
    : {
        projectId: preassignments.projectId ? [preassignments.projectId] : [projectPlaceholder.value],
        taskId: preassignments.taskId ? [preassignments.taskId] : [],
        userId: preassignments.userId ? preassignments.userId : userPlaceholder.value,
        allowFullProjectAccess: preassignments.taskId ? !preassignments.taskId : false,
      }

  const updateTasksBasedOnSelection = (
    value: string[],
    setFieldValue: FormikHelpers<UserAssignmentFormValues>["setFieldValue"],
    type: "projectId" | "userId"
  ) => {
    if (!contextUserAssignments) return

    // check if the user is already assigned to the project
    const existingAssignments =
      contextUserAssignments?.filter((userAssignment) => userAssignment[type] === value.at(0)) || []

    if (existingAssignments.length > 0) {
      setFieldValue(
        "allowFullProjectAccess",
        existingAssignments.some((userAssignment) => !userAssignment.taskId)
      )

      setFieldValue("taskId", existingAssignments?.map((ua) => ua.taskId)?.filter(Boolean))
    } else {
      // If we are already preassigned, we don't want to do anything to mutate that now
      if (preassignments.taskId) return
      setFieldValue("allowFullProjectAccess", true)
      setFieldValue("taskId", [])
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        userId: Yup.string().required().label("User"),
        projectId: Yup.array().length(1).of(Yup.string()).required().label("Project"),
        allowFullProjectAccess: Yup.boolean(),
        taskId: Yup.array().when("allowFullProjectAccess", {
          is: false,
          then: (schema) => schema.min(1, "Must choose at least one task").of(Yup.string()).required().label("Task"),
        }),
      })}
      onSubmit={onSubmit}
    >
      {({ submitForm, setFieldValue, resetForm, values }) => (
        <MuiModal
          {...modalProps}
          isOpen={modalProps.isOpen}
          isLoading={bulkUpdateUserAssignmentFetching}
          contentLabel={`${userAssignments ? "Edit" : "Add"} User Access`}
          submitForm={submitForm}
          submitButtonText={`${userAssignments ? "Save" : "Add"} Access`}
          handleCloseModal={() => {
            modalProps.handleCloseModal()
            resetForm()
            if (refetch) refetch()
          }}
        >
          <Form>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem", minHeight: "200px" }}>
              {preassignments.userId ? (
                <>
                  <Typography>To assign project and task access, please select a project first.</Typography>
                  <hr />
                </>
              ) : preassignments.taskId ? (
                <>
                  <Typography>To assign task access, please select one team member.</Typography>
                  <hr />
                </>
              ) : (
                <>
                  <Typography>To assign project and task access, please select one user.</Typography>
                  <hr />
                </>
              )}

              {!preassignments.userId && (
                <UserSelect
                  name="userId"
                  label="Team Member"
                  onChangeNotify={(val) => updateTasksBasedOnSelection(val, setFieldValue, "userId")}
                />
              )}

              {!(preassignments.projectId || preassignments.taskId) && (
                <MuiMultiSelect
                  containerClassName="flex-1"
                  label="Project"
                  name="projectId"
                  selectedLabel="Project"
                  options={
                    data?.activeProjects.map((p) => ({
                      id: p.id,
                      label: p.name,
                      searchableTextString: p.name,
                      value: p.id,
                    })) || [projectPlaceholder]
                  }
                  onChange={(value) => {
                    updateTasksBasedOnSelection(value, setFieldValue, "projectId")
                  }}
                  required
                  isSingleSelect
                  withErrorHandling
                />
              )}

              {!preassignments.taskId && <Switch name="allowFullProjectAccess" label="Allow full project access" />}

              {!(values.allowFullProjectAccess || preassignments.taskId) && (
                <MuiMultiSelect
                  containerClassName="flex-1"
                  label="Task"
                  name="taskId"
                  selectedLabel="Task"
                  options={
                    values.projectId[0] === projectPlaceholder.value
                      ? [taskPlaceholder]
                      : (data?.activeProjects || [])
                          .find((p) => p.id === values.projectId[0])
                          ?.tasks.map((t) => ({
                            disabled:
                              !!userAssignments?.find(
                                (userAssignment) => userAssignment.taskId === t.id && userAssignment.isCurrentAssignment
                              ) ||
                              !!contextUserAssignments?.find(
                                (userAssignment) => userAssignment.taskId === t.id && userAssignment.isCurrentAssignment
                              ),
                            id: t.id,
                            label: t.name,
                            searchableTextString: t.name,
                            value: t.id,
                          })) || []
                  }
                  withErrorHandling
                  withSelectAll
                />
              )}
            </Box>
          </Form>
        </MuiModal>
      )}
    </Formik>
  )
}

import { Button, Skeleton } from "@mui/material"
import { Form, Formik } from "formik"
import { FC, useContext } from "react"
import { CombinedError, useQuery } from "urql"
import * as Yup from "yup"
import { Maybe, useUpdateTaskGroupMutation } from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { useHandleError } from "../../../hooks/useHandleError"
import { ButtonFilled, H2, H5 } from "../../Elements"
import { FormRow } from "../../FormRow"
import { TextField } from "../../Formik/TextField"
import { errorSnack, successSnack } from "../../Notistack/ThemedSnackbars"
import { DrawerFooter } from "./DrawerFooter"
import { SingleDrawerContext } from "./Drawer"
import { DrawerBody } from "./DrawerBody"
import { DrawerHeader } from "./DrawerHeader"

const TaskGroupEditDrawerDcoument = graphql(`
  query TaskGroupEditDrawer($id: String!) {
    taskGroup(id: $id) {
      id
      completedTaskCount
      description
      name
      notes
      projectId
      taskCount
    }
  }
`)

type Values = {
  description?: Maybe<string>
  notes: string
  name: string
  taskType: string
}

type Props = {
  taskGroupId: string
  onSuccess?: (arg0?: any) => void
}

const validationSchema = Yup.object().shape({
  taskType: Yup.string(),
  name: Yup.string().trim().required("Required").label("Task Name"),
  description: Yup.string().nullable(),
  workersCompCodeId: Yup.string().nullable(),
  metadata: Yup.array(
    Yup.object().shape({
      content: Yup.string().trim(),
      label: Yup.string().trim(),
    })
  ),
})

export const TaskGroupEditDrawer: FC<Props> = ({ taskGroupId, onSuccess }) => {
  const { handleClose } = useContext(SingleDrawerContext)
  const [{ error: updateTaskGroupError }, updateTaskGroup] = useUpdateTaskGroupMutation()
  const [{ data: taskGroupQuery, fetching: taskGroupIsLoading }] = useQuery({
    query: TaskGroupEditDrawerDcoument,
    variables: { id: taskGroupId },
  })

  const taskGroup = taskGroupQuery?.taskGroup

  useHandleError(updateTaskGroupError, "There was an error saving the task group details.")

  if (taskGroupIsLoading || !taskGroup) {
    return <Skeleton />
  }

  const handleError = (error: CombinedError | undefined, message: string) => {
    errorSnack(message)
    console.error(error)
  }

  const handleSuccess = (message: string) => {
    successSnack(message)
    onSuccess?.()
    handleClose()
  }

  const onSubmit = async (values: Values) => {
    updateTaskGroup({
      id: taskGroupId,
      ...values,
    }).then((result) => {
      if (result.error) {
        return handleError(result.error, "There was an error saving the summary task details.")
      }
      handleSuccess("Updated summary task details.")
    })
  }

  const initialValues: Values = {
    ...taskGroup,
    description: taskGroup.description || "",
    notes: taskGroup.notes || "",
    name: taskGroup.name || "",
    taskType: "summary-task",
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ isSubmitting }) => {
        return (
          <Form>
            <DrawerHeader
              handleClose={handleClose}
              href={`/projects/${taskGroup?.projectId}/group/${taskGroup?.id}/details`}
            />

            <DrawerBody>
              <H2 className="mb-6 text-gray-800">Edit Summary Task</H2>

              <H5 className="mt-10 mb-0">Basic Info</H5>
              <hr className="mt-2 mb-4 md:mb-6" />

              <FormRow>
                <TextField fullWidth name="name" label="Task name" />
              </FormRow>

              <FormRow>
                <TextField fullWidth name="description" label="Task description" multiline rows={4} />
              </FormRow>

              <H5 className="mt-10 mb-0">Task Notes</H5>
              <hr className="mt-2 mb-4 md:mb-6" />

              <DrawerFooter>
                <div className="col-span-12 flex justify-start flex-col md:flex-row gap-4 md:gap-6 md:max-w-md ">
                  <ButtonFilled color="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting ? "Saving" : "Update"}
                  </ButtonFilled>
                  <Button variant="text" type="button" onClick={handleClose} size="large">
                    Cancel
                  </Button>
                </div>
              </DrawerFooter>
            </DrawerBody>
          </Form>
        )
      }}
    </Formik>
  )
}

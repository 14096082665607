import { FC } from "react"
import { BiStopwatch } from "react-icons/bi"
import { getHoursAndMinutesFromSeconds } from "../../../helpers/getHoursAndMinutesFromSeconds"
import { StackedHorizontalBarChart } from "../../StackedHorizontalBarChart"
import { SummaryWidget } from "./SummaryWidget"
import { HOURS_ACTIVE_COLORS, HOURS_INACTIVE_COLORS, createSource } from "./WidgetSourceColors"

type Props = {
  estimatedHours?: number
  currentSeconds: number
  previousSeconds: number
  completedHours: number
  isLoading: boolean
}

export const HoursClockedWidget: FC<Props> = ({
  estimatedHours = 0,
  currentSeconds,
  previousSeconds,
  completedHours,
}) => {
  const currentHours = currentSeconds / 3600
  const previousHours = previousSeconds / 3600
  const recentHours = completedHours - previousHours - currentHours
  const sources =
    currentHours === 0
      ? [createSource(previousHours + recentHours, HOURS_INACTIVE_COLORS)]
      : [
          createSource(previousHours, HOURS_INACTIVE_COLORS),
          createSource(currentHours, HOURS_ACTIVE_COLORS),
          createSource(recentHours, HOURS_INACTIVE_COLORS),
        ]
  const { hours, minutes } = getHoursAndMinutesFromSeconds(currentSeconds)

  return (
    <SummaryWidget
      Icon={BiStopwatch}
      value={`${hours}h ${minutes}m`}
      description={"time"}
      extras={<StackedHorizontalBarChart sources={sources} expectedMaximum={estimatedHours} />}
    />
  )
}

import { Box, Button, MenuItem, Select } from "@mui/material"
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro"
import { FC, useReducer } from "react"
import { useQuery } from "urql"
import { Project, useReassignUserMutation } from "../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../graphql/generated/gql"
import { CompleteTaskReassignUsersQuery } from "../../../../graphql/generated/gql/graphql"
import { colors } from "../../../../helpers/colors"
import { useHandleError } from "../../../../hooks/useHandleError"
import { errorSnack } from "../../../Notistack/ThemedSnackbars"
import { UserBadge } from "../../../UserBadge"
import { TaskCompletionWorkflowSkeleton } from "../../Projects/Tasks/TaskCompletionWorkflow.skeleton"

export const testLabel_CompleteTaskReassignUsersProjectSelect = "complete-task-reassign-users-project-select"
export const testLabel_CompleteTaskReassignUserTaskSelect = "complete-task-reassign-user-task-select"
export const testLabel_CompleteTaskReassignUsersAssignButton = "complete-task-reassign-users-assign-button"

type RowAssignmentMap = {
  [userId: string]: RowAssignmentState
}

type RowAssignmentAction =
  | { userId: string; type: "assignTask"; taskId: string }
  | { userId: string; type: "assignProject"; projectId: string }

type RowAssignmentState = {
  status: "pending" | "needsTask" | "ready"
  selectedProjectId?: string
  selectedTaskId?: string
}

const CompleteTaskReassignUsersDocument = graphql(`
  query CompleteTaskReassignUsers($taskId: String!) {
    usersList(status: "active", taskId: $taskId) {
      id
      currentProjectId
      currentTaskId
      firstName
      imageUrl
      isClockedIn
      jobTitle
      lastName
      projectId
      taskId
    }
    projectsByStatus(status: active) {
      id
      name
      imageUrl
      tasks {
        id
        name
      }
    }
  }
`)

const rowAssignmentReducer = (state: RowAssignmentMap, action: RowAssignmentAction): RowAssignmentMap => {
  let userState = state[action.userId]
  switch (action.type) {
    case "assignProject":
      userState = { status: "needsTask", selectedProjectId: action.projectId }
      break
    case "assignTask":
      if (userState.status !== "needsTask") {
        // Return to sender... restart
        userState = { status: "pending" }
      } else {
        userState = {
          ...userState,
          status: "ready",
          selectedTaskId: action.taskId,
        }
      }
      break
  }

  return { ...state, [action.userId]: userState }
}

type RowData = CompleteTaskReassignUsersQuery["usersList"][0]

export const CompleteTaskReassignUsers: FC<{
  onCancel?: () => void
  onSuccess?: () => void
  projectId: Project["id"]
  taskId: string
}> = ({ onCancel, onSuccess, projectId, taskId }) => {
  const [assignments, dispatch] = useReducer(rowAssignmentReducer, {})

  const [{ data, error, fetching }] = useQuery({
    query: CompleteTaskReassignUsersDocument,
    variables: { taskId },
  })

  const [_, reassignUserMutation] = useReassignUserMutation()

  useHandleError(error, "There was an error loading needed data.")

  const users: RowData[] = data?.usersList || []
  const projects = data?.projectsByStatus || []

  const assignmentColumnSettings = {
    filterable: false,
    hideable: false,
    sortable: false,
  }

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Team Member",
      flex: 3,
      renderCell: ({ row }) => <UserBadge user={row} />,
    },
    {
      ...assignmentColumnSettings,
      field: "projectSelect",
      flex: 2,
      headerName: "Project",
      renderCell: ({ row }: { row: RowData }) => {
        const assignment = assignments[row.id]
        return (
          <Select
            fullWidth
            onChange={(e) => dispatch({ userId: row.id, type: "assignProject", projectId: e.target.value })}
            renderValue={assignment?.selectedProjectId ? undefined : () => "Select a project"}
            value={assignment?.selectedProjectId || "Select a project"}
            test-label={testLabel_CompleteTaskReassignUsersProjectSelect}
          >
            {projects.map((p) => (
              <MenuItem key={p.id} value={p.id}>
                {p.name}
              </MenuItem>
            ))}
          </Select>
        )
      },
    },
    {
      ...assignmentColumnSettings,
      field: "taskSelect",
      flex: 2,
      headerName: "Task",
      renderCell: ({ row }: { row: RowData }) => {
        const assignment = assignments[row.id]
        return (
          <Select
            fullWidth
            onChange={(e) => dispatch({ userId: row.id, type: "assignTask", taskId: e.target.value })}
            disabled={!["ready", "needsTask"].includes(assignment?.status)}
            renderValue={assignment?.selectedTaskId ? undefined : () => "Select a task"}
            value={assignment?.selectedTaskId || "Select a task"}
            test-label={testLabel_CompleteTaskReassignUserTaskSelect}
          >
            {(projects.find((p) => p.id === assignment?.selectedProjectId)?.tasks || []).flatMap((p) => (
              <MenuItem disabled={p.id === taskId} key={p.id} value={p.id}>
                {p.name}
              </MenuItem>
            ))}
          </Select>
        )
      },
    },
    {
      type: "actions",
      field: "actions",
      renderCell: ({ row }: { row: RowData }) => {
        const assignment = assignments[row.id]
        return (
          <Button
            variant="contained"
            color="primary"
            disabled={assignment?.status !== "ready"}
            test-label={testLabel_CompleteTaskReassignUsersAssignButton}
            onClick={() => {
              if (assignment.status === "ready") {
                reassignUserMutation({ userId: row.id, taskId: assignment.selectedTaskId!, projectId }).then(
                  (result) => {
                    if (result.error) {
                      errorSnack(`Whoops! Error reassigning user: ${row.firstName} ${row.lastName}`)
                    } else {
                    }
                  }
                )
              } else {
                errorSnack("Something went wrong; please try again")
              }
            }}
          >
            Assign
          </Button>
        )
      },
      disableColumnMenu: true,
    },
  ]

  return (
    <>
      {fetching && <TaskCompletionWorkflowSkeleton />}
      {!fetching && <DataGridPro columns={columns} disableRowSelectionOnClick pageSizeOptions={[10]} rows={users} />}
      <Box
        className="border-t border-gray-200"
        sx={{
          backgroundColor: "white",
          borderTop: colors.gray[200],
          bottom: 20,
          display: "flex",
          justifyItems: "space-between",
          left: 0,
          maxHeight: 60,
          position: "absolute",
          right: 0,
        }}
        paddingLeft={20}
        padding={3}
        alignContent="center"
      >
        <Button sx={{ marginLeft: 2 }} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          sx={{ marginLeft: 2 }}
          variant="contained"
          color="primary"
          disabled={users.length > 0}
          onClick={onSuccess}
        >
          Continue
        </Button>
      </Box>
    </>
  )
}

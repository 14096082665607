import { LinearProgress } from "@mui/material"
import {
  DataGridPro,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GridColDef,
  GridEventListener,
  GridRowParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro"
import { addDays, eachWeekOfInterval, format, isSameWeek, isSameYear } from "date-fns"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { useQuery } from "urql"
import { graphql } from "../../../../../graphql/generated/gql"
import { getTimeEntryArrayTotalSeconds } from "../../../../../helpers/getTimeEntryArrayTotalSeconds"
import { secondsToFormattedString } from "../../../../../helpers/time-utility"
import { groupBy } from "../../../../../helpers/util-functions"
import { useHandleError } from "../../../../../hooks/useHandleError"
import { CustomDetailPanelToggleColDef } from "../../../DataGrid/CustomDetailPanelToggle"
import { TimeEntryWithProjectAndTaskName } from "../../EditDayActivityForm/EditDayActivityForm"
import { WeeklyDetailPanel } from "./WeeklyDetailPanel"

const UserTimeCardsDocument = graphql(`
  query UserTimeCards($userId: String!) {
    user(id: $userId) {
      id
      firstName
      lastName
      currentProjectId
      archived
      project {
        id
        name
      }
      currentTaskId
      task {
        id
        name
      }
      timeEntries {
        id
        durationInSeconds
        endAt
        signInPhotoUrl
        signOutPhotoUrl
        projectId
        taskId
        startAt
        project {
          id
          isDefault
          name
        }
        task {
          id
          parentTaskId
          visibilityLevel
          name
          parentTask {
            id
            name
          }
        }
      }
    }
  }
`)

export const defaultTimeEntryColumnProps: Partial<GridColDef> = {
  disableColumnMenu: true,
  editable: false,
  filterable: false,
  sortable: false,
  flex: 1,
}

const columns: GridColDef[] = [
  {
    ...defaultTimeEntryColumnProps,
    field: "week",
    headerName: "Week",
    minWidth: 210,
    valueGetter: ({ row }) => row.title,
  },
  {
    ...defaultTimeEntryColumnProps,
    field: "weeklyTotal",
    headerName: "Weekly Total",
    minWidth: 100,
    valueGetter: ({ row }) => row.subtitle,
  },
  CustomDetailPanelToggleColDef,
]

type TimeEntriesGroup = { timeEntries: TimeEntryWithProjectAndTaskName[]; title: string; subtitle: string }

export const TimeEntryDataGrid: FC<{
  userId: string
  openEditDayActivityModal: (timeEntries: TimeEntryWithProjectAndTaskName[]) => void
}> = ({ userId, openEditDayActivityModal }) => {
  const [timeEntriesGroupedByWeek, setTimeEntriesGroupedByWeek] = useState<TimeEntriesGroup[]>([])

  const apiRef = useGridApiRef()

  const [{ data, fetching, error }] = useQuery({
    query: UserTimeCardsDocument,
    variables: { userId },
  })

  useHandleError(error, "There was a problem getting time card history")

  const timeEntries = useMemo(() => {
    return (data?.user?.timeEntries || []).map((te) => ({
      ...te,
      isBreak: te.project.isDefault && te.task.visibilityLevel === "HIDDEN",
    }))
  }, [data?.user])

  const handleScroll: GridEventListener<"scrollPositionChange"> = useCallback(
    (params, _event, _details) => {
      apiRef.current.scroll(params)
    },
    [apiRef]
  )

  useEffect(() => {
    if (!timeEntries?.length) return

    const latestEntry = timeEntries[0]
    const firstEntry = timeEntries[timeEntries.length - 1]

    const firstDate = firstEntry.startAt
    const lastDate = latestEntry.startAt

    const weeks = eachWeekOfInterval({ start: firstDate, end: lastDate }, { weekStartsOn: 0 }).reverse()

    const groupedTimeEntries = weeks
      .map((firstDayOfWeek) => {
        const lastDayOfWeek = addDays(firstDayOfWeek, 6)
        const weeklyTimeEntries = timeEntries.filter((timeEntry) => isSameWeek(firstDayOfWeek, timeEntry.startAt))
        const totalSeconds = getTimeEntryArrayTotalSeconds(weeklyTimeEntries)
        const weekStartFormat = isSameYear(firstDayOfWeek, lastDayOfWeek) ? "MMMM dd" : "MMMM dd, yyyy"
        const title = `${format(firstDayOfWeek, weekStartFormat)} - ${format(lastDayOfWeek, "MMMM dd, yyyy")}`

        return {
          id: title,
          timeEntries: weeklyTimeEntries,
          title: `${format(firstDayOfWeek, weekStartFormat)} - ${format(lastDayOfWeek, "MMMM dd, yyyy")}`,
          subtitle: secondsToFormattedString(totalSeconds),
        }
      })
      .filter(({ timeEntries }) => timeEntries.length)

    setTimeEntriesGroupedByWeek(groupedTimeEntries)
  }, [timeEntries])

  return (
    <DataGridPro
      apiRef={apiRef}
      columns={columns}
      disableRowSelectionOnClick
      density="comfortable"
      loading={fetching}
      getDetailPanelContent={(params) => getDetailPanelContent(params, openEditDayActivityModal, handleScroll)}
      getDetailPanelHeight={getDetailPanelHeight}
      initialState={{
        pinnedColumns: { right: [GRID_DETAIL_PANEL_TOGGLE_FIELD] },
      }}
      keepNonExistentRowsSelected
      rows={timeEntriesGroupedByWeek}
      rowCount={timeEntriesGroupedByWeek.length}
      slots={{ loadingOverlay: LinearProgress }}
      sx={{
        fontSize: 16,
        paddingX: 2,
        "& .MuiDataGrid-detailPanel .MuiDataGrid-row": { border: "none" },
        "& .MuiDataGrid-cell:focus": { outline: "none" },
        "& .MuiDataGrid-cell:focus-within": { outline: "none" },
        "& .MuiDataGrid-columnHeader:focus": { outline: "none" },
        "& .MuiDataGrid-columnHeader:focus-within": { outline: "none" },
        "& .MuiDataGrid-pinnedColumnHeaders": { display: "none" },
        "& .MuiDataGrid-pinnedColumns": { boxShadow: "none" },
        "& .MuiDataGrid-virtualScroller": { overflowY: "hidden" },
      }}
    />
  )
}

const getDetailPanelContent = (
  params: GridRowParams,
  openEditDayActivityModal: (timeEntries: TimeEntryWithProjectAndTaskName[]) => void,
  handleScroll: GridEventListener<"scrollPositionChange">
) => {
  const days = groupBy(params.row.timeEntries, (timeEntry: TimeEntryWithProjectAndTaskName) =>
    format(timeEntry.startAt, "EE, MMM do")
  )

  return (
    <WeeklyDetailPanel
      timeEntries={days}
      openEditDayActivityModal={openEditDayActivityModal}
      handleScroll={handleScroll}
    />
  )
}

const getDetailPanelHeight = () => "auto" as "auto"

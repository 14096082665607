import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material"

import { Form, FormikValues, useFormikContext } from "formik"
import { FC } from "react"
import { BiPlus } from "react-icons/bi"
import { gray } from "tailwindcss/colors"
import { NeedsRepair, assetStatusOptions, getAssetStatusOption } from "../../../helpers/assetStatus"
import { TemplateListItem } from "../Drawer/CustomInspectionTemplateDrawer"
import { listItemTypes } from "./CustomInspectionTemplateForm"

export const ruleOptions: { value: string; label: string }[] = [{ label: "Change status", value: "changeStatus" }]

export const imageLabelOptions = ["Front", "Rear", "Left", "Right"]

export const InspectionItemForm: FC<{
  handleAddListItem?: (values: FormikValues, resetForm: () => void) => void
  isEditing?: boolean
}> = ({ handleAddListItem, isEditing = false }) => {
  const { values, setFieldValue, errors, handleChange, resetForm, touched } = useFormikContext<TemplateListItem>()
  const { label, type, photoRequired, photoLabel, addRule, rule, failedStatus } = values

  const onSubmit = () => handleAddListItem?.({ ...values, rowId: Date.now() }, resetForm)

  return (
    <Form onSubmit={onSubmit}>
      <div className="p-6 border rounded">
        <Typography variant="h5" fontSize={20} marginBottom={2}>
          Add Inspection Item
        </Typography>

        <TextField
          name="label"
          label="List item name"
          value={label}
          onChange={handleChange}
          error={touched.label && Boolean(errors.label)}
          fullWidth
        />

        <RadioGroup name="type" row value={type} className="my-4">
          {listItemTypes.map((listType, i) => (
            <Box key={i} marginX={"5px"}>
              <FormControl>
                <Chip
                  clickable
                  label={listType.label}
                  onClick={() => {
                    if (listType.value !== "y/n") {
                      setFieldValue("addRule", false)
                      setFieldValue("rule", undefined)
                      setFieldValue("failedStatus", undefined)
                    }
                    setFieldValue("type", listType.value)
                  }}
                  sx={{
                    backgroundColor: type === listType.value ? gray[800] : gray[100],
                    transition: "all 0.5s",
                    ":hover": { backgroundColor: type === listType.value ? gray[800] : null },
                    "& .MuiChip-label": {
                      color: type === listType.value ? "#fff" : gray[800],
                    },
                  }}
                />
              </FormControl>
            </Box>
          ))}
        </RadioGroup>

        {type === "y/n" && (
          <FormGroup className="pl-2 mb-4">
            <FormControlLabel
              name="addRule"
              value={addRule}
              checked={addRule}
              onChange={(e, checked) => {
                if (!checked) {
                  setFieldValue("rule", null)
                  setFieldValue("failedStatus", null)
                } else {
                  setFieldValue("rule", { label: "Change status", value: "changeStatus" })
                  setFieldValue("failedStatus", getAssetStatusOption(NeedsRepair))
                }
                handleChange(e)
              }}
              control={<Checkbox />}
              label="Add rule"
            />
            {addRule && (
              <FormGroup row className="flex items-center w-full">
                <Box paddingRight={2} paddingTop={2}>
                  <Typography variant="body1">If fail</Typography>
                </Box>
                <Autocomplete
                  className="mt-4"
                  value={rule || null}
                  options={ruleOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Change status"
                      onChange={(e) => setFieldValue("rule", e.target.value)}
                    />
                  )}
                  onChange={(_e, value) => setFieldValue("rule", value)}
                  sx={{ width: "230px" }}
                />
                <Box paddingX={2} paddingTop={2}>
                  <Typography variant="body1">to</Typography>
                </Box>
                <Autocomplete
                  className="mt-4"
                  value={failedStatus || null}
                  options={assetStatusOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select status"
                      onChange={(e) => setFieldValue("failedStatus", e.target.value)}
                    />
                  )}
                  onChange={(_e, value) => setFieldValue("failedStatus", value)}
                  sx={{ width: "230px" }}
                />
              </FormGroup>
            )}
          </FormGroup>
        )}

        <FormGroup className="pl-2">
          <FormControlLabel
            name="photoRequired"
            value={photoRequired}
            checked={photoRequired}
            onChange={(e, checked) => {
              if (!checked) {
                setFieldValue("photoLabel", undefined)
              }
              handleChange(e)
            }}
            control={<Checkbox checked={photoRequired} />}
            label="Image required"
          />

          {photoRequired && (
            <Autocomplete
              className="mt-4"
              value={photoLabel || null}
              options={imageLabelOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Image label (optional"
                  onChange={(e) => setFieldValue("photoLabel", e.target.value)}
                />
              )}
              onChange={(_e, value) => setFieldValue("photoLabel", value)}
            />
          )}
        </FormGroup>
        {!isEditing && (
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            startIcon={<BiPlus />}
            className="mt-4"
            onClick={onSubmit}
            disabled={!label}
          >
            Add list item
          </Button>
        )}
      </div>
    </Form>
  )
}

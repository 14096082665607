import { add, formatDistance, isAfter, isPast, isToday } from "date-fns"
import { FC } from "react"
import { Asset } from "../../../graphql/generated/client-types-and-hooks"
import { classNames } from "../../../helpers/classNames"
import { PickPlus } from "../../../types/helpers"
import { InventoryIntervalLookup } from "./CreateOrEditAssetForm/InventoryIntervalOptions"

export const AssetInventoryDueIn: FC<{
  asset: PickPlus<Asset, "inventoryRequirements" | "lastInventoriedAt">
  dueInOnly?: boolean
  className?: string
}> = ({ asset, dueInOnly, className }) => {
  if (!asset.inventoryRequirements?.intervalInSeconds) return <p className={className}>No set inventory</p>

  let color = "text-orange-600"
  let dueAt
  if (asset.lastInventoriedAt) {
    dueAt = add(asset.lastInventoriedAt, { seconds: asset.inventoryRequirements.intervalInSeconds })
    if (isPast(dueAt)) color = "text-red-400"
    if (isToday(dueAt)) color = "text-orange-600"
    // Ok, we are good... so lets go ahead and set it to the normal
    color = "text-gray-400"
  }

  const dueInString = dueAt && `Due in ${formatDistance(new Date(), dueAt)}`

  return (
    <>
      {asset.inventoryRequirements.intervalInSeconds ? (
        <>
          {dueInOnly && !dueAt && <div className="w-2 h-2 rounded-full bg-orange-600 mr-2" />}
          <span className={classNames(color, className)}>{dueAt ? dueInString : "Due now"}</span>
          {!dueInOnly && (
            <>
              <span> / </span>
              <span>{InventoryIntervalLookup[asset.inventoryRequirements.intervalInSeconds]}</span>
            </>
          )}
        </>
      ) : (
        "No set inventory"
      )}
    </>
  )
}

export const AssetInspectionDueIn: FC<{
  assetReport: {
    intervalInSeconds?: number | null | undefined
    lastReportedAt?: Date | null | undefined
    startDate?: Date | null | undefined
  }
}> = ({ assetReport }) => {
  if (!assetReport.intervalInSeconds) {
    return <p>No set inspection</p>
  }

  let color = "text-orange-400"
  let dueAt

  const now = new Date()
  const startDate = assetReport?.startDate ? new Date(assetReport.startDate) : null

  if (startDate && isAfter(startDate, now)) {
    return null
  }

  if (assetReport.lastReportedAt) {
    dueAt = add(assetReport.lastReportedAt, { seconds: assetReport.intervalInSeconds })

    if (isPast(dueAt)) {
      color = "text-red-400"
    }

    if (isToday(dueAt)) {
      color = "text-orange-400"
    }

    color = "text-gray-400"
  }

  return (
    <>
      {assetReport.intervalInSeconds ? (
        <>
          <span className={color}>{dueAt ? <>Due in {formatDistance(new Date(), dueAt)}</> : <>Due now</>}</span>
          <span> / </span>
          <span>{InventoryIntervalLookup[assetReport.intervalInSeconds]}</span>
        </>
      ) : (
        "No set inspection"
      )}
    </>
  )
}

import { Menu, Transition } from "@headlessui/react"
import { useField, useFormikContext } from "formik"
import { Fragment, useEffect, useState } from "react"
import { BiCheck, BiChevronDown } from "react-icons/bi"
import { MdError } from "react-icons/md"
import { classNames } from "../../../helpers/classNames"
import { getTruncatedToOneWordString } from "../../../helpers/getTruncatedToOneWordString"
import { TailwindIcon } from "../../../types/tailwind"
import { Label } from "../../Elements"

export type DropdownItem = {
  value: string
  label: string
  color?: string
  parentName?: string
  disabled?: boolean
}

type Props = {
  Icon?: TailwindIcon
  chevron?: boolean
  items: DropdownItem[][]
  label?: string
  name: string
  placeholderText: string
  className?: string
  tooltipLabel?: string
  tooltipText?: string
  wrapperClassName?: string
  disabled?: boolean
  onChange?: (value: any) => void
  defaultSelectedItem?: DropdownItem
  placeholderParentName?: string
  errorStyle?: "contained"
  selectBorderClassName?: string
  opacity?: string
}

export const DeprecatedDropdown = ({
  Icon,
  chevron,
  items,
  label = "",
  name,
  placeholderText,
  className,
  tooltipLabel,
  tooltipText,
  wrapperClassName,
  onChange = (_value: any) => {},
  defaultSelectedItem,
  disabled,
  placeholderParentName,
  errorStyle,
  selectBorderClassName,
  opacity,
}: Props) => {
  const [fieldValue, fieldMeta, fieldHelpers] = useField(name)
  const [selectedItem, setSelectedItem] = useState({ value: "", label: "" })
  const { errors }: { errors: any } = useFormikContext()

  // If Formik state changes, update the selected item.
  useEffect(() => {
    const newSelectedItem = items.flat().find((item) => item.value === fieldValue.value)
    setSelectedItem(newSelectedItem || { value: "", label: "" })
  }, [items, fieldValue.value])

  // For some reason the errors aren't getting passed down from the
  // users form component. This just updates based on the errors
  // from the formik context.
  useEffect(() => {
    if (errors[name] && fieldMeta.error !== errors[name]) {
      fieldHelpers.setError(errors[name])
    }
  }, [errors, fieldHelpers, fieldMeta.error, name])

  useEffect(() => {
    if (defaultSelectedItem) {
      setSelectedItem(defaultSelectedItem)
    }
  }, [defaultSelectedItem])

  const displayError = !fieldValue.value && fieldMeta.touched && fieldMeta.error && !disabled
  const errorContained = errorStyle === "contained"

  // sort items by disabled
  const sortedItems = items.map((item) =>
    item.sort(({ disabled: a = 0 }, { disabled: b = 0 }) => {
      if (a > b) return 1
      if (a < b) return -1
      return 0
    })
  )

  return (
    <Menu>
      <div className={classNames("relative gap-y-2", disabled && (opacity || "opacity-40"), wrapperClassName)}>
        {label && (
          <Label htmlFor={fieldValue.name} tooltipLabel={tooltipLabel} tooltipText={tooltipText}>
            {label}
          </Label>
        )}
        <div>
          <div
            className={classNames(
              "flex lg:hidden rounded border border-gray-400",
              errorContained && displayError && "border-2 rounded border-red-600"
            )}
          >
            {Icon && (
              <div className={classNames("flex items-center px-2 rounded-l-md bg-gray-50 border-r border-r-gray-400")}>
                <Icon className="h-6 w-6 text-gray-600" />
              </div>
            )}
            <select
              disabled={disabled}
              className={classNames(
                "block w-full overflow-hidden focus:ring-0 border border-gray-400",
                selectedItem.value || fieldValue.value?.value || fieldValue.value ? `text-gray-800` : `text-gray-400`,
                Icon ? "rounded-r" : "rounded",
                !chevron && "bg-none",
                selectBorderClassName
              )}
              onChange={(e) => {
                fieldHelpers.setValue(e.target.value)
                const newSelectedItem = items.flat().find((item) => item.value === e.target.value)
                if (newSelectedItem) {
                  setSelectedItem(newSelectedItem)
                  onChange(newSelectedItem)
                }
              }}
              value={selectedItem.value || fieldValue.value?.value || fieldValue.value}
            >
              {!fieldValue.value?.value && !fieldValue.value && (
                <option className="text-gray-400" value="">
                  {placeholderText}
                </option>
              )}
              {sortedItems.map((itemGroup) =>
                itemGroup.map((item) => {
                  return (
                    <option key={item.value} value={item.value} disabled={item.disabled}>
                      {item.parentName && `${getTruncatedToOneWordString(item.parentName)} / `}
                      {item.label}
                    </option>
                  )
                })
              )}
            </select>
          </div>

          <Menu.Button
            disabled={disabled}
            className={classNames(
              "hidden w-full lg:flex items-center justify-between rounded h-[42px] transition-colors",
              !disabled && "hover:bg-gray-100",
              disabled && opacity && "opacity-80",
              className
            )}
          >
            <div
              className={classNames(
                "flex items-center w-full h-full leading-tight truncate rounded border border-gray-400",
                errorContained && displayError && "border-2 border-red-600"
              )}
            >
              {Icon && (
                <div
                  className={classNames(
                    "flex items-center px-2 rounded-l-md bg-gray-50 border-r border-r-gray-400 h-full"
                  )}
                >
                  <Icon className="h-6 w-6 text-gray-600" />
                </div>
              )}
              <p className="w-full truncate leading-7 px-3 flex items-center gap-1">
                {selectedItem.label ? (
                  selectedItem.label
                ) : (
                  <>
                    {placeholderParentName && <span className="text-gray-400 truncate">{placeholderParentName}</span>}
                    {placeholderParentName && <span className="text-gray-400">{"/"}</span>}
                    <span className="truncate text-gray-400">{placeholderText}</span>
                  </>
                )}
              </p>

              <div className="px-2 flex items-center">
                {errorContained && displayError && <MdError className="h-5 w-5 text-red-600" />}
                {chevron && <BiChevronDown className="h-6 w-6 text-gray-500" />}
              </div>
            </div>
          </Menu.Button>
          <Transition
            as={Fragment}
            {...(disabled ? { show: false } : {})}
            enter="transition ease-out duration-75"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="absolute z-10 max-h-60 overflow-auto text-base shadow-lg focus:outline-none sm:text-sm drop-shadow-md mt-2 border-px bg-white rounded w-80"
            >
              {sortedItems.map((itemGroup, i) => (
                <div key={`group-${i}`} className={"py-2 px-1 last:py-1 first:py-1 border-b last:border-b-0"}>
                  {itemGroup.map((item) => (
                    <Menu.Item key={item.value} disabled={item.disabled}>
                      <div
                        onClick={() => {
                          fieldHelpers.setValue(item.value)
                          setSelectedItem(item)
                          onChange(item)
                        }}
                        className={classNames(
                          "relative cursor-pointer rounded select-none py-3 pl-3 pr-9 text-gray-900",
                          !item.disabled && "hover:bg-gray-100"
                        )}
                      >
                        <span
                          className={classNames(
                            "block leading-tight truncate",
                            item.color,
                            item.disabled && "text-gray-300"
                          )}
                        >
                          {item.parentName && <span className="text-gray-400">{item.parentName + " / "}</span>}
                          {item.label}
                        </span>
                        {fieldValue.value === item.value && (
                          <span className="absolute inset-y-0 right-0 flex items-center pr-2 text-blue-600">
                            <BiCheck className={"h-5 w-5"} />
                          </span>
                        )}
                      </div>
                    </Menu.Item>
                  ))}
                </div>
              ))}
            </Menu.Items>
          </Transition>

          {!errorContained &&
            (displayError ? (
              <div className="text-red-600 text-sm">{fieldMeta.error}</div>
            ) : (
              <div className="text-sm">&nbsp;</div>
            ))}
        </div>
      </div>
    </Menu>
  )
}

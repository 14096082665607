import { Box, Button, MenuItem, Select } from "@mui/material"
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro"
import { FC, useMemo, useReducer } from "react"
import { useQuery } from "urql"
import {
  AssetAssignableType,
  CompleteTaskReassignAssetsQuery,
  useTransferAssetsMutation,
} from "../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../graphql/generated/gql"
import { colors } from "../../../../helpers/colors"
import { useHandleError } from "../../../../hooks/useHandleError"
import { AssetBadge } from "../../../AssetBadge"
import { errorSnack, successSnack } from "../../../Notistack/ThemedSnackbars"
import { TaskCompletionWorkflowSkeleton } from "../../Projects/Tasks/TaskCompletionWorkflow.skeleton"

export const testLabel_CompleteTaskReassignAssetsAssignmentType = "complete-task-reassign-assets-assignment-type"
export const testLabel_CompleteTaskReassignAssetsAssignmentOption = "complete-task-reassign-assets-assignment-option"
export const testLabel_CompleteTaskReassignAssetsAssignmentButton = "complete-task-reassign-assets-assignment-button"

type RowAssignmentAction =
  | { assetId: string; type: "selectType"; assignableType: string }
  | { assetId: string; type: "assign"; assignableId: string }
type RowAssignmentMap = { [assetId: string]: RowAssignmentState }
type RowAssignmentState = {
  status: "pending" | "needsType" | "ready"
  selectedAssignableType?: string
  selectedAssignableId?: string
}
type RowData = CompleteTaskReassignAssetsQuery["taskAssets"][0]

type ValueObject = { value: string; label: string }

const CompleteTaskReassignAssetsAssignmentTargetDocument = graphql(`
  query TaskCompletionAssetReassignmentTargets {
    usersList(status: "active") {
      id
      firstName
      lastName
    }
    projectsByStatus(status: active) {
      id
      name
      tasks {
        id
        name
      }
    }
    allAssets: assets {
      id
      name
    }
  }
`)

const CompleteTaskReassignAssetsDocument = graphql(`
  query CompleteTaskReassignAssets($taskId: String!) {
    taskAssets: assets(taskId: $taskId) {
      id
      name
      assignableId
      imageUrl
      companyAssetNumber
    }
  }
`)

const rowAssignmentReducer = (state: RowAssignmentMap, action: RowAssignmentAction): RowAssignmentMap => {
  let assetState = state[action.assetId]
  switch (action.type) {
    case "selectType":
      assetState = { status: "needsType", selectedAssignableType: action.assignableType }
      break
    case "assign":
      if (assetState.status !== "needsType") {
        // Return to sender... restart
        assetState = { status: "pending" }
      } else {
        assetState = {
          ...assetState,
          selectedAssignableId: action.assignableId,
          status: "ready",
        }
      }
      break
  }

  return { ...state, [action.assetId]: assetState }
}

export const CompleteTaskReassignAssets: FC<{
  onCancel?: () => void
  onSuccess?: () => void
  projectIsEligibleForCompletion: boolean
  taskId: string
}> = ({ onCancel, onSuccess, projectIsEligibleForCompletion, taskId }) => {
  const [assignments, dispatch] = useReducer(rowAssignmentReducer, {})

  const [{ data: targetData }] = useQuery({
    query: CompleteTaskReassignAssetsAssignmentTargetDocument,
  })

  const [{ data, error, fetching }, refetchTarget] = useQuery({
    query: CompleteTaskReassignAssetsDocument,
    variables: { taskId },
  })

  const [_, transferAssetMutation] = useTransferAssetsMutation()

  useHandleError(error, "There was an error loading needed data.")

  const assets = data?.taskAssets || []

  const userOptions: ValueObject[] = useMemo(() => {
    return (targetData?.usersList || [])
      .sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
      .map((u) => ({ label: `${u.firstName} ${u.lastName}`, value: u.id }))
  }, [targetData?.usersList])

  const projectAndTaskOptions = useMemo(() => {
    let projectAndTaskOptions: ValueObject[] = []
    ;(targetData?.projectsByStatus || []).forEach((project) => {
      project.tasks.forEach((task) => {
        projectAndTaskOptions.push({ label: `${project.name} / ${task.name}`, value: task.id })
      })
    })
    return projectAndTaskOptions
  }, [targetData?.projectsByStatus])

  const assetOptions: ValueObject[] = useMemo(() => {
    return (targetData?.allAssets || []).map((asset) => ({ value: asset.id, label: asset.name }))
  }, [targetData?.allAssets])

  const assignmentColumnSettings = {
    filterable: false,
    hideable: false,
    sortable: false,
  }

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Asset",
      flex: 3,
      renderCell: ({ row }) => <AssetBadge asset={row} />,
    },
    {
      ...assignmentColumnSettings,
      field: "selectType",
      flex: 2,
      headerName: "Type",
      renderCell: ({ row }: { row: RowData }) => {
        const assignment = assignments[row.id]
        return (
          <Select
            fullWidth
            onChange={(e) => dispatch({ assetId: row.id, type: "selectType", assignableType: e.target.value })}
            renderValue={assignment?.selectedAssignableType ? undefined : () => "Select a type"}
            value={assignment?.selectedAssignableType || "Select a type"}
            test-label={testLabel_CompleteTaskReassignAssetsAssignmentType}
          >
            <MenuItem key="asset" value="Asset">
              Asset
            </MenuItem>
            <MenuItem key="task" value="Task">
              Task
            </MenuItem>
            <MenuItem key="user" value="User">
              User
            </MenuItem>
          </Select>
        )
      },
    },
    {
      ...assignmentColumnSettings,
      field: "assignableId",
      flex: 2,
      headerName: "Assignment",
      renderCell: ({ row }: { row: RowData }) => {
        const assignment = assignments[row.id]
        let menuItems: ValueObject[] = []

        switch (assignment?.selectedAssignableType) {
          case "User":
            menuItems = userOptions
            break
          case "Task":
            menuItems = projectAndTaskOptions
            break
          case "Asset":
            menuItems = assetOptions.filter((a) => a.value !== row.id)
            break
        }

        return (
          <Select
            fullWidth
            onChange={(e) => dispatch({ assetId: row.id, type: "assign", assignableId: e.target.value })}
            disabled={!["ready", "needsType"].includes(assignment?.status)}
            renderValue={assignment?.selectedAssignableId ? undefined : () => "Select"}
            value={assignment?.selectedAssignableId || "Select"}
            test-label={testLabel_CompleteTaskReassignAssetsAssignmentOption}
          >
            {menuItems.map((item) => (
              <MenuItem disabled={item.value === taskId} key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        )
      },
    },
    {
      type: "actions",
      field: "actions",
      renderCell: ({ row }: { row: RowData }) => {
        const assignment = assignments[row.id]
        return (
          <Button
            variant="contained"
            color="primary"
            disabled={assignment?.status !== "ready"}
            test-label={testLabel_CompleteTaskReassignAssetsAssignmentButton}
            onClick={() => {
              const assignment = assignments[row.id]
              if (assignment.status === "ready") {
                transferAssetMutation({
                  assetIds: [row.id],
                  assignableId: assignment?.selectedAssignableId!,
                  assignableType: assignment?.selectedAssignableType as AssetAssignableType,
                }).then((result) => {
                  if (result.error) {
                    errorSnack(`Whoops! Error transferring asset: ${row.name}`)
                  } else {
                    successSnack(`Success: ${row.name} has been transferred`)
                    refetchTarget()
                  }
                })
              } else {
                errorSnack("Something went wrong; please try again")
              }
            }}
          >
            Assign
          </Button>
        )
      },
      disableColumnMenu: true,
    },
  ]

  return (
    <>
      {fetching && <TaskCompletionWorkflowSkeleton />}
      {!fetching && <DataGridPro columns={columns} disableRowSelectionOnClick pageSizeOptions={[10]} rows={assets} />}
      <Box
        className="border-t border-gray-200"
        sx={{
          backgroundColor: "white",
          borderTop: colors.gray[200],
          bottom: 20,
          display: "flex",
          justifyItems: "space-between",
          left: 0,
          maxHeight: 60,
          position: "absolute",
          right: 0,
        }}
        paddingLeft={20}
        padding={3}
        alignContent="center"
      >
        <Button sx={{ marginLeft: 2 }} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          sx={{ marginLeft: 2 }}
          variant="contained"
          color="primary"
          disabled={assets.length > 0}
          onClick={onSuccess}
        >
          {projectIsEligibleForCompletion ? "Complete task and project" : "Complete Task"}
        </Button>
      </Box>
    </>
  )
}

import { FC } from "react"
import { BackButton } from "../../BackButton"
import { SkeletonContainer } from "../../Skeletons/SkeletonContainer"
import { SkeletonElement } from "../../Skeletons/SkeletonElement"
import { UserBadgeSkeleton } from "../User/UserBadge.skeleton"

export const AssetTransferSkeleton: FC = () => {
  return (
    <>
      <BackButton />
      <SkeletonContainer>
        <div className="grid gap-12">
          <SkeletonElement className={"h-7 w-72 mt-2 mb-4"} />
          <div className="grid gap-6">
            <div className="border-b pb-2">
              <SkeletonElement className={"h-7 w-36 mt-2 mb-4"} />
            </div>
            <UserBadgeSkeleton />
          </div>
          <div className="grid gap-6">
            <div className="border-b pb-2">
              <SkeletonElement className={"h-7 w-12 mt-2 mb-4"} />
            </div>
            <SkeletonElement className={"h-10 w-48 mt-2 mb-4"} />
          </div>
        </div>
      </SkeletonContainer>
    </>
  )
}

import { FC } from "react"
import { BiDislike, BiLike } from "react-icons/bi"
import { InspectionReportAggregations } from "../graphql/generated/client-types-and-hooks"
import { classNames } from "../helpers/classNames"
import { NAButton } from "./NAbutton"

type Props = {
  report: InspectionReportAggregations
}

export const InspectionSummary: FC<Props> = ({ report: { trueCount, falseCount } }) => {
  const showThumbs = trueCount > 0 || falseCount > 0
  const thumbStyles = "text-white p-1 rounded h-6 w-6"
  const numStyles = "mx-1.5 text-md flex items-center"

  return (
    <div className="flex items-center justify-center">
      {showThumbs ? (
        <>
          <BiLike className={classNames("bg-green-600", thumbStyles)} />
          <div className={classNames("min-w-[20px]", numStyles)}>{trueCount}</div>
          {falseCount > 0 && (
            <>
              <BiDislike className={classNames(" bg-orange-600", thumbStyles)} />
              <div className={classNames(numStyles)}>{falseCount}</div>
            </>
          )}
        </>
      ) : (
        <NAButton />
      )}
    </div>
  )
}

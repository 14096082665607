import { Button } from "@mui/material"
import { FC, useContext } from "react"
import { BiClipboard } from "react-icons/bi"
import { Asset, AssetGroup, User } from "../graphql/generated/client-types-and-hooks"
import { DrawerContext } from "../providers/DrawerProvider"
import { PermissionsContext } from "../providers/PermissionsProvider/PermissionsProvider"
import { PickPlus } from "../types/helpers"
import { InventoryReportDrawer } from "./Partials/Drawer/InventoryReportDrawer"

type AssetProps = PickPlus<
  Asset,
  | "id"
  | "name"
  | "assignableId"
  | "assetGroupId"
  | "assignableType"
  | "companyAssetNumber"
  | "groupQuantity"
  | "status"
  | "inferredProjectId"
  | "inventoryRequirements"
  | "isAssetGroup"
  | "assetChildCount"
  | "ownershipType"
> & { inferredOwner?: PickPlus<User, "id"> | undefined | null } & { depth?: number }

type AssetGroupProps = PickPlus<AssetGroup, "assignableId" | "assetGroupId" | "assignableType" | "count" | "status">[]

type Props = {
  asset: AssetProps
  assetGroups: AssetGroupProps
  includeIcon?: boolean
  className?: string
}

export const InventoryButton: FC<Props> = ({ asset, includeIcon = false, className }) => {
  const { hasPermissionTo } = useContext(PermissionsContext)
  const { push: pushDrawer } = useContext(DrawerContext)

  const canReportAsset = hasPermissionTo("asset:report", {
    userId: asset.inferredOwner?.id,
    projectId: asset.inferredProjectId,
  })
  const isInventoryRequired = Boolean(asset.inventoryRequirements?.intervalInSeconds)

  return (
    <Button
      className={className}
      color="black"
      disabled={!isInventoryRequired || !canReportAsset}
      onClick={() => pushDrawer(<InventoryReportDrawer asset={asset} />)}
      type="button"
      variant="contained"
      startIcon={includeIcon ? <BiClipboard className="h-5 w-5" /> : null}
    >
      Inventory
    </Button>
  )
}

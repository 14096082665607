import { TaskListItem } from "../graphql/generated/client-types-and-hooks"

export const getTaskListItemSortOrder = ({
  completedTaskCount,
  sortOrder,
  taskCount,
  createdAt,
}: TaskListItem): number => {
  const completedOffset = completedTaskCount > 0 && completedTaskCount === taskCount ? 999999999999 : 0
  const placeInList =
    typeof sortOrder === "number"
      ? sortOrder + completedOffset
      : Math.floor(createdAt.getTime() / 1000) - 16800000 + completedOffset
  return placeInList
}

import { FC, useContext, useEffect, useState } from "react";
import { graphql } from "../../../../graphql/generated/gql";
import { useQuery } from "urql";
import { Document, DocumentsList } from "../../Documents/DocumentsList";
import { DocumentsContext } from "../../../../providers/DocumentsProvider";

const AssetDocumentsQuery = graphql(`
  query AssetDocuments($assetId: String!) {
    asset(id: $assetId) {
      id
      files {
        id
        fileName
        key
        contentType
        objectVersion
        assetId
        status
        expiresAt
        createdAt
        documentUrl
      }
    }
  }
`)

type Props = {
  assetId: string
}

type AssetDocuments = 
  | {
      id: string
      fileName: string
      key: string
      contentType: string
      objectVersion: number
      assetId?: string | null | undefined
      status?: string | null | undefined
      expiresAt?: Date | null | undefined
      createdAt: Date
      documentUrl?: string | null | undefined
    }[]
  | undefined

export const AssetDocumentsList: FC<Props> = ({ assetId }) => {
  const [{ data, fetching }, getDocuments] = useQuery({
    query: AssetDocumentsQuery,
    variables: { assetId },
    pause: !assetId
  })

  const { startRefresh, onCompleteRefresh, editDocument, searchText, refreshList, documentModalProps } = useContext(DocumentsContext)
  const [filteredRows, setFilteredRows] = useState<Document[]>([])

  useEffect(() => {
    if (startRefresh) {
      getDocuments()
      onCompleteRefresh()
    }
  }, [assetId, startRefresh, onCompleteRefresh, getDocuments])

  useEffect(() => {
    if (searchText && data?.asset.files) {
      const filtered = data.asset.files.filter((doc) => doc.fileName.toLowerCase().includes(searchText.toLowerCase())) || []

      setFilteredRows(transformData(filtered))
    } else {
      setFilteredRows(transformData(data?.asset?.files))
    }
  }, [searchText, data?.asset?.files])

  return (
    <DocumentsList
      fetching={fetching}
      filteredRows={filteredRows}
      documentModalProps={documentModalProps}
      editDocument={editDocument}
      refreshList={refreshList} />
  )
}

const transformData = (assetDocuments: AssetDocuments) => {
  const rowData: Document[] = assetDocuments?.map((doc) => ({
    id: doc.id,
    documentUrl: doc?.documentUrl || undefined,
    name: doc.fileName,
    uploaded: doc.createdAt,
    type: doc.contentType.split("/")?.[1],
    status: doc?.status || undefined,
    expirationDate: doc?.expiresAt || undefined
  })) || []

  return rowData
}
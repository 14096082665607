import { UnitGoalReports } from "../UnitsGraph"
import { DateRangeFilterString } from "../types"
import { getGroupingKey } from "./getGroupingKey"
import { sortGroupedItems } from "./sortGroupedItems"

export type GroupedObject = {
  key: string
  id: string
  documentUrl?: string
  note?: string
  reporterName: string
  uploaded: Date
  type?: string
  taskName?: string
}
export type GraphData = {
  description: string
  color: string
  progress: number
}

type TaskProgressEvent = {
  id: string
  createdAt: Date
  imageUrls?: (string | null)[]
  note?: string | null
  reporter?: {
    id: string
    fullName: string
  }
  task?: {
    name: string
  }
  unitGoalProgressReports?: UnitGoalReports[]
}

export function groupImageAndNotesByDate(
  taskProgressEvents: TaskProgressEvent[],
  dateRangeType: DateRangeFilterString = "daily"
): { [key: string]: GroupedObject[] } {
  const groupedItems: { [key: string]: GroupedObject[] } = {}

  for (const event of taskProgressEvents) {
    const groupingKey = getGroupingKey(new Date(event.createdAt), dateRangeType)

    if (!groupedItems[groupingKey]) {
      groupedItems[groupingKey] = []
    }

    // Handling images
    const validImageUrls = event.imageUrls?.filter((url): url is string => typeof url === "string") || []
    if (validImageUrls.length > 0) {
      const imagesWithDetails: GroupedObject[] = validImageUrls.map((url) => ({
        key: event.id,
        id: event.id,
        documentUrl: url,
        reporterName: event.reporter?.fullName || "",
        uploaded: event.createdAt,
        type: "",
        taskName: event.task?.name,
      }))
      groupedItems[groupingKey].push(...imagesWithDetails)
    }

    // Handling notes
    if (event.note) {
      const noteWithDetails: GroupedObject = {
        key: event.id,
        id: event.id,
        note: event.note,
        reporterName: event.reporter?.fullName || "",
        uploaded: event.createdAt,
        taskName: event.task?.name,
      }
      groupedItems[groupingKey].push(noteWithDetails)
    }
  }

  return sortGroupedItems(groupedItems)
}

import { Typography } from "@mui/material"
import { grey } from "@mui/material/colors"
import { FC } from "react"

export const Notes: FC<{ notes: string | null | undefined }> = ({ notes }) => {
  if (!notes) {
    return null
  }

  return (
    <div className="pt-6 border-t">
      <div className="bg-yellow-100 border rounded mb-8 p-4">
        <Typography color={grey[800]} fontSize={14} fontWeight={700} textTransform="uppercase">
          Inspection Notes
        </Typography>
        <Typography fontSize={16} color={grey[600]}>
          {notes}
        </Typography>
      </div>
    </div>
  )
}

import { AssetAssignableType, AssetGroup } from "../graphql/generated/client-types-and-hooks"
import { Available } from "./assetStatus"

export const sortAssetGroups = (assetGroups: AssetGroup[], showEmptyInventory = true) => {
  if (!assetGroups?.length) {
    return assetGroups
  }

  // descending sort by count
  let sorted = [...assetGroups]?.sort((a, b) => b.count - a.count)
  const inventoryIndex = sorted?.findIndex((a) => a.assetGroupId === a.assignableId) // -1 when there are no inventory assets

  // keep the inventory assets as first item in the sorted array
  if (inventoryIndex > 0) {
    const [inventoryAssets] = sorted?.splice(inventoryIndex, 1)
    sorted?.unshift(inventoryAssets)
  }

  if (showEmptyInventory && inventoryIndex === -1) {
    const groupParent = sorted[0].groupParent
    const id = groupParent.id

    const zeroInventory = {
      assetGroupId: id,
      assignableId: id,
      assignableType: AssetAssignableType.Asset,
      assignedAsset: { ...groupParent },
      compositeKey: [id, id, AssetAssignableType.Asset, Available].join("|"),
      status: Available,
      count: 0,
      groupParent,
    }

    if (sorted?.some((a) => a !== zeroInventory)) {
      sorted?.unshift(zeroInventory)
    }
  }

  return sorted
}

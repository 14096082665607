import { Button } from "@mui/material"
import { FC } from "react"
import { classNames } from "../helpers/classNames"

type Props = {
  label: string
  onClick?: () => void
  shadow?: boolean
  disabled?: boolean
}

export const AddRow: FC<Props> = ({ label, onClick, shadow = false, disabled = false }) => {
  return (
    <div
      className={classNames(
        "text-center text-gray-400 font-light text-xs mt-2 mb-8 rounded-lg border border-gray-200 bg-white p-4 md:border-hidden md:bg-transparent",
        shadow ? "shadow-2xl" : ""
      )}
    >
      <Button
        className="hover:text-gray-600 transition-colors"
        type="button"
        onClick={onClick}
        disabled={disabled}
        color="secondary"
      >
        + {label}
      </Button>
    </div>
  )
}

import { Button } from "@mui/material"
import { FC, useEffect, useState } from "react"
import {
  TimeEntry,
  User,
  useClockInMutation,
  useClockOutUserMutation,
} from "../../graphql/generated/client-types-and-hooks"
import { toastClockInError } from "../../helpers/toastClockInError"
import { toastClockOutError } from "../../helpers/toastClockOutError"
import { PickPlus } from "../../types/helpers"

type Props = {
  user: PickPlus<User, "id" | "firstName" | "lastName" | "archived" | "isClockedIn" | "taskId"> & {
    latestTimeEntry?: PickPlus<TimeEntry, "id" | "startAt" | "endAt"> | null
  }
  onSuccess?: () => void
  disabled?: boolean
}

export const PureClockInOut: FC<Props> = ({ user, onSuccess, disabled = false }) => {
  const [isClockedIn, setIsClockedIn] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [_, clockInMutation] = useClockInMutation()
  const [__, clockOutMutation] = useClockOutUserMutation()

  const clockIn = () => {
    setIsLoading(true)

    clockInMutation({
      taskId: user.taskId!,
      userId: user.id,
    }).then((result) => {
      if (result.error) {
        toastClockInError(result.error, user)
      }

      if (result?.data?.clockIn?.user.isClockedIn!) {
        setIsClockedIn(true)
        onSuccess?.()
      }
      setIsLoading(false)
    })
  }

  const clockOut = () => {
    clockOutMutation({
      userId: user.id,
    }).then((result) => {
      if (result.error) {
        toastClockOutError(result.error, user)
      }

      if (!result?.data?.clockOutUser?.user.isClockedIn!) {
        setIsClockedIn(false)
        onSuccess?.()
      }

      setIsLoading(false)
    })
  }

  const handleClockEvent = () => {
    if (isClockedIn) {
      clockOut()
    } else {
      clockIn()
    }
  }

  useEffect(() => {
    setIsClockedIn(user?.isClockedIn)
  }, [user?.isClockedIn])

  return (
    <Button
      fullWidth
      variant={isClockedIn ? "outlined" : "contained"}
      color="primary"
      disabled={user.archived || isLoading || disabled}
      onClick={!user.archived ? handleClockEvent : undefined}
    >
      <span>Clock {isClockedIn ? "out" : "in"}</span>
    </Button>
  )
}

import { FC, useMemo } from "react"
import { assetStatusOptions } from "../../../../helpers/assetStatus"
import { MultiSelect, MultiSelectOption } from "../MultiSelect"

type Props = {
  disabled?: boolean
  name: string
  withErrorHandling?: boolean
  onChange?: (selectedValues: string[]) => void
}

export const AssetStatusMultiSelect: FC<Props> = ({ disabled = false, name, onChange, withErrorHandling = false }) => {
  const statuses: MultiSelectOption[][] = useMemo(() => {
    return [
      assetStatusOptions.map((status) => ({
        id: status.value,
        label: status.label,
        value: status.value,
        searchableTextString: status.label,
        template: () => <div>{status.label}</div>,
      })),
    ]
  }, [])

  return (
    <MultiSelect
      disabled={disabled}
      name={name}
      options={statuses}
      isSingleSelect={true}
      selectedLabel={"status"}
      onChange={onChange}
      withErrorHandling={withErrorHandling}
    />
  )
}

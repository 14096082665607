/* eslint-disable @next/next/no-img-element */
import { Typography } from "@mui/material"
import { format } from "date-fns"
import { FC } from "react"
import { BiBlock } from "react-icons/bi"

export const ClockInOutCell: FC<{ clockTime: Date | null | undefined; photoUrl?: string | null | undefined }> = ({
  clockTime,
  photoUrl,
}) => {
  return (
    <div className="flex gap-1">
      {photoUrl ? (
        <img className="w-6 h-6 rounded" src={photoUrl} alt="time entry photo" />
      ) : (
        <div className="flex justify-center items-center w-6 h-6 rounded bg-gray-800 text-white">
          <BiBlock className="w-4 h-4 text-white" />
        </div>
      )}
      <Typography>{!!clockTime ? format(clockTime, "h:mm aaa") : ""}</Typography>
    </div>
  )
}

import { TextField } from "@mui/material"
import { FC, useRef, useState } from "react"
import { BiHealth } from "react-icons/bi"
import SignatureCanvas from "react-signature-canvas"
import { classNames } from "../../../helpers/classNames"
import { ButtonFilled, ButtonHollow } from "../../Elements"
import Checkbox from "../../Elements/Checkbox"
import { ModalBody } from "../../Modals/ModalBody"
import { ModalFooter } from "../../Modals/ModalFooter"
import { SignaturePad } from "../../SignaturePad"

type Props = {
  handleCancel: () => void
  handleSuccess: () => void
  setInjuryStatus: (isInjured: boolean) => void
  setInjuryNotes?: (notes: string) => void
  setSignature: (signature: Blob) => void
}

export const SignatureModalBody: FC<Props> = ({
  setSignature,
  setInjuryStatus,
  setInjuryNotes,
  handleSuccess,
  handleCancel,
}) => {
  const [isDisabled, setIsDisabled] = useState(true)
  const [isInjured, setInjuredState] = useState(false)
  const signatureCanvasRef = useRef<SignatureCanvas>(null)

  const setIsInjured = (b: boolean): void => {
    setInjuredState(b)
    setInjuryStatus(b)
  }

  return (
    <>
      <ModalBody>
        <div
          className={classNames(
            "flex justify-between p-4 bg-gray-100 rounded-lg mb-4 items-center text-gray-400 transition-all"
          )}
        >
          <div className="w-full">
            <div className="flex justify-between">
              <div>
                <p className={"uppercase font-semibold text-sm mb-4"}>Were you injured?</p>
                <div className="flex gap-8">
                  <label className={"flex gap-2 items-center"}>
                    <Checkbox checked={!isInjured} onChange={() => setIsInjured(false)} />
                    No
                  </label>
                  <label className={"flex gap-2 items-center"}>
                    <Checkbox
                      checked={isInjured}
                      onChange={() => setIsInjured(true)}
                      className={isInjured ? "text-orange-600" : ""}
                    />
                    Yes
                  </label>
                </div>
              </div>
              <BiHealth
                className={classNames(
                  "h-10 w-10 text-gray-400 mr-2 transition-all self-center",
                  isInjured && "text-orange-600"
                )}
              />
            </div>
            {isInjured && (
              <div className="pt-6 w-full">
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Injury report notes"
                  type="text"
                  multiline
                  onChange={(e) => setInjuryNotes?.(e.target.value)}
                />
              </div>
            )}
          </div>
        </div>
        <SignaturePad ref={signatureCanvasRef} setIsEmpty={setIsDisabled} />
      </ModalBody>
      <ModalFooter>
        <ButtonHollow type={"button"} onClick={handleCancel}>
          Cancel
        </ButtonHollow>
        <ButtonFilled
          disabled={isDisabled}
          type={"button"}
          onClick={() => {
            if (signatureCanvasRef.current && !isDisabled) {
              signatureCanvasRef.current.getCanvas().toBlob((blob) => {
                if (blob) {
                  setSignature(blob)
                  handleSuccess()
                }
              }, "image/webp")
            }
          }}
        >
          Next
        </ButtonFilled>
      </ModalFooter>
    </>
  )
}

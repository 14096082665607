import { isBefore } from "date-fns"
import { TimeEntry } from "../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../types/helpers"

export const canBeClockedOut = (
  timeEntry: PickPlus<TimeEntry, "startAt" | "endAt"> | undefined | null,
  proposedEndTime: Date,
  error?: string
) => {
  if (error) return false

  if (!timeEntry || timeEntry.endAt) return false

  return !isBefore(proposedEndTime, timeEntry.startAt)
}

import { Button, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import Box from "@mui/material/Box"
import { GridRowsProp } from "@mui/x-data-grid-pro"
import { ArrayHelpers, FieldArray, Form, Formik, useFormikContext } from "formik"
import { FC, FormEvent, useState } from "react"
import { BiCheck, BiLoaderAlt, BiTrash, BiX } from "react-icons/bi"
import { useQuery } from "urql"
import { v4 as uuidv4 } from "uuid"
import * as Yup from "yup"
import { AssetQuickAddQueryQuery, useAssetCreateMutation } from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { classNames } from "../../../helpers/classNames"
import { getVehicleData } from "../../../helpers/vinLookup"
import { useHandleError } from "../../../hooks/useHandleError"
import { ModalProps } from "../../../hooks/useModalProps"
import { AddButton } from "../../AddButton"
import { MultiSelect, MultiSelectOption } from "../../Formik/MultiSelect/MultiSelect"
import AssetWithAssigneeRow from "../../Formik/MultiSelect/Rows/AssetWithAssigneeRow"
import UserWithTaskRow from "../../Formik/MultiSelect/Rows/UserWithTaskRow"
import { StandardInput } from "../../Formik/StandardInput"
import { MuiModal } from "../../Modals/MuiModal"
import { ModalTitle } from "../../Modals/StandardModal"
import { infoSnack } from "../../Notistack/ThemedSnackbars"
import { ProjectBadge } from "../../ProjectBadge"

type AssetQuickAddTableProps = ModalProps & {
  parentAssetId?: string
}

const AssetQuickAddQuery = graphql(`
  query AssetQuickAddQuery {
    assets(deleted: false) {
      id
      active
      assetChildCount
      assignableId
      assignableType
      assignedAssetId
      assignedUserId
      companyAssetNumber
      deletedAt
      imageUrl
      isAssetGroup
      name
      ownershipType
      status
      manufacturer {
        id
      }
    }

    usersList(status: "active") {
      id
      currentProjectId
      currentTaskId
      firstName
      imageUrl
      jobTitle
      lastName
      projectId
      taskId
    }

    projectsByStatus(status: active) {
      id
      code
      name
      isArchived
      isComplete
      imageUrl
    }
  }
`)

const AssetQuickAddAssignableTaskDocument = graphql(`
  query AssetQuickAddAssignableTask($projectId: String!) {
    tasks(projectId: $projectId, status: active) {
      id
      name
    }
  }
`)

const getRowTemplate = (parentAssetId?: string) => {
  return {
    id: uuidv4(),
    assignableId: parentAssetId ? [parentAssetId] : [],
    assignableTo: parentAssetId ? "Asset" : "User",
    assignment: "",
    companyAssetNumber: "",
    manufacturer: {
      id: "",
      make: "",
      model: "",
      year: "",
    },
    name: "",
    vin: "",
  }
}

export const AssetQuickAddTable: FC<AssetQuickAddTableProps> = ({
  parentAssetId,
  contentLabel,
  isOpen,
  handleCloseModal,
}) => {
  const [{ data: assignableToQueryData, fetching: assignableToIsLoading, error: assignableToError }] = useQuery({
    query: AssetQuickAddQuery,
    pause: !isOpen,
  })
  useHandleError(assignableToError, "Could not load required data")

  const initialRows: GridRowsProp = Array.from({ length: 10 }, () => getRowTemplate(parentAssetId))
  const [{ fetching: createIsLoading }, createAssetMutation] = useAssetCreateMutation()

  const assets = assignableToQueryData?.assets || []
  const users = assignableToQueryData?.usersList || []
  const projects = assignableToQueryData?.projectsByStatus || []

  const rowSchema = Yup.object().shape(
    {
      // only required if vin or companyAssetNumber is provided
      name: Yup.string()
        .trim()
        .label("Name")
        .when(["companyAssetNumber", "manufacturer.id"], {
          is: (companyAssetNumber: string, manufacturerId: string) => companyAssetNumber || manufacturerId,
          then: (schema) => schema.required("Required"),
          otherwise: (schema) => schema,
        }),
      // only required if name or vin entered
      companyAssetNumber: Yup.string()
        .trim()
        .label("Company asset number")
        .when(["name", "manufacturer.id"], {
          is: (name: string, manufacturerId: string) => name || manufacturerId,
          then: (schema) =>
            schema.test("isUnique", "There is another asset with this number", (companyAssetNumber) => {
              return !assets.find((asset) => asset?.companyAssetNumber === companyAssetNumber)
            }),
          otherwise: (schema) => schema,
        }),
      assignableTo: Yup.string().required("Required"),
      // only required if name or companyAssetNumber entered
      assignableId: Yup.array()
        .of(Yup.string().label("Assignee"))
        .when(["name", "companyAssetNumber", "manufacturer.id"], {
          is: (name: string, companyAssetNumber: string, manufacturerId: string) =>
            name || companyAssetNumber || manufacturerId,
          then: (schema) => schema.length(1, "Required").required("Required"),
          otherwise: (schema) => schema,
        }),
      manufacturer: Yup.object().shape({
        id: Yup.string()
          .trim()
          .label("Vin / Serial number")
          .nullable()
          .test("isUnique", "There is another asset with this VIN / Serial number", (id) =>
            assets.every((asset) => (id ? asset?.manufacturer?.id !== id : true))
          ),
        year: Yup.string().label("Year").nullable(),
        make: Yup.string().trim().label("Manufacturer").nullable(),
        model: Yup.string().trim().label("Model").nullable(),
      }),
    },
    [
      ["companyAssetNumber", "name"],
      ["companyAssetNumber", "assignableId"],
      ["name", "assignableId"],
    ]
  )

  return (
    <>
      <Formik
        initialValues={{ rows: initialRows }}
        onSubmit={async (values, actions) => {
          const createValues = values.rows
            // do not submit rows that don't have a name, companyAssetNumber, and assignableId
            .filter((row) => row.name && row.companyAssetNumber && row.assignableId.length)
            .map((row) => ({
              assignableId: row.assignableId[0],
              assignableType: row.assignableTo,
              manufacturer: {
                ...row.manufacturer,
                id: row.manufacturer.id || undefined,
                year: row.manufacturer.year ? parseInt(row.manufacturer.year) : null,
              },
              companyAssetNumber: row.companyAssetNumber || undefined,
              name: row.name,
              ownershipType: "OWN",
            }))

          const successIndexes = new Set<number>()
          const failIndexes = new Set<number>()

          await Promise.all(
            createValues.map((value, index) =>
              createAssetMutation(value).then(
                (result) => {
                  if (result.error) {
                    failIndexes.add(index)
                    return
                  }

                  successIndexes.add(index)
                },
                (error) => {
                  failIndexes.add(index)
                  console.error("createAssetMutation", error)
                }
              )
            )
          )

          if ([...successIndexes].length > 0) {
            infoSnack(`Created ${[...successIndexes].length} assets`)
          }

          if ([...failIndexes].length > 0) {
            const failedRows = [...failIndexes].map((index) => values.rows.at(index))
            actions.setFieldValue("rows", failedRows)
            setTimeout(() => {
              actions.validateForm()
            }, 0)
          } else {
            actions.resetForm()
            handleCloseModal()
          }
        }}
        validationSchema={Yup.object().shape({
          rows: Yup.array().of(rowSchema),
        })}
      >
        {({ submitForm }) => {
          return (
            <MuiModal
              isOpen={isOpen}
              contentLabel={<ModalTitle>{contentLabel}</ModalTitle>}
              handleCloseModal={handleCloseModal}
              variant="large"
              submitForm={submitForm}
            >
              <AssetQuickAddTableForm
                assets={assets}
                users={users}
                projects={projects}
                assignableToIsLoading={assignableToIsLoading}
                isSaving={createIsLoading}
                hideAssignment={!!parentAssetId}
              />
            </MuiModal>
          )
        }}
      </Formik>
    </>
  )
}

type AssetRow = {
  id: string
  manufacturer: {
    id: string
    make: string
    model: string
    year: string
  }
  name: string
  companyAssetNumber: string
  assignableTo: string
  assignment: string
}

const AssetQuickAddTableForm: FC<{
  assets: AssetQuickAddQueryQuery["assets"]
  assignableToIsLoading: boolean
  isSaving: boolean
  projects: AssetQuickAddQueryQuery["projectsByStatus"]
  users: AssetQuickAddQueryQuery["usersList"]
  hideAssignment?: boolean
}> = ({ assets, assignableToIsLoading, hideAssignment, isSaving, projects, users }) => {
  const { values, setFieldValue } = useFormikContext<{
    rows: AssetRow[]
  }>()

  const handleAddRow = () => {
    setFieldValue("rows", values.rows.concat(getRowTemplate()))
  }

  return (
    <Form className={classNames(isSaving && "opacity-50", "transition-opacity")}>
      <Box>
        <Box className="rounded-lg pt-2">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left" variant="head">
                  VIN Lookup
                </TableCell>
                <TableCell align="left" variant="head">
                  Name
                </TableCell>
                <TableCell align="left" variant="head">
                  Asset Number
                </TableCell>
                {!hideAssignment && (
                  <TableCell
                    align="left"
                    sx={{
                      maxWidth: "3rem",
                    }}
                    variant="head"
                  >
                    Asgmt. Type
                  </TableCell>
                )}
                {!hideAssignment && (
                  <TableCell align="left" variant="head">
                    Assignment
                  </TableCell>
                )}
                <TableCell variant="head"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                "& tr:last-of-type .MuiTableCell-root": {
                  border: 0,
                },
                "& tr .MuiTableCell-body": {
                  borderBottom: 0,
                },
                "& .MuiTableRow-root:first-of-type .MuiTableCell-body": {
                  paddingTop: "1rem",
                },
                "& .MuiTableRow-root .MuiTableCell-body": {
                  verticalAlign: "top",
                  fontSize: "16px",
                  minWidth: "10rem",
                },
              }}
            >
              <FieldArray
                name="rows"
                render={(arrayHelpers: ArrayHelpers) =>
                  values.rows.map((row, index) => (
                    <QuickAddRow
                      assets={assets}
                      assignableToIsLoading={assignableToIsLoading}
                      key={row.id}
                      index={index}
                      arrayHelpers={arrayHelpers}
                      projects={projects}
                      users={users}
                      hideAssignment={hideAssignment}
                    />
                  ))
                }
              />
            </TableBody>
          </Table>
        </Box>
        <Box sx={{ marginTop: "1rem" }}>
          <AddButton label="Add row" onClick={handleAddRow} fullWidth />
        </Box>
      </Box>
    </Form>
  )
}

const QuickAddRow: FC<{
  assets: AssetQuickAddQueryQuery["assets"]
  assignableToIsLoading: boolean
  projects: AssetQuickAddQueryQuery["projectsByStatus"]
  users: AssetQuickAddQueryQuery["usersList"]
  index: number
  arrayHelpers: ArrayHelpers
  hideAssignment?: boolean
}> = ({ assets, assignableToIsLoading, hideAssignment, index, arrayHelpers, projects, users }) => {
  const [taskQueryVariables, setTaskQueryVariables] = useState({ projectId: "" })

  const [{ data: tasksData, fetching: tasksIsLoading, error: tasksError }, fetchTasksByProjectId] = useQuery({
    query: AssetQuickAddAssignableTaskDocument,
    variables: taskQueryVariables,
    pause: !taskQueryVariables.projectId,
  })
  useHandleError(tasksError, "Could not load required task data")

  const tasks = tasksData?.tasks || []

  const { values, setFieldValue, setFieldError } = useFormikContext<{
    rows: AssetRow[]
  }>()

  const rowValue = values.rows.at(index)

  const [vinLookupState, setVinLookupState] = useState<"initial" | "loading" | "success" | "error">("initial")

  return (
    <TableRow>
      <TableCell className="w-64">
        <div className="relative">
          <StandardInput
            className="pr-8"
            name={`rows.${index}.manufacturer.id`}
            placeholder="VIN Number"
            type="text"
            onChange={async (
              event: FormEvent<HTMLInputElement> & {
                target: HTMLInputElement
              }
            ) => {
              const target = event.target

              setFieldValue(`rows.${index}.manufacturer.id`, target.value)
              if (target.value.length === 17) {
                setVinLookupState("loading")
                // this looks like a vin
                const generateAssetName = (year: string, make: string, model: string, trim: string) => {
                  return `${year} ${make} ${model} ${trim}`.trim()
                }

                try {
                  const data = await getVehicleData(target.value)

                  setFieldValue(
                    `rows.${index}.name`,
                    generateAssetName(data?.year || "", data?.make || "", data?.model || "", data?.trim || "")
                  )
                  setFieldValue(`rows.${index}.manufacturer.make`, data?.make || "")
                  setFieldValue(`rows.${index}.manufacturer.model`, data?.model || "")
                  setFieldValue(`rows.${index}.manufacturer.year`, data?.year || "")
                  setVinLookupState("success")
                } catch (error) {
                  setVinLookupState("error")
                  setFieldError(
                    `rows.${index}.manufacturer.id`,
                    "Could not find vehicle data for this VIN. Please enter the vehicle name manually."
                  )
                  console.error("vin lookup error", error)
                }
              } else {
                setVinLookupState("initial")
              }
            }}
          />
          {vinLookupState !== "initial" && (
            <span className="absolute right-2 top-2">
              {vinLookupState === "loading" && <BiLoaderAlt className="p-1 h-6 w-6 animate-spin" />}
              {vinLookupState === "success" && <BiCheck className="h-6 w-6 text-blue-600" />}
              {vinLookupState === "error" && <BiX className="h-6 w-6" />}
            </span>
          )}
        </div>
      </TableCell>
      <TableCell>
        <StandardInput name={`rows.${index}.name`} placeholder="Name" type="text" />
      </TableCell>
      <TableCell>
        <StandardInput name={`rows.${index}.companyAssetNumber`} placeholder="Asset Number" type="text" />
      </TableCell>
      {!hideAssignment && (
        <TableCell className="w-32">
          <Select
            fullWidth
            onChange={(event) => {
              setFieldValue(`rows.${index}.assignableTo`, event.target.value)
            }}
            renderValue={rowValue?.assignableTo ? undefined : () => "Select a type"}
            value={rowValue?.assignableTo || "Select a type"}
          >
            <MenuItem key="asset" value="Asset">
              Asset
            </MenuItem>
            <MenuItem key="task" value="Task">
              Project
            </MenuItem>
            <MenuItem key="user" value="User">
              User
            </MenuItem>
          </Select>
        </TableCell>
      )}
      {!hideAssignment && (
        <TableCell className={classNames(assignableToIsLoading && "opacity-50", "transition-opacity !min-w-[280px]")}>
          {values.rows[index].assignableTo === "Task" && (
            <div className="grid lg:grid-cols-2 gap-y-1 lg:gap-2">
              <MultiSelect
                containerClassName="grow"
                placeholder="Select a project"
                options={[
                  projects.map((project) => {
                    return {
                      id: project.id,
                      label: project.name,
                      searchableTextString: project.name,
                      value: project.id,
                      template: (_item: MultiSelectOption) => <ProjectBadge project={project} noHover />,
                    }
                  }),
                ]}
                optionGroupHeadingTemplate={(title) => title}
                name={`rows.${index}.projectId`}
                isSingleSelect={true}
                onChange={(value) => {
                  if (value) {
                    setTaskQueryVariables({ projectId: value.at(0) || "" })
                    fetchTasksByProjectId()
                  }
                }}
                withErrorHandling={false}
              />
              <MultiSelect
                containerClassName="grow"
                disabled={!taskQueryVariables.projectId || tasksIsLoading}
                placeholder="Select a task"
                options={[
                  tasks.map((task) => {
                    return {
                      id: task.id,
                      label: task.name,
                      searchableTextString: task.name,
                      value: task.id,
                      template: (_item: MultiSelectOption) => <div>{task.name}</div>,
                    }
                  }),
                ]}
                optionGroupHeadingTemplate={(title) => title}
                name={`rows.${index}.assignableId`}
                isSingleSelect={true}
              />
            </div>
          )}
          {values.rows[index].assignableTo === "Asset" && (
            <MultiSelect
              containerClassName="grow"
              selectedLabel="assignee"
              options={[
                assets
                  ?.filter((asset) => asset?.id)
                  ?.map((asset) => {
                    return {
                      id: asset.id,
                      label: `${asset.name}`,
                      searchableTextString: `${asset.name} ${asset.companyAssetNumber}`,
                      value: asset.id,
                      template: (_item: MultiSelectOption) => <AssetWithAssigneeRow asset={asset} />,
                    }
                  }),
              ]}
              optionGroupHeadingTemplate={(title) => title}
              name={`rows.${index}.assignableId`}
              isSingleSelect
            />
          )}
          {values.rows[index].assignableTo === "User" && (
            <MultiSelect
              containerClassName="grow"
              selectedLabel="assignee"
              options={[
                users.map((user) => {
                  return {
                    id: user.id,
                    label: `${user.firstName} ${user.lastName}`,
                    searchableTextString: `${user.firstName} ${user.lastName}`,
                    value: user.id,
                    template: (_item: MultiSelectOption) => <UserWithTaskRow user={user} />,
                  }
                }),
              ]}
              optionGroupHeadingTemplate={(title) => title}
              name={`rows.${index}.assignableId`}
              isSingleSelect
            />
          )}
        </TableCell>
      )}
      <TableCell className="w-12 !min-w-[3rem]">
        <Button onClick={() => arrayHelpers.remove(index)}>
          <BiTrash className="text-gray-400 cursor-pointer w-5 h-5" />
        </Button>
      </TableCell>
    </TableRow>
  )
}

import { Grid } from "@mui/material"
import { NextPage } from "next"
import { FC, useMemo } from "react"
import { useQuery } from "urql"
import { AssetBadge } from "../../../components/AssetBadge"
import { H5 } from "../../../components/Elements"
import { PageTitle } from "../../../components/PageTitle"
import { Asset } from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { colors } from "../../../helpers/colors"
import { useHandleError } from "../../../hooks/useHandleError"
import AssetsPageLayout from "./_layout"

type AssetAssetsProps = {
  assetId: string
}

const AssetAssetsDocument = graphql(
  `
    query AssetAssets($assetId: String!) {
      asset(id: $assetId) {
        id
        name

        childAssets {
          id
          active
          assetGroupId
          assignableId
          assignableType
          companyAssetNumber
          groupQuantity
          imageUrl
          isAssetGroup
          name
          ownershipType
          status
        }
      }
    }
  `
)

export const AssetAssets: FC<AssetAssetsProps> = ({ assetId }) => {
  const [{ data, error }] = useQuery({
    query: AssetAssetsDocument,
    variables: { assetId },
  })
  useHandleError(error, "An error occurred while fetching asset details. Please reload the page to try again.")

  const { asset, childAssets } = useMemo(() => {
    if (!data?.asset) {
      return {
        asset: {} as Asset,
        childAssets: [],
      }
    }

    return {
      asset: data.asset,
      childAssets: data.asset.childAssets?.filter(Boolean) || [],
    }
  }, [data])

  return (
    <>
      <PageTitle title={`${asset?.name || "Asset"} assets`} />
      <H5>Nested Assets</H5>
      <div className="relative">
        {childAssets.length > 0 && (
          <Grid container gap={1}>
            {childAssets?.map((asset) => (
              <Grid
                key={asset.id}
                item
                md={3}
                xs={12}
                border="1px solid"
                borderColor={colors.gray[200]}
                borderRadius="8px"
                alignItems="center"
                padding="10px"
                height="64px"
              >
                <AssetBadge asset={{ ...asset, assetChildCount: 0 }} />
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    </>
  )
}

const AssetAssetsPage: NextPage<AssetAssetsProps> = ({ assetId }) => {
  return (
    <AssetsPageLayout assetId={assetId}>
      <AssetAssets assetId={assetId} />
    </AssetsPageLayout>
  )
}

AssetAssetsPage.getInitialProps = async ({ query }) => {
  return {
    assetId: query._id as string,
  }
}

export default AssetAssetsPage

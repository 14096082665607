import { Button } from "@mui/material"
import { differenceInMinutes, format } from "date-fns"
import { FC, useContext, useState } from "react"
import { BiCamera, BiHealth, BiTimeFive } from "react-icons/bi"
import { FaSignature } from "react-icons/fa"
import { TimeEntry, User, useClockOutUserMutation } from "../../../graphql/generated/client-types-and-hooks"
import { toastClockOutError } from "../../../helpers/toastClockOutError"
import { uploadTimeEntryEvidence } from "../../../helpers/uploadTimeEntryEvidence"
import { GeolocationProviderContext } from "../../../providers/GeolocationProvider"
import { PickPlus } from "../../../types/helpers"
import { MuiModal } from "../../Modals/MuiModal"
import StandardModal from "../../Modals/StandardModal"
import { PhotoBooth } from "../../PhotoBooth"
import { ModalTitle } from "./ModalTitle"
import { RecapRow } from "./RecapRow"
import { SignatureModalBody } from "./SignatureModalBody"

type Props = {
  user: PickPlus<User, "id" | "firstName" | "isClockedIn" | "lastName"> & {
    latestTimeEntry?: PickPlus<TimeEntry, "id" | "startAt" | "endAt"> | null
  }
  className?: string
  onSuccess?: () => void
  disabled?: boolean
}

export const ClockOutWithEvidence: FC<Props> = ({ user, onSuccess = () => {}, disabled = false }) => {
  const [photo, setPhoto] = useState<Blob>()
  const [signature, setSignature] = useState<Blob>()
  const [photoBoothIsOpen, setPhotoBoothIsOpen] = useState(false)
  const [signatureModalIsOpen, setSignatureModalIsOpen] = useState(false)
  const [clockOutRecapModalIsOpen, setClockOutRecapModalIsOpen] = useState(false)
  const [isInjured, setIsInjured] = useState(false)
  const [injuryNotes, setInjuryNotes] = useState("")

  const { location, locationError } = useContext(GeolocationProviderContext)

  const [_, clockOutMutation] = useClockOutUserMutation()
  const [isLoading, setIsLoading] = useState(false)

  const onAcceptPhoto = (image: Blob) => {
    setPhoto(image)
    setSignatureModalIsOpen(true)
  }

  const handleClockOut = () => {
    if (photo && signature) {
      setIsLoading(true)
      Promise.all([uploadTimeEntryEvidence(photo, user.id), uploadTimeEntryEvidence(signature, user.id)])
        .then(([imagePath, signaturePath]) => {
          const endEvidence = {
            location,
            locationError: locationError?.message || undefined,
            imagePath,
            signaturePath,
            injured: isInjured,
            injuryNotes,
          }

          clockOutMutation({
            userId: user.id,
            endEvidence,
          }).then((result) => {
            if (result.error) {
              toastClockOutError(result.error, user)
            } else {
              setClockOutRecapModalIsOpen(false)
              onSuccess()
            }

            setIsLoading(false)
          })
        })
        .catch((e) => {
          setIsLoading(false)
          console.error(e)
        })
    }
  }

  return (
    <>
      <Button
        disabled={disabled}
        color="primary"
        variant="outlined"
        fullWidth
        sx={{
          padding: "0.375rem",
          display: "flex",
          alignItems: "center",
          minWidth: "120px",
          width: { md: "150px" },
          justifyContent: "center",
        }}
        onClick={() => setPhotoBoothIsOpen(true)}
      >
        <span>Clock out</span>
      </Button>
      <PhotoBooth
        title={<ModalTitle user={user} type="Out" />}
        isOpen={photoBoothIsOpen}
        setIsOpen={setPhotoBoothIsOpen}
        onAcceptPhoto={onAcceptPhoto}
        onFail={(message) => {
          //  TODO: Render messages and bail.
          console.error(message)
        }}
        withFaceFrame={true}
      />
      <StandardModal
        isOpen={signatureModalIsOpen}
        contentLabel={<ModalTitle user={user} type="Out" />}
        handleCloseModal={() => {
          setSignatureModalIsOpen(false)
        }}
      >
        <SignatureModalBody
          handleCancel={() => setSignatureModalIsOpen(false)}
          handleSuccess={() => {
            setSignatureModalIsOpen(false)
            setClockOutRecapModalIsOpen(true)
          }}
          setInjuryStatus={setIsInjured}
          setInjuryNotes={setInjuryNotes}
          setSignature={setSignature}
        />
      </StandardModal>
      <MuiModal
        isOpen={clockOutRecapModalIsOpen}
        isLoading={isLoading}
        contentLabel={<ModalTitle user={user} type="Out" />}
        submitForm={handleClockOut}
        handleCloseModal={() => {
          setClockOutRecapModalIsOpen(false)
        }}
        submitButtonText="Clock Out"
      >
        <div className="flex flex-col gap-2">
          <RecapRow Icon={BiCamera} image={photo}>
            ✓ Time entry photo
          </RecapRow>
          <RecapRow Icon={FaSignature} image={signature}>
            ✓ Signature
          </RecapRow>
          <RecapRow Icon={BiTimeFive}>{format(new Date(), "h:mm aaa")}</RecapRow>
          <RecapRow className={isInjured ? "text-orange-400" : ""} Icon={BiHealth} injured={isInjured}>
            {isInjured ? "Injured" : "Not injured"}
          </RecapRow>
        </div>
        <p className="mt-4 text-gray-400">
          Clock out {user.firstName} {user.lastName} on {format(new Date(), "E, MMMM d yyyy")} at{" "}
          {format(new Date(), "h:mm aaa")}
        </p>
        <p className="mt-4 text-gray-400">
          Total time:{" "}
          {Math.floor(
            differenceInMinutes(new Date(), user?.latestTimeEntry ? user?.latestTimeEntry.startAt : new Date()) / 60
          )}{" "}
          hr {differenceInMinutes(new Date(), user?.latestTimeEntry ? user?.latestTimeEntry.startAt : new Date()) % 60}{" "}
          min
        </p>
      </MuiModal>
    </>
  )
}

import { FC } from "react"
import { SkeletonContainer } from "../../Skeletons/SkeletonContainer"
import { SkeletonElement } from "../../Skeletons/SkeletonElement"
import { UserBadgeSkeleton } from "../User/UserBadge.skeleton"

export const AssetQuantitySkeleton: FC = () => {
  return (
    <SkeletonContainer>
      <SkeletonElement className={"h-7 w-36 mt-2 mb-4"} />
      <div className={"flex flex-col md:flex-row justify-between border-gray-200 border-y py-6 gap-6 md:items-center"}>
        <UserBadgeSkeleton />
        <SkeletonElement className={"h-10 w-96 mb-4"} />
      </div>
      <div className={"flex flex-col md:flex-row justify-between border-gray-200 border-b py-6 gap-6 md:items-center"}>
        <div className={"flex flex-col gap-2"}>
          <SkeletonElement className={"h-6 w-24"} />
          <SkeletonElement className={"h-4 w-36"} />
        </div>
        <SkeletonElement className={"h-10 w-32"} />
      </div>
    </SkeletonContainer>
  )
}

import { useField } from "formik"
import { FC, useCallback, useContext, useEffect, useState } from "react"
import { useQuery } from "urql"
import { DeliverableUnit } from "../graphql/generated/client-types-and-hooks"
import { graphql } from "../graphql/generated/gql"
import { uniqBy } from "../helpers/util-functions"
import { useHandleError } from "../hooks/useHandleError"
import { useModalProps } from "../hooks/useModalProps"
import { OrganizationSettingsContext } from "../providers/OrganizationSettingsProvider"
import { PermissionsContext } from "../providers/PermissionsProvider/PermissionsProvider"
import { AddOrEditUnitModal } from "./CustomReportingUnits/AddOrEditUnitModal"
import { CreatableAutocomplete } from "./Formik/CreatableAutocomplete"
import { StandardInput } from "./Formik/StandardInput"

const UnitInputQueryDocument = graphql(`
  query UnitInput {
    deliverableUnits {
      id
      description
      unitOfMeasure
    }
  }
`)

type UnitInputProps = {
  label?: string
  deliverableUnitId?: string
  disabled?: boolean
  placeholder?: string
  chevron?: boolean
  opacity?: string
  isCreatable?: boolean
}

export const UnitInput: FC<UnitInputProps> = ({
  label = "Unit",
  disabled = false,
  deliverableUnitId,
  placeholder,
  isCreatable = false,
}) => {
  const [{ data: deliverableUnitsData, error: unitsError }, refetchDeliverableUnits] = useQuery({
    query: UnitInputQueryDocument,
  })

  const { unitsOfMeasure } = useContext(OrganizationSettingsContext)

  useHandleError(unitsError, "Error fetching units")

  const { hasPermissionTo } = useContext(PermissionsContext)
  const hasPermissionToCreateUnit = hasPermissionTo("unit:create")
  const shouldBeCreatable = isCreatable && hasPermissionToCreateUnit && !disabled

  const [deliverableUnitIdField, ,] = useField("deliverableUnitId")

  const deliverableUnits = uniqBy(deliverableUnitsData?.deliverableUnits || [], (unit) => unit.description)

  const selected = deliverableUnits.find(({ id }) => id === deliverableUnitIdField.value)
  const { unitOfMeasure, description } = selected || {}
  const uom = unitsOfMeasure.find((uom) => uom.name === unitOfMeasure)

  return (
    <>
      <div className="grid grid-cols-12 gap-2.5 md:gap-2">
        <div className="col-span-12 md:col-span-8 h-10">
          <CreatableUnitSelect
            disabled={disabled}
            defaultValue={deliverableUnitId}
            label={label}
            name="deliverableUnitId"
            placeholder={placeholder || "Select Unit"}
            deliverableUnits={deliverableUnits}
            refetchDeliverableUnits={refetchDeliverableUnits}
            shouldBeCreatable={shouldBeCreatable}
          />
        </div>
        <div className="col-span-12 md:col-span-4 flex">
          <StandardInput
            name="unitGoalTarget"
            placeholder="Amount"
            type="number"
            disabled={!description}
            required={true}
            errorStyle="contained"
            containerClassName="grow"
            inputClassName={unitOfMeasure ? "rounded-r-none" : ""}
          />
          {unitOfMeasure && (
            <div className="flex px-2 items-center rounded-r border border-l-0 bg-gray-50  border-gray-400 text-sm h-full">
              {uom?.symbol || unitOfMeasure}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

type CreatableUnitSelectProps = {
  label?: string
  defaultValue?: string
  disabled?: boolean
  placeholder?: string
  deliverableUnits: Pick<DeliverableUnit, "id" | "description">[]
  name: string
  refetchDeliverableUnits: () => void
  shouldBeCreatable?: boolean
}

export const CreatableUnitSelect: FC<CreatableUnitSelectProps> = ({
  disabled = false,
  defaultValue,
  label = "Unit",
  placeholder,
  deliverableUnits,
  name,
  refetchDeliverableUnits,
  shouldBeCreatable,
}) => {
  const [, , deliverableUnitIdHelpers] = useField(name)
  const newDeliverableUnitModalProps = useModalProps("Add New Unit")

  const [descriptionToCreate, setDescriptionToCreate] = useState("")

  const onCreateUnit = useCallback(
    (value: string) => {
      newDeliverableUnitModalProps.handleOpenModal()
      setDescriptionToCreate(value)
    },
    [newDeliverableUnitModalProps]
  )

  const onSuccess = useCallback(
    (data: Partial<DeliverableUnit>) => {
      if (data?.id) deliverableUnitIdHelpers.setValue(data.id)
      newDeliverableUnitModalProps.handleCloseModal()
      refetchDeliverableUnits()
    },
    [deliverableUnitIdHelpers, newDeliverableUnitModalProps, refetchDeliverableUnits]
  )

  useEffect(() => {
    deliverableUnitIdHelpers.setValue(defaultValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue])

  return (
    <>
      <CreatableAutocomplete
        disabled={disabled}
        defaultValue={defaultValue}
        label={label}
        name={name}
        placeholder={placeholder || "Select Unit"}
        options={deliverableUnits.map((unit) => ({
          id: unit.id,
          label: unit.description,
        }))}
        onCreate={shouldBeCreatable ? onCreateUnit : undefined}
      />
      {newDeliverableUnitModalProps.isOpen && (
        <AddOrEditUnitModal
          {...newDeliverableUnitModalProps}
          initialValues={{ description: descriptionToCreate }}
          onSuccess={onSuccess}
        />
      )}
    </>
  )
}

import { Skeleton } from "@mui/material"

import { Form, Formik, FormikHelpers, FormikValues } from "formik"
import { FC, useContext } from "react"
import * as Yup from "yup"
import {
  AssetReportType,
  NestedAssetInspectionTemplatesQuery,
  useInsertManyAssetReportsMutation,
} from "../../../graphql/generated/client-types-and-hooks"
import { isOperational } from "../../../helpers/assetStatus"
import { AssetInspectionWizardContext } from "../../../providers/AssetInspectionWizardProvider"
import { errorSnack, successSnack } from "../../Notistack/ThemedSnackbars"
import { SingleDrawerContext } from "../Drawer/Drawer"
import { InspectionReportContainer } from "./InspectionReportContainer"

type Template =
  NestedAssetInspectionTemplatesQuery["nestedAssets"][0]["inspectionTemplatesAssignments"][0]["inspectionTemplate"]

export const InspectionReportForm: FC<{
  assetId: string
  template: Template
}> = ({ assetId, template }) => {
  const { activeStep, handleComplete, steps } = useContext(AssetInspectionWizardContext)
  const assetName = steps[activeStep]

  const { handleClose } = useContext(SingleDrawerContext)
  const [, createAssetReportsMutation] = useInsertManyAssetReportsMutation()
  const isOnLastStep = activeStep === steps.length - 1

  const templateFields = template.fields.reduce(
    (fields, field) => ({
      ...fields,
      [field.id]: undefined,
      [`${field.id}-photos`]: undefined,
    }),
    {}
  )

  const initialValues = { ...templateFields, status: null, notes: "" }

  const onSubmit = (values: FormikValues, helpers: FormikHelpers<any>) => {
    const inspectionReport =
      template?.fields
        ?.map((field) => {
          const input = values[field.id]?.toString()
          const fileIds = values[field.id + "-photos"]?.map((photo: any) => photo.fileId)
          const photos = values[field.id + "-photos"]?.map((photo: any) => photo.objectKey)

          return {
            ...field,
            input,
            fileIds,
            photos,
          }
        })
        .filter((field) => {
          return (typeof field.input === "string" && field.input) || field.fileIds !== undefined
        }) || []

    const [status] = values.status || []
    const report = {
      assetId,
      inspectionReport,
      assetReportTemplateId: template?.id,
      statusChange: {
        status,
        active: isOperational(status),
      },
      type: AssetReportType.Inspection,
      notes: values.notes,
    }

    createAssetReportsMutation({ reports: [report] }).then((result) => {
      if (result.error) {
        errorSnack("Error reporting")
      } else {
        if (isOnLastStep) {
          handleClose()
        } else {
          helpers.resetForm({ values: initialValues })
          handleComplete()
        }

        successSnack(`Successfully submitted inspection for ${assetName}`)
      }
    })
  }

  const validate = (values: FormikValues) => {
    const errors: { [key: string]: string } = {}

    template?.fields.map((item) => {
      if (item.photoRequired && !values[item.id + "-photos"]?.length) {
        errors[`${item.id}-photos`] = "Required"
      }
    })

    return errors
  }

  if (!template) {
    return <Skeleton />
  }

  const fieldsSchema = template.fields.reduce(
    (schema, field) => ({
      ...schema,
      [field.id]: field.type === "y/n" ? Yup.boolean() : Yup.string(),
      [`${field.id}-photos`]: field.photoRequired
        ? Yup.array()
            .of(
              Yup.object().shape({
                fileId: Yup.string(),
                objectKey: Yup.string(),
                uploaded: Yup.boolean(),
              })
            )
            .required("Required")
        : Yup.array().of(
            Yup.object().shape({
              fileId: Yup.string(),
              objectKey: Yup.string(),
              uploaded: Yup.boolean(),
            })
          ),
    }),
    {}
  )

  const schema = Yup.object().shape({
    ...fieldsSchema,
    status: Yup.array(Yup.string()).required("Required field"),
    notes: Yup.string(),
  })

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={schema}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
    >
      <Form>
        <InspectionReportContainer template={template} assetId={assetId} />
      </Form>
    </Formik>
  )
}

import { format } from "date-fns"
import { PickPlus } from "../types/helpers"
import { User } from "../types/User"
import { infoSnack, errorSnack } from '../components/Notistack/ThemedSnackbars'

export const toastClockInError = (error: Error, user: PickPlus<User, "firstName" | "lastName">) => {
  switch (error.message) {
    case "ALREADY_CLOCKED_IN":
      infoSnack(`${user.firstName} ${user.lastName} is already clocked in.`)
      break
    case "ATTEMPTED_DOUBLE_BOOKING":
      infoSnack(
        `${user.firstName} ${user.lastName} is unable to be clocked in because the specified time (${format(
          new Date(),
          "h:mm aaa"
        )}) overlaps with a previous clock-in`
      )
      break
    default:
      errorSnack(`There was an error clocking ${user.firstName} ${user.lastName} in`)
      console.error(error)
      break
  }
}

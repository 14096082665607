import { Typography } from "@mui/material"
import { orange } from "@mui/material/colors"
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro"
import { format } from "date-fns"
import { FC } from "react"
import { BiDislike } from "react-icons/bi"
import { useQuery } from "urql"
import { graphql } from "../../../graphql/generated/gql"
import { pluralize } from "../../../helpers/pluralize"
import { formatDuration, getDuration } from "../../../helpers/time-utility"
import { useHandleError } from "../../../hooks/useHandleError"

type InspectionFails = {
  id?: string | null
  input?: string | null
  label?: string | null
  photoLabel?: string | null
  type?: string | null
}[]

const InspectionFailsDocument = graphql(`
  query InspectionFailsQuery($assetId: String!) {
    assetReports(assetId: $assetId) {
      id
      createdAt
      updatedAt
      inspectionReport {
        id
        input
        label
        photoLabel
        type
      }
    }
  }
`)

export const FailedInspectionsDataGrid: FC<{ assetId: string }> = ({ assetId }) => {
  const [{ data, error }] = useQuery({
    query: InspectionFailsDocument,
    variables: { assetId },
    pause: !assetId,
  })

  useHandleError(error, "There was a problem retrieving failed inspection items. Please try again.")

  const columns: GridColDef[] = [
    {
      field: "result",
      headerName: "Result",
      flex: 1,
      renderCell: () => <BiDislike className="bg-orange-600 text-white p-1 rounded h-6 w-6" />,
    },
    {
      field: "label",
      headerName: "Name",
      flex: 2,
    },
    {
      field: "photoLabel",
      headerName: "Label",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 2,
      valueGetter: (params) => format(params.row.createdAt, "yyyy-MM-dd"),
    },
    {
      field: "duration",
      headerName: "Timer",
      flex: 2,
      renderCell: (params) => {
        const duration = getDuration(params.row.createdAt, new Date())
        let time = ""

        if (duration.hours > 24) {
          const durationDays = Math.floor(duration.hours / 24)
          time = `${durationDays} ${pluralize("day", durationDays)}`
        } else {
          const formatted = formatDuration(duration)
          time = `${formatted.hours} ${formatted.minutes}`
        }

        return <Typography color={orange[600]}>{time}</Typography>
      },
    },
  ]

  if (!data?.assetReports) {
    return null
  }

  const inspectionFails = data.assetReports.reduce<InspectionFails>((failedItems, report) => {
    const failed = report?.inspectionReport?.filter((item) => item?.type === "y/n" && item?.input === "false")

    if (!failed?.length) {
      return failedItems
    }

    const failedInspectionItems = failed.map((item) => ({
      ...item,
      id: `${report.id}:${item.id}`,
      createdAt: report.createdAt,
    }))

    return [...failedItems, ...failedInspectionItems]
  }, [])

  return inspectionFails?.length ? <DataGridPro columns={columns} rows={inspectionFails} /> : null
}

import { createContext, FC, ReactNode, useState } from "react"
import Modal from "react-modal"
import { LoadingContainer } from "../Loading/LoadingContainer"
import { LoadingIndicator } from "../Loading/LoadingIndicator"

type Props = {
  children?: ReactNode
  className?: string
  contentLabel: ReactNode
  handleCloseModal: () => void
  isOpen: boolean
}

type ContextProps = {
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
}

export const ModalLoadingContext = createContext<ContextProps>({} as ContextProps)

const LoadingIndicatorModal: FC<Props> = ({
  children,
  isOpen,
  contentLabel,
  handleCloseModal,
  className = "",
  ...props
}) => {
  Modal.setAppElement("#__next")
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Modal
      className="bg-transparent border-0 w-full max-w-lg flex justify-center items-center mx-auto focus:outline-none"
      overlayClassName={`absolute z-50 w-screen h-screen top-0 flex items-center justify-center px-4 bg-gray-900 bg-opacity-60`}
      {...props}
      isOpen={isOpen}
      onRequestClose={isLoading ? () => {} : handleCloseModal}
      closeTimeoutMS={200}
      shouldCloseOnOverlayClick={false}
    >
      <div className={`rounded-lg bg-white w-full max-h-screen sm:h-auto overflow-y-auto ${className}`}>
        <ModalLoadingContext.Provider value={{ isLoading, setIsLoading }}>
          <LoadingContainer isLoading={isLoading}>
            <div className="relative p-8 lg:m-4">
              <main className="mt-4 flex justify-center">
                <LoadingIndicator />
              </main>
            </div>
          </LoadingContainer>
        </ModalLoadingContext.Provider>
      </div>
    </Modal>
  )
}
export default LoadingIndicatorModal

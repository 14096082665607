import { Button } from "@mui/material"
import { FC, useContext } from "react"
import { BiWrench } from "react-icons/bi"
import { Asset, AssetReportTemplate } from "../graphql/generated/client-types-and-hooks"
import { AssetInspectionTemplateAssignments } from "../graphql/generated/gql/graphql"
import { useModalProps } from "../hooks/useModalProps"
import { AssetInspectionWizardProvider } from "../providers/AssetInspectionWizardProvider"
import { DevelopmentFeatureFlagContext } from "../providers/DevelopmentFeatureFlagProvider"
import { DrawerContext } from "../providers/DrawerProvider"
import { PickPlus } from "../types/helpers"
import { AssetInspectionReportModal } from "./AssetInspectionReportModal"
import { InspectionReportDrawer } from "./Partials/Drawer/InspectionReportDrawer"

type ReportTemplateRowProps = {
  assetId: string
  template: PickPlus<AssetReportTemplate, "id" | "name">
  includeIcon?: boolean
  className?: string
}

type InspectionTemplatesAssignments = (PickPlus<
  AssetInspectionTemplateAssignments,
  "id" | "intervalInSeconds" | "lastReportedAt" | "startDate"
> & { inspectionTemplate: PickPlus<AssetReportTemplate, "id" | "name"> })[]

type InspectButtonProps = {
  asset: PickPlus<Asset, "id"> & {
    inspectionTemplatesAssignments: InspectionTemplatesAssignments
  }
  includeIcon?: boolean
  className?: string
}

export const InspectButton: FC<InspectButtonProps> = ({ asset, includeIcon = false, className }) => {
  return (
    <>
      {asset?.inspectionTemplatesAssignments?.length === 0 && (
        <Button
          className={className}
          color="black"
          disabled
          type="button"
          variant="contained"
          startIcon={includeIcon ? <BiWrench className="h-5 w-5" /> : null}
        >
          Inspect
        </Button>
      )}
      {asset?.inspectionTemplatesAssignments?.map(({ inspectionTemplate }) => (
        <ReportButton
          key={inspectionTemplate.id}
          assetId={asset.id}
          template={inspectionTemplate}
          includeIcon={includeIcon}
          className={className}
        />
      ))}
    </>
  )
}

export const ReportButton: FC<ReportTemplateRowProps> = ({ assetId, template, includeIcon = false, className }) => {
  const inspectionCheckListProps = useModalProps("Inspection Checklist")
  const { flagIsEnabled } = useContext(DevelopmentFeatureFlagContext)
  const { push: pushDrawer } = useContext(DrawerContext)

  const onReport = () => {
    if (flagIsEnabled("Custom Inspection Templates")) {
      pushDrawer(
        <AssetInspectionWizardProvider>
          <InspectionReportDrawer assetId={assetId} />
        </AssetInspectionWizardProvider>
      )
    } else {
      inspectionCheckListProps.handleOpenModal()
    }
  }

  return (
    <>
      <Button
        className={className}
        color="black"
        onClick={onReport}
        type="button"
        variant="contained"
        startIcon={includeIcon ? <BiWrench className="h-5 w-5" /> : null}
      >
        Inspect
      </Button>

      {inspectionCheckListProps.isOpen && (
        <AssetInspectionReportModal
          assetId={assetId}
          assetReportTemplateId={template.id}
          formModalProps={inspectionCheckListProps}
        />
      )}
    </>
  )
}

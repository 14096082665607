import { Button } from "@mui/material"
import { AssetAssignableType } from "@prisma/client"
import { Form, Formik } from "formik"
import Link from "next/link"
import { FC, useContext, useMemo } from "react"
import { BiCheck, BiError, BiLayer, BiTask, BiWrench } from "react-icons/bi"
import { useQuery } from "urql"
import { userBadgeTestSelectors } from "../../../cypress/support/testSelectors"
import { AssetReportTemplate, useAssetEditMutation } from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { Available, getAssetStatusOption, isOperational, stringToAssetStatus } from "../../../helpers/assetStatus"
import { classNames } from "../../../helpers/classNames"
import { getFullName } from "../../../helpers/getFullName"
import { useHandleError } from "../../../hooks/useHandleError"
import { DrawerContext } from "../../../providers/DrawerProvider"
import { PermissionsContext } from "../../../providers/PermissionsProvider/PermissionsProvider"
import { PickPlus } from "../../../types/helpers"
import { AssetImage } from "../../AssetImage"
import { H5 } from "../../Elements"
import { AssetStatusMultiSelect } from "../../Formik/MultiSelect/implementations/AssetStatusMultiSelect"
import { InspectButton } from "../../InspectionButton"
import { InventoryButton } from "../../InventoryButton"
import { MonogramRing } from "../../Monogram"
import { ProjectImage } from "../../ProjectImage"
import { QuickActionContainer } from "../../QuickActionContainer"
import { QuickActionRow } from "../../QuickActionRow"
import { AssetDrawer } from "../Drawer/AssetDrawer"
import { DrawerLink } from "../Drawer/DrawerLink"
import { ReassignAssetDrawer } from "../Drawer/ReassignAssetDrawer"
import { TaskDrawer } from "../Drawer/TaskDrawer"
import { UserDrawer } from "../Drawer/UserDrawer"
import { AssetInspectionDueIn, AssetInventoryDueIn } from "./ReportDueIn"

const AssetQuickActionsDocument = graphql(`
  query AssetQuickActions($assetId: String!) {
    asset(id: $assetId) {
      id
      name
      active
      assetChildCount
      assetGroupId
      assignableId
      assignableType
      compositeKey
      companyAssetNumber
      isAssetGroup
      lastInventoriedAt
      ownershipType
      status
      inferredOwner {
        id
      }
      inferredProjectId
      inventoryRequirements {
        photoRequired
        intervalInSeconds
      }
      assignedUser {
        id
        firstName
        imageUrl
        isClockedIn
        jobTitle
        lastName
        currentProject {
          id
          name
        }
        currentTask {
          id
          name
        }
      }
      assignedAsset {
        id
        assignableId
        companyAssetNumber
        compositeKey
        name
      }
      assignedTask {
        id
        name
        project {
          id
          name
          imageUrl
          isArchived
          isComplete
        }
      }
      inspectionTemplatesAssignments {
        id
        intervalInSeconds
        lastReportedAt
        startDate
        inspectionTemplate {
          id
          name
        }
      }
    }
    assetGroups(assetGroupId: $assetId) {
      assetGroupId
      assignableId
      assignableType
      assignedUserId
      count
      status
    }
  }
`)

type AssetQuickActionsProps = {
  assetId: string
  template: PickPlus<AssetReportTemplate, "id" | "name">
}

export const AssetQuickActions: FC<AssetQuickActionsProps> = ({ assetId }) => {
  const [{ data, fetching, error }, fetch] = useQuery({
    query: AssetQuickActionsDocument,
    variables: { assetId },
  })

  useHandleError(error, "An error occurred while fetching asset details. Please reload the page to try again.")

  const asset = useMemo(() => {
    if (!data?.asset) return undefined

    return data.asset
  }, [data])

  const { project, task, assignedAsset, assignedUser, assignedTask } = useMemo(() => {
    if (!data?.asset?.assignedAsset && !data?.asset?.assignedUser && !data?.asset?.assignedTask) {
      return {
        project: null,
        task: null,
        assignedAsset: null,
        assignedUser: null,
        assignedTask: null,
      }
    }

    const assignedUser = data.asset.assignedUser
    const assignedAsset = data.asset.assignedAsset
    const assignedTask = data.asset.assignedTask
    const task = assignedUser?.currentTask || null
    const project = assignedUser?.currentProject || assignedTask?.project || null

    return {
      project,
      task,
      assignedAsset,
      assignedUser,
      assignedTask,
    }
  }, [data?.asset?.assignedAsset, data?.asset?.assignedUser, data?.asset?.assignedTask])

  const { push: pushDrawer } = useContext(DrawerContext)

  const { hasPermissionTo } = useContext(PermissionsContext)
  const noReassignPermission = !hasPermissionTo("asset:transfer", {
    userId: data?.asset?.inferredOwner?.id,
    projectId: data?.asset?.inferredProjectId,
  })
  const noViewTaskPermission = !hasPermissionTo("task:read", { projectId: data?.asset?.inferredProjectId })
  const noReportAssetPermission = !hasPermissionTo("asset:report", {
    userId: data?.asset?.inferredOwner?.id,
    projectId: data?.asset?.inferredProjectId,
  })

  const statusOption = getAssetStatusOption(data?.asset?.status || Available)

  const [_, editAssetMutation] = useAssetEditMutation()

  useHandleError(error, "An error occurred while fetching data. Please reload the page to try again.")

  return (
    <div className={classNames("transition-opacity", fetching && "opacity-50")}>
      <H5>Quick Actions</H5>
      <hr />

      {asset && (
        <QuickActionContainer>
          {assignedUser && !asset.isAssetGroup && (
            <>
              <QuickActionRow
                disableIconBackground
                icon={
                  <DrawerLink
                    href={`/team/${assignedUser?.id}/details`}
                    className="text-left"
                    component={<UserDrawer userId={assignedUser.id} />}
                    data-test={userBadgeTestSelectors.name}
                  >
                    <MonogramRing user={assignedUser} />
                  </DrawerLink>
                }
                title="Current Assignment"
                subTitle={
                  <>
                    <p>
                      <DrawerLink
                        className="hover:underline"
                        href={`/team/${assignedUser?.id}/details`}
                        component={<UserDrawer userId={assignedUser.id} />}
                        data-test={userBadgeTestSelectors.name}
                      >
                        {getFullName(assignedUser)}
                      </DrawerLink>
                    </p>
                    <p>/</p>
                    <p>
                      <DrawerLink
                        className="hover:underline"
                        href={`/team/${assignedUser?.id}/details`}
                        component={<UserDrawer userId={assignedUser.id} />}
                        data-test={userBadgeTestSelectors.name}
                      >
                        {assignedUser.jobTitle}
                      </DrawerLink>
                    </p>
                  </>
                }
                action={
                  <Button
                    color="black"
                    disabled={noReassignPermission}
                    onClick={() => {
                      pushDrawer(
                        <ReassignAssetDrawer
                          assetsIdsToReassign={asset.isAssetGroup ? [] : [asset.id]}
                          assetGroupToReassign={
                            asset.isAssetGroup
                              ? {
                                  assignableId: asset.assignableId,
                                  assignableType: asset.assignableType,
                                  assetGroupId: asset.assetGroupId!,
                                  status: asset.status!,
                                  count: asset.assetChildCount,
                                  groupParent: asset,
                                  compositeKey: asset.compositeKey,
                                }
                              : null
                          }
                        />
                      )
                    }}
                    type="button"
                    variant="contained"
                  >
                    Reassign
                  </Button>
                }
              />
              <QuickActionRow
                icon={<BiLayer className="h-5 w-5" />}
                title="Project Location"
                subTitle={
                  <>
                    <p>
                      <Link href={`/projects/${project?.id}/details`} className="hover:underline">
                        {project?.name}
                      </Link>
                    </p>
                    <p>/</p>
                    <p>
                      {task && (
                        <DrawerLink
                          className="hover:underline"
                          href={`/task/${task?.id}/details`}
                          component={<TaskDrawer taskId={task.id} />}
                        >
                          {task?.name}
                        </DrawerLink>
                      )}
                    </p>
                  </>
                }
                action={
                  <Button
                    disabled={noViewTaskPermission}
                    color="black"
                    variant="contained"
                    onClick={() => {
                      pushDrawer(<TaskDrawer taskId={task?.id!} />)
                    }}
                    type="button"
                  >
                    View Task
                  </Button>
                }
              />
            </>
          )}
          {assignedAsset && (
            <QuickActionRow
              disableIconBackground
              icon={
                <DrawerLink
                  href={`/asset/${assignedAsset?.id}/details`}
                  className="text-left"
                  component={<AssetDrawer assetId={assignedAsset.id} />}
                  data-test={userBadgeTestSelectors.name}
                >
                  <AssetImage asset={assignedAsset} />
                </DrawerLink>
              }
              title="Current Assignment"
              subTitle={
                <DrawerLink
                  className="flex gap-2"
                  href={`/asset/${assignedAsset?.id}/details`}
                  component={<AssetDrawer assetId={assignedAsset.id} />}
                  data-test={userBadgeTestSelectors.name}
                >
                  <p className="hover:underline">{assignedAsset.name}</p>
                  <p>/</p>
                  <p className="hover:underline">{assignedAsset.companyAssetNumber}</p>
                </DrawerLink>
              }
              action={
                <Button
                  disabled={noReassignPermission}
                  color="black"
                  onClick={() => {
                    pushDrawer(
                      <ReassignAssetDrawer
                        assetsIdsToReassign={asset.assetGroupId ? [] : [asset.id]}
                        assetGroupToReassign={
                          asset.assetGroupId
                            ? {
                                assignableId: asset.assignableId,
                                assignableType: asset.assignableType,
                                assetGroupId: asset.assetGroupId!,
                                status: asset.status!,
                                count: asset.assetChildCount,
                                groupParent: asset,
                                compositeKey: asset.compositeKey,
                              }
                            : null
                        }
                      />
                    )
                  }}
                  type="button"
                  variant="contained"
                >
                  Reassign
                </Button>
              }
            />
          )}
          {assignedTask && project && (
            <QuickActionRow
              disableIconBackground
              icon={
                <Link href={`/projects/${project.id}/details`} className="hover:underline">
                  <ProjectImage project={project} />
                </Link>
              }
              title="Current Assignment"
              subTitle={
                <DrawerLink
                  className="flex gap-2"
                  href={`/task/${assignedTask.id}/details`}
                  component={<TaskDrawer taskId={assignedTask.id} />}
                  data-test={userBadgeTestSelectors.name}
                >
                  <p className="hover:underline">{project.name}</p>
                  <p>/</p>
                  <p className="hover:underline">{assignedTask.name}</p>
                </DrawerLink>
              }
              action={
                <Button
                  disabled={noReassignPermission}
                  color="black"
                  onClick={() => {
                    pushDrawer(
                      <ReassignAssetDrawer
                        assetsIdsToReassign={asset.assetGroupId ? [] : [asset.id]}
                        assetGroupToReassign={
                          asset.assetGroupId
                            ? {
                                assignableId: asset.assignableId,
                                assignableType: asset.assignableType,
                                assetGroupId: asset.assetGroupId!,
                                status: asset.status!,
                                count: asset.assetChildCount,
                                groupParent: asset,
                                compositeKey: asset.compositeKey,
                              }
                            : null
                        }
                        onSuccess={fetch}
                      />
                    )
                  }}
                  type="button"
                  variant="contained"
                >
                  Reassign
                </Button>
              }
            />
          )}
          <QuickActionRow
            icon={<BiTask className="h-5 w-5" />}
            title="Inventory Report"
            subTitle={<AssetInventoryDueIn asset={asset} />}
            action={data && <InventoryButton asset={data.asset} assetGroups={data.assetGroups} />}
          />

          {asset?.inspectionTemplatesAssignments?.length === 0 && (
            <QuickActionRow
              icon={<BiWrench className="h-5 w-5" />}
              title="Inspection"
              subTitle={<AssetInspectionDueIn assetReport={data?.asset?.inspectionTemplatesAssignments?.[0] || {}} />}
              action={
                data && (
                  <InspectButton
                    asset={{
                      id: data.asset.id,
                      inspectionTemplatesAssignments: data.asset.inspectionTemplatesAssignments,
                    }}
                  />
                )
              }
            />
          )}
          {asset?.inspectionTemplatesAssignments?.map(({ inspectionTemplate }) => (
            <QuickActionRow
              key={inspectionTemplate.id}
              icon={<BiWrench className="h-5 w-5" />}
              title={inspectionTemplate.name}
              subTitle={<AssetInspectionDueIn assetReport={data?.asset?.inspectionTemplatesAssignments?.[0] || {}} />}
              action={
                data && (
                  <InspectButton
                    asset={{
                      id: data.asset.id,
                      inspectionTemplatesAssignments: data.asset.inspectionTemplatesAssignments,
                    }}
                  />
                )
              }
            />
          ))}
          {!asset.isAssetGroup && (
            <QuickActionRow
              icon={
                isOperational(statusOption.value) ? (
                  <BiCheck className={"text-blue-600 h-6 w-6"} />
                ) : (
                  <BiError className={"text-orange-600 h-6 w-6"} />
                )
              }
              title="Status"
              subTitle={<p>{statusOption.label}</p>}
              action={
                <Formik
                  initialValues={{
                    userAssignment: [asset.assignableType === AssetAssignableType.User ? asset.assignableId : null],
                    status: [statusOption.value],
                  }}
                  onSubmit={() => {}}
                  enableReinitialize={true}
                >
                  <Form>
                    <AssetStatusMultiSelect
                      disabled={noReportAssetPermission}
                      name={"status"}
                      onChange={(options) => {
                        const currentAssetStatus = statusOption.value
                        const selectedAssetStatus = stringToAssetStatus(options[0])

                        if (selectedAssetStatus !== currentAssetStatus) {
                          if (isOperational(selectedAssetStatus)) {
                            editAssetMutation({ id: asset.id, active: true, status: selectedAssetStatus })
                          } else {
                            editAssetMutation({ id: asset.id, active: false, status: selectedAssetStatus })
                          }
                        }
                      }}
                    />
                  </Form>
                </Formik>
              }
            />
          )}
        </QuickActionContainer>
      )}
    </div>
  )
}

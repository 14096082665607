/* eslint-disable @next/next/no-img-element */
import { FC, ReactNode } from "react"
import { classNames } from "../../../helpers/classNames"
import { TailwindIcon } from "../../../types/tailwind"

type Props = {
  Icon: TailwindIcon
  image?: Blob
  injured?: boolean
  children: ReactNode
  childrenContainerClassName?: string
  className?: string
}

export const RecapRow: FC<Props> = ({
  Icon,
  children,
  image,
  injured = false,
  className,
  childrenContainerClassName,
}) => {
  return (
    <div
      className={classNames(
        "flex rounded items-center border border-gray-400",
        className,
        injured && "border-orange-400"
      )}
    >
      <div
        className={classNames(
          "py-2 px-2 bg-gray-50 rounded-l border-r border-gray-400",
          injured && "bg-orange-50 border-orange-400"
        )}
      >
        {<Icon className={classNames("h-7 w-7 text-gray-600", injured && "text-orange-600")} />}
      </div>
      <div
        className={classNames(
          "py-2 pl-4 pr-2 flex flex-grow justify-between items-center rounded-r bg-gray-100",
          childrenContainerClassName,
          injured && "bg-orange-100"
        )}
      >
        <div className={"grow h-7 pt-[3px]"}>{children}</div>
        {image && (
          <div className="bg-white flex items-center h-7 w-7">
            <img src={URL.createObjectURL(image)} alt={"Time entry signature"} className={"rounded-sm"} />
          </div>
        )}
      </div>
    </div>
  )
}

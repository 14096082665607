import { Box, Chip } from "@mui/material"
import { FC } from "react"

export const ToggleChip: FC<{
  label: string
  color: string
  isActive: boolean
  toggleVisibility: () => void
}> = ({ label, color, isActive, toggleVisibility }) => {
  const chipStyles = {
    padding: "20px 10px",
    marginBottom: "10px",
    marginRight: "10px",
    borderRadius: "100px",
  }

  const swatchStyle = {
    borderRadius: "100%",
    width: 10,
    height: 10,
    marginLeft: 6,
  }

  return (
    <Chip
      label={label}
      onClick={toggleVisibility}
      avatar={
        label === "Hours" ? (
          <Box
            style={{
              ...swatchStyle,
              backgroundColor: "transparent",
              border: isActive ? `3px solid ${color}` : "3px solid gray",
            }}
          />
        ) : (
          <Box
            style={{
              ...swatchStyle,
              backgroundColor: isActive ? color : "gray",
            }}
          />
        )
      }
      style={chipStyles}
      sx={{
        color: isActive ? "black" : "gray",
      }}
    />
  )
}

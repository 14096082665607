import { FC } from "react"
import { PickPlus } from "../../../types/helpers"
import { User } from "../../../types/User"

type Props = {
  user: PickPlus<User, "firstName" | "lastName">
  type: "In" | "Out"
}

export const ModalTitle: FC<Props> = ({ user, type }) => {
  return (
    <div>
      Clock {type}{" "}
      <span className={"text-gray-400"}>
        / {user.firstName} {user.lastName}
      </span>
    </div>
  )
}

import { FC } from "react"
import { BiUserCircle } from "react-icons/bi"
import { classNames } from "../helpers/classNames"
import { pluralize } from "../helpers/pluralize"

type Props = {
  count: number
  className?: string
  isWarning?: boolean
}

export const UserCount: FC<Props> = ({ count, className, isWarning = false }) => {
  return (
    <div className={classNames("inline-flex items-center gap-4 text-sm", className)}>
      {count > 0 && (
        <>
          <BiUserCircle className={classNames("h-5 w-5", isWarning ? "text-orange-600" : "text-gray-600")} />
          <span
            className={classNames(
              "ml-px py-1.5 px-3 rounded font-medium",
              isWarning ? "text-orange-600 bg-orange-50" : "bg-gray-100"
            )}
          >
            {count > 99 ? "99+" : count}
            <span className="md:hidden xl:inline">&nbsp;team {pluralize("member", count)}</span>
          </span>
        </>
      )}
    </div>
  )
}

import { UnitGoal } from "../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../types/helpers"

export const taskProductionRate = ({
  clockedSeconds,
  unitsDelivered,
  unitGoals,
  estimatedHours,
}: {
  clockedSeconds: number
  unitsDelivered: number
  unitGoals: PickPlus<UnitGoal, "targetQuantity">[]
  estimatedHours: number | undefined | null
}) => {
  const actual = clockedSeconds && clockedSeconds > 0 ? Math.round((unitsDelivered || 0) / (clockedSeconds / 3600)) : 0
  const expected =
    unitGoals.length > 0 && estimatedHours
      ? Math.round(
          (unitGoals.reduce((sum, unitGoal) => sum + (unitGoal.targetQuantity || 0), 0) / estimatedHours) * 10
        ) / 10
      : Infinity
  const rate = isFinite(actual) && isFinite(expected) ? actual / expected : 0

  return { actual, expected, rate }
}

export const taskProductionPercentageRate = ({
  clockedSeconds,
  unitsDelivered,
  targetQuantity,
  estimatedHours,
}: {
  clockedSeconds: number
  unitsDelivered: number
  targetQuantity: number
  estimatedHours: number | undefined | null
}) => {
  const actual = clockedSeconds && clockedSeconds > 0 ? (unitsDelivered || 0) / (clockedSeconds / 3600) : 0
  const expected =
    targetQuantity > 0 && estimatedHours && estimatedHours > 0 ? targetQuantity / estimatedHours : Infinity
  const rate = isFinite(actual) && isFinite(expected) ? (actual / expected) : 0

  return { actual, expected, rate }
}

import { Transition } from "@headlessui/react"
import { Button, Typography } from "@mui/material"
import { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from "react"
import { BiDotsHorizontalRounded, BiEdit, BiTrash } from "react-icons/bi"
import { useQuery } from "urql"
import { graphql } from "../../../graphql/generated/gql"
import { classNames } from "../../../helpers/classNames"
import { useHandleError } from "../../../hooks/useHandleError"
import { useModalProps } from "../../../hooks/useModalProps"
import { BreadcrumbsContext } from "../../../providers/BreadcrumbsProvider"
import { DrawerContext } from "../../../providers/DrawerProvider"
import { PermissionsContext } from "../../../providers/PermissionsProvider/PermissionsProvider"
import { useCurrentUser } from "../../../providers/PermissionsProvider/currentUserProvider"
import { H4, H5 } from "../../Elements"
import { LabeledSection } from "../../LabeledSection"
import { PageTitle } from "../../PageTitle"
import { MenuItem, QuickMenuMui } from "../../QuickMenuMui"
import { RenderIf } from "../../RenderIf"
import { SkeletonContainer } from "../../Skeletons/SkeletonContainer"
import { UnitReportModal } from "../../UnitReportModal"
import { ValueOrNoneLabel } from "../Assets/BasicInfo"
import { SkeletonRow } from "../Documents/DocumentList.skeleton"
import { TaskGroupEditDrawer } from "../Drawer/TaskGroupEditDrawer"
import { TaskList } from "./TaskList/TaskList"
import { DeleteTaskModal } from "./Tasks/DeleteTaskModal"

const GetDetailsForTaskGroup = graphql(`
  query GetDetailsForTaskGroup($groupId: String!, $projectId: String!) {
    taskGroup(id: $groupId) {
      id
      name
      notes
      description
      completedTaskCount
      assetCount
      organizationId
      unitProgress
      unitTargetGoal
      isComplete
      taskCount
      updatedAt
      userCount
      projectId
    }

    tasksForGroupId(groupId: $groupId) {
      id
      groupId
      name
      projectId
      assetCount
      hasReportableUnit
      completedHours
      isComplete
      isDefault
      isParentTask
      subtaskCount
      userCount
    }

    project(id: $projectId) {
      id
      name
      isComplete
      isArchived
    }
  }
`)

type Props = {
  taskGroupId: string
  projectId: string
  currentTab?: string
  withTitle?: boolean
  setCurrentTab?: Dispatch<SetStateAction<string>>
  onSuccess?: () => void
}

type Tab = {
  value: string
  label: string
}

const tabs: Tab[] = [
  {
    value: "details",
    label: "Details",
  },
  {
    value: "sub-tasks",
    label: "Sub-tasks",
  },
]

export const TaskGroupDetails: FC<Props> = ({
  taskGroupId,
  projectId,
  currentTab = "details",
  withTitle = false,
  setCurrentTab,
  onSuccess,
}) => {
  const [{ data, fetching, error }, fetch] = useQuery({
    query: GetDetailsForTaskGroup,
    variables: { groupId: taskGroupId, projectId },
    pause: !taskGroupId || !projectId,
  })

  const refetch = () => {
    fetch()
    onSuccess?.()
  }

  const [reportUnitTaskId, setReportUnitTaskId] = useState<string | undefined>(undefined)
  const [taskToDelete, setTaskToDelete] = useState<
    { id: string; name: string; assetCount?: number; userCount?: number } | undefined
  >(undefined)
  const [deleteTaskGroup, setDeleteTaskGroup] = useState<boolean>(false)

  const { push: pushDrawer, pop: closeDrawer } = useContext(DrawerContext)
  const { hasPermissionTo } = useContext(PermissionsContext)
  const currentUser = useCurrentUser()
  const deleteTaskModalProps = useModalProps("Delete Task")

  useHandleError(error, "There was an error retrieving the summary task details. Please try again")

  const handleReport = (taskId: string | undefined) => {
    setReportUnitTaskId(taskId)
  }
  const handleDelete = (
    taskId: string,
    taskName: string,
    isTaskGroup: boolean,
    assetCount?: number,
    userCount?: number
  ) => {
    setTaskToDelete({ id: taskId, name: taskName, assetCount, userCount })
    setDeleteTaskGroup(isTaskGroup)

    deleteTaskModalProps.handleOpenModal()
  }

  const menuItems: MenuItem[][] = [
    [
      {
        value: "Edit",
        onClick: () => pushDrawer(<TaskGroupEditDrawer taskGroupId={taskGroup.id} />),
        Icon: BiEdit,
      },
    ],
    [
      {
        value: "Delete",
        onClick: () => handleDelete(taskGroupId, taskGroup.name, true),
        Icon: BiTrash,
        color: "red",
      },
    ],
  ]

  const { setAdditionalBreadcrumbs, setIsLoading, setLabelSubstitutions } = useContext(BreadcrumbsContext)

  useEffect(() => {
    setIsLoading(fetching)

    if (!data?.taskGroup || !data?.project) return

    setLabelSubstitutions([
      {
        original: data.project.id,
        replacement: data.project.name,
      },
      {
        original: data.taskGroup.id,
        replacement: data.taskGroup.name,
      },
    ])

    return () => {
      setAdditionalBreadcrumbs([])
    }
  }, [data?.taskGroup, data?.project, fetching, setAdditionalBreadcrumbs, setIsLoading, setLabelSubstitutions])

  if (fetching) {
    return (
      <SkeletonContainer>
        <SkeletonRow />
        <SkeletonRow />
      </SkeletonContainer>
    )
  }

  const taskGroup = data?.taskGroup!
  const groupTasks = data?.tasksForGroupId!
  const project = data?.project!

  return (
    <>
      <PageTitle title={`${taskGroup?.name || "Summary Task"}`} />
      {withTitle && (
        <div className="flex gap-x-2 mb-2">
          <div className="lg:flex lg:flex-wrap lg:justify-between">
            <Typography fontSize="36px" lineHeight="40px" fontWeight={700}>
              {taskGroup?.name}
            </Typography>
          </div>
          <QuickMenuMui className="self-center" items={menuItems} buttonShape="round">
            <BiDotsHorizontalRounded className="text-2xl" />
          </QuickMenuMui>
        </div>
      )}
      <div className="flex gap-6 overflow-x-scroll">
        {tabs.map((tab) => {
          const isSelected = tab.value === currentTab

          return (
            <RenderIf
              key={tab.value}
              permissionsInclude={["project:read"]}
              context={{ projectId: currentUser.projectId! }}
            >
              <button
                onClick={() => setCurrentTab?.(tab.value)}
                className={classNames(
                  "relative my-2 pb-2 font-bold uppercase whitespace-nowrap md:my-8",
                  !isSelected && "text-gray-400"
                )}
              >
                {tab.label}
                <Transition
                  appear={true}
                  show={true}
                  enter="transition ease-in-out duration-150 transform"
                  enterFrom="opacity-0 translate-y-full"
                  enterTo="opacity-100 translate-y-0"
                >
                  <div
                    className={classNames(
                      "w-full relative top-1 h-1 transition-all rounded-sm",
                      isSelected ? "bg-blue-600" : "bg-transparent"
                    )}
                  />
                </Transition>
              </button>
            </RenderIf>
          )
        })}
      </div>
      {currentTab === "details" && (
        <>
          <H4>Summary Task Details</H4>
          <div className="flex justify-between items-center min-h-[40px]">
            <H5 className="mt-0 mb-0">Basic Info</H5>
            {hasPermissionTo("task:update") && (
              <Button
                variant="text"
                onClick={() => pushDrawer(<TaskGroupEditDrawer taskGroupId={taskGroup.id} onSuccess={refetch} />)}
              >
                Edit
              </Button>
            )}
          </div>
          <hr className={"mt-2 mb-4 md:mb-6"} />

          <div className="md:grid md:grid-cols-2 md:gap-4 grid gap-y-4">
            <LabeledSection label="Summary Task Name">
              <ValueOrNoneLabel value={taskGroup?.name} />
            </LabeledSection>
            <LabeledSection label="Description">
              <ValueOrNoneLabel value={taskGroup?.description} />
            </LabeledSection>
          </div>

          <div className="mb-20">
            <div className={"flex justify-between items-center min-h-[40px]"}>
              <H5 className={"mt-0 mb-0"}>Task Notes</H5>
            </div>
            <hr className={"mt-2 mb-4 md:mb-6"} />
            <LabeledSection label={taskGroup?.notes || "none"}>{taskGroup?.notes}</LabeledSection>
          </div>
        </>
      )}
      {currentTab === "sub-tasks" && (
        <>
          <PageTitle title={`${taskGroup?.name || "Summary Task"} sub-tasks`} />
          {project && taskGroup && (
            <TaskList
              project={project}
              tasks={groupTasks}
              taskGroup={taskGroup}
              handleReport={handleReport}
              handleDelete={handleDelete}
              refetch={refetch}
            />
          )}
        </>
      )}
      <UnitReportModal
        isOpen={!!reportUnitTaskId}
        closeModal={() => setReportUnitTaskId(undefined)}
        taskId={reportUnitTaskId!}
      />
      <DeleteTaskModal
        modalProps={deleteTaskModalProps}
        closeModal={deleteTaskModalProps.handleCloseModal}
        task={taskToDelete}
        isTaskGroup={deleteTaskGroup}
        onSuccess={closeDrawer}
      />
    </>
  )
}

import { useRouter } from "next/router"
import { FC,useContext } from "react"
import { BiDotsHorizontalRounded,BiLayer,BiListUl,BiSortAlt2,BiTrash,BiUserCircle } from "react-icons/bi"
import {
User,
useUserDeleteMutation,
useUserRestoreMutation,
} from "../../../../graphql/generated/client-types-and-hooks"
import { classNames } from "../../../../helpers/classNames"
import { PermissionsContext } from "../../../../providers/PermissionsProvider/PermissionsProvider"
import { PickPlus } from "../../../../types/helpers"
import { ClockInOut } from "../../../ClockInOut"
import Checkbox from "../../../Elements/Checkbox"
import { IconLabel } from "../../../IconLabel"
import { errorSnack,successSnack } from "../../../Notistack/ThemedSnackbars"
import { QuickMenu } from "../../../QuickMenu"
import { MenuItem } from "../../../QuickMenuMui"
import { RenderIf } from "../../../RenderIf"
import { Row } from "../../../Table/Row"
import { UserBadge } from "../../../UserBadge"
import { UserTimeCounter } from "../../../UserTimeCounter"
import { UserSkeletonRow } from "../../User/Users.skeleton"

export const testLabel_TeamMemberRow = "team-member-row"

type Props = {
  hideProjectColumn?: boolean
  rowSelected: any
  user: PickPlus<
    User,
    | "id"
    | "archived"
    | "currentProjectId"
    | "currentTaskId"
    | "firstName"
    | "imageUrl"
    | "isClockedIn"
    | "jobTitle"
    | "lastName"
    | "organizationId"
    | "projectId"
    | "secondsClockedSinceOrgMidnight"
    | "taskId"
  > & {
    project: PickPlus<User["project"], "name">
    task: PickPlus<User["task"], "name">
  }
  toggleUserSelection: (value: Props["user"]) => void
  setModalData: (user: Props["user"]) => void
}

export const TeamListRow: FC<Props> = ({
  user,
  rowSelected = false,
  toggleUserSelection,
  hideProjectColumn = false,
  setModalData,
}) => {
  const router = useRouter()
  const { hasPermissionTo } = useContext(PermissionsContext)
  const [, deleteUserMutation] = useUserDeleteMutation()
  const [, restoreUserMutation] = useUserRestoreMutation()

  if (!user) {
    return <UserSkeletonRow />
  }

  const ellipsisMenuItems: MenuItem[][] = [
    [
      {
        requiredPermission: "user:read",
        requiredPermissionContext: { userId: user.id },
        value: "View Profile",
        onClick: () => {
          router.push(`/team/${user.id}`)
        },
        Icon: BiUserCircle,
      },
    ],
  ]

  const projectId = user.projectId

  if (hasPermissionTo("user:assign", { projectId }) && !user?.archived)
    ellipsisMenuItems[0].push({
      value: "Reassign",
      onClick: () => setModalData(user),
      Icon: BiSortAlt2,
      iconStyles: "rotate-90 w-5 h-5",
    })

  if (hasPermissionTo("user:archive") && !user?.archived)
    ellipsisMenuItems.push([
      {
        value: "Archive user",
        onClick: () => {
          // User is clocked out by this action, in the backend, where it matters
          deleteUserMutation({ id: user.id }).then((result) =>
            result.error ? errorSnack("Error archiving user") : successSnack("User archived successfully")
          )
        },
        color: "red",
        Icon: BiTrash,
      },
    ])

  if (hasPermissionTo("user:archive") && user?.archived)
    ellipsisMenuItems.push([
      {
        value: "Unarchive user",
        onClick: () => {
          restoreUserMutation({ id: user.id }).then((result) =>
            result.error ? errorSnack("Error unarchiving user") : successSnack("User unarchived successfully")
          )
        },
        color: "red",
        Icon: BiTrash,
      },
    ])

  const projectMenuItems: MenuItem[][] = []

  if (hasPermissionTo("project:read", { projectId })) {
    projectMenuItems.push([
      {
        value: "View project",
        Icon: BiLayer,
        onClick: () => {
          router.push(`/projects/${user.projectId}`)
        },
        iconStyles: "w-5 h-5",
      },
    ])
  }

  if (hasPermissionTo("user:assign", { projectId })) {
    projectMenuItems.push([
      {
        value: "Reassign",
        onClick: () => setModalData(user),
        Icon: BiSortAlt2,
        iconStyles: "rotate-90 w-5 h-5",
      },
    ])
  }

  const taskMenuItems: MenuItem[][] = []

  if (hasPermissionTo("task:read", { projectId })) {
    taskMenuItems.push([
      {
        value: "View task",
        Icon: BiListUl,
        onClick: () => {
          router.push(`/projects/${user.projectId}/${user.taskId}`)
        },
        iconStyles: "w-5 h-5",
      },
    ])
  }

  if (hasPermissionTo("user:assign", { projectId })) {
    taskMenuItems.push([
      {
        value: "Reassign",
        onClick: () => setModalData(user),
        Icon: BiSortAlt2,
        iconStyles: "rotate-90 w-5 h-5",
      },
    ])
  }

  return (
    <Row
      className={classNames(
        "grid grid-cols-12 md:gap-y-6 group",
        "md:grid-flow-row md:grid-rows-1 md:items-center md:gap-x-6"
      )}
      testLabel={testLabel_TeamMemberRow}
    >
      <div className="col-span-12 flex justify-between gap-2 md:col-span-3">
        <div className="flex gap-x-3 items-center truncate">
          <RenderIf permissionsInclude={["timeEntry:create", "user:assign"]}>
            <div className="hidden md:inline-block">
              <Checkbox
                checked={rowSelected}
                onChange={() => toggleUserSelection(user)}
              />
            </div>
          </RenderIf>
          <UserBadge user={user} />
        </div>
        <div className="flex items-center gap-x-4 md:hidden">
          <QuickMenu items={ellipsisMenuItems}>
            <BiDotsHorizontalRounded className="w-6 h-6" />
          </QuickMenu>
          <Checkbox checked={rowSelected} onChange={() => toggleUserSelection(user)} />
        </div>
      </div>
      <div className="col-span-12 mt-6 md:mt-0 font-medium text-sm text-gray-400 md:hidden uppercase">Assignment</div>
      {!hideProjectColumn && (
        <div className="col-span-12 md:col-span-2 mt-2 md:mt-0 bg-gray-100 rounded">
          <QuickMenu
            items={projectMenuItems}
            className="w-full"
            menuButtonClassName="w-full text-left cursor-pointer p-2.5 hover:bg-gray-200 hover:rounded transition-all"
            disabled={user?.archived}
          >
            <p className="w-full truncate leading-tight">{user.project.name}</p>
          </QuickMenu>
        </div>
      )}
      <QuickMenu
        items={taskMenuItems}
        className={classNames("col-span-12 mt-2 md:mt-0", hideProjectColumn ? "md:col-span-4" : "md:col-span-2")}
        menuButtonClassName="w-full text-left cursor-pointer p-2.5 bg-gray-100 hover:bg-gray-200 hover:rounded transition-all md:col-span-2"
        disabled={user?.archived}
      >
        <IconLabel icon={BiListUl} label={user.task.name} />
      </QuickMenu>
      <div className="col-span-12 mt-6 md:mt-0 flex items-center font-medium text-sm text-gray-400 md:hidden">TIME</div>
      <div className="col-span-12 mt-2 md:mt-0 md:col-span-2">
        <RenderIf permissionsInclude={["timeEntry:clockInOut"]} context={{ projectId, userId: user.id }}>
          <ClockInOut user={user} />
        </RenderIf>
      </div>
      <div className="col-span-12 mt-6 md:mt-0 pb-1 md:pb-0 md:pl-0 md:col-span-2 flex justify-between">
        <RenderIf permissionsInclude={["timeEntry:clockInOut"]} context={{ projectId, userId: user.id }}>
          <UserTimeCounter user={user} />
        </RenderIf>
      </div>
      <div className={classNames("hidden col-span-2 justify-self-end", "md:block md:row-auto md:col-span-1")}>
        <QuickMenu items={ellipsisMenuItems}>
          <BiDotsHorizontalRounded className="w-6 h-6" />
        </QuickMenu>
      </div>
    </Row>
  )
}

import { Transition } from "@headlessui/react"
import { FC, useContext, useRef, useState } from "react"
import { useQuery } from "urql"
import { UserNotificationModelType } from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { DevelopmentFeatureFlagName } from "../../../helpers/api/developmentFeatureFlags"
import { classNames } from "../../../helpers/classNames"
import { TeamMemberPageContent } from "../../../pages/team/[_id]/_layout"
import { UserAssetsPage } from "../../../pages/team/[_id]/assets"
import { UserPageDetails } from "../../../pages/team/[_id]/details"
import { UserPageDocuments } from "../../../pages/team/[_id]/documents"
import { UserPageOfflineEvents } from "../../../pages/team/[_id]/offline-events"
import { UserPageTimeCards } from "../../../pages/team/[_id]/time-cards"
import { useFlags } from "../../../providers/DevelopmentFeatureFlagProvider"
import { RenderIf } from "../../RenderIf"
import { NotificationBubble } from "../../UserNotifications/NotificationBubble"
import { SingleDrawerContext } from "./Drawer"
import { DrawerBody } from "./DrawerBody"
import { DrawerHeader } from "./DrawerHeader"

type Props = {
  userId: string
  tab?: string
}

type Tab = {
  value: string
  label: string
  permissions: "user:read" | "timeEntry:read"
  TabNotificationBubble?: FC
  featureFlag?: DevelopmentFeatureFlagName
}

const UserDrawerDocument = graphql(`
  query UserDrawerDocument($id: String!) {
    user(id: $id) {
      id
      organizationId
      currentProjectId
    }
  }
`)

export const UserDrawer: FC<Props> = ({ userId, tab = "details" }) => {
  const [currentTab, setCurrentTab] = useState(tab)
  const { handleClose } = useContext(SingleDrawerContext)
  const { flagIsEnabled } = useFlags()

  const drawerRef = useRef(null)

  const [{ data }] = useQuery({
    query: UserDrawerDocument,
    variables: { id: userId },
    pause: !userId,
  })

  const tabs: Tab[] = [
    {
      value: "details",
      label: "Details",
      permissions: "user:read",
    },
    {
      value: "assets",
      label: "Assets",
      permissions: "user:read",
    },
    {
      value: "time-cards",
      label: "Time Cards",
      permissions: "timeEntry:read",
      TabNotificationBubble: () => (
        <NotificationBubble
          shouldRender={(userNotifications) =>
            userNotifications.some((un) => un.modelType === UserNotificationModelType.User && un.user?.id === userId)
          }
        />
      ),
    },
    {
      value: "documents",
      label: "Documents",
      permissions: "user:read",
    },
    {
      value: "offline-events",
      label: "Offline Events",
      permissions: "user:read",
      featureFlag: "Show Offline Events for User",
    },
  ]

  return (
    <>
      <DrawerHeader handleClose={handleClose} href={`/team/${userId}/${currentTab}`} />

      <DrawerBody>
        <TeamMemberPageContent userId={userId} withTabs={false}>
          <div className="flex gap-6 overflow-x-auto" ref={drawerRef}>
            {tabs.map((tab) => {
              if (tab.featureFlag && !flagIsEnabled(tab.featureFlag)) return

              const { TabNotificationBubble } = tab

              const isSelected = tab.value === currentTab
              return (
                <RenderIf
                  key={tab.value}
                  permissionsInclude={tab.permissions}
                  context={{ projectId: data?.user?.currentProjectId, userId }}
                >
                  <button
                    onClick={() => setCurrentTab(tab.value)}
                    className={classNames(
                      "relative my-2 pb-2 font-bold uppercase whitespace-nowrap",
                      "md:my-8",
                      !isSelected && "text-gray-400"
                    )}
                  >
                    {TabNotificationBubble && <TabNotificationBubble />}
                    {tab.label}

                    <Transition
                      appear={true}
                      show={true}
                      enter="transition ease-in-out duration-150 transform"
                      enterFrom="opacity-0 translate-y-full"
                      enterTo="opacity-100 translate-y-0"
                    >
                      <div
                        className={classNames(
                          "w-full relative top-1 h-1 transition-all rounded-sm",
                          isSelected ? "bg-blue-600" : "bg-transparent"
                        )}
                      />
                    </Transition>
                  </button>
                </RenderIf>
              )
            })}
          </div>

          {currentTab === "assets" && <UserAssetsPage userId={userId} />}
          {currentTab === "details" && <UserPageDetails userId={userId} />}
          {currentTab === "documents" && <UserPageDocuments />}
          {currentTab === "offline-events" && <UserPageOfflineEvents userId={userId} drawerRef={drawerRef} />}
          {currentTab === "time-cards" && <UserPageTimeCards userId={userId} drawerRef={drawerRef} />}
        </TeamMemberPageContent>
      </DrawerBody>
    </>
  )
}

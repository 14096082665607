import { Button, InputAdornment, TextField, Typography } from "@mui/material"
import { grey, red } from "@mui/material/colors"
import { Formik, FormikValues } from "formik"
import { FC } from "react"
import * as Yup from "yup"
import {
  AssetGroup,
  useReturnQuantityToInventoryMutation,
} from "../../../../../graphql/generated/client-types-and-hooks"
import { pluralize } from "../../../../../helpers/pluralize"
import { ModalProps } from "../../../../../hooks/useModalProps"
import { ModalBody } from "../../../../Modals/ModalBody"
import { ModalFooter } from "../../../../Modals/ModalFooter"
import StandardModal from "../../../../Modals/StandardModal"
import { errorSnack, successSnack } from "../../../../Notistack/ThemedSnackbars"

export const ReturnToInventoryModal: FC<{ modalProps: ModalProps; asset: AssetGroup }> = ({ modalProps, asset }) => {
  const [_, returnAssetsToInventory] = useReturnQuantityToInventoryMutation()

  const handleSubmit = (values: FormikValues) => {
    if (values.quantityToReturn === 0) {
      modalProps.handleCloseModal()
    }

    returnAssetsToInventory({
      assetGroupId: asset.assetGroupId,
      assignableId: asset.assignableId,
      assignableType: asset.assignableType,
      status: asset.status,
      quantityToReturn: Number(values.quantityToReturn),
    }).then((result) => {
      if (result.error) {
        modalProps.handleCloseModal()

        return errorSnack("An error occurred while returning assets to inventory. Please try again.")
      }

      modalProps.handleCloseModal()

      successSnack(`Returned ${values.quantityToReturn} ${pluralize("asset", values.quantityToReturn)} to inventory`)
    })
  }

  return (
    <StandardModal {...modalProps}>
      <Formik
        initialValues={{ quantityToReturn: asset.count }}
        validationSchema={Yup.object().shape({
          quantityToReturn: Yup.number()
            .min(1, "Quantity must be 1 or greater")
            .max(asset.count, `Quantity cannot be greater than ${asset.count}`)
            .integer("Quantity must be a whole number")
            .required("A value must be entered"),
        })}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isSubmitting, values, setFieldValue, errors, isValid }) => (
          <>
            <ModalBody>
              <div className="flex flex-col">
                <Typography variant="body1">How many units would you like to return to inventory?</Typography>
                <TextField
                  name="quantityToReturn"
                  className="py-4"
                  value={values.quantityToReturn}
                  onChange={(e) => {
                    setFieldValue("quantityToReturn", e.target.value)
                  }}
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography fontSize={14} color={grey[400]}>
                          of {asset.count}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ width: "150px" }}
                />
                {errors.quantityToReturn && (
                  <Typography variant="body1" color={red[600]}>
                    {errors.quantityToReturn}
                  </Typography>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button variant="text" onClick={modalProps.handleCloseModal}>
                Cancel
              </Button>
              <Button variant="contained" onClick={submitForm} disabled={isSubmitting || !isValid}>
                Return Inventory
              </Button>
            </ModalFooter>
          </>
        )}
      </Formik>
    </StandardModal>
  )
}

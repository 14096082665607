import { FC } from "react"
import { useQuery } from "urql"
import { graphql } from "../../../graphql/generated/gql"
import { UserAssignmentsTable } from "../../UserAssignmentsTable"

const TaskExtraAssignmentTableDocument = graphql(`
  query TaskExtraAssignmentTable($taskId: String!) {
    task(id: $taskId) {
      id
      isComplete
      name
      projectId
      assignees {
        id
        isCurrentAssignment
        projectId
        taskId
        userId
        user {
          id
          firstName
          imageUrl
          isClockedIn
          jobTitle
          lastName
        }
      }
    }
  }
`)

type TaskExtraAssignmentTableProps = {
  taskId: string
  projectId: string
}

export const TaskExtraAssignmentTable: FC<TaskExtraAssignmentTableProps> = ({ taskId, projectId }) => {
  const [{ data }, refetch] = useQuery({
    query: TaskExtraAssignmentTableDocument,
    variables: { taskId },
  })

  return (
    <div className="pt-8">
      <UserAssignmentsTable
        assignments={(data?.task.assignees || []).map((a) => {
          if (data?.task) {
            const { assignees: _assignees, ...task } = data.task
            return {
              task,
              ...a,
            }
          }
          return a
        })}
        preassignments={{ taskId, projectId }}
        refetch={refetch}
        showCurrentAssignments
        title="User Task Access"
      />
    </div>
  )
}

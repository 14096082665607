import { Box } from "@mui/material"
import { useField } from "formik"
import { FC, useEffect, useMemo } from "react"
import { useQuery } from "urql"
import { graphql } from "../../../../graphql/generated/gql"
import { User } from "../../../../graphql/generated/gql/graphql"
import { useHandleError } from "../../../../hooks/useHandleError"
import { usePrevious } from "../../../../hooks/usePrevious"
import { MuiMultiSelect } from "../MuiMultiSelect"
import { MultiSelectOption } from "../MultiSelect"

type Props = {
  formGroupId: string
  users?: Pick<User, "id" | "taskId">[]
  includeCurrentAssignment?: boolean
  withErrorHandling?: boolean
}

const ProjectAndTasksMultiSelect = graphql(`
  query ProjectAndTasksMultiSelect {
    projectsByStatus(status: active) {
      id
      name
      isDefault
      tasks {
        id
        name
      }
    }
  }
`)

export const ProjectAndTasksMultiSelects: FC<Props> = ({
  formGroupId,
  includeCurrentAssignment,
  users = [],
  withErrorHandling = false,
}) => {
  const { projectFieldName, taskFieldName } = useMemo(
    () => ({
      projectFieldName: `${formGroupId}.selectedProjectId`,
      taskFieldName: `${formGroupId}.selectedTaskId`,
    }),
    [formGroupId]
  )

  const [projectField, _projectMeta, _projectHelpers] = useField(projectFieldName)
  const [_taskField, _taskMeta, taskHelpers] = useField(taskFieldName)

  const projectId = projectField.value.at(0)
  const previousProjectId = usePrevious(projectId)

  const [{ data, error }] = useQuery({ query: ProjectAndTasksMultiSelect })

  useHandleError(error, "Could not load required data")

  useEffect(() => {
    if (previousProjectId && projectId !== previousProjectId) {
      taskHelpers.setValue([])
    }
  }, [projectId, previousProjectId, taskHelpers])

  const availableProjectsOptions = useMemo(() => {
    return (data?.projectsByStatus || []).map((project) => ({
      id: project.id,
      label: project.name,
      value: project.id,
      searchableTextString: project.name,
      template: (_item: MultiSelectOption) => <div>{project.name}</div>,
    })) as MultiSelectOption[]
  }, [data?.projectsByStatus])

  const availableTasksOptions = useMemo(() => {
    return (
      (data?.projectsByStatus.find((p) => p.id === projectId)?.tasks || [])
        // Temporarily disabling this, it should be implemented in the backend query
        .filter((task) => (includeCurrentAssignment ? true : users.some((user) => user.taskId !== task.id)))
        .map((task) => ({
          id: task.id,
          label: task.name,
          value: task.id,
          searchableTextString: task.name,
          template: (_item: MultiSelectOption) => <div>{task.name}</div>,
        })) as MultiSelectOption[]
    )
  }, [data?.projectsByStatus, includeCurrentAssignment, projectId, users])

  //TODO: Add loading behavior.
  return (
    <Box className="flex gap-6 flex-col md:gap-4">
      <MuiMultiSelect
        containerClassName="flex-1"
        label="Project"
        name={projectFieldName}
        selectedLabel={"project"}
        options={availableProjectsOptions}
        withErrorHandling={withErrorHandling}
        required
        isSingleSelect
      />
      <MuiMultiSelect
        containerClassName="flex-1"
        label="Task"
        name={taskFieldName}
        selectedLabel={"task"}
        options={availableTasksOptions}
        withErrorHandling={withErrorHandling}
        required
        isSingleSelect
      />
    </Box>
  )
}

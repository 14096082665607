import { NextPage } from "next"
import Error from "next/error"
import { UserDocuments } from "../../../components/Partials/User/Documents/UserDocuments"
import { FC, useContext } from "react"
import { PageTitle } from "../../../components/PageTitle"
import { getFullName } from "../../../helpers/getFullName"
import { UserSkeleton } from "../../../components/Partials/User/User.skeleton"
import UserPageLayout, { UserLayoutContext } from "./_layout"
import { RenderIf } from "../../../components/RenderIf"
import { DocumentsProvider } from "../../../providers/DocumentsProvider"

type UserDocumentsPageProps = {
  userId: string
}

export const UserPageDocuments: FC = ({}) => {
  const { user } = useContext(UserLayoutContext)

  return (
    <>
      <PageTitle title={!user?.firstName ? "User documents" : `${getFullName(user)} documents`} />
      {!user?.id && <UserSkeleton />}
      {user?.id && (
        <>
          <div className="flex flex-col gap-9">
            <DocumentsProvider>
              <UserDocuments userId={user.id} />
            </DocumentsProvider>
          </div>
        </>
      )}
    </>
  )
}

const UserPage: NextPage<UserDocumentsPageProps> = ({ userId }) => {
  return (
    <RenderIf permissionsInclude="user:read" context={{ userId }} fallbackComponent={<Error statusCode={404} />}>
      <UserPageLayout userId={userId}>
        <UserPageDocuments />
      </UserPageLayout>
    </RenderIf>
  )
}

UserPage.getInitialProps = async ({ query }) => {
  return {
    userId: query._id as string
  }
}

export default UserPage
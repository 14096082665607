import { format } from "date-fns"
import { FC } from "react"
import { useQuery } from "urql"
import { AssetHistoryReportQuery } from "../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../graphql/generated/gql"
import { AssetBadge } from "../../../AssetBadge"
import { TaskBadge } from "../../../TaskBadge"
import { UserBadge } from "../../../UserBadge"
import { SkeletonRow } from "../../Documents/DocumentList.skeleton"

const AssetQueryDocument = graphql(`
  query TransferredAssetDetails($assetId: String!) {
    asset(id: $assetId) {
      id
      name
      assetGroupId
      companyAssetNumber
      isAssetGroup
      assetChildCount
      ownershipType
      groupQuantity
    }
  }
`)

type TransferReport = AssetHistoryReportQuery["assetReport"]["transferReport"]

export const TransferDetails: FC<{ transferReport: TransferReport; createdAt: Date }> = ({
  transferReport,
  createdAt,
}) => {
  const [{ data: fromAsset }] = useQuery({
    query: AssetQueryDocument,
    variables: { assetId: transferReport?.from?.asset?.id || "" },
    pause: !transferReport?.from?.asset?.id,
  })

  const [{ data: toAsset }] = useQuery({
    query: AssetQueryDocument,
    variables: { assetId: transferReport?.to?.asset?.id || "" },
    pause: !transferReport?.to?.asset?.id,
  })

  if (!transferReport) {
    return null
  }

  if (!fromAsset?.asset || !toAsset?.asset) {
    return <SkeletonRow />
  }

  const { from, to } = transferReport

  const assetFrom = fromAsset?.asset
  const assetTo = toAsset?.asset

  const headerClass = "pb-2 font-bold"
  const dataClass = "grid-row border-t border-b pt-2 pb-2 h-16 flex items-center"

  return (
    <>
      <div className="grid grid-cols-3">
        <div>
          <p className={headerClass}>From</p>
          <div className={dataClass}>
            {from?.user && <UserBadge user={from.user} />}
            {from?.asset && <AssetBadge asset={assetFrom} />}
            {from?.task && <TaskBadge project={from.task?.project} task={from.task} />}
          </div>
        </div>
        <div>
          <p className={headerClass}>To</p>
          <div className={dataClass}>
            {to?.user && <UserBadge user={to.user} />}
            {to?.asset && <AssetBadge asset={assetTo} />}
            {to?.task && <TaskBadge project={to.task?.project} task={to.task} />}
          </div>
        </div>
        <div>
          <p className={headerClass}>Time</p>
          <div className={dataClass}>
            <p className="text-md">{format(createdAt, "hh:mm aaaaa'm'")}</p>
          </div>
        </div>
      </div>
    </>
  )
}

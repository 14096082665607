import { FC, ReactNode, useEffect, useRef, useState } from "react"
import { BiRefresh, BiX } from "react-icons/bi"
import Modal from "react-modal"
import Webcam from "react-webcam"
import { classNames } from "../helpers/classNames"
import { ButtonFilled } from "./Elements"

type Props = {
  title: ReactNode
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  onAcceptPhoto: (image: Blob) => void
  onFail?: (message: string) => void
  withFaceFrame?: boolean
}

type VideoProps = {
  onAcceptPhoto: (image: Blob) => void
  onFail: (message: string) => void
  withFaceFrame?: boolean
}

const imageDimensions = {
  height: 600,
  width: 600,
}

export const PHOTO_BOOTH_IMAGE_TYPE = "image/webp"

const FaceFrame: FC = () => {
  return (
    <div
      className={
        "absolute h-[70%] w-[60%] left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] border-8 border-gray-100 border-dashed rounded-[50%] opacity-50"
      }
    />
  )
}

const VideoWithFrameGrab: FC<VideoProps> = ({ onAcceptPhoto, onFail, withFaceFrame }) => {
  const [facingMode, setFacingMode] = useState<"user" | { exact: "environment" }>("user")
  const [showCameraFlipButton, setShowCameraFlipButton] = useState(false)
  const [imageBlob, setImageBlob] = useState<Blob | null>(null)
  const [isReady, setIsReady] = useState(false)
  const ref = useRef<Webcam>(null)

  // If the user doesn't have multiple cameras, don't render the "Switch cameras" button.
  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      setShowCameraFlipButton(devices.filter((device) => device.kind === "videoinput").length > 1)
    })
  })

  // Stop all active media stream tracks, then change the camera facing mode.
  const switchCameras = () => {
    setFacingMode(facingMode === "user" ? { exact: "environment" } : "user")
  }

  const captureImage = () => {
    try {
      if (ref.current) {
        const canvas = ref.current.getCanvas()
        canvas &&
          canvas.toBlob(
            (blob) => {
              setImageBlob(blob)
            },
            PHOTO_BOOTH_IMAGE_TYPE,
            0.9
          )
      }
    } catch (e: any) {
      onFail(e.message)
    }
  }

  const retakePhoto = () => {
    setImageBlob(null)
  }

  return (
    <div className={"w-full md:w-[600px] aspect-1"}>
      {!imageBlob && (
        <>
          <div className={"relative w-full aspect-1"}>
            <Webcam
              ref={ref}
              screenshotFormat={PHOTO_BOOTH_IMAGE_TYPE}
              videoConstraints={{ facingMode, ...imageDimensions }}
              className={"w-full aspect-1"}
              onUserMedia={() => setIsReady(true)}
            />
            {withFaceFrame && <FaceFrame />}
          </div>
          <div className={"flex p-4 justify-between content-center"}>
            <div className={"w-12"} />
            <button
              className={classNames(
                "h-16 w-16 border-[12px] border-gray-300 bg-white rounded-full",
                isReady ? "" : "opacity-40"
              )}
              disabled={!isReady}
              onClick={captureImage}
            />
            <div className={"w-12 flex content-center"}>
              {showCameraFlipButton && (
                <button onClick={switchCameras}>
                  <BiRefresh className={"text-white h-10 w-10"} />
                </button>
              )}
            </div>
          </div>
        </>
      )}{" "}
      {imageBlob && (
        <>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img src={URL.createObjectURL(imageBlob)} alt={"Captured image"} className={"h-full aspect-1"} />
          <div className={"flex p-4 justify-between py-7 sm:px-6"}>
            <button type={"button"} className={"text-white font-bold text-left flex-grow"} onClick={retakePhoto}>
              Retake
            </button>
            <ButtonFilled
              type={"button"}
              onClick={() => {
                onAcceptPhoto(imageBlob)
              }}
              className={"w-auto"}
            >
              Use Photo
            </ButtonFilled>
          </div>
        </>
      )}
    </div>
  )
}

export const PhotoBooth: FC<Props> = ({
  title,
  isOpen,
  setIsOpen,
  onAcceptPhoto,
  onFail = () => null,
  withFaceFrame,
}) => {
  Modal.setAppElement("#__next")

  return (
    <Modal
      className={classNames("bg-black border-0 w-full h-full text-white", "md:h-auto md:w-auto md:rounded-md")}
      overlayClassName={"fixed z-50 w-screen h-screen top-0 flex items-center justify-center bg-gray-900 bg-opacity-60"}
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
    >
      <div
        className={classNames("px-4 py-3 flex gap-2 content-center w-full", "md:justify-between md:flex-row-reverse")}
      >
        <button onClick={() => setIsOpen(false)}>
          <BiX className={"text-white h-6 w-6"} />
        </button>
        <div className={"font-medium"}>{title}</div>
      </div>
      <VideoWithFrameGrab
        onAcceptPhoto={(photoBuffer) => {
          onAcceptPhoto(photoBuffer)
          setIsOpen(false)
        }}
        onFail={onFail}
        withFaceFrame={withFaceFrame}
      />
    </Modal>
  )
}

import { addDays, endOfMonth, endOfWeek, format, parse, startOfMonth, startOfWeek } from "date-fns"
import { getMidnightForDayInTimeZone } from "./getMidnightForDayInTimeZone"

export const getBookendDates = (
  date: string | undefined,
  timezone?: string,
  rangeType: "daily" | "weekly" | "monthly" | "all-time" = "daily"
) => {
  if (!timezone) return { rangeStart: new Date(), rangeEnd: new Date() }

  const formatString = "yyyy-MM-dd"
  let parsedDate = date ? parse(date, formatString, new Date()) : new Date()

  switch (rangeType) {
    case "daily":
      return {
        rangeStart: getMidnightForDayInTimeZone(format(parsedDate, formatString), timezone),
        rangeEnd: getMidnightForDayInTimeZone(format(addDays(parsedDate, 1), formatString), timezone),
      }
    case "weekly":
      return {
        rangeStart: getMidnightForDayInTimeZone(
          format(startOfWeek(parsedDate, { weekStartsOn: 0 }), formatString),
          timezone
        ),
        rangeEnd: getMidnightForDayInTimeZone(
          format(endOfWeek(parsedDate, { weekStartsOn: 0 }), formatString),
          timezone
        ),
      }
    case "monthly":
      return {
        rangeStart: getMidnightForDayInTimeZone(format(startOfMonth(parsedDate), formatString), timezone),
        rangeEnd: getMidnightForDayInTimeZone(format(endOfMonth(parsedDate), formatString), timezone),
      }
    case "all-time":
      return {
        rangeStart: new Date(0),
        rangeEnd: getMidnightForDayInTimeZone(format(addDays(new Date(), 1), formatString), timezone),
      }
    default:
      return { rangeStart: new Date(), rangeEnd: new Date() }
  }
}

/* eslint-disable @next/next/no-img-element */
import { DataGridPro, GridColDef, GridRowParams, GridValueFormatterParams } from "@mui/x-data-grid-pro"
import { differenceInDays, format } from "date-fns"
import { FC } from "react"
import { BiSolidCheckCircle, BiStopwatch } from "react-icons/bi"
import { useQuery } from "urql"
import {
  ReportedUnitsInDateRangeQuery,
  TimeEntryActivityQuery,
} from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { colors } from "../../../helpers/colors"
import { getHoursAndMinutesFromSeconds } from "../../../helpers/getHoursAndMinutesFromSeconds"
import { pluralize } from "../../../helpers/pluralize"
import { H5 } from "../../Elements"
import { LabeledSection } from "../../LabeledSection"
import { CustomDetailPanelToggleColDef } from "../DataGrid/CustomDetailPanelToggle"
import { DataGridEmptyState } from "../DataGridEmptyState"
import { TaskTitle } from "../Projects/Tasks/TaskTitle"
import { defaultColumnProps } from "./Sections/ProjectAndTaskSummaryTeamTable"

const TimeEntryActivityDocument = graphql(`
  query TimeEntryActivity($rangeStart: DateTime, $rangeEnd: DateTime, $projectId: String!, $userId: String!) {
    user(id: $userId) {
      id
      timeEntries(rangeEnd: $rangeEnd, rangeStart: $rangeStart, projectId: $projectId) {
        id
        projectId
        taskId

        durationInSeconds
        endAt
        startAt

        task {
          id
          name
          isComplete
        }
        project {
          id
          name
        }
      }
    }
  }
`)

const ReportedUnitsInDateRangeDocument = graphql(`
  query ReportedUnitsInDateRange($rangeStart: DateTime, $rangeEnd: DateTime, $projectId: String!, $userId: String!) {
    user(id: $userId) {
      reportedUnitsInDateRangeByProjectOrTask(projectId: $projectId, rangeStart: $rangeStart, rangeEnd: $rangeEnd) {
        id
        taskId
        task {
          name
          id
          isComplete
        }
        createdAt
        imageUrls
        note
        unitGoalProgressReports {
          id
          progress
          unitGoal {
            id
            deliverableUnit {
              id
              description
              unitOfMeasure
            }
          }
        }
      }
    }
  }
`)

export interface TeamSummaryQueryProps {
  rangeStart: Date
  rangeEnd: Date
  projectId: string
  userId: string
}

interface DropDownTablesProps extends TeamSummaryQueryProps {
  initialState: any
}

type TeamMemberDetailTaskReportRow = NonNullable<
  NonNullable<ReportedUnitsInDateRangeQuery["user"]>["reportedUnitsInDateRangeByProjectOrTask"]
>[0]

export type TeamMemberDetailTimeEntryRow = NonNullable<NonNullable<TimeEntryActivityQuery["user"]>["timeEntries"]>[0]

const TimeEntryTable: FC<DropDownTablesProps> = ({ rangeStart, rangeEnd, projectId, userId, initialState }) => {
  const timeEntryTableColumns: GridColDef[] = [
    {
      field: "createdAt",
      headerName: "Date",
      renderCell: ({ row }: { row: TeamMemberDetailTimeEntryRow }) => format(row.startAt, "MM.dd.yyyy"),
    },
    {
      field: "task",
      renderCell: ({ row }: { row: TeamMemberDetailTimeEntryRow }) => (
        <div className="flex gap-x-2 items-center">
          <BiSolidCheckCircle color={colors.green[600]} />
          <TaskTitle
            taskOrGroupId={row.task.id}
            projectId={projectId}
            isComplete={Boolean(row.task.isComplete)}
            size="small"
            name={row.task.name}
            secondaryTitle
          />
        </div>
      ),
      flex: 2,
      headerName: "Task",
    },
    {
      headerName: "Started",
      field: "startAt",
      minWidth: 90,
      valueFormatter: ({ value }) => format(new Date(value), "h:mm a"),
    },
    {
      headerName: "Ended",
      field: "endAt",
      minWidth: 90,
      renderCell: ({ row }: { row: TeamMemberDetailTimeEntryRow }) =>
        row.endAt ? format(new Date(row.endAt), "h:mm a") : <BiStopwatch className="text-blue-600 h-6 w-6" />,
    },
    {
      field: "durationInSeconds",
      headerName: "Total",
      minWidth: 120,
      renderCell: ({ row }: { row: TeamMemberDetailTimeEntryRow }) => {
        const { hours, minutes } = getHoursAndMinutesFromSeconds(row.durationInSeconds)
        return (
          <span className={row.endAt ? "" : "text-blue-600"}>{`${hours} ${pluralize(
            "hr",
            hours
          )} ${minutes} min`}</span>
        )
      },
    },
  ]
  const [{ data }] = useQuery({
    query: TimeEntryActivityDocument,
    variables: { rangeStart, rangeEnd, projectId, userId },
  })
  return (
    <div className="pt-2">
      <H5>Time Entries</H5>
      {data?.user?.timeEntries && data?.user?.timeEntries.length > 0 ? (
        <DataGridPro
          autoHeight
          className="pl-2"
          columns={timeEntryTableColumns.map((column) => ({
            ...defaultColumnProps,
            ...column,
          }))}
          disableRowSelectionOnClick
          hideFooter
          initialState={initialState}
          rows={data?.user?.timeEntries ?? []}
        />
      ) : (
        <DataGridEmptyState text="No time entries" />
      )}
    </div>
  )
}

const TaskReportTable: FC<DropDownTablesProps> = ({ rangeStart, rangeEnd, projectId, userId, initialState }) => {
  const taskReportTableColumns: GridColDef[] = [
    {
      field: "createdAt",
      headerName: "Date",
      minWidth: 120,
      valueFormatter: ({ value }: GridValueFormatterParams<TeamMemberDetailTaskReportRow["createdAt"]>) =>
        format(new Date(value), "MM.dd.yyyy"),
    },
    {
      field: "time",
      headerName: "Time",
      minWidth: 80,
      renderCell: ({ row }: { row: TeamMemberDetailTaskReportRow }) => format(new Date(row.createdAt), "h:mm a"),
    },
    {
      field: "task",
      minWidth: 150,
      renderCell: ({ row }: { row: TeamMemberDetailTimeEntryRow }) => (
        <TaskTitle
          taskOrGroupId={row.task.id}
          projectId={projectId}
          isComplete={Boolean(row.task.isComplete)}
          size="small"
          name={row.task.name}
          secondaryTitle
        />
      ),
      flex: 2,
      headerName: "Task",
    },
    {
      field: "reported",
      headerName: "Reported",
      minWidth: 110,
      renderCell: ({ row }: { row: TeamMemberDetailTaskReportRow }) => {
        const reported =
          row.unitGoalProgressReports.length > 1
            ? "Multiple"
            : row.unitGoalProgressReports.length === 1
            ? `${row.unitGoalProgressReports[0].progress} ${row.unitGoalProgressReports[0].unitGoal.deliverableUnit.unitOfMeasure}`
            : "None"

        return <div>{reported}</div>
      },
    },
    {
      field: "unit",
      headerName: "Unit",
      minWidth: 110,
      renderCell: ({ row }: { row: TeamMemberDetailTaskReportRow }) => {
        const unitDescription =
          row.unitGoalProgressReports.length > 1
            ? "Multiple"
            : row.unitGoalProgressReports.length === 1
            ? row.unitGoalProgressReports[0].unitGoal.deliverableUnit.description
            : "None"

        return <div>{unitDescription}</div>
      },
    },
    {
      field: "imageUrls",
      headerName: "Images",
      minWidth: 80,
      valueFormatter: ({ value }: GridValueFormatterParams<TeamMemberDetailTaskReportRow["imageUrls"]>) =>
        (value || []).length,
    },
    {
      field: "note",
      headerName: "Note",
      minWidth: 60,
      valueFormatter: ({ value }: GridValueFormatterParams<TeamMemberDetailTaskReportRow["note"]>) =>
        value ? "Yes" : "No",
    },
    { ...CustomDetailPanelToggleColDef },
  ]
  const [{ data }] = useQuery({
    query: ReportedUnitsInDateRangeDocument,
    variables: { rangeStart, rangeEnd, projectId, userId },
  })
  return (
    <div className="pt-4 pb-10">
      <H5>Task Reports</H5>
      {data?.user?.reportedUnitsInDateRangeByProjectOrTask &&
      data?.user?.reportedUnitsInDateRangeByProjectOrTask.length > 0 ? (
        <DataGridPro
          autoHeight
          className="pl-2"
          getDetailPanelHeight={() => "auto" as "auto"}
          getDetailPanelContent={getDetailPanelContentForTaskReport}
          columns={taskReportTableColumns.map((column) => ({
            ...defaultColumnProps,
            ...column,
          }))}
          disableRowSelectionOnClick
          hideFooter
          initialState={initialState}
          rows={data?.user?.reportedUnitsInDateRangeByProjectOrTask ?? []}
        />
      ) : (
        <DataGridEmptyState text="No reports" />
      )}
    </div>
  )
}

export const TeamMemberDetailPanel: FC<TeamSummaryQueryProps> = ({ userId, projectId, rangeStart, rangeEnd }) => {
  const isSameDay = differenceInDays(rangeEnd, rangeStart) <= 1

  const initialState = {
    columns: {
      columnVisibilityModel: { createdAt: isSameDay ? false : true },
    },
  }

  return (
    <div className="px-10 pt-2">
      <TimeEntryTable
        userId={userId}
        projectId={projectId}
        rangeEnd={rangeEnd}
        rangeStart={rangeStart}
        initialState={initialState}
      />
      <TaskReportTable
        userId={userId}
        projectId={projectId}
        rangeEnd={rangeEnd}
        rangeStart={rangeStart}
        initialState={initialState}
      />
    </div>
  )
}

const getDetailPanelContentForTaskReport = (params: GridRowParams<TeamMemberDetailTaskReportRow>) => {
  const { note, imageUrls, createdAt } = params.row

  if (!note && (!imageUrls || imageUrls.length === 0)) return null

  return (
    <div>
      {imageUrls && imageUrls.length > 0 && (
        <div className="p-4 grid grid-cols-2 md:grid-cols-4 xl:grid-cols-6 gap-4">
          {imageUrls.map(
            (url) =>
              url && (
                <img
                  key={url}
                  alt="task update image"
                  src={url}
                  className="shadow-md rounded-md aspect-1 object-cover h-40"
                />
              )
          )}
        </div>
      )}
      <div className="p-4">
        {note && (
          <LabeledSection
            label={format(createdAt, "MMM do")}
            className="p-4 mr-4 m-2 bg-yellow-100 shadow-md border-gray-200 rounded-md inline-block"
            note
          >
            {note}
          </LabeledSection>
        )}
      </div>
    </div>
  )
}

import { errorSnack } from '../components/Notistack/ThemedSnackbars'
import { getTimeEntryEvidenceSignedPutUrl } from "../data/api"

const defaultContentType = "image/webp"

export const uploadTimeEntryEvidence = async (file: Blob, userId: string): Promise<string | undefined> => {
  try {
    const { url, path } = await getTimeEntryEvidenceSignedPutUrl(defaultContentType, userId)

    await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": defaultContentType,
      },
      body: file,
    })

    return path
  } catch (e) {
    errorSnack("There was a problem uploading your image. Please try again.")
  }
}

import { Form, Formik } from "formik"
import { FC, useContext } from "react"
import { useMutation } from "urql"
import * as Yup from "yup"
import { User } from "../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../graphql/generated/gql"
import { ButtonFilled, ButtonHollow } from "../../../Elements"
import { ProjectAndTasksMultiSelects } from "../../../Formik/MultiSelect/implementations/ProjectAndTaskMultiSelects"
import { UserSelect } from "../../../Formik/UserSelect"
import { ModalBody } from "../../../Modals/ModalBody"
import { ModalFooter } from "../../../Modals/ModalFooter"
import { ModalLoadingContext } from "../../../Modals/StandardModal"
import { errorSnack } from "../../../Notistack/ThemedSnackbars"

type UserExpectation = Pick<
  User,
  | "id"
  | "currentProjectId"
  | "currentTaskId"
  | "firstName"
  | "imageUrl"
  | "jobTitle"
  | "lastName"
  | "projectId"
  | "taskId"
>

type Props = {
  onCancel: () => void
  onSuccess: (
    assignments: {
      userId: string
      taskId: string
    }[]
  ) => void
  preselectedUsers?: UserExpectation[]
  projectId?: string
}

type Values = {
  assignment: {
    selectedProjectId: string[]
    selectedTaskId: string[]
  }
  userIds: string[]
}

const ReassignUsersMutation = graphql(`
  mutation ReassignUserTaskForm($assignments: [UserAssignmentAssignment!]!) {
    reassignUsers(assignments: $assignments) {
      id
      imageUrl
      currentProjectId
      currentTaskId
      projectId
      project {
        id
        name
      }
      taskId
      task {
        id
        name
      }
    }
  }
`)

export const ReassignUserTaskForm: FC<Props> = ({ projectId, onSuccess, onCancel, preselectedUsers }) => {
  const { setIsLoading } = useContext(ModalLoadingContext)
  const [_, reassignUsersMutation] = useMutation(ReassignUsersMutation)
  const initialValues: Values = {
    assignment: {
      selectedProjectId: projectId ? [projectId] : [],
      selectedTaskId: [],
    },
    userIds: (preselectedUsers || []).map(({ id }) => id),
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        userIds: Yup.array(Yup.string()).min(1, "At least one user must be selected.").required(),
        assignment: Yup.object().shape({
          selectedProjectId: Yup.array(Yup.string()).min(1, "A project must be selected.").required(),
          selectedTaskId: Yup.array(Yup.string()).min(1, "A task must be selected.").required(),
        }),
      })}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values) => {
        const {
          userIds,
          assignment: {
            selectedTaskId: [taskId],
          },
        } = values
        const assignments = userIds.map((userId) => ({ userId, taskId, projectId: projectId || "" }))

        reassignUsersMutation({ assignments }).then((result) => {
          if (result.error) {
            setIsLoading(false)
            errorSnack("An error was encountered while reassigning users. Please try again.")
          } else {
            onSuccess?.(assignments)
          }
        })
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Form className={"h-full flex flex-col justify-around"}>
            <ModalBody>
              <div className="grid gap-y-6 mb-8">
                <UserSelect
                  className="col-span-12"
                  label="Team Member"
                  multiple
                  name="userIds"
                  preselected={preselectedUsers}
                />
                <div className="col-span-12">
                  <ProjectAndTasksMultiSelects
                    formGroupId={"assignment"}
                    users={preselectedUsers}
                    includeCurrentAssignment={false}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <ButtonHollow onClick={onCancel} type="button">
                Cancel
              </ButtonHollow>
              <ButtonFilled type="submit" disabled={isSubmitting}>
                Reassign
              </ButtonFilled>
            </ModalFooter>
          </Form>
        )
      }}
    </Formik>
  )
}

import { Form, Formik } from "formik"
import { FC } from "react"
import { BiSitemap } from "react-icons/bi"
import { useQuery } from "urql"
import { User, useSetUserParentMutation } from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { PickPlus } from "../../../types/helpers"
import { TeamMembersMultiSelect } from "../../Formik/MultiSelect/implementations/TeamMembersMultiSelect"

type UserExpectation = PickPlus<User, "id" | "childAncestorNodes"> & {
  parents: PickPlus<User, "id" | "firstName" | "lastName" | "jobTitle">[]
}

type Props = {
  user: UserExpectation
}

const ParentAssignmentAndControlQuery = graphql(`
  query ParentAssignmentAndControlQuery {
    users {
      edges {
        node {
          id
          currentProjectId
          currentTaskId
          firstName
          jobTitle
          lastName
          projectId
          taskId
          childAncestorNodes {
            userId
            depth
          }
          parents {
            id
            firstName
            lastName
            jobTitle
          }
        }
      }
    }
  }
`)

export const ParentAssignmentAndControl: FC<Props> = ({ user }) => {
  const parent = user.parents.at(0)

  const [{ data }] = useQuery({
    query: ParentAssignmentAndControlQuery,
  })

  const users = data?.users?.edges?.map((edge) => edge!.node) ?? []

  const potentialParentUsers = users.filter((parentUser) => {
    const isChildAncestor = user.childAncestorNodes.some(({ userId }) => parentUser.id === userId)
    const isSelf = parentUser.id === user.id

    return !isChildAncestor && !isSelf
  })

  const [, setUserParentMutation] = useSetUserParentMutation()

  // TODO: Filter all children, children's children, etc, from the list of potential parents.

  return (
    <div className="border-gray-200 flex flex-col md:flex-row py-4 justify-between md:items-center gap-y-8">
      <div className="flex gap-4 items-center">
        <div className="rounded-lg bg-gray-100 h-10 w-10 flex justify-center items-center">
          <BiSitemap className="h-5 w-5" />
        </div>
        <div className="flex gap-4">
          <p>Reports to</p>
          <p className="text-gray-400">
            {parent ? `${parent.firstName} ${parent.lastName} / ${parent.jobTitle}` : "no one"}
          </p>
        </div>
      </div>
      <Formik initialValues={{ parent: user.parents.map(({ id }) => id) }} onSubmit={() => {}}>
        <Form className="w-full md:w-96">
          <TeamMembersMultiSelect
            teamMembers={potentialParentUsers.filter(({ id }) => id !== user.id)}
            name="parent"
            isSingleSelect={true}
            withErrorHandling={false}
            onChange={([parentId]) => {
              if (parentId && parentId !== parent?.id) {
                setUserParentMutation({ childId: user.id, parentId })
              }
            }}
          />
        </Form>
      </Formik>
    </div>
  )
}

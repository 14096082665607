import { Box, Button, Typography } from "@mui/material"
import { Form, Formik } from "formik"
import { FC, useContext, useMemo } from "react"
import { useQuery } from "urql"
import * as Yup from "yup"
import {
  AssetReportType,
  AssetStatus,
  AssetStatusChange,
  NestedAssetInventoryQuery,
  useInsertManyAssetReportsMutation,
} from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { useWindowSize } from "../../../hooks/useWindowSize"
import { errorSnack, successSnack } from "../../Notistack/ThemedSnackbars"
import { ColumnTitle } from "../../Table/ColumnTitle"
import { ColumnTitles } from "../../Table/ColumnTitles"
import { Table } from "../../Table/Table"
import { AssetInventoryRow } from "../Inventory/InventoryReportRow"
import { SingleDrawerContext } from "./Drawer"
import { DrawerFooter } from "./DrawerFooter"
import { DrawerHeader } from "./DrawerHeader"

const NestedAssetInventoryDocument = graphql(`
  query NestedAssetInventory($id: String!) {
    nestedAssetsForInventory(id: $id) {
      id
      assetChildCount
      assetGroupId
      companyAssetNumber
      depth
      imageUrl
      isAssetGroup
      lastInventoriedAt
      name
      ownershipType
      status
      inventoryRequirements {
        photoRequired
        intervalInSeconds
      }
    }
  }
`)

export type InventoryAssetExpectation = NestedAssetInventoryQuery["nestedAssetsForInventory"][0]

interface FormValues {
  assetReports: {
    [key: string]: AssetReport
  }
}
interface AssetReport {
  currentStatus?: AssetStatus
  isAvailable?: boolean
  photos?: {
    fileId: string
    objectKey: string
    uploaded: boolean
  }[]
  photoRequired: boolean
  note?: string
  quantityReported?: number
  isRoot: boolean
  isQuantized: boolean
}

interface AssetReportValidationSchema {
  [key: string]: Yup.ObjectSchema<AssetReport>
}

type ReportData = {
  assetId: string
  type: AssetReportType
  notes?: string | undefined
  statusChange?: AssetStatusChange | undefined
  inventoryReport?: {
    fileIds: string[] | undefined
    photos: string[] | undefined
  }
  quantityReported?: number
}

export const InventoryReportDrawer: FC<{ asset: InventoryAssetExpectation; isQuantizedAsset?: boolean }> = ({
  asset,
  isQuantizedAsset,
}) => {
  const [{ data }] = useQuery({
    query: NestedAssetInventoryDocument,
    variables: {
      id: asset.id,
    },
    pause: isQuantizedAsset || !asset.assetChildCount,
  })
  const { width: browserWidth } = useWindowSize()
  const isMobile = useMemo(() => (browserWidth || 0) < 768, [browserWidth])
  const { handleClose } = useContext(SingleDrawerContext)
  const [, createAssetReportsMutation] = useInsertManyAssetReportsMutation()
  const allAssets = isQuantizedAsset ? [asset] : [asset, ...(data?.nestedAssetsForInventory || [])]

  const initialValues = {
    assetReports: allAssets.reduce((acc, asset, i) => {
      acc[asset.id] = {
        currentStatus: asset.status || AssetStatus.Available,
        photoRequired: Boolean(asset.inventoryRequirements?.photoRequired),
        isRoot: i === 0,
        isQuantized: Boolean(asset.isAssetGroup),
      }
      return acc
    }, {} as FormValues["assetReports"]),
  }

  const handleSubmit = async ({ assetReports }: FormValues) => {
    const reportsToSubmit: ReportData[] = []
    try {
      for (const [assetId, report] of Object.entries(assetReports)) {
        const fileIds = report.photos?.map((img) => img.fileId)
        const photos = report.photos?.map((img) => img.objectKey)
        const assetInfo = allAssets.find((a) => a.id === assetId)
        const assetChildCount = assetInfo?.assetChildCount ?? 0
        const missingCount = assetChildCount - (report.quantityReported ?? 0)

        const reportData: ReportData = {
          assetId,
          type: AssetReportType.Inventory,
        }

        if (report.note) {
          reportData.notes = report.note
        }

        if (report.photos) {
          reportData.inventoryReport = { fileIds, photos }
        }

        // Handle Quantized Assets
        if (report.quantityReported !== undefined) {
          reportData.statusChange = {
            active: (report.quantityReported ?? 0) > 0,
            status: report.quantityReported === 0 ? AssetStatus.Missing : report.currentStatus ?? AssetStatus.Available,
            missingCount,
          }
          reportData.quantityReported = report.quantityReported
          reportsToSubmit.push(reportData)
        }

        // Handle Non-Quantized Assets
        if (report.isAvailable !== undefined) {
          reportData.statusChange = {
            active: report.isAvailable,
            status: report.isAvailable ? report.currentStatus ?? AssetStatus.Available : AssetStatus.Missing,
          }
          reportsToSubmit.push(reportData)
        }
      }

      for (const reportData of reportsToSubmit) {
        const result = await createAssetReportsMutation({ reports: [reportData] })
        if (result.error) {
          throw new Error("Error submitting one or more reports.")
        }
      }
      handleClose()
      successSnack("All reports submitted successfully")
    } catch (error) {
      errorSnack("Error submitting one or more reports")
      console.error(error)
    }
  }

  const validationSchema = Yup.object().shape({
    assetReports: Yup.lazy((assetReports) =>
      Yup.object().shape(
        Object.keys(assetReports).reduce((acc: AssetReportValidationSchema, key) => {
          acc[key] = Yup.object().shape({
            isRoot: Yup.boolean(),
            isQuantized: Yup.boolean(),
            photoRequired: Yup.boolean(),
            currentStatus: Yup.mixed<AssetStatus>(),
            note: Yup.string().notRequired(),

            // Conditional validation for root assets
            isAvailable: Yup.boolean().when(["isRoot", "isQuantized"], {
              is: (isRoot: boolean, isQuantized: boolean) => isRoot && !isQuantized,
              then: (schema) => schema.required("isAvailable must be defined for the root asset."),
            }),
            quantityReported: Yup.number()
              .min(0)
              .when(["isRoot", "isQuantized"], {
                is: (isRoot: boolean, isQuantized: boolean) => isRoot && isQuantized,
                then: (schema) => schema.required("quantityReported must be defined for the root asset."),
              }),

            // General schema for all assets
            photos: Yup.array()
              .of(
                Yup.object().shape({
                  fileId: Yup.string().required(),
                  objectKey: Yup.string().required(),
                  uploaded: Yup.boolean().required(),
                })
              )
              .when("photoRequired", (photoRequired, schema) =>
                schema.when(["isAvailable", "quantityReported"], {
                  is: (isAvailable: boolean, quantityReported: number) => {
                    photoRequired[0] && (typeof isAvailable !== "undefined" || typeof quantityReported !== "undefined")
                  },
                  then: (schema) => schema.required("At least one photo is required"),
                })
              ),
          }) as Yup.ObjectSchema<AssetReport>
          return acc
        }, {})
      )
    ),
  })
  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
      <Form>
        <DrawerHeader handleClose={handleClose} />

        <Box width="100%" paddingBottom={4} paddingX={2} className="md:px-8">
          <Typography variant="h4" paddingY={5} paddingX={4} fontSize={32} fontWeight={700} className="md:px-0">
            Inventory <span className="text-gray-400">{asset.name}</span>
          </Typography>

          <ColumnTitles className="hidden md:block">
            <div className="md:col-span-4 ">
              <ColumnTitle isSortable={false} className="md:ml-2">
                Asset
              </ColumnTitle>
            </div>
            <ColumnTitle isSortable={false} className="md:col-span-3">
              Due
            </ColumnTitle>
            <ColumnTitle isSortable={false} className="md:col-span-2">
              Last Inventory
            </ColumnTitle>
            <ColumnTitle isSortable={false} className="md:col-span-2">
              Actions
            </ColumnTitle>
          </ColumnTitles>

          <Table>
            {allAssets.map((asset, i) => (
              <AssetInventoryRow key={i} asset={asset} isMobile={isMobile} />
            ))}
          </Table>
        </Box>

        <DrawerFooter>
          <Button className="ml-4" variant="contained" color="primary" type="submit">
            Submit
          </Button>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
        </DrawerFooter>
      </Form>
    </Formik>
  )
}

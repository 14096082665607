import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import { FC } from "react"
import { BiLogOut, BiStopwatch } from "react-icons/bi"
import { ModernUserWithTimeEntry } from "."
import { useClockedInUsers } from "../../../../hooks/useClockedInUsers"
import { useClockedOutUsers } from "../../../../hooks/useClockedOutUsers"
import { useModalProps } from "../../../../hooks/useModalProps"
import StandardModal from "../../../Modals/StandardModal"
import { BulkClockInModalContents } from "./BulkClockInModalContents"
import { BulkClockOutModalContents } from "./BulkClockOutModalContents"

type Props = {
  onSuccess?: () => void
  selectedUsers: ModernUserWithTimeEntry[]
}

export const BulkClockActionsWithEvidence: FC<Props> = ({ onSuccess, selectedUsers }) => {
  const bulkClockInModalProps = useModalProps("Bulk Clock In")
  const bulkClockOutModalProps = useModalProps("Bulk Clock Out")

  const clockedInUsers = useClockedInUsers(selectedUsers)
  const clockedOutUsers = useClockedOutUsers(selectedUsers)

  return (
    <>
      {clockedOutUsers.length > 0 && (
        <>
          <ListItem disablePadding>
            <ListItemButton onClick={bulkClockInModalProps.handleOpenModal}>
              <ListItemIcon>
                <BiStopwatch className="h-5 w-5" />
              </ListItemIcon>
              <ListItemText primary="Clock in" />
            </ListItemButton>
          </ListItem>
          <StandardModal {...bulkClockInModalProps}>
            <BulkClockInModalContents
              onCancel={bulkClockInModalProps.handleCloseModal}
              onSuccess={() => {
                bulkClockInModalProps.handleCloseModal()
                onSuccess?.()
              }}
              userIds={clockedOutUsers.map(({ id }) => id)}
            />
          </StandardModal>
        </>
      )}
      {clockedInUsers.length > 0 && (
        <>
          <ListItem disablePadding>
            <ListItemButton onClick={bulkClockOutModalProps.handleOpenModal}>
              <ListItemIcon>
                <BiLogOut className="h-5 w-5" />
              </ListItemIcon>
              <ListItemText primary="Clock out" />
            </ListItemButton>
          </ListItem>
          <StandardModal {...bulkClockOutModalProps}>
            <BulkClockOutModalContents
              onCancel={bulkClockOutModalProps.handleCloseModal}
              onSuccess={() => {
                bulkClockOutModalProps.handleCloseModal()
                onSuccess?.()
              }}
              userIds={clockedInUsers.map(({ id }) => id)}
            />
          </StandardModal>
        </>
      )}
    </>
  )
}

import { FC, useContext } from "react"
import { BiCheck, BiDotsHorizontalRounded, BiPackage, BiPlus, BiSortAlt2, BiTask, BiTrash } from "react-icons/bi"
import { AssetGroup } from "../../../../../graphql/generated/client-types-and-hooks"
import { classNames } from "../../../../../helpers/classNames"
import { DrawerContext } from "../../../../../providers/DrawerProvider"
import { QuickMenu } from "../../../../QuickMenu"
import { MenuItem } from "../../../../QuickMenuMui"
import { ReassignAssetDrawer } from "../../../Drawer/ReassignAssetDrawer"

export const QuantizedAssetQuickActionsCell: FC<{
  assetGroup: AssetGroup
  isArchived?: boolean
  className?: string
  openAddQuantityModal: (assetGroup: AssetGroup) => void
  handleArchiveAsset: (assetGroup: AssetGroup) => void
  handleUnarchiveAsset: (assetGroup: AssetGroup) => void
  handleInventoryReport: (assignment: AssetGroup) => void
  handleReturnToInventory: (assetGroup: AssetGroup) => void
}> = ({
  assetGroup,
  className,
  openAddQuantityModal,
  handleArchiveAsset,
  handleUnarchiveAsset,
  handleInventoryReport,
  handleReturnToInventory,
  isArchived = false,
}) => {
  const { push: pushDrawer } = useContext(DrawerContext)

  const isNotInventoryAsset = assetGroup.assetGroupId !== assetGroup.assignableId

  const quickMenuItems: MenuItem[][] = [
    [
      {
        value: "Reassign Inventories",
        Icon: BiSortAlt2,
        iconStyles: "rotate-90",
        onClick: () => pushDrawer(<ReassignAssetDrawer assetsIdsToReassign={[]} assetGroupToReassign={assetGroup} />),
      },
      {
        value: "Inventory",
        Icon: BiTask,
        onClick: () => handleInventoryReport(assetGroup),
      },
      {
        value: "Add",
        Icon: BiPlus,
        onClick: () => openAddQuantityModal(assetGroup),
      },
    ],
  ]

  const menuGroup: MenuItem[] = []

  if (isNotInventoryAsset) {
    menuGroup.push({
      value: "Return to inventory",
      Icon: BiPackage,
      onClick: () => handleReturnToInventory(assetGroup),
    })
  }
  menuGroup.push(
    isArchived
      ? {
          value: "Unarchive assets",
          Icon: BiCheck,
          color: "green",
          iconStyles: "text-green-600",
          onClick: () => handleUnarchiveAsset(assetGroup),
        }
      : {
          value: "Archive",
          Icon: BiTrash,
          color: "red",
          iconStyles: "text-red-600",
          onClick: () => handleArchiveAsset(assetGroup),
        }
  )

  quickMenuItems.push(menuGroup)

  return (
    <div className={classNames("hidden mt-2", "md:flex md:mt-0 md:col-span-1 justify-self-end", className)}>
      <QuickMenu
        className={classNames(
          "h-10 w-10 -mr-4 md:mr-0 flex items-center justify-center flex-none rounded-full transition-colors p-2",
          "hover:bg-gray-50"
        )}
        items={quickMenuItems}
      >
        <BiDotsHorizontalRounded className="w-6 h-6" />
      </QuickMenu>
    </div>
  )
}

import { Typography } from "@mui/material"
import { useRouter } from "next/router"
import { FC } from "react"
import { BiLabel, BiUserCircle } from "react-icons/bi"
import { userBadgeTestSelectors } from "../cypress/support/testSelectors"
import { UserNotificationModelType } from "../graphql/generated/client-types-and-hooks"
import { User } from "../graphql/generated/gql/graphql"
import { classNames } from "../helpers/classNames"
import { PickPlus } from "../types/helpers"
import { Monogram, MonogramRing } from "./Monogram"
import { DrawerLink } from "./Partials/Drawer/DrawerLink"
import { UserDrawer } from "./Partials/Drawer/UserDrawer"
import { QuickMenu } from "./QuickMenu"
import { MenuItem } from "./QuickMenuMui"
import { NotificationBubble } from "./UserNotifications/NotificationBubble"

type Props = {
  className?: string
  user?: PickPlus<User, "id" | "firstName" | "lastName" | "jobTitle" | "imageUrl" | "isClockedIn">
  showLastNameInitial?: boolean
}

export const UserBadge: FC<Props> = ({ user, className = "", showLastNameInitial = false }) => {
  const href = `/team/${user?.id}/details`

  return (
    <div
      className={classNames(
        "flex gap-x-1 w-full overflow-hidden",
        className,
        user ? "" : "skeleton h-12 w-full min-w-[200px]"
      )}
    >
      <div className="w-10 mr-0.5 shrink-0 flex justify-center items-center">
        {user && (
          <DrawerLink
            href={href}
            className="text-left"
            component={<UserDrawer userId={user.id} />}
            data-test={userBadgeTestSelectors.name}
          >
            <MonogramRing user={user} />
          </DrawerLink>
        )}
      </div>
      {user && (
        <DrawerLink
          href={href}
          className="text-left"
          component={<UserDrawer userId={user.id} />}
          data-test={userBadgeTestSelectors.name}
        >
          <div className="grid content-center gap-y-0.5">
            <Typography variant="h6" className="mt-1 h-[17px] overflow-hidden break-all">
              <NotificationBubble
                shouldRender={(notifications) => {
                  return !!notifications.filter(
                    (n) => n.modelType === UserNotificationModelType.User && n.user?.id === user.id
                  ).length
                }}
              />
              {user.firstName} {showLastNameInitial && user.lastName.at(0) ? `${user.lastName.at(0)}.` : user.lastName}
            </Typography>
            <div className="flex gap-px text-gray-600 items-center">
              <BiLabel className="w-3 h-3" />
              <span className="flex-1 text-xs h-[16px] overflow-hidden break-all">{user.jobTitle}</span>
            </div>
          </div>
        </DrawerLink>
      )}
    </div>
  )
}

export const CompactUserBadge: FC<{
  user: PickPlus<User, "archived" | "id" | "firstName" | "lastName">
}> = ({ user }) => {
  const router = useRouter()

  const compactUserBadgeQuickMenu: MenuItem[][] = [
    [
      {
        value: "View user",
        Icon: BiUserCircle,
        onClick: () => {
          router.push(`/team/${user.id}`)
        },
      },
    ],
  ]
  const nameLen = user.firstName.length + user.lastName.length + 1

  return (
    <>
      <QuickMenu
        items={compactUserBadgeQuickMenu}
        className="w-full col-span-12 mt-2 md:mt-0 md:col-span-2"
        menuButtonClassName="w-full text-left cursor-pointer p-2 bg-gray-100 rounded hover:bg-gray-200 hover:rounded transition-all"
        disabled={user?.archived}
      >
        <div className="w-full leading-tight flex gap-2 items-center pr-3">
          {user && (
            <>
              <Monogram height="h-6" width="w-6" user={user} />
              {user?.firstName} {nameLen > 12 ? user?.lastName[0].toUpperCase() : user?.lastName}
            </>
          )}
        </div>
      </QuickMenu>
    </>
  )
}

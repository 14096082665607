import { FC, ReactNode, useContext } from "react"
import { OrganizationSettingsContext } from "../providers/OrganizationSettingsProvider"

type Props = {
  children: ReactNode
  fallbackComponent?: ReactNode
}

export const OrganizationRequiresClockInEvidence: FC<Props> = ({ children, fallbackComponent }) => {
  const { requireTimeEntryEvidence } = useContext(OrganizationSettingsContext)
  return requireTimeEntryEvidence ? <>{children}</> : <>{fallbackComponent}</>
}

import { FC, ReactNode } from "react"
import { classNames } from "../helpers/classNames"

type Props = {
  children: ReactNode
  isOpen: boolean
  className?: string
}

export const TableContextMenuContainer: FC<Props> = ({ isOpen, children, className }) => {
  if (!isOpen) return null

  return (
    <div
      className={classNames(
        "flex gap-2 overflow-x-auto lg:pb-0",
        "lg:justify-end lg:right-0 lg:overflow-x-visible lg:mt-0",
        className
      )}
    >
      {children}
    </div>
  )
}

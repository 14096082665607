import { FC } from "react"
import { classNames } from "../../../helpers/classNames"
import { RenderIf } from "../../RenderIf"
import { SkeletonContainer } from "../../Skeletons/SkeletonContainer"
import { SkeletonElement } from "../../Skeletons/SkeletonElement"
import { SkeletonIcon } from "../../Skeletons/SkeletonIcon"
import { SkeletonTextSmall } from "../../Skeletons/SkeletonTextSmall"
import { ColumnTitles } from "../../Table/ColumnTitles"
import { Row } from "../../Table/Row"
import { Table } from "../../Table/Table"

export const UserSkeletonRow: FC = () => {
  return (
    <Row
      className={classNames(
        "grid auto-rows-min grid-cols-12 grid-flow-col gap-y-6",
        "md:grid-flow-row md:grid-cols-12 md:grid-rows-1 md:items-center md:gap-x-6"
      )}
    >
      <div className="col-span-10 md:col-span-3 flex gap-x-4 items-center">
        <div className="flex items-center gap-2">
          <SkeletonElement className={"h-4 w-4"} />
          <SkeletonElement className={"h-10 w-10"} />
        </div>
        <div className={"flex flex-col justify-center gap-y-2"}>
          <SkeletonTextSmall />
          <SkeletonTextSmall />
        </div>
      </div>
      <div className={"col-span-10 md:col-span-2"}>
        <SkeletonElement className={"h-8 w-40"} />
      </div>
      <div className={"col-span-10 md:col-span-2"}>
        <SkeletonElement className={"h-8 w-40"} />
      </div>
      <div className={"col-span-10 md:col-span-2 flex justify-between"}>
        <SkeletonTextSmall />
      </div>
      <div className={"col-span-10 md:col-span-2 flex justify-between"}>
        <SkeletonTextSmall />
      </div>
      <div className={"row-span-3 col-span-2 justify-self-end md:row-auto md:col-span-1"}>
        <SkeletonIcon />
      </div>
    </Row>
  )
}

export const UsersSkeleton: FC = () => {
  return (
    <SkeletonContainer>
      <div className="flex justify-between align-center">
        <SkeletonElement className={"h-12 w-24 mb-6"} />
      </div>
      <div className="flex justify-between mb-4">
        <div className="my-4 flex items-center justify-start gap-6">
          <SkeletonElement className="h-8 w-16" />
          <SkeletonElement className="h-8 w-24" />
        </div>
        <div className="my-4 flex items-center justify-start gap-2">
          <SkeletonElement className="h-10 w-10" />
          <RenderIf permissionsInclude="user:create">
            <SkeletonElement className="h-10 w-10" />
          </RenderIf>
        </div>
      </div>
      <ColumnTitles>
        <SkeletonElement className={"h-4 w-16 col-span-3"} />
        <SkeletonElement className={"h-4 w-16 col-span-2"} />
        <SkeletonElement className={"h-4 w-16 col-span-2"} />
        <SkeletonElement className={"h-4 w-16 col-span-2"} />
        <SkeletonElement className={"h-4 w-16 col-span-2"} />
      </ColumnTitles>
      <Table>
        <UserSkeletonRow />
        <UserSkeletonRow />
        <UserSkeletonRow />
      </Table>
      <div className="flex justify-center pt-8">
        <SkeletonTextSmall />
      </div>
    </SkeletonContainer>
  )
}

import { Box } from "@mui/material"
import { DataGridPro, GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro"
import { format } from "date-fns"
import Link from "next/link"
import { FC } from "react"
import { BiDotsHorizontalRounded, BiEditAlt, BiTrash } from "react-icons/bi"
import { useDeleteTimeEntryMutation } from "../../../../../graphql/generated/client-types-and-hooks"
import { classNames } from "../../../../../helpers/classNames"
import { secondsToFormattedString } from "../../../../../helpers/time-utility"
import { QuickMenu } from "../../../../QuickMenu"
import { DrawerLink } from "../../../Drawer/DrawerLink"
import { TaskDrawer } from "../../../Drawer/TaskDrawer"
import { TimeEntryWithProjectAndTaskName } from "../../EditDayActivityForm/EditDayActivityForm"
import { defaultTimeEntryColumnProps } from "./TimeEntryDataGrid"

export const DailyDetailPanel: FC<{
  timeEntries: TimeEntryWithProjectAndTaskName[]
  openEditDayActivityModal: (timeEntries: TimeEntryWithProjectAndTaskName[]) => void
}> = ({ timeEntries, openEditDayActivityModal }) => {
  const [, deleteTimeEntryMutation] = useDeleteTimeEntryMutation()

  const columns: GridColDef[] = [
    {
      ...defaultTimeEntryColumnProps,
      field: "project",
      headerName: "Project",
      flex: 1.5,
      renderCell: ({ row }: GridRenderCellParams<TimeEntryWithProjectAndTaskName>) => (
        <Link
          className={classNames(
            "underline",
            row.isBreak ? "text-violet-600 hover:text-violet-700" : "text-blue-600 hover:text-blue-700"
          )}
          href={`/projects/${row.projectId}`}
        >
          {row.project.name}
        </Link>
      ),
    },
    {
      ...defaultTimeEntryColumnProps,
      field: "task",
      headerName: "Task",
      renderCell: ({ row }: GridRenderCellParams<TimeEntryWithProjectAndTaskName>) =>
        row.isBreak ? (
          <div className="text-violet-600 hover:text-violet-700">{row.task.name}</div>
        ) : (
          <DrawerLink
            className="underline text-blue-600 hover:text-blue-700"
            component={<TaskDrawer taskId={row.taskId} />}
            href={`/projects/${row.projectId}/${row.taskId}`}
          >
            {row.task.name}
          </DrawerLink>
        ),
    },
    {
      ...defaultTimeEntryColumnProps,
      field: "startAt",
      headerName: "Started",
      valueGetter: ({ row }: GridRenderCellParams<TimeEntryWithProjectAndTaskName>) => format(row.startAt, "h:mm a"),
    },
    {
      ...defaultTimeEntryColumnProps,
      field: "endAt",
      headerName: "Ended",
      valueGetter: ({ row }: GridRenderCellParams<TimeEntryWithProjectAndTaskName>) =>
        row.endAt ? format(row.endAt, "h:mm a") : "",
    },
    {
      ...defaultTimeEntryColumnProps,
      field: "duration",
      headerName: "Task Total",
      valueGetter: ({ row }: GridRenderCellParams<TimeEntryWithProjectAndTaskName>) =>
        secondsToFormattedString(row.durationInSeconds),
    },
    {
      field: "quickActionsMenu",
      headerName: "",
      width: 64,
      renderCell: ({ row }: GridRenderCellParams<TimeEntryWithProjectAndTaskName>) => (
        <QuickMenu
          className="w-[50px] pr-1"
          items={[
            [
              {
                requiredPermission: "timeEntry:update",
                value: "Edit day activity",
                onClick: () => openEditDayActivityModal([row]),
                Icon: BiEditAlt,
              },
              {
                requiredPermission: "timeEntry:delete",
                value: "Delete time entry",
                color: "red",
                onClick: () => deleteTimeEntryMutation({ id: row.id }),
                Icon: BiTrash,
              },
            ],
          ]}
        >
          <BiDotsHorizontalRounded className="h-6 w-6" />
        </QuickMenu>
      ),
    },
  ]
  return (
    <Box paddingX={2}>
      <DataGridPro
        columns={columns}
        rows={timeEntries}
        disableRowSelectionOnClick
        hideFooter
        sx={{
          border: "none",
          fontSize: 16,
          "& .MuiDataGrid-columnHeaders": { borderBottom: "1px solid gray-100" },
          "& .MuiDataGrid-cell:focus": { outline: "none" },
          "& .MuiDataGrid-cell:focus-within": { outline: "none" },
          "& .MuiDataGrid-columnHeader:focus": { outline: "none" },
          "& .MuiDataGrid-columnHeader:focus-within": { outline: "none" },
          "& .MuiDataGrid-pinnedColumnHeaders": { display: "none" },
          "& .MuiDataGrid-pinnedColumns": { boxShadow: "none" },
        }}
      />
    </Box>
  )
}

import { Typography } from "@mui/material"
import { FC, ReactElement } from "react"
import { BiSortAlt2, BiTask, BiWrench } from "react-icons/bi"

export const AssetReportType: FC<{ type: string }> = ({ type }) => {
  let icon: ReactElement | null = null
  switch (type) {
    case "Inventory":
      icon = <BiTask />
      break
    case "Transfer":
      icon = <BiSortAlt2 />
      break
    case "Inspection":
      icon = <BiWrench />
      break
  }

  return (
    <div className="flex flex-row items-center max-w-[150px]">
      <div className="bg-gray-800 mr-2 rounded h-6 w-6 p-1 text-white">{type && icon}</div>
      <Typography fontWeight={400}>{type === "Transfer" ? "Reassigned" : type}</Typography>
    </div>
  )
}

import { TimeEntry } from "../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../types/helpers"

export function getTimeEntryDateBookends<T extends PickPlus<TimeEntry, "endAt" | "startAt">>(timeEntries: T[]) {
  const maxEnd = Math.max(...timeEntries.map((timeEntry) => timeEntry.endAt?.getTime() || 0))
  const firstStart = Math.min(...timeEntries.map((te) => te.startAt.getTime()))

  const startOfDay = new Date(firstStart)
  startOfDay.setHours(0, 0, 0, 0)
  const endOfDay = new Date(maxEnd)
  endOfDay.setHours(23, 59, 59, 999)
  return [startOfDay, endOfDay]
}

import { BiBullseye } from "react-icons/bi"
import { DeliverableUnit } from "../../../graphql/generated/client-types-and-hooks"
import { classNames } from "../../../helpers/classNames"
import { getFriendlyFloat } from "../../../helpers/getFriendlyFloat"
import { PickPlus } from "../../../types/helpers"

type TargetProductionRateProps = {
  estimatedHours: number | "" | undefined
  deliverableUnitId: string | undefined
  unitGoalTarget: number | "" | undefined
  units: PickPlus<DeliverableUnit, "id" | "unitOfMeasure">[]
}
export const TargetProductionRate = ({
  estimatedHours,
  deliverableUnitId,
  unitGoalTarget,
  units,
}: TargetProductionRateProps) => {
  const predictable = !!(estimatedHours && deliverableUnitId && unitGoalTarget)

  const calculatedProductionRate = !!(
    typeof estimatedHours == "number" &&
    typeof unitGoalTarget == "number" &&
    estimatedHours !== 0
  )
    ? getFriendlyFloat(unitGoalTarget / estimatedHours)
    : "N/A"

  return (
    <div className="rounded bg-gray-50 px-6 py-4 flex items-center gap-5">
      <BiBullseye className={classNames("h-6 w-6", predictable && "fill-blue-600")} />
      <div>
        <div className={"mb-1 font-medium"}>Target Production Rate</div>
        <div className={classNames("text-sm", predictable ? "text-blue-600" : "text-gray-400")}>
          {predictable
            ? `${getFriendlyFloat(calculatedProductionRate)} ${
                units.find((u) => u.id === deliverableUnitId)?.unitOfMeasure
              } per man-hour`
            : "Requires unit type, quantity and man-hours"}
        </div>
      </div>
    </div>
  )
}

import { useEffect, useState } from "react"

export const useInitialVisibility = (units: any[]): [Record<string, boolean>, (unitDescription: string) => void] => {
  const [visibility, setVisibility] = useState<Record<string, boolean>>({})

  useEffect(() => {
    if (units.length) {
      const defaultVisibility = units.reduce((acc, unit) => {
        acc[unit.description] = true
        return acc
      }, {} as Record<string, boolean>)
      setVisibility(defaultVisibility)
    }
  }, [units])

  const toggleVisibility = (unitDescription: string) => {
    setVisibility((prevVisibility) => ({
      ...prevVisibility,
      [unitDescription]: !prevVisibility[unitDescription],
    }))
  }

  return [visibility, toggleVisibility]
}

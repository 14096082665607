import {
  AssetReportInput,
  AssetReportTemplateField,
  AssetStatusChangeInput,
} from "../graphql/generated/client-types-and-hooks"
import { stringToAssetStatus } from "./assetStatus"

const statusPrecedence = ["OutOfService", "NeedsRepair", "Missing", "BeingRepaired", "Active"]
const statusIndex = (status: string) => statusPrecedence.indexOf(status)

export const updateStatusChangeFromRules = (reports: AssetReportInput[]) => {
  reports.forEach((report) => {
    const mxReports = report.inspectionReport?.flatMap((r) => r)
    const failedReportsWithRules = mxReports?.filter(
      (mxReport) => mxReport && mxReport.type === "y/n" && mxReport.input === "false" && Boolean(mxReport?.failedStatus)
    )

    // TODO: take the "rule" property into account when we have more than just a "changeStatus" rule

    const failureStatuses = failedReportsWithRules?.sort(
      (a, b) => statusIndex(a.failedStatus!) - statusIndex(b.failedStatus!)
    )

    if (failureStatuses?.length && failureStatuses?.[0]?.failedStatus) {
      const newStatus = stringToAssetStatus(failureStatuses[0].failedStatus)
      const changeStatus: AssetStatusChangeInput = { active: true, status: newStatus }

      report.statusChange = changeStatus

      return
    }
  })
}

export const getStatusBasedOnRules = (failedFields: AssetReportTemplateField[]) => {
  const failureStatuses = failedFields?.sort((a, b) => statusIndex(a.failedStatus!) - statusIndex(b.failedStatus!))

  if (failureStatuses.length && failureStatuses?.[0]?.failedStatus) {
    const newStatus = stringToAssetStatus(failureStatuses[0].failedStatus)

    return newStatus
  }
}

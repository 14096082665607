import {
  eachDayOfInterval,
  eachMonthOfInterval,
  eachWeekOfInterval,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from "date-fns"
import { DateRangeFilterString } from "../types"

export function generateGraphLabels(
  date: Date,
  dateRangeType: DateRangeFilterString,
  startDate: Date | null | undefined,
  endDate: Date | null | undefined
): string[] {
  switch (dateRangeType) {
    case "weekly":
      const startDaily = startOfWeek(date)
      const endDaily = endOfWeek(date)
      const days = eachDayOfInterval({ start: startDaily, end: endDaily })
      return days.map((day) => format(day, "MMM dd"))

    case "monthly":
      const startMonthly = startOfMonth(date)
      const endMonthly = endOfMonth(date)
      const weeks = eachWeekOfInterval({ start: startMonthly, end: endMonthly })
      return weeks.map((_, index) => `Week ${index + 1}`)

    case "all-time":
      const startOfAllTime = startDate || startOfYear(new Date())
      const endOfAllTime = endDate || endOfYear(new Date())
      const yearInterval = { start: startOfAllTime, end: endOfAllTime }
      const months = eachMonthOfInterval(yearInterval)
      return months.map((month) => format(month, "MMM yy"))

    default:
      return []
  }
}

export function mergeDataWithPlaceholders(
  chartData: any[],
  dateRangeType: DateRangeFilterString,
  rangeStart: Date,
  startDate: Date | null | undefined,
  endDate: Date | null | undefined
): any[] {
  const currentLabels = generateGraphLabels(rangeStart, dateRangeType, startDate, endDate)

  // Extract units from the provided chartData
  const allUnits = new Set<string>()
  chartData.forEach((data) => {
    Object.keys(data).forEach((key) => {
      if (key !== "name") {
        allUnits.add(key)
      }
    })
  })

  // Generate a default data structure for each label
  const defaultChartData = currentLabels.map((label) => {
    const defaultItem: any = { name: label }
    allUnits.forEach((unit) => {
      defaultItem[unit] = 0
    })
    return defaultItem
  })

  // Merge the placeholder data with the actual chartData
  return defaultChartData.map((defaultItem) => {
    const actualData = chartData.find((item) => item.name === defaultItem.name)
    return actualData || defaultItem
  })
}

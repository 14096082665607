import { FC } from "react"
import { BiLeftArrowAlt } from "react-icons/bi"

export const BackButton: FC = () => {
  return (
    <button
      className={
        "py-2 pr-4 pl-3 mb-4 inline-flex gap-2 items-center font-bold bg-gray-100 text-gray-800 rounded-md transition-colors hover:bg-gray-200"
      }
      onClick={() => {
        window.history.back()
      }}
      type="button"
    >
      <BiLeftArrowAlt className={"h-6 w-6"} />
      <span>Back</span>
    </button>
  )
}

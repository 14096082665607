import { isAfter } from "date-fns"
import { TimeEntry } from "../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../types/helpers"

export const canBeClockedIn = (
  latestTimeEntry: PickPlus<TimeEntry, "endAt"> | undefined | null,
  proposedStartTime: Date,
  backEndError?: string
) => {
  if (backEndError) return false
  if (!latestTimeEntry) return true

  if (latestTimeEntry.endAt) return isAfter(proposedStartTime, latestTimeEntry.endAt)

  return false
}

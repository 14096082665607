import { Typography } from "@mui/material"
import { FC, useContext } from "react"
import { BiSortAlt2 } from "react-icons/bi"
import { Asset } from "../../../../graphql/generated/client-types-and-hooks"
import { DrawerContext } from "../../../../providers/DrawerProvider"
import { QuickMenu } from "../../../QuickMenu"
import { MenuItem } from "../../../QuickMenuMui"
import { AssetGroupReassignmentFilterDefinition, ReassignAssetDrawer } from "../../Drawer/ReassignAssetDrawer"

export const QuantityCell: FC<{ asset: Asset }> = ({ asset }) => {
  const { push: pushDrawer } = useContext(DrawerContext)

  const menuItems: MenuItem[][] = [
    [
      {
        value: asset.assetGroupId || asset.assetChildCount > 1 ? "Reassign quantities" : "Reassign",
        Icon: BiSortAlt2,
        iconStyles: "rotate-90 w-5 h-5",
        onClick: () => {
          pushDrawer(
            <ReassignAssetDrawer
              assetsIdsToReassign={asset.assetGroupId ? [] : [asset?.id]}
              assetGroupToReassign={
                asset.assetGroupId
                  ? ({
                      ...asset,
                      count: asset.assetChildCount,
                      groupParent: asset,
                    } as AssetGroupReassignmentFilterDefinition)
                  : null
              }
            />
          )
        },
      },
    ],
  ]

  const isParentAsset = asset.isAssetGroup && !asset.assetGroupId
  const isChildAsset = !!asset.assetGroupId

  let text = (asset.groupQuantity || 1).toString()
  if (isChildAsset) text = asset.assetChildCount.toString()
  if (isParentAsset) text = `${asset.assetChildCount} / ${asset.groupQuantity || 1}`

  return (
    <QuickMenu
      items={menuItems}
      className="w-full mt-2 md:mt-0"
      menuButtonClassName="w-full text-left cursor-pointer p-2 bg-gray-100 rounded hover:bg-gray-200 hover:rounded transition-all"
    >
      <div className="bg-gray-100 w-full min-h-[40px] items-center rounded pl-1 flex bg-inherit">
        <Typography fontSize={16}>{text}</Typography>
      </div>
    </QuickMenu>
  )
}
